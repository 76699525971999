import React, { useEffect, useState } from "react";
import waitlistProgress from "../../../assets/WaitlistPageImages/waitlist_Progress.png";
import loading from "../../loaderComponent/loader";
import "./WaitlistProgress.css";

const WaitlistProgress = () => {
  const [progress, setProgress] = useState(0);
  const [direction, setDirection] = useState(1);
  useEffect(() => {
    const interval = setInterval(() => {
      if (direction === 1) {
        setProgress((prevProgress) =>
          prevProgress < 70 ? prevProgress + 1 : 70
        );
        if (progress === 70) {
          setDirection(-1);
        }
      } else {
        setProgress((prevProgress) =>
          prevProgress > 0 ? prevProgress - 1 : 0
        );
        if (progress === 0) {
          setDirection(1);
        }
      }
    }, 50);

    return () => {
      clearInterval(interval);
    };
  }, [progress, direction]);

  return (
    <>
      <section className="progress-sec">
        <div className="progress-div">
          <img
            src={waitlistProgress}
            alt="waitlistProgress"
            className="w-100 progress-img"
          />
          <div className="progress-content">
            <h5>OUR CODERS ARE WORKING</h5>
            <p className="mt-4">COMING SOON</p>
            <div className="progress-container-box mt-4">
              <div className="progress-container">
                <div
                  className="Pprogress-bar"
                  style={{
                    width: `${progress}%`,
                    transition: "width 0.6s ease",
                  }}
                ></div>
              </div>
              <div>
                <h6>{progress}%</h6>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WaitlistProgress;
