import React from "react";
import { Oval } from "react-loader-spinner";

const Loader = () => {
  return (
    <Oval
      visible={true}
      height="150"
      width="150"
      color="#014e7f"
      ariaLabel="oval-loading"
      wrapperStyle={{}}
      wrapperClass="loader-wrapper"
    />
  );
};

export default Loader;
