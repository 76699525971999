import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import GoalIcon1 from "../../assets/aboutusImages/goal_icon1.png";
import GoalIcon2 from "../../assets/aboutusImages/goal_icon2.png";

const OurGoals = () => {
    return (
        <div className='about_goal_main'>
            <Container>
                <h4>Our Goals</h4>
                <div className='about_goal_section'>
                    <Row className='justify-content-evenly' style={{gap: "15px 0"}}>
                        <Col md={2} xs={6}>
                            <div>
                                <img src={GoalIcon1} />
                                <h6>Lorem ipsum</h6>
                            </div>
                        </Col>
                        <Col md={2} xs={6}>
                            <div>
                                <img src={GoalIcon2} />
                                <h6>Lorem ipsum</h6>
                            </div>
                        </Col>
                        <Col md={2} xs={6}>
                            <div>
                                <img src={GoalIcon1} />
                                <h6>Lorem ipsum</h6>
                            </div>
                        </Col>
                        <Col md={2} xs={6}>
                            <div>
                                <img src={GoalIcon2} />
                                <h6>Lorem ipsum</h6>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default OurGoals