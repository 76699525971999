import React from 'react'
import Header from "../components/Header/header"
import TrendingArticlePage from "../components/TrendingArticlesComponents/TrendingArticles"
import Footer from "../components/Footer/footer"

const TrendingArticlesPage = () => {
  return (
    <div>
      <Header />
      <TrendingArticlePage />
      <Footer />
    </div>
  )
}

export default TrendingArticlesPage