import React from 'react'
import banner from './assets/mission-banner.svg'
import './bannermission.css'

const MissionVisionBanner = () => {
  return (
    <>
      <div className="mission-banner">
        <div className="main-mission-banner">
          <div className="div-1">
            <div className="banner-heading">
              <h1>
                WHAT'S <br /> OUR <span>MISSION ?</span>
              </h1>
              <p>
              ShifaLia’s mission is simple. We are working towards a Pakistan where access to healthcare is affordable to all, regardless of their financial status. In our endeavor, we depend on the generosity and philanthropy of the Pakistani public to help out their fellow Pakistanis so they can reach their financial goals to afford the healthcare they require.  
              </p>
            </div>
          </div>
          <div className="div-2">
            <img src={banner} alt="" />
          </div>
        </div>
      </div>
    </>
  );
}

export default MissionVisionBanner
