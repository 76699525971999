// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HeaderImg {
  position: relative;
  width: 100%;
  height: auto;
}

.HeaderImg img {
  width: 100%;
  height: auto;
}

.HeaderImgText {
  text-align: center;
  color: #ffffff;
  font-size: 2vw;
  font-weight: 600;
  font-family: "Poppins";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.h-row {
  padding-top: 25px;
}
@media (max-width: 600px) {
  .h-row {
    padding-top: 0px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/AllCasesPageComponents/HeaderImgSection/HeaderImgSection.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,cAAc;EACd,gBAAgB;EAChB,sBAAsB;EACtB,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;AAClC;AACA;EACE,iBAAiB;AACnB;AACA;EACE;IACE,2BAA2B;EAC7B;AACF","sourcesContent":[".HeaderImg {\n  position: relative;\n  width: 100%;\n  height: auto;\n}\n\n.HeaderImg img {\n  width: 100%;\n  height: auto;\n}\n\n.HeaderImgText {\n  text-align: center;\n  color: #ffffff;\n  font-size: 2vw;\n  font-weight: 600;\n  font-family: \"Poppins\";\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n.h-row {\n  padding-top: 25px;\n}\n@media (max-width: 600px) {\n  .h-row {\n    padding-top: 0px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
