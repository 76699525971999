import React from 'react'
import { Col, Form, Row } from 'react-bootstrap';
import gigImg from "../../assets/GigDescriptionImages/gig_title_main.jpg";
import gigSubImg from "../../assets/GigDescriptionImages/sub_img.jpg";

const Step2 = () => {
    return (
        <>
            <h3>Payment Details</h3>
            <p>Recheck your payment total</p>

            <div>
                <ul className="step_progressbar">
                    <li className="active"></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>

            <Row>
                <Col md={6}>
                    <div className='input_fields'>
                        <label>Gig Name</label>
                        <input placeholder='Gig Name' />
                    </div>

                    <div className='input_fields'>
                        <label>Price</label>
                        <input placeholder='Rs. 15,000' />
                    </div>

                    <div className='input_fields'>
                        <label>Gig Detail</label>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis egestas dolor diamLorem ipsum dolor sit amet, consectetur adipiscing elit. Duis egestas dolor diamLorem ipsum dolor sit amet, consectetur adipiscing elit. Duis egestas dolor diamLorem ipsum dolor sit amet, consectetur adipiscing elit. Duis egestas dolor diamLorem ipsum dolor sit amet, consectetur adipiscing elit. Duis egestas dolor diamLorem ipsum dolor sit amet, consectetur adipiscing elit. Duis egestas dolor diamLorem ipsum dolor sit amet, consectetur adipiscing elit. Duis egestas dolor diamLorem ipsum dolor sit amet, consectetur adipiscing elit. Duis egestas dolor diamLorem ipsum dolor sit amet, consectetur adipiscing elit. Duis egestas dolor diam</p>
                    </div>
                </Col>
                <Col md={6} className='payment_right_col'>
                    <img src={gigImg} alt='' />

                    <Row className='mt-3'>
                        <Col md={4} sm={4} xs={4}>
                            <img src={gigSubImg} alt='' />
                        </Col>
                        <Col md={4} sm={4} xs={4}>
                            <img src={gigSubImg} alt='' />
                        </Col>
                        <Col md={4} sm={4} xs={4}>
                            <img src={gigSubImg} alt='' />
                        </Col>
                    </Row>
                </Col>

                <Col md={12}>
                    <div className='additional_services'>
                        <h5>Additional Services</h5>

                        <div className='services_show'>
                            <div className='services_individual'>
                                <div>
                                    <Form.Check
                                        type={"checkbox"}
                                        label={`Additional  Service Name`}
                                    />
                                </div>
                                <h6>Rs. 2500</h6>
                            </div>
                            <div className='services_individual'>
                                <div>
                                    <Form.Check
                                        type={"checkbox"}
                                        label={`Additional  Service Name`}
                                    />
                                </div>
                                <h6>Rs. 2500</h6>
                            </div>
                            <div className='services_individual'>
                                <div>
                                    <Form.Check
                                        type={"checkbox"}
                                        label={`Additional  Service Name`}
                                    />
                                </div>
                                <h6>Rs. 2500</h6>
                            </div>
                            <div className='services_individual'>
                                <div>
                                    <Form.Check
                                        type={"checkbox"}
                                        label={`Additional  Service Name`}
                                    />
                                </div>
                                <h6>Rs. 2500</h6>
                            </div>
                        </div>

                        <div className='services_individual mt-4'>
                            <div>
                                <h6>Total</h6>
                            </div>
                            <h6>Rs. 2500</h6>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default Step2;