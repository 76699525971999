import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faChevronUp,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import "@fontsource/poppins";
import "./WaitlistFAQS.css";
import editIcon from "../../../assets/WaitlistPageImages/editIcon.svg";

const Accordion = ({ question, answer }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Row
        className="ContentRow"
        style={{
          paddingTop: "30px",
          border: "solid 2px #A8BAB8",
          margin: "12px",
          borderRadius: "10px",
          paddingTop: "20px",
          transition: " 1s ease-in-out",
        }}
      >
        <Col xs={1} md={1} className="text-center ">
          <img src={editIcon} alt="editIcon" />
        </Col>
        <Col xs={9} sm={9} md={10} lg={10}>
          <button
            className={`accordion-button ${show ? "active" : ""}`}
            type="button"
            onClick={() => setShow(!show)}
            style={{
              border: "none",
              background: "none",
              cursor: "pointer",
              transition: "transform 1s ease-in-out",
            }}
          >
            <h3
              className="QuestionsTxt"
              style={{
                fontSize: "20px",
                fontFamily: "Poppins",
                color: "#595858",
                fontWeight: "400",
                transition: "transform 1s ease-in-out",
              }}
            >
              {question}
            </h3>
          </button>

          <p
            className="AnswersTxt"
            style={{
              fontSize: "15px",
              fontFamily: "Poppins",
              color: "#014E7F",
              maxHeight: show ? "500px" : "0",
              overflow: "hidden",
              transition: "max-height 0.9s ease",
            }}
          >
            {" "}
            <p
              style={{
                borderTop: "2px solid rgba(123, 125, 124, 0.27)",
                margin: "20px 0px 20px 0px",
                transition: "transform 1s ease-in-out",
              }}
            ></p>
            {answer}
          </p>
        </Col>
        <Col xs={1} md={1} className="text-center">
          <p style={{ cursor: "pointer" }} onClick={() => setShow(!show)}>
            {show ? (
              <FontAwesomeIcon
                icon={faChevronUp}
                style={{
                  color: "#595858",
                }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faChevronDown}
                style={{
                  color: "#595858",
                }}
              />
            )}
          </p>
        </Col>
      </Row>
    </>
  );
};
export default Accordion;
