import React from "react";
import { Col, Row, Container } from 'react-bootstrap';
import HeaderImgSection from "../HeaderImgSection/HeaderImgSection";
import PopularArticlesSection from "../PopularArticlesSection/PopularArticlesSection"
import AllArticlesSection from "../AllArticlesSection/AllArticlesSection";
import ReadyToStartSection from "../../AllCasesPageComponents/ReadyToStartSection/ReadyToStartSection"
import "./BlogPageMainComp.css"

const BlogPageMainComp = () => {
    return (
        <>
            <section className="BlogPageMainComp">
                <HeaderImgSection />
                <Container>
                    <Container>
                        <Row>
                            <PopularArticlesSection />
                        </Row>
                        <Row>
                            <AllArticlesSection />
                        </Row>
                        <Row>
                            <ReadyToStartSection />
                        </Row>
                    </Container>
                </Container>

            </section>
        </>
    )
}

export default BlogPageMainComp