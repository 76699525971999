import React, { useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import "./Accordion.css";
import FAQs from "../../WaitListPageComponents/WaitlistFAQS/WaitlistFAQS";
import FaqData from "../FAQSection/FAQs.json";
const FAQSection = () => {
  return (
    <>
      <Container className="GoalCalculatorContainer d-flex mt-5 justify-content-center align-items-center">
        <Row className="d-flex justify-content-center align-items-center">
          <h4 className="goalCal_heading">Frequently Asked Questions</h4>
          <hr className="goalCal_line mt-2" />
          <p className="goalCal_p px-5">
            Answers to commonly asked questions can be found below.<br></br> If
            you have any specific inquiries, please don't hesitate<br></br> to
            reach out to us.
          </p>
        </Row>
      </Container>
      <FAQs faqData={FaqData} hideelement="d-none" />
      <p className="text-center mb-5">
        *ShifaLia’s promotional services incur extra costs for the Campaigner.{" "}
      </p>
    </>
  );
};

export default FAQSection;
