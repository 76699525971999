import React, { useState } from "react";
import WaitlistHeader from "../WaitlistHeader/WaitlistHeader";
import Toast from "../../../functions/Toast";
import "./WaitlistBanner.css";
import { Button } from "react-bootstrap";
import waitlist_bannerImg from "../../../assets/WaitlistPageImages/bannerW.webp";
import { useMutation } from "@apollo/client";
import { WAITLIST_MUTATION } from "../../../services/methods/Mutations";
import { isEmailValid } from "../../..//services/helper/helper.js";
import { useApi } from "../../../services/mutation&Query/GraphqlApi";
import { Container } from "reactstrap";
import Waitlistmodal2 from "../../../components/Modals/WaitlistModals/Modal2/Waitlistmodal2";

const WaitlistBanner = () => {
  const [email, setEmail] = useState("");
  const [ModalDone, setModalDone] = useState(false);
  const [waitList] = useMutation(WAITLIST_MUTATION);
  const [isValid, setIsValid] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);

  const { subscribeToWaitlist } = useApi();
  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setIsValid(isEmailValid(newEmail));
    setIsEmpty(newEmail.trim() === "");
  };
  const handleSubscribe = () => {
    const inputData = {
      email: email,
    };
    if (email !== "") {
      subscribeToWaitlist(waitList, inputData);
      setEmail("");
      setModalDone(true);
    } else {
      Toast.error("Fill the correct email address");
    }
  };

  const closeModal = () => {
    setModalDone(false);
  };
  return (
    <>
      <section>
        <div className="banner-top">
          <div className="banner-img">
            <img
              src={waitlist_bannerImg}
              alt="waitlist_bannerImg"
              className="waitlist_bannerImg "
            ></img>
          </div>
          <div className="header">
            <WaitlistHeader />
          </div>

          <div className="banner-content col-lg-6 col-md-9 col-sm-12 col-xs-12 ">
            <h4>Want to be a lifesaver?</h4>
            <p>
              <span>Sign</span> <span> Up</span> <span> Now</span>{" "}
              <span> For</span> <span> ShifaLia’s</span> <span> Waitlist</span>{" "}
              <span> And</span> <span> Help</span> <span> People</span>{" "}
              <span> In</span> <span> Need</span> <span> Through</span>{" "}
              <span> Healthcare</span> <span> Financing.</span>{" "}
            </p>
            <div className="mt-4 input-email">
              <input
                type="email"
                placeholder="Enter your Email"
                className="input-Email"
                value={email}
                onChange={handleEmailChange}
              ></input>
              <Button onClick={handleSubscribe} disabled={!isValid}>
                Join Waitlist
              </Button>
              {!isValid && (
                <p style={{ color: "red" }}>
                  {isEmpty ? "" : "Please enter a valid email address."}
                </p>
              )}
            </div>
          </div>
        </div>
        {ModalDone && (
          <Waitlistmodal2 isOpen={ModalDone} onClose={closeModal} />
        )}
      </section>
    </>
  );
};

export default WaitlistBanner;
