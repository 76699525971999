import React from "react";
import StartFundraisingImg1 from "../../../assets/images/StartFundraisingImg1.png";
import StartFundraisingImg2 from "../../../assets/images/StartFundraisingImg2.png";
import "./startfund.scss";
import { useNavigate } from "react-router-dom";

const Startfund = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="startfund-section">
        <div className="readtostart-section">
          <div className="left-div">
            <h6>Ready to start your own Campaign?</h6>
            <button onClick={() => navigate("/starFundarisingForm")}>Start Campaigning</button>
          </div>
          <div className="rightdiv">
            <img src={StartFundraisingImg1} alt="" />
          </div>
        </div>
        <div className="confusedabtFund-section">
          <h6>Confused about how to start a Campaign?</h6>
          <div className="btn-div">
            <button onClick={() => navigate("/starFundarisingForm")}>Check Here</button>
            <img src={StartFundraisingImg2} alt="" className="rightimg"/>
          </div>
        </div>
      </div>
    </>
  );
};

export default Startfund;
