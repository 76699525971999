import React from 'react';
import Header from "../components/Header/header";
import Footer from "../components/Footer/footer";
import Banner from '../components/TermsOfUseComponents/Banner/Banner';
import Description from '../components/TermsOfUseComponents/Description/Description';

const TermsOfUse = () => {
    return (
        <div>
            <Header />
            <Banner />
            <Description />
            <Footer />
        </div>
    )
}

export default TermsOfUse