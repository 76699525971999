import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Heading, Line, TitleWrap, Section } from "./OurGoalsElement";
import { Button } from "../../ButtonElement";
import ourGoalImg from "../../../assets/images/our_goal_right_img.png";
import "./OurGoals.css";
import { useNavigate } from "react-router-dom";

const OurGoals = () => {
  const navigate = useNavigate();
  return (
    <Section style={{ overflowX: "hidden" }} className="our_goal_section">
      <Row className="pt-5 px-4">
        <Col className="mb-5" lg={6}>
          <Container className="px-5">
            <Row>
              <Col>
                <TitleWrap>
                  <Line></Line>
                  <Heading>OUR GOAL</Heading>
                </TitleWrap>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col lg={12}>
                <h1>
                  ShifaLia aims to create a Pakistan where healthcare is not
                  limited by a person’s financial status.
                </h1>
              </Col>
              <Col lg={12}>
                <p>
                  We believe that healthcare is an integral right that must not
                  be dependent on financial limitations. This is why we aim to
                  provide a platform where people can start their own Campaigns
                  and have supporters back them financially and help them
                  achieve their financial goal.
                </p>
              </Col>
              <Col>
                <Button
                  onClick={() => navigate("/about-us")}
                  backgroundColor="#B5D5D4"
                  textColor="#595858"
                >
                  learn more about us.
                </Button>
              </Col>
            </Row>
          </Container>
        </Col>
        <Col lg={6} className="">
          <img src={ourGoalImg} alt="" style={{ maxWidth: "100%" }} />
        </Col>
      </Row>
    </Section>
  );
};

export default OurGoals;
