import AllArticlesImg1 from "../../assets/images/AllArticlesImg1.png"
import AllArticlesImg2 from "../../assets/images/AllArticlesImg2.png"
import AllArticlesImg3 from "../../assets/images/AllArticlesImg3.png"
import AllArticlesImg4 from "../../assets/images/AllArticlesImg4.png"
import AllArticlesImg5 from "../../assets/images/AllArticlesImg5.png"
import AllArticlesImg6 from "../../assets/images/AllArticlesImg6.png"
import userDp from "../../assets/images/UserDp.png"

const PopularArticlesData=[
    {
        id: 1,
        ImgUrl: AllArticlesImg1,
        ArticleDate: '10-Aug-2023',
        ArticleTitle: 'Al contrario del pensamiento popular, el texto de Lorem Ipsum no es simplemente',
        UserDpImg: userDp,
        ArticleAuthorName: 'username',
    },
    {
        id: 2,
        ImgUrl: AllArticlesImg2,
        ArticleDate: '10-Aug-2023',
        ArticleTitle: 'Al contrario del pensamiento popular, el texto de Lorem Ipsum no es simplemente',
        UserDpImg: userDp,
        ArticleAuthorName: 'username',
    },
    {
        id: 3,
        ImgUrl: AllArticlesImg3,
        ArticleDate: '10-Aug-2023',
        ArticleTitle: 'Al contrario del pensamiento popular, el texto de Lorem Ipsum no es simplemente',
        UserDpImg: userDp,
        ArticleAuthorName: 'username',
    },
    {
        id: 4,
        ImgUrl: AllArticlesImg4,
        ArticleDate: '10-Aug-2023',
        ArticleTitle: 'Al contrario del pensamiento popular, el texto de Lorem Ipsum no es simplemente',
        UserDpImg: userDp,
        ArticleAuthorName: 'username',
    },
    {
        id: 5,
        ImgUrl: AllArticlesImg5,
        ArticleDate: '10-Aug-2023',
        ArticleTitle: 'Al contrario del pensamiento popular, el texto de Lorem Ipsum no es simplemente',
        UserDpImg: userDp,
        ArticleAuthorName: 'username',
    },
    {
        id: 6,
        ImgUrl: AllArticlesImg6,
        ArticleDate: '10-Aug-2023',
        ArticleTitle: 'Al contrario del pensamiento popular, el texto de Lorem Ipsum no es simplemente',
        UserDpImg: userDp,
        ArticleAuthorName: 'username',
    },
]

export default PopularArticlesData