import React from "react";
import "./loader.css";

const DefaultLoader = () => {

    return (
        <>
            <section className="loader-section">
                <div className="loader_">
                    <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72" fill="none">
                        <circle cx="36" cy="11" r="11" fill="#457B6D" className="c1" />
                        <circle cx="11" cy="36" r="11" fill="#02627B" className="c2" />
                        <circle cx="36" cy="61" r="11" fill="#E2CDA6" className="c3" />
                        <circle cx="61" cy="36" r="11" fill="#B5D5D4" className="c4" />
                    </svg>
                </div>
            </section>
        </>
    );
};

export default DefaultLoader;
