import React from "react";
import "./GigDescription.css";
import { Col, Container, Row } from "react-bootstrap";
import gigImg from "../../assets/GigDescriptionImages/gig_title_main.jpg";
import gigSubImg from "../../assets/GigDescriptionImages/sub_img.jpg";
import tickIcon from "../../assets/GigDescriptionImages/tick_icon.png";
import msgIcon from "../../assets/GigDescriptionImages/msg_icon.png";
import reviewImg from "../../assets/GigDescriptionImages/review_img.png";
import { Accordion } from "react-bootstrap";
import { GET_SERVICE_BY_ID } from "../../services/methods/Query";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import Slider from "react-slick";
import Loader from "../Loader/Loader";

const GigDescriptionComponent = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const params = useParams();
  const { data, loading } = useQuery(GET_SERVICE_BY_ID, {
    variables: {
      getMyServicesByIdId: params?.id,
    },
  });
  const service = data?.getMyServicesByID?.data;
  return (
    <div className="gig_desciption_main">
      <Container className="py-5">
        {loading ? (
          <Loader />
        ) : (
          <>
            <Row>
              <Col md={6}>
                <div>
                  <h3>{service?.title}</h3>

                  <div className="d-flex justify-content-between align-items-center">
                    <h6>{service?.userDetails?.firstName}</h6>
                    <h6>2 orders in queue</h6>
                  </div>
                </div>
              </Col>
            </Row>

            <div className="gig_details">
              <Row className="justify-content-between">
                <Col md={6}>
                  <Slider {...settings}>
                    {service?.photoLinks?.map((item) => (
                      <div>
                        <img src={item} alt="" />
                      </div>
                    ))}
                    {/* <div>
                  <img src={service?.photoLinks[1]} alt="" />
                </div>
                <div>
                  <img src={service?.photoLinks[2]} alt="" />
                </div> */}
                  </Slider>
                </Col>
                <Col md={6}>
                  <div className="about_gig">
                    <div>
                      <p>About this Gig</p>
                      <p>Rs. {service?.amount}</p>
                    </div>

                    <h6>Includes:</h6>

                    <ul>
                      {service?.features?.map((item) => (
                        <li>
                          <img src={tickIcon} alt="" />
                          <p>{item}</p>
                        </li>
                      ))}
                      {/* <li>
                    <img src={tickIcon} alt="" />
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sed{" "}
                    </p>
                  </li>
                  <li>
                    <img src={tickIcon} alt="" />
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sed{" "}
                    </p>
                  </li>
                  <li>
                    <img src={tickIcon} alt="" />
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sed{" "}
                    </p>
                  </li>
                  <li>
                    <img src={tickIcon} alt="" />
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sed{" "}
                    </p>
                  </li> */}
                    </ul>

                    <div className="mb-0">
                      <button>Buy</button>

                      <img src={msgIcon} alt="" />
                    </div>
                  </div>
                </Col>

                <Col md={6}>
                  <div className="mt-5">
                    <h3>Gig's Description</h3>
                    <p>{service?.description}</p>
                  </div>

                  <div>
                    <h3>Reviews</h3>

                    <div className="reviews_main">
                      <h6>12 reviews</h6>
                      <div className="mt-3">
                        <p>5 stars</p>
                        <span></span>
                        <p>(09)</p>
                      </div>
                      <div>
                        <p>4 stars</p>
                        <span></span>
                        <p>(02)</p>
                      </div>
                      <div>
                        <p>3 stars</p>
                        <span></span>
                        <p>(01)</p>
                      </div>
                      <div>
                        <p>2 stars</p>
                        <span></span>
                        <p>(0)</p>
                      </div>
                      <div>
                        <p>1 stars</p>
                        <span></span>
                        <p>(0)</p>
                      </div>
                    </div>

                    <div className="review_div mt-4 left_review">
                      <div>
                        <div className="review_user_name">
                          <div>
                            <img src={reviewImg} alt="" />
                            <div>
                              <h6>Username</h6>
                              <p>1 day ago</p>
                            </div>
                          </div>

                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M7.32831 19.923L8.56831 14.61L4.44531 11.038L9.87631 10.568L12.0033 5.55701L14.1303 10.567L19.5603 11.037L15.4383 14.609L16.6783 19.922L12.0033 17.102L7.32831 19.923Z"
                                fill="#F8F3EA"
                              />
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M7.32831 19.923L8.56831 14.61L4.44531 11.038L9.87631 10.568L12.0033 5.55701L14.1303 10.567L19.5603 11.037L15.4383 14.609L16.6783 19.922L12.0033 17.102L7.32831 19.923Z"
                                fill="#F8F3EA"
                              />
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M7.32831 19.923L8.56831 14.61L4.44531 11.038L9.87631 10.568L12.0033 5.55701L14.1303 10.567L19.5603 11.037L15.4383 14.609L16.6783 19.922L12.0033 17.102L7.32831 19.923Z"
                                fill="#F8F3EA"
                              />
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M7.32831 19.923L8.56831 14.61L4.44531 11.038L9.87631 10.568L12.0033 5.55701L14.1303 10.567L19.5603 11.037L15.4383 14.609L16.6783 19.922L12.0033 17.102L7.32831 19.923Z"
                                fill="#F8F3EA"
                              />
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M7.32831 19.923L8.56831 14.61L4.44531 11.038L9.87631 10.568L12.0033 5.55701L14.1303 10.567L19.5603 11.037L15.4383 14.609L16.6783 19.922L12.0033 17.102L7.32831 19.923Z"
                                fill="#F8F3EA"
                              />
                            </svg>
                          </div>
                        </div>
                        <p className="mt-2">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Nullam quis pellentesque sem. Duis elementum
                          luctus justo, quis posuere magna facilisis nec. Donec
                          quis ipsum ut nunc vulputate fermentum at nec dolor.
                          Praesent at nibh vitae tortor maximus volutpat. Morbi
                          ut risus sed ante condimentum aliquam eget vitae est.
                        </p>
                      </div>
                    </div>
                    <div className="review_div mt-4 left_review">
                      <div>
                        <div className="review_user_name">
                          <div>
                            <img src={reviewImg} alt="" />
                            <div>
                              <h6>Username</h6>
                              <p>1 day ago</p>
                            </div>
                          </div>

                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M7.32831 19.923L8.56831 14.61L4.44531 11.038L9.87631 10.568L12.0033 5.55701L14.1303 10.567L19.5603 11.037L15.4383 14.609L16.6783 19.922L12.0033 17.102L7.32831 19.923Z"
                                fill="#F8F3EA"
                              />
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M7.32831 19.923L8.56831 14.61L4.44531 11.038L9.87631 10.568L12.0033 5.55701L14.1303 10.567L19.5603 11.037L15.4383 14.609L16.6783 19.922L12.0033 17.102L7.32831 19.923Z"
                                fill="#F8F3EA"
                              />
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M7.32831 19.923L8.56831 14.61L4.44531 11.038L9.87631 10.568L12.0033 5.55701L14.1303 10.567L19.5603 11.037L15.4383 14.609L16.6783 19.922L12.0033 17.102L7.32831 19.923Z"
                                fill="#F8F3EA"
                              />
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M7.32831 19.923L8.56831 14.61L4.44531 11.038L9.87631 10.568L12.0033 5.55701L14.1303 10.567L19.5603 11.037L15.4383 14.609L16.6783 19.922L12.0033 17.102L7.32831 19.923Z"
                                fill="#F8F3EA"
                              />
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M7.32831 19.923L8.56831 14.61L4.44531 11.038L9.87631 10.568L12.0033 5.55701L14.1303 10.567L19.5603 11.037L15.4383 14.609L16.6783 19.922L12.0033 17.102L7.32831 19.923Z"
                                fill="#F8F3EA"
                              />
                            </svg>
                          </div>
                        </div>
                        <p className="mt-2">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Nullam quis pellentesque sem. Duis elementum
                          luctus justo, quis posuere magna facilisis nec. Donec
                          quis ipsum ut nunc vulputate fermentum at nec dolor.
                          Praesent at nibh vitae tortor maximus volutpat. Morbi
                          ut risus sed ante condimentum aliquam eget vitae est.
                        </p>
                      </div>
                    </div>
                    <div className="review_div mt-4 left_review">
                      <div>
                        <div className="review_user_name">
                          <div>
                            <img src={reviewImg} alt="" />
                            <div>
                              <h6>Username</h6>
                              <p>1 day ago</p>
                            </div>
                          </div>

                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M7.32831 19.923L8.56831 14.61L4.44531 11.038L9.87631 10.568L12.0033 5.55701L14.1303 10.567L19.5603 11.037L15.4383 14.609L16.6783 19.922L12.0033 17.102L7.32831 19.923Z"
                                fill="#F8F3EA"
                              />
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M7.32831 19.923L8.56831 14.61L4.44531 11.038L9.87631 10.568L12.0033 5.55701L14.1303 10.567L19.5603 11.037L15.4383 14.609L16.6783 19.922L12.0033 17.102L7.32831 19.923Z"
                                fill="#F8F3EA"
                              />
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M7.32831 19.923L8.56831 14.61L4.44531 11.038L9.87631 10.568L12.0033 5.55701L14.1303 10.567L19.5603 11.037L15.4383 14.609L16.6783 19.922L12.0033 17.102L7.32831 19.923Z"
                                fill="#F8F3EA"
                              />
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M7.32831 19.923L8.56831 14.61L4.44531 11.038L9.87631 10.568L12.0033 5.55701L14.1303 10.567L19.5603 11.037L15.4383 14.609L16.6783 19.922L12.0033 17.102L7.32831 19.923Z"
                                fill="#F8F3EA"
                              />
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M7.32831 19.923L8.56831 14.61L4.44531 11.038L9.87631 10.568L12.0033 5.55701L14.1303 10.567L19.5603 11.037L15.4383 14.609L16.6783 19.922L12.0033 17.102L7.32831 19.923Z"
                                fill="#F8F3EA"
                              />
                            </svg>
                          </div>
                        </div>
                        <p className="mt-2">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Nullam quis pellentesque sem. Duis elementum
                          luctus justo, quis posuere magna facilisis nec. Donec
                          quis ipsum ut nunc vulputate fermentum at nec dolor.
                          Praesent at nibh vitae tortor maximus volutpat. Morbi
                          ut risus sed ante condimentum aliquam eget vitae est.
                        </p>
                      </div>
                    </div>

                    <div className="load_more_btn">
                      <button>Load More</button>
                    </div>
                  </div>
                </Col>

                <Col md={6}>
                  <div className="mt-5 review_main">
                    <h3>Reviews</h3>

                    <Slider {...settings}>
                      <div className="review_div mt-4">
                        <div>
                          <div className="review_user_name">
                            <div>
                              <img src={reviewImg} alt="" />
                              <div>
                                <h6>Username</h6>
                                <p>1 day ago</p>
                              </div>
                            </div>

                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M7.32831 19.923L8.56831 14.61L4.44531 11.038L9.87631 10.568L12.0033 5.55701L14.1303 10.567L19.5603 11.037L15.4383 14.609L16.6783 19.922L12.0033 17.102L7.32831 19.923Z"
                                  fill="#F8F3EA"
                                />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M7.32831 19.923L8.56831 14.61L4.44531 11.038L9.87631 10.568L12.0033 5.55701L14.1303 10.567L19.5603 11.037L15.4383 14.609L16.6783 19.922L12.0033 17.102L7.32831 19.923Z"
                                  fill="#F8F3EA"
                                />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M7.32831 19.923L8.56831 14.61L4.44531 11.038L9.87631 10.568L12.0033 5.55701L14.1303 10.567L19.5603 11.037L15.4383 14.609L16.6783 19.922L12.0033 17.102L7.32831 19.923Z"
                                  fill="#F8F3EA"
                                />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M7.32831 19.923L8.56831 14.61L4.44531 11.038L9.87631 10.568L12.0033 5.55701L14.1303 10.567L19.5603 11.037L15.4383 14.609L16.6783 19.922L12.0033 17.102L7.32831 19.923Z"
                                  fill="#F8F3EA"
                                />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M7.32831 19.923L8.56831 14.61L4.44531 11.038L9.87631 10.568L12.0033 5.55701L14.1303 10.567L19.5603 11.037L15.4383 14.609L16.6783 19.922L12.0033 17.102L7.32831 19.923Z"
                                  fill="#F8F3EA"
                                />
                              </svg>
                            </div>
                          </div>
                          <p className="mt-2">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Nullam quis pellentesque sem. Duis elementum
                            luctus justo, quis posuere magna facilisis nec.
                            Donec quis ipsum ut nunc vulputate fermentum at nec
                            dolor. Praesent at nibh vitae tortor maximus
                            volutpat. Morbi ut risus sed ante condimentum
                            aliquam eget vitae est.
                          </p>
                        </div>
                      </div>
                      <div className="review_div mt-4">
                        <div>
                          <div className="review_user_name">
                            <div>
                              <img src={reviewImg} alt="" />
                              <div>
                                <h6>Username</h6>
                                <p>1 day ago</p>
                              </div>
                            </div>

                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M7.32831 19.923L8.56831 14.61L4.44531 11.038L9.87631 10.568L12.0033 5.55701L14.1303 10.567L19.5603 11.037L15.4383 14.609L16.6783 19.922L12.0033 17.102L7.32831 19.923Z"
                                  fill="#F8F3EA"
                                />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M7.32831 19.923L8.56831 14.61L4.44531 11.038L9.87631 10.568L12.0033 5.55701L14.1303 10.567L19.5603 11.037L15.4383 14.609L16.6783 19.922L12.0033 17.102L7.32831 19.923Z"
                                  fill="#F8F3EA"
                                />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M7.32831 19.923L8.56831 14.61L4.44531 11.038L9.87631 10.568L12.0033 5.55701L14.1303 10.567L19.5603 11.037L15.4383 14.609L16.6783 19.922L12.0033 17.102L7.32831 19.923Z"
                                  fill="#F8F3EA"
                                />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M7.32831 19.923L8.56831 14.61L4.44531 11.038L9.87631 10.568L12.0033 5.55701L14.1303 10.567L19.5603 11.037L15.4383 14.609L16.6783 19.922L12.0033 17.102L7.32831 19.923Z"
                                  fill="#F8F3EA"
                                />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M7.32831 19.923L8.56831 14.61L4.44531 11.038L9.87631 10.568L12.0033 5.55701L14.1303 10.567L19.5603 11.037L15.4383 14.609L16.6783 19.922L12.0033 17.102L7.32831 19.923Z"
                                  fill="#F8F3EA"
                                />
                              </svg>
                            </div>
                          </div>
                          <p className="mt-2">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Nullam quis pellentesque sem. Duis elementum
                            luctus justo, quis posuere magna facilisis nec.
                            Donec quis ipsum ut nunc vulputate fermentum at nec
                            dolor. Praesent at nibh vitae tortor maximus
                            volutpat. Morbi ut risus sed ante condimentum
                            aliquam eget vitae est.
                          </p>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </Col>
              </Row>
            </div>
          </>
        )}
      </Container>
    </div>
  );
};

export default GigDescriptionComponent;
