import React from "react";
import Helpcenterpage from "../components/HelpComponent/helpcenter";
import Header from "../components/Header/header";
import Footer from "../components/Footer/footer";

function HelpCenter() {
  return (
    <div>
      <Header />
      <Helpcenterpage />
      <Footer />
    </div>
  );
}

export default HelpCenter;
