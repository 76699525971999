import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const BannerSection = () => {
    return (
        <div className='about_banner_main'>
            <Container>
                <div className='about_heading'>
                    <h4>About Us</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                </div>
            </Container>

            <div className='banner_div'>
                <Container>
                    <p>Lorem ipsum dolor sit amet. At consequatur cumque et asperiores neque eum
                        dolorem facere. Sit ipsam quia et omnis odit id quidem harum et illo
                        accusantium eum alias nemo et voluptatem perferendis. Lorem ipsum dolor sit amet. </p>
                </Container>
            </div>

            <div className='banner_footer_content'>
                <Container>
                    <Row>
                        <Col md={5}>
                            <p>Lorem ipsum dolor sit amet. At consequatur cumque et asperiores neque eum dolorem facere. Sit ipsam quia et omnis odit id quidem harum et illo accusantium eum alias nemo et voluptatem perferendis. Lorem ipsum dolor sit amet. At consequatur cumque et asperiores neque eum dolorem facere.  Lorem ipsum dolor sit amet. </p>
                        </Col>
                        <Col md={2} className='d-flex justify-content-center'>
                            <div></div>
                        </Col>
                        <Col md={5}>
                            <p>Lorem ipsum dolor sit amet. At consequatur cumque et asperiores neque eum dolorem facere. Sit ipsam quia et omnis odit id quidem harum et illo accusantium eum alias nemo et voluptatem perferendis. Lorem ipsum dolor sit amet. At consequatur cumque et asperiores neque eum dolorem facere.  Lorem ipsum dolor sit amet. </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default BannerSection