import React, { useRef, useState } from "react";
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import ImgIcon from "../../assets/FundraisingTipsImages/imageIcon.png";
import { useNavigate } from "react-router-dom";
import { postNewServiceData } from "../../services/http/newServiceApi";
import Toast from "../../functions/Toast";
import { ThreeDots } from "react-loader-spinner";

const Step5 = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [photoLinks, setphotoLinks] = useState([]);
  const [phoneNumber, setphoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState("");
  const [features, setFeatures] = useState([]);
  const [featVal, setFeatVal] = useState("");
  const [alternatePhone, setAlternatePhone] = useState("");
  const [imagesPreview, setImagesPreview] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    fileInputRef.current?.click();
  };
  const handleAdd = (e) => {
    e.preventDefault();
    if (featVal == "") {
      return;
    }
    setFeatVal("");
    setFeatures([...features, featVal]);
  };

  const navigateHandler = async () => {
    if (
      photoLinks.length <= 0 ||
      title == "" ||
      description == "" ||
      phoneNumber == "" ||
      email == "" ||
      alternatePhone == "" ||
      amount == "" ||
      features.length <= 0
    ) {
      Toast.error("Please provide all fields");
      return;
    }
    const formData = new FormData();

    formData.append("title", title);
    formData.append("description", description);
    for (let i = 0; i < photoLinks?.length; i++) {
      formData.append("photoLinks", photoLinks[i]);
    }
    formData.append("phoneNumber", phoneNumber);
    formData.append("email", email);
    formData.append("alternatePhone", alternatePhone);
    formData.append("amount", Number(amount));
    formData.append("features", alternatePhone);

    try {
      setLoading(true);
      const res = await postNewServiceData(formData);
      setLoading(false);
      if (res?.data?.success) {
        Toast.success("Created");
        navigate("/previewPage");
      }
    } catch (error) {
      setLoading(false);
      Toast.error("Something went wrong");
    }
  };

  const handleImage = (e) => {
    setphotoLinks(e.target.files);
    const files = Array.from(e.target.files);

    setImagesPreview([]);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImagesPreview((old) => [...old, reader.result]);
        }
      };

      reader.readAsDataURL(file);
    });
  };

  return (
    <div className="fundraising_step5_main">
      <Container>
        <div className="change_lang">
          <Dropdown>
            <Dropdown.Toggle>language</Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item>English</Dropdown.Item>
              <Dropdown.Item>Urdu</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="step5_form">
          <div>
            <label>Service Title</label>
            <input
              disabled={loading}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div>
            <label>Service Description</label>
            <textarea
              disabled={loading}
              rows={5}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div>
            <label>Upload Photos that describe your service</label>

            <Row style={{ gap: "10px 0" }}>
              <Col md={12}>
                <div className="upload_box">
                  <img src={ImgIcon} alt="" />
                  <p>Drag a photo or</p>
                  <button onClick={handleClick}>Browse</button>
                  <input
                    disabled={loading}
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    multiple
                    onChange={handleImage}
                  />
                </div>
                <div id="imagePreview">
                  {imagesPreview.map((image, index) => (
                    <img
                      id="img"
                      key={index}
                      src={image}
                      alt="Product Preview"
                    />
                  ))}
                </div>
              </Col>
              {/* <Col md={4}>
                <div className="upload_box">
                  <img src={ImgIcon} alt="" />
                  <p>Drag a photo or</p>
                  <button onClick={handleClick}>Browse</button>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    multiple
                    onChange={(e) => setphotoLinks(e.target.files)}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="upload_box">
                  <img src={ImgIcon} alt="" />
                  <p>Drag a photo or</p>
                  <button onClick={handleClick}>Browse</button>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    multiple
                    onChange={(e) => setphotoLinks(e.target.files)}
                  />
                </div>
              </Col> */}
            </Row>
          </div>

          <div>
            <label>Contact Information</label>

            <Row style={{ gap: "20px 0" }}>
              <Col md={4}>
                <input
                  disabled={loading}
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setphoneNumber(e.target.value)}
                />
              </Col>
              <Col md={4}>
                <input
                  disabled={loading}
                  placeholder="Alternate Phone Number"
                  value={alternatePhone}
                  onChange={(e) => setAlternatePhone(e.target.value)}
                />
              </Col>
              <Col md={4}>
                <input
                  type="number"
                  disabled={loading}
                  placeholder="Amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </Col>
              <Col md={12}>
                <input
                  disabled={loading}
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Col>
              <Col md={12} style={{ display: "flex", gap: "5px" }}>
                <input
                  disabled={loading}
                  placeholder="Features"
                  value={featVal}
                  onChange={(e) => setFeatVal(e.target.value)}
                />
                <button
                  onClick={handleAdd}
                  style={{
                    borderRadius: "5px",
                    padding: "10px",
                    backgroundColor: "#afc8c9",
                    border: "none",
                  }}
                >
                  Add
                </button>
              </Col>
            </Row>
            {features.length > 0 && (
              <div>
                {features.map((item) => (
                  <li>{item}</li>
                ))}
              </div>
            )}
          </div>

          <div className="done_btn">
            {loading ? (
              <button>
                <ThreeDots
                  visible={true}
                  height="20"
                  width="30"
                  color="#FFFFFF  "
                  radius="9"
                  ariaLabel="three-dots-loading"
                />
              </button>
            ) : (
              <button onClick={navigateHandler}>Done</button>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Step5;
