import React from "react";
import docImg from "../../../assets/casesImgs/docImg.png";
import "./doc.scss";

const Patientdoccard = ({ url }) => {
  return (
    <div className="doc-card">
      <img src={url} alt="docImg" />
      <h6 className="mt-3 mx-3">Document Name</h6>
      <a href={url} download="doc" className="my-3 mx-3">
        Download
      </a>
    </div>
  );
};

export default Patientdoccard;
