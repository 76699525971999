import React,{useState} from "react";
import Waitlistmodal from "../components/Modals/WaitlistModals/Modal1/Waitlistmodal";
import Waitlistmodal2 from "../components/Modals/WaitlistModals/Modal2/Waitlistmodal2";
import Waitlistmodal3 from "../components/Modals/WaitlistModals/Modal3/Waitlistmodal3";
//import WaitlistPage from "../components/Waitlist/WaitlistPage";

function Waitlist() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div>
      <button onClick={openModal}>Open Modal</button>
      {/* <Waitlistmodal3  isOpen={isModalOpen} onClose={closeModal}/> */}
      <Waitlistmodal isOpen={isModalOpen} onClose={closeModal}/>
      {/* <Waitlistmodal2 isOpen={isModalOpen} onClose={closeModal}/> */}
    </div>
  );
}

export default Waitlist;
