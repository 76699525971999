import React from "react";
import { Col, Row, Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Facebook from "../../../assets/images/FacebookIcon.png";
import Whatsapp from "../../../assets/images/WhatsappIcon.png";
import ContactUsWave from "../../../assets/images/ContactUsWave.png";
import "./ContactUsSection.css";

const ContactUsSection = () => {
  return (
    <>
      <section style={{ backgroundColor: "#F8F3EA" }}>
        <img
          src={ContactUsWave}
          alt={ContactUsWave}
          className="w-100 pt-4"
        ></img>
        <div style={{ backgroundColor: "#D9E6E5" }}>
          <Container>
            <div className="ContactWithUSSection pb-5">
              <Row className="ContactUsContent justify-content-center">
                <Col lg={8} md={12} sm={12} xs={12} className="mt-3">
                  <p className="ContactusText">
                    Have a question? Want to know more? Check out our{" "}
                    <Link to={`/works`} className="FAQStxt">
                      FAQs
                    </Link>{" "}
                    page or chat with us.
                  </p>
                </Col>
                <Col lg={2} md={12} sm={12} xs={12} className="text-center">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://wa.me/+923323165701"
                    className="WhatsAppbutton w-100"
                  >
                    <img src={Whatsapp} className="mx-1" alt="whatsapp_icon" />
                    Contact Us
                  </a>
                </Col>
                <Col lg={2} md={12} sm={12} xs={12} className="text-center">
                  <a
                    href="https://www.facebook.com/profile.php?id=100089498583736&mibextid=LQQJ4d"
                    rel="noreferrer"
                    target="_blank"
                    className="Facebookbutton w-100"
                  >
                    <img src={Facebook} className="mx-1" alt="facebook_icon" />
                    Visit our page
                  </a>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </section>
    </>
  );
};

export default ContactUsSection;
