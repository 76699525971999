import React, { useState } from "react";
import "../Social Profile/socialprofile.css";
import { Col, Row, Container, Button, Form, FormGroup } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { Provider } from "../../../context/Context";
import Toast from "../../../functions/Toast";
const Patientsverification = ({ isSidebarOpen }) => {
  const navigate = useNavigate();
  const { mrNumber, setMrNumber, employmentStatus, setEmploymentStatus } =
    Provider();

  const handleSubmit = () => {
    navigate("/detailpage5");
  };
  return (
    <>
      <div
        className={`form-container ${
          isSidebarOpen ? "form-container-open" : ""
        }`}
      >
        <h3 className="socialprofile">Patient's Verfication</h3>
        <Container className=" mb-5 form-09">
          <Row>
            <Container className=" patient-con py-2 px-5 ">
              <Form>
                <FormGroup className="d-flex flex-wrap form-colom ">
                  <Col lg={4} md={4} sm={12} xs={12} className="form-group ">
                    <div>
                      <input
                        type="tel"
                        id="document"
                        className="fields px-4"
                        placeholder="Partient’s registration/ MR number"
                        value={mrNumber}
                        onChange={(e) => setMrNumber(e.target.value)}
                      />
                      <Form.Text className="Form-Text">
                        Please enter the patient’s registration number/ MR
                        number of the hospital in which the treatment is
                        ongoing.
                      </Form.Text>
                    </div>
                  </Col>
                  <Col lg={4} md={4} sm={12} xs={12} className="form-group ">
                    <div style={{display: "flex", gap: "5px"}}>
                      <input
                        type="radio"
                        id="true"
                        checked="checked" 
                        name="status"
                        value="true"
                        onChange={(e) => setEmploymentStatus(e.target.value)}
                      />
                      <label for="true">Yes</label>
                      <br />
                      <input
                        type="radio"
                        id="false"
                        name="status"
                        value="false"
                        onChange={(e) => setEmploymentStatus(e.target.value)}
                      />
                      <label for="false">No</label>
                      <br />
                    </div>

                    <Form.Text className="Form-Text">
                      Please specify if the patient is employed, unemployed, or
                      a student.
                    </Form.Text>
                  </Col>
                </FormGroup>

                <Button onClick={handleSubmit} className="Done-button mt-4">
                  Done
                </Button>
              </Form>
            </Container>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Patientsverification;
