import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import "./UserPSidebar.scss";
import logo from "../../../assets/images/Logo.png";
import patientDp from "../../../assets/images/PatientsDp.png";
import MyProfile from "../myProfile/myProfile";
import AccountSettings from "../accountSetting/accountsetting.js";
import Notifications from "../notifications/notifications.jsx";
import { Provider } from "../../../context/Context.jsx";
import Loader from "../../Loader/Loader.jsx";
import { useQuery } from "@apollo/client";
import { GET_PROFILE_QUERY } from "../../../services/methods/Query.js";
import Cookies from "universal-cookie";
import { useDispatch, useSelector } from "react-redux";
import Toast from "../../../functions/Toast.js";
import { checkToken, getUser } from "../../../redux/userSlice.js";
import { useNavigate } from "react-router-dom";

const UserPSidebar = () => {
  const [activeFilter, setActiveFilter] = useState("MyProfile");
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await cookies.remove("token", { path: "/" });
    await dispatch(getUser(null));
    dispatch(checkToken(false));
    Toast.success("Logout Successfully");
  };

  const cookies = new Cookies();

  const { data, refetch, loading } = useQuery(GET_PROFILE_QUERY, {
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: cookies.get("token"),
      },
    },
  });
  console.log(data);
  const userData = data?.GetPatientProfile?.data?.data;
  return loading ? (
    <Loader />
  ) : (
    <>
      <div className="user-dashboard">
        <div className="user-sidebar">
          <div className="user-dp">
            <img src={userData?.profileImageLink} alt="" />
            <h6>{userData?.firstName}</h6>
            <span>
              {userData?.email}
              <p>{userData?.phoneNumber}</p>
            </span>
          </div>
          <div className="sidebar-opt">
            <button
              className={`${activeFilter === "MyProfile" ? "active" : ""}`}
              onClick={() => setActiveFilter("MyProfile")}
            >
              <Icon
                icon="gg:profile"
                width="48"
                height="48"
                className="side-icon"
              />
              <span>My Profile</span>
            </button>
            <button
              className={`${
                activeFilter === "AccountSettings" ? "active" : ""
              }`}
              onClick={() => setActiveFilter("AccountSettings")}
            >
              <Icon
                icon="ic:outline-manage-accounts"
                width="48"
                height="48"
                className="side-icon"
              />
              <span>Account Settings</span>
            </button>
            <button
              className={`${activeFilter === "Notifications" ? "active" : ""}`}
              onClick={() => setActiveFilter("Notifications")}
            >
              <Icon
                icon="iconamoon:notification-bold"
                width="48"
                height="48"
                className="side-icon"
              />
              <span>Notifications</span>
            </button>
          </div>
          <div className="bottom">
            <button onClick={handleLogout}>
              <Icon
                icon="ic:baseline-logout"
                color="white"
                width="40"
                height="40"
              />
              <span>Logout</span>
            </button>
          </div>
        </div>
        <div className="user-main-content">
          <div
            className="top"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/")}
          >
            <img src={logo} alt="" />
          </div>
          <div className="components-sec">
            {activeFilter === "MyProfile" ? (
              <MyProfile user={userData} />
            ) : (
              activeFilter === ""
            )}
            {activeFilter === "Notifications" ? (
              <Notifications />
            ) : (
              activeFilter === ""
            )}
            {activeFilter === "AccountSettings" ? (
              <AccountSettings data={userData} refetch={refetch} />
            ) : (
              activeFilter === ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserPSidebar;
