import React from "react";
import { Col, Row, Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import "./TrendingBlogsSection.css";
import ArticlesData from '../PopularArticlesSection/ArticlesData';
import loadmoreArrow from "../../../assets/images/loadmoreArrow.png";

const TrendingBlogsSection = () => {

    return (
        <>
            <Row className="recent__post mb-4">
                <h5 className=" fw-bold TrendingBlogsHeading">Trending Blogs</h5>
            </Row>
            {ArticlesData.slice(2, 7).map((item) => (
                <Row className="recent__blog-post mb-4" key={item.id}>
                    <div className="ArticleDateTxt mt-2">
                        <i className="ri-date-line "></i>{item.ArticleDate}
                    </div>
                    <div className="recent__blog-item d-flex gap-3 align-items-center">
                        <div className="profile-picture">
                            <img src={item.ImgUrl} alt="" className="profile-img" />
                        </div>
                        <h6 className="mt-3">
                            <Link to={`/blogs/${item.ArticleTitle}`} className="ArticleTitleLink">{item.ArticleTitle}</Link>
                        </h6>
                    </div>

                    <div className="ArticleAuthorNameTxt mt-2">
                        <i className="ri-authorName-line "></i>{item.ArticleAuthorName}
                    </div>
                </Row>
            ))}
            <Row>
                <Col lg={{ span: 3, offset: 5 }} md={12} sm={12} xs={12}>
                    <Link to='/trendingArticles'>
                        <Button className="ViewAllBtn">View All
                            <img src={loadmoreArrow} alt="loadmoreArrow" className="loadmoreImg mx-2"></img>
                        </Button>
                    </Link>
                </Col>
            </Row>
        </>
    )
}

export default TrendingBlogsSection;
