import React, { useState, useEffect } from "react";
import "./WaitlistBenefits.css";
import benefit from "../../../assets/WaitlistPageImages/benefits1.png";
// import Waitlistmodal3 from "../../../components/Modals/WaitlistModals/Modal3/Waitlistmodal3";
import Waitlistmodal from "../../../components/Modals/WaitlistModals/Modal1/Waitlistmodal";
import AOS from "aos";
import "aos/dist/aos.css";

function WaitlistBenefits() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    useEffect(() => {
        AOS.init({
            duration: 1200,
        });
    }, []);
    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    return (
        <>
            {/* <div className="container-1"> */}
                <div className="Benefits-container-1" id="benefit">
                    <p className="Benefits-para-1">
                        Why Should You Join Our Waitlist?
                    </p>
                    <span className="benfit-span">
                        By joining the ShifaLia waitlist, you'll be among the
                        first to experience a healthcare revolution. Your
                        support will help us create a platform that changes
                        lives, one campaign at a time.
                    </span>
                    <div className="benefit-card-row">
                        <div className="benefit-card" data-aos="fade-left">
                            <div className="benefit-card-header">
                                <h6>Exclusive Early Access</h6>
                                <img
                                    src={benefit}
                                    alt=""
                                    className="benefit-img"
                                />
                            </div>
                            <div className="content">
                                <p>
                                    Be the first to explore ShifaLia's
                                    innovative features and user-friendly
                                    interface for healthcare financing.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="benefit-card-row2">
                        <div className="benefit-card" data-aos="fade-right">
                            <div className="benefit-card-header">
                                <h6>Be a Healthcare Hero</h6>
                                <img
                                    src={benefit}
                                    alt=""
                                    className="benefit-img "
                                />
                            </div>
                            <div className="content">
                                <p>
                                    Your early involvement is crucial in
                                    building a supportive community dedicated to
                                    helping those in need with Campaigning for
                                    medical bills.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="benefit-card-row">
                        <div className="benefit-card" data-aos="fade-left">
                            <div className="benefit-card-header">
                                <h6>Stay Informed</h6>
                                <img
                                    src={benefit}
                                    alt=""
                                    className="benefit-img "
                                />
                            </div>
                            <div className="content">
                                <p>
                                    We'll keep you updated on our progress,
                                    stories of hope, and the impact we're making
                                    in the world of healthcare financing.
                                </p>
                            </div>
                        </div>
                    </div>
                    <span className="benfit-span">
                        Join the ShifaLia waitlist today and be part of a
                        transformative movement.
                    </span>
                    <button className="benfit-waitlist-btn" onClick={openModal}>
                        Join Waitlist Now
                    </button>
                <Waitlistmodal isOpen={isModalOpen} onClose={closeModal} />
                </div>
            {/* </div> */}
        </>
    );
}

export default WaitlistBenefits;
