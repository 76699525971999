import React, { useState } from 'react'
import './ImagesContainer.css';
import { Row, Col } from 'react-bootstrap';
import Patientstoryimg1 from "../../../assets/images/Patientstoryimg1.png"
import Patientstoryimg2 from "../../../assets/images/Patientstoryimg2.png"
import Patientstoryimg3 from "../../../assets/images/Patientstoryimg3.png"

const ImagesContainer = () => {
    const [image, setImage] = useState(2);

    const selectImage1 = () => setImage(1);
    const selectImage2 = () => setImage(2);
    const selectImage3 = () => setImage(3);

    return (
        <>
            <div className='images-container'>
                <img src={Patientstoryimg1} alt="Patientstoryimg1" onClick={selectImage1}
                    className={`child-image ${image === 1 && 'selected-child-image'}`}></img>
                <img src={Patientstoryimg2} alt="Patientstoryimg2" onClick={selectImage2}
                    className={`child-image ${image === 2 && 'selected-child-image'}`}></img>
                <img src={Patientstoryimg3} alt="Patientstoryimg3" onClick={selectImage3}
                    className={`child-image ${image === 3 && 'selected-child-image'}`}></img>
            </div>
            <Row>
                <Col className="carousel-dots">
                    <span onClick={selectImage1} className={`${image === 1 ? 'selected-dot' : 'unselected-dot'}`}></span>
                    <span onClick={selectImage2} className={`${image === 2 ? 'selected-dot' : 'unselected-dot'}`}></span>
                    <span onClick={selectImage3} className={`${image === 3 ? 'selected-dot' : 'unselected-dot'}`}></span>
                </Col>
            </Row>
        </>
    )
}

export default ImagesContainer;