import React from "react";
import "./toc.scss";
import planimg from "../../../assets/FundraisingTipsImages/plan.png";
import timelineimg from "../../../assets/FundraisingTipsImages/timeline.png";
import budgetimg from "../../../assets/FundraisingTipsImages/budget.png";
import audienceimg from "../../../assets/FundraisingTipsImages/audience.png";
import strategiesimg from "../../../assets/FundraisingTipsImages/strategies.png";
import consistentimg from "../../../assets/FundraisingTipsImages/consistent.png";
import specificimg from "../../../assets/FundraisingTipsImages/specific.png";
import impactimg from "../../../assets/FundraisingTipsImages/impact.png";
import trackimg from "../../../assets/FundraisingTipsImages/track.png";
import appreciationImg from "../../../assets/FundraisingTipsImages/appreciation.png";

const Toc = () => {
  const TipsContent = [
    {
      id: 1,
      listHeading: "1. Craft a Compelling Story",
      listImg: planimg,
      listPara:
        "Every successful Campaigning campaign starts with a compelling story. Share the 'why' behind your cause, creating an emotional connection that resonates with potential donors. Personal stories have the power to inspire and drive action.",
    },
    {
      id: 2,
      listHeading: "2. Set Clear Goals",
      listImg: timelineimg,
      listPara:
        "Clearly define your Campaigning goals. Whether it's a specific amount of money, a target number of donors, or a tangible project outcome, having clear objectives provides focus and motivation.",
    },
    {
      id: 3,
      listHeading: "3. Leverage Social Media",
      listImg: budgetimg,
      listPara:
        "Harness the power of social media to amplify your message. Utilize platforms like Facebook, Instagram, and Twitter to share updates, engage with your audience, and encourage them to participate in your Campaigning efforts.",
    },
    {
      id: 4,
      listHeading: "4. Create Engaging Content",
      listImg: audienceimg,
      listPara:
        "Capture attention with visually appealing and emotionally resonant content. Utilize images, videos, and infographics to tell your story and showcase the impact of donations. Make your content shareable to broaden its reach.",
    },
    {
      id: 5,
      listHeading: "5. Offer Incentives",
      listImg: strategiesimg,
      listPara:
        "Encourage participation by offering incentives. Whether it's personalized thank-you notes, exclusive updates, or small tokens of appreciation, incentives add an extra layer of motivation for donors to get involved.",
    },
    {
      id: 6,
      listHeading: "6. Host Virtual Events",
      listImg: consistentimg,
      listPara:
        "Embrace the digital age by hosting virtual events. From webinars to online auctions and live streaming, virtual events provide a dynamic platform to engage donors, share your mission, and foster a sense of community.",
    },
    {
      id: 7,
      listHeading: "7. Empower Peer-to-Peer Campaigning",
      listImg: specificimg,
      listPara:
        "Encourage supporters to become ambassadors for your cause. Empower them to create their own Campaigning pages and leverage their networks to expand your reach. Peer-to-peer Campaigning adds a personal touch and widens your impact.",
    },
    {
      id: 8,
      listHeading: "8. Express Gratitude",
      listImg: impactimg,
      listPara:
        "Show genuine appreciation to your donors. Regularly express gratitude through personalized messages, social media shout-outs, and updates on how their contributions are making a difference.",
    },
    {
      id: 9,
      listHeading: "9. Keep Supporters Updated",
      listImg: trackimg,
      listPara:
        "Keep your supporters informed about the progress of your campaign. Share milestones, success stories, and the impact of their contributions. Transparency builds trust and keeps donors engaged.",
    },
    {
      id: 10,
      listHeading: "10. Utilize Campaigning Platforms",
      listImg: appreciationImg,
      listPara:
        "Explore online Campaigning platforms like ShifaLia that simplify the donation process. Platforms like Shifalia provide secure, user-friendly interfaces, making it easier for supporters to contribute to your cause.",
    },
  ];
  return (
    <>
      <div className="toc-section">
        <div className="left-content">
          <div className="toc-content">
            <h1>Table of Content</h1>
            <div className="tips-div">
              <p>Tips for successful Campaigns</p>
              <div className="tips-points">
                <p>1. Craft a Compelling Story</p>
                <p>2. Set Clear Goals</p>
                <p>3. Leverage Social Media</p>
                <p>4. Create Engaging Content</p>
                <p>5. Offer Incentives</p>
                <p>6. Host Virtual Events</p>
                <p>7. Empower Peer-to-Peer Campaigning</p>
                <p>8. Express Gratitude</p>
                <p>9. Keep Supporters Updated</p>
                <p>10. Utilize Campaigning Platforms</p>
              </div>
            </div>
          </div>
        </div>
        <div className="right-content">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
            volutpat elit ut quam viverra efficitur. Sed eu sem tristique,
            vestibulum nunc sed, pharetra libero. Lorem ipsum dolor sit amet,
            consectetur adipiscing elit. Fusce volutpat elit ut quam viverra
            efficitur. Sed eu sem tristique, vestibulum nunc sed, pharetra
            libero. <br></br>Lorem ipsum dolor sit amet, consectetur adipiscing
            elit. Fusce volutpat elit ut quam viverra efficitur. Sed eu sem
            tristique, vestibulum nunc sed, pharetra libero. Lorem ipsum dolor
            sit amet, consectetur adipiscing elit. Fusce volutpat elit ut quam
            viverra efficitur. Sed eu sem tristique, vestibulum nunc sed,
            pharetra libero.
          </p>
          <h5>Tips for successful Campaigns</h5>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
            volutpat elit ut quam viverra efficitur. Sed eu sem tristique,
            vestibulum nunc sed, pharetra libero. Lorem ipsum dolor sit amet,
            consectetur adipiscing elit. Fusce volutpat elit ut quam viverra
            efficitur. Sed eu sem tristique, vestibulum nunc sed, pharetra
            libero.{" "}
          </p>
          {TipsContent.map((content) => (
            <div className="list" key={content.id}>
              <h6>{content.listHeading}</h6>
              <div className="img-div">
                <img src={content.listImg} alt="" />
              </div>
              <p>{content.listPara}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Toc;
