// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DonationImg {
    position: relative;
    width: 100%;
    min-height: 140px;
  }
  
  .DonationImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .DonationImgText {
    text-align: center;
    color: #FFFFFF;
    font-size: 30px; 
    font-weight: 600;
    font-family: "Poppins";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -30%);
  }
  .startDonatingBtn{
    background-color: #014E7F !important;
    position: absolute;
    bottom: 20%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.5vw;
    color: #FFFFFF;
    /* width: 195px; */
    /* height: 50px; */
    padding: 10px 15px !important;
}
.startDonatingBtn:hover {
    background-color: #024574;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
@media (max-width: 480px) {
  .DonationImgText {
    font-size: 20px; 
  }
  .startDonatingBtn{
    bottom: 10%;
    left: 50%;
    width: 195px;
    height: 40px;
}
}
`, "",{"version":3,"sources":["webpack://./src/components/AllCasesPageComponents/StartMonthlyDonationSection/StartMonthlyDonationSection.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,iBAAiB;EACnB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,iBAAiB;EACnB;;EAEA;IACE,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,sBAAsB;IACtB,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;EAClC;EACA;IACE,oCAAoC;IACpC,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,2BAA2B;IAC3B,gBAAgB;IAChB,cAAc;IACd,kBAAkB;IAClB,kBAAkB;IAClB,6BAA6B;AACjC;AACA;IACI,yBAAyB;IACzB,wCAAwC;AAC5C;AACA;EACE;IACE,eAAe;EACjB;EACA;IACE,WAAW;IACX,SAAS;IACT,YAAY;IACZ,YAAY;AAChB;AACA","sourcesContent":[".DonationImg {\n    position: relative;\n    width: 100%;\n    min-height: 140px;\n  }\n  \n  .DonationImg img {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n  }\n  \n  .DonationImgText {\n    text-align: center;\n    color: #FFFFFF;\n    font-size: 30px; \n    font-weight: 600;\n    font-family: \"Poppins\";\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -30%);\n  }\n  .startDonatingBtn{\n    background-color: #014E7F !important;\n    position: absolute;\n    bottom: 20%;\n    left: 50%;\n    transform: translateX(-50%);\n    font-size: 1.5vw;\n    color: #FFFFFF;\n    /* width: 195px; */\n    /* height: 50px; */\n    padding: 10px 15px !important;\n}\n.startDonatingBtn:hover {\n    background-color: #024574;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n}\n@media (max-width: 480px) {\n  .DonationImgText {\n    font-size: 20px; \n  }\n  .startDonatingBtn{\n    bottom: 10%;\n    left: 50%;\n    width: 195px;\n    height: 40px;\n}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
