import React from "react";
import Header from "../components/Header/header";
import Donation from "../components/PreviewPageComponents/DonationSection/donation";
import Story from "../components/PreviewPageComponents/StorySection/story";
import Footer from "../components/Footer/footer";
import Support from "../components/PreviewPageComponents/WordsofSupportSection/support";

const PreviewPage = () => {
  return (
    <>
      <Header />
      <Donation />
      <Story />
      <Support />
      <Footer />
    </>
  );
};

export default PreviewPage;
