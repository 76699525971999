import React from "react";
import Header from "../components/Header/header";
import SafetyComponent from "../components/SafetyComponent/SafetyComponent";
import Footer from "../components/Footer/footer";

const TrustSafety = () => {
  return (
    <div>
      <Header />
      <SafetyComponent />
      <Footer />
    </div>
  );
};

export default TrustSafety;
