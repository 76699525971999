import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import './Faqs.css';

const BannerBox = () => {
    return (
        <Container>
            <Row className='justify-content-center'>
                <Col md={9}>
                    <div className='banner_main_box'>
                        <p>Lorem ipsum dolor sit amet. At consequatur cumque et asperiores neque
                            eum dolorem facere. Sit ipsam quia et omnis odit id quidem harum et
                            illo accusantium eum alias nemo et voluptatem perferendis.</p>

                        <div>
                            <button>FAQS by donors</button>
                            <button>FAQS by Campaigners</button>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default BannerBox