import React from "react";
import { Row, Col } from "react-bootstrap";
import gig1 from "../../assets/images/gig1.png";
import gig2 from "../../assets/images/gig2.png";
import gig3 from "../../assets/images/gig3.png";
import "./myProfile/myProfile.scss";
import { Link } from "react-router-dom";

const Gigscard = ({ data }) => {
  const gigData = [
    {
      id: 1,
      img: gig1,
      title: "Gig’s Title",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dui egestas dolor diam",
      amt: "12000 Rs.",
    },
    {
      id: 2,
      img: gig2,
      title: "Gig’s Title",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dui egestas dolor diam",
      amt: "14000 Rs.",
    },
    {
      id: 3,
      img: gig3,
      title: "Gig’s Title",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dui egestas dolor diam",
      amt: "120 Rs.",
    },
  ];
  return (
    <>
      <Row>
        {data.map((index) => (
          <Col lg={4} md={4} sm={12}>
            <div className="gig-card" key={index._id}>
              <img
                src={index.photoLinks[0]}
                alt="gigImg"
                className="w-100 h-100 "
              />
              <div className="card-content  d-flex flex-column pt-3">
                <h6>{index.title}</h6>
                <p>{index.description}</p>
                <div className="gig-bottom d-flex">
                  <Link to={`/gigDescriptionPage/${index._id}`}>
                    <button>Check out</button>
                  </Link>
                  <span>${index.amount}</span>
                </div>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default Gigscard;
