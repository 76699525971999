import React from "react";
import { Row, Container } from 'react-bootstrap';
import Banner from '../BannerSection/Banner'
import ContactUsCards from '../ContactUsCardsSection/ContactUsCards'
import OurLocation from '../OurLocationSection/OurLocation'
import ContactUsButtonsRow from '../ContactUsButtonsRow/ContactUsButtonsRow'
import Form from '../FormSection/Form'
import "./ContactUsMainPageCom.css"

const ContactUsMainPageCom = () => {
    return (
        <>
            <section className="ContactUsMainPageCom pt-5">
                <Banner />
                <Container>
                    <Container>
                        <Row>
                            <ContactUsCards />
                        </Row>
                    </Container>
                </Container>
                <OurLocation />
                <Container>
                    <Container>
                        <Row>
                            <Form />
                        </Row>
                        <Row>
                            <ContactUsButtonsRow />
                        </Row>
                    </Container>
                </Container>
            </section>
        </>
    )
}

export default ContactUsMainPageCom