import React from "react";
import { Row, Container } from 'react-bootstrap';
import HeaderImg from "../HeaderImgSection/HeaderImgSection"
import GoalCalculatorSection from "../GoalCalculatorSection/GoalCalculatorSection"
import WhyFoundationSection from "../WhyFoundationSection/WhyFoundationSection"
import FAQSection from "../FAQSection/index";
import "./PricingPageMainCom.css"

const PricingPageMainCom = () => {
    return (
        <>
            <section className="PricingMainPageCom">
                <Container>
                    <Container>
                        <Row>
                            <HeaderImg />
                        </Row>
                        <Row>
                            <GoalCalculatorSection />
                        </Row>
                    </Container>
                </Container>
                <WhyFoundationSection />
                <Container>
                    <Container>
                        <Row>
                            <FAQSection />
                        </Row>
                    </Container>
                </Container>
            </section>
        </>
    )
}

export default PricingPageMainCom