import React, { useState } from 'react';
import './DocumentImages.css';
import { Row, Col } from 'react-bootstrap';
import PatientDoc1 from "../../../assets/images/PatientDoc1.png";
import PatientDoc2 from "../../../assets/images/PatientDoc2.png";

const DocumentImages = () => {
    const [selectedImage, setSelectedImage] = useState(null);
    const handleImageClick = (imageSrc) => {
        setSelectedImage(imageSrc);
    };
    const handleCloseModal = () => {
        setSelectedImage(null);
    };

    return (
        <>
            <img src={PatientDoc1} alt="PatientDoc1" onClick={() => handleImageClick(PatientDoc1)} style={{cursor:"pointer"}}/>
            <Row className='mt-4 mx-1 mb-4'>
                <Col lg={4} md={4} xs={3}>
                    <img src={PatientDoc2} alt="PatientDoc2" onClick={() => handleImageClick(PatientDoc2)} style={{cursor:"pointer"}} />
                </Col>
                <Col lg={4} md={4} xs={3}>
                    <img src={PatientDoc2} alt="PatientDoc2" onClick={() => handleImageClick(PatientDoc2)} style={{cursor:"pointer"}} />
                </Col>
                <Col lg={4} md={4} xs={3}>
                    <img src={PatientDoc2} alt="PatientDoc2" onClick={() => handleImageClick(PatientDoc2)} style={{cursor:"pointer"}} />
                </Col>
            </Row>

            {selectedImage && (
                <div className="modalContainer">
                    <div className="modalContent">
                        <span className="closeModal" onClick={handleCloseModal}>
                            &times;
                        </span>
                        <img src={selectedImage} alt="Full Size" className="centerImage" />
                    </div>
                </div>
            )}
        </>
    );
};

export default DocumentImages;
