import "./MultistepForm.css";
import { useRef, useState } from "react";
import ProfileIcon from "../../assets/images/multiform-profile.png";
import InfoIcon from "../../assets/images/multiform-info.png";
import ProfileMoneyIcon from "../../assets/images/multiform-profile-mony.png";
import CameraIcon from "../../assets/images/multiform-camera.png";
import Step1Img from "../../assets/images/multiform-step1-img.png";
import Step2Img from "../../assets/images/multiform-step2-img.png";
import Step3Img from "../../assets/images/multiform-step3-img.png";
import Step4Img from "../../assets/images/multiform-step4-img.png";
import UploadImg1 from "../../assets/images/multiform-upload-img.png";
import UploadImg2 from "../../assets/images/multiform-upload-img2.png";
import FooterDashboard from "../StartFundraising_Step1Component/ContactUsSection/ContactUsSection";
import { ThreeDots } from "react-loader-spinner";
import Step5 from "./Step5";
import Toast from "../../functions/Toast";
import { postFundRaiserData } from "../../services/http/fundraiserApi";
import { GET_DICTIONARY_QUERY } from "../../services/methods/Query";
import { useQuery } from "@apollo/client";

function MultistepForm() {
  const { data } = useQuery(GET_DICTIONARY_QUERY);
  const arrOfData = data?.getDictionary?.data;

  function checkGroup(arrOfData) {
    return arrOfData.group === "Categories";
  }

  const filterData = arrOfData?.filter(checkGroup);

  const [activeTab, setActiveTab] = useState("tab1");
  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const fileInputRef4 = useRef(null);
  const fileInputRef5 = useRef(null);
  const fileInputRef6 = useRef(null);

  const [loading, setLoading] = useState(false);
  const [categoryId, setcategoryId] = useState("");
  const [fullName, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [patientPhone, setPatientPhone] = useState("");
  const [patientFullName, setPatFullname] = useState("");
  const [CNICNumber, setCnic] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [patientAddress, setAddress] = useState("");
  const [relationWithPatient, setRelationWithPatient] = useState("");
  const [healthIssue, setHealthIssue] = useState("");
  const [hospitalName, setHospital] = useState("");
  const [city, setCity] = useState("");
  const [title, setTitle] = useState("");
  const [goalAmount, setGoalAmount] = useState("");
  const [anonymous, setanonymous] = useState(false);
  const [CNICImageLinks, setCNICImageLinks] = useState([]);
  const [CNICSupporterImageLinks, setCNICSupporterImageLinks] = useState([]);
  const [utilityBillImageLinks, setutilityBillImageLinks] = useState([]);
  const [patientConsent, setpatientConsent] = useState([]);
  const [doctorTestimonial, setdoctorTestimonial] = useState([]);
  const [prescription, setprescription] = useState([]);

  const navigateHandler = async () => {
    if (
      !CNICImageLinks ||
      !CNICSupporterImageLinks ||
      !utilityBillImageLinks ||
      !patientConsent ||
      !doctorTestimonial ||
      !prescription
    ) {
      Toast.error("Please uplaod images");
      return;
    }

    const formData = new FormData();

    formData.append("categoryId", categoryId);
    formData.append("fullName", fullName);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("patientPhone", patientPhone);
    formData.append("patientFullName", patientFullName);
    formData.append("CNICNumber", CNICNumber);
    formData.append("maritalStatus", maritalStatus);
    formData.append("patientAddress", patientAddress);
    formData.append("relationWithPatient", relationWithPatient);
    formData.append("healthIssue", healthIssue);
    formData.append("hospitalName", hospitalName);
    formData.append("city", city);
    formData.append("title", title);
    formData.append("goalAmount", Number(goalAmount));
    formData.append("anonymous", Boolean(anonymous));
    for (let i = 0; i < CNICImageLinks?.length; i++) {
      formData.append("CNICImageLinks", CNICImageLinks[i]);
    }
    for (let i = 0; i < CNICSupporterImageLinks?.length; i++) {
      formData.append("CNICSupporterImageLinks", CNICSupporterImageLinks[i]);
    }
    for (let i = 0; i < patientConsent?.length; i++) {
      formData.append("patientConsent", patientConsent[i]);
    }
    for (let i = 0; i < utilityBillImageLinks?.length; i++) {
      formData.append("utilityBillImageLinks", utilityBillImageLinks[i]);
    }
    for (let i = 0; i < doctorTestimonial?.length; i++) {
      formData.append("doctorTestimonial", doctorTestimonial[i]);
    }
    for (let i = 0; i < prescription?.length; i++) {
      formData.append("prescription", prescription[i]);
    }
    try {
      setLoading(true);
      const res = await postFundRaiserData(formData);
      setLoading(false);
      if (res?.data?.success) {
        Toast.success("Created");
        setActiveTab("tab5");
      }
    } catch (error) {
      setLoading(false);
      Toast.error("Something went wrong");
    }
  };

  return (
    <>
      {activeTab === "tab5" ? (
        <Step5 />
      ) : (
        <section className="multistep-sec">
          <div className="container">
            <div className="multistep-sec-heading">
              <h4 className={activeTab === "tab1" ? "" : "d-none"}>
                Start Fundraising
              </h4>
            </div>
            <div className="multistep-form-wrap px-lg-5">
              <div className="row align-items-stretch">
                <div className="col-lg-auto p-0">
                  <div className="multistep-head">
                    <ul>
                      <li
                        className={activeTab === "tab1" ? "active" : ""}
                        onClick={() => setActiveTab("tab1")}
                      >
                        <div className="multistep-item">
                          <img src={ProfileIcon} />
                        </div>
                      </li>
                      <li
                        className={activeTab === "tab2" ? "active" : ""}
                        onClick={() => setActiveTab("tab2")}
                      >
                        <div className="multistep-item">
                          <img src={InfoIcon} />
                        </div>
                      </li>
                      <li
                        className={activeTab === "tab3" ? "active" : ""}
                        onClick={() => setActiveTab("tab3")}
                      >
                        <div className="multistep-item">
                          <img src={ProfileMoneyIcon} />
                        </div>
                      </li>
                      <li
                        className={activeTab === "tab4" ? "active" : ""}
                        onClick={() => setActiveTab("tab4")}
                      >
                        <div className="multistep-item">
                          <img src={CameraIcon} />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col p-0">
                  <div className="multistep-form-inner-main">
                    <div className={activeTab === "tab1" ? "" : "d-none"}>
                      <div className="row flex-column-reverse flex-lg-row align-items-center align-items-lg-end">
                        <div className="col-lg-6">
                          <div className="multiform-select mb-4">
                            <select>
                              <option value="self">
                                I am raising funds for myself
                              </option>
                              <option value="someone">
                                I am raising funds for myselfI am raising funds
                                on the behalf of someone else
                              </option>
                            </select>
                          </div>
                          <div className="multiform-select-inner mb-5">
                            <h5>I am raising funds for</h5>
                            <select
                              onChange={(e) => setcategoryId(e.target.value)}
                            >
                              {filterData &&
                                filterData.map((item) => (
                                  <>
                                    <option selected disabled hidden>
                                      Please select a category
                                    </option>
                                    <option value={item._id}>
                                      {item.code}
                                    </option>
                                  </>
                                ))}
                            </select>
                          </div>
                          <div className="multiform-input mb-4">
                            <input
                              type="text"
                              placeholder="Full Name"
                              value={fullName}
                              onChange={(e) => setFullname(e.target.value)}
                            />
                          </div>
                          <div className="multiform-input mb-4">
                            <input
                              type="text"
                              placeholder="Email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                          <div className="multiform-input mb-4">
                            <input
                              type="text"
                              placeholder="Mobile Number"
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                            />
                          </div>
                          {/* <div className="multiform-input mb-4">
                            <input
                              type="text"
                              placeholder="Your own Mobile Number"
                            />
                          </div> */}
                          <div className="multiform-input mb-4">
                            <input
                              type="text"
                              placeholder="Patient’s Contact Number"
                              value={patientPhone}
                              onChange={(e) => setPatientPhone(e.target.value)}
                            />
                          </div>
                          <div className="input-check">
                            <input type="checkbox" name="agree1" id="agree1" />
                            <label htmlFor="agree1">
                              I agree to the <a href="#">terms</a> and{" "}
                              <a href="#">privacy policy</a>.
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6 text-center text-lg-end">
                          <div className="step-img pb-5 pb-lg-0">
                            <img src={Step1Img} />
                          </div>
                        </div>
                      </div>
                      <div className="step-center-text text-center mt-5 pt-5">
                        {/* <h5>
                          Already have an account? <a href="#">Sign in</a>.
                        </h5> */}
                        <button
                          className="stepMain-btn mt-4"
                          onClick={() => {
                            if (
                              categoryId == "" ||
                              fullName == "" ||
                              email == "" ||
                              phone == "" ||
                              patientPhone == ""
                            ) {
                              Toast.error("Please fill all fields");
                              return;
                            }
                            setActiveTab("tab2");
                          }}
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                    <div className={activeTab === "tab2" ? "" : "d-none"}>
                      <div className="row flex-column-reverse flex-lg-row align-items-center align-items-lg-end">
                        <div className="col-lg-6">
                          <div className="multiform-input mb-4">
                            <input
                              type="text"
                              placeholder="Patient’s Full Name"
                              value={patientFullName}
                              onChange={(e) => setPatFullname(e.target.value)}
                            />
                          </div>
                          <div className="multiform-input mb-4">
                            <input
                              type="text"
                              placeholder="CNIC"
                              value={CNICNumber}
                              onChange={(e) => setCnic(e.target.value)}
                            />
                          </div>
                          <div className="multiform-input mb-4">
                            <select
                              disabled={loading}
                              placeholder="Marital status"
                              value={maritalStatus}
                              onChange={(e) => setMaritalStatus(e.target.value)}
                            >
                              <option selected hidden>
                                Select status
                              </option>
                              <option>single</option>
                              <option>married</option>
                            </select>
                            {/* <input
                              type="text"
                              placeholder="Marital status"
                              value={maritalStatus}
                              onChange={(e) => setMaritalStatus(e.target.value)}
                            /> */}
                          </div>
                          <div className="multiform-input mb-4">
                            <input
                              type="text"
                              placeholder="Address"
                              value={patientAddress}
                              onChange={(e) => setAddress(e.target.value)}
                            />
                          </div>
                          <div className="multiform-input mb-4">
                            {/* <input
                              type="text"
                              placeholder="Your relation with the patient"
                              value={relationWithPatient}
                              onChange={(e) =>
                                setRelationWithPatient(e.target.value)
                              }
                            /> */}
                            <select
                              disabled={loading}
                              placeholder="Marital status"
                              value={relationWithPatient}
                              onChange={(e) =>
                                setRelationWithPatient(e.target.value)
                              }
                            >
                              <option selected hidden>
                                Select Relation with patient
                              </option>
                              <option>Father</option>
                              <option>Mother</option>
                              <option>Brother</option>
                              <option>Sister</option>
                              <option>Friend</option>
                              <option>Uncle</option>
                              <option>Others</option>
                            </select>
                          </div>
                          <div className="multiform-input mb-4">
                            <input
                              type="text"
                              placeholder="What is the health issue?"
                              value={healthIssue}
                              onChange={(e) => setHealthIssue(e.target.value)}
                            />
                          </div>
                          <div className="multiform-input mb-4">
                            <input
                              type="text"
                              placeholder="Which hospital"
                              value={hospitalName}
                              onChange={(e) => setHospital(e.target.value)}
                            />
                          </div>
                          <div className="multiform-input mb-4">
                            <input
                              type="text"
                              placeholder="City"
                              value={city}
                              onChange={(e) => setCity(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 text-center text-lg-end">
                          <div className="step-img pb-5 pb-lg-0">
                            <img src={Step2Img} />
                          </div>
                        </div>
                      </div>
                      <div className="step-center-text mt-5">
                        <button
                          className="stepMain-btn"
                          onClick={() => {
                            if (
                              patientFullName == "" ||
                              CNICNumber == "" ||
                              maritalStatus == "" ||
                              patientAddress == "" ||
                              relationWithPatient == "" ||
                              healthIssue == "" ||
                              hospitalName == "" ||
                              city == ""
                            ) {
                              Toast.error("Please fill all fields");
                              return;
                            }

                            setActiveTab("tab3");
                          }}
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                    <div className={activeTab === "tab3" ? "" : "d-none"}>
                      <div className="row flex-column-reverse flex-lg-row align-items-center align-items-lg-end">
                        <div className="col-lg-6">
                          <div className="multiform-input-title mb-5">
                            <label>Fundraising Title</label>
                            <input
                              type="text"
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                            />
                          </div>
                          <div className="multiform-input mb-5">
                            <input
                              type="number"
                              placeholder="Goal Amount"
                              value={goalAmount}
                              onChange={(e) => setGoalAmount(e.target.value)}
                            />
                          </div>
                          <div className="input-check mb-5">
                            <input
                              type="checkbox"
                              name="agree1"
                              id="agree1"
                              onClick={(e) => {
                                if (e.target.checked) {
                                  setanonymous(true);
                                } else {
                                  setanonymous(false);
                                }
                              }}
                            />
                            <label htmlFor="agree1">
                              Make my fundraiser <a href="#">private</a>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6 text-center text-lg-end">
                          <div className="step-img pb-5 pb-lg-0">
                            <img src={Step3Img} />
                          </div>
                        </div>
                      </div>
                      <div className="step-center-text mt-5">
                        <button
                          className="stepMain-btn"
                          onClick={() => {
                            if (title == "" || goalAmount == "") {
                              Toast.error("Please fill all fields");
                              return;
                            }

                            setActiveTab("tab4");
                          }}
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                    <div className={activeTab === "tab4" ? "" : "d-none"}>
                      <div className="row flex-column-reverse flex-lg-row align-items-center">
                        <div className="col-lg-6">
                          <div className="multiform-input-file mb-5">
                            <label htmlFor="docs">
                              <h5>
                                Please upload the following documents to
                                increase your cause’s credibility.
                              </h5>
                              <img src={UploadImg1} />
                            </label>
                            {/* <input type="file" name="docs" id="docs" /> */}
                          </div>
                          <div className="multiform-docs-list">
                            <ul>
                              <li>
                                <label htmlFor="docs">
                                  <input
                                    type="file"
                                    ref={fileInputRef1}
                                    style={{ display: "none" }}
                                    multiple
                                    onChange={(e) =>
                                      setCNICImageLinks(e.target.files)
                                    }
                                  />

                                  <h5>
                                    Pictures of Patient’s CNIC (front and back){" "}
                                  </h5>

                                  <img
                                    src={UploadImg2}
                                    onClick={() =>
                                      fileInputRef1.current?.click()
                                    }
                                  />
                                  <h5 style={{ color: "black" }}>
                                    {CNICImageLinks.length > 0 &&
                                      CNICImageLinks.length}
                                  </h5>
                                </label>
                              </li>
                              <li>
                                <label htmlFor="docs">
                                  <input
                                    type="file"
                                    ref={fileInputRef2}
                                    style={{ display: "none" }}
                                    multiple
                                    onChange={(e) => {
                                      setCNICSupporterImageLinks(
                                        e.target.files
                                      );
                                    }}
                                  />
                                  <h5>
                                    Pictures of CNIC of the person collecting
                                    these funds (front and back)
                                  </h5>
                                  <img
                                    src={UploadImg2}
                                    onClick={() =>
                                      fileInputRef2.current?.click()
                                    }
                                  />
                                  <h5 style={{ color: "black" }}>
                                    {CNICSupporterImageLinks.length > 0 &&
                                      CNICSupporterImageLinks.length}
                                  </h5>
                                </label>
                              </li>
                              <li>
                                <label htmlFor="docs">
                                  <input
                                    type="file"
                                    ref={fileInputRef3}
                                    style={{ display: "none" }}
                                    multiple
                                    onChange={(e) => {
                                      setpatientConsent(e.target.files);
                                    }}
                                  />
                                  <h5>
                                    Picture of a patient's consent form giving
                                    you authority to raise these funds
                                  </h5>
                                  <img
                                    src={UploadImg2}
                                    onClick={() =>
                                      fileInputRef3.current?.click()
                                    }
                                  />
                                  <h5 style={{ color: "black" }}>
                                    {patientConsent.length > 0 &&
                                      patientConsent.length}
                                  </h5>
                                </label>
                              </li>
                              <li>
                                <label htmlFor="docs">
                                  <input
                                    type="file"
                                    ref={fileInputRef4}
                                    style={{ display: "none" }}
                                    multiple
                                    onChange={(e) => {
                                      setutilityBillImageLinks(e.target.files);
                                    }}
                                  />
                                  <h5>
                                    Picture of the patient’s latest utility bill
                                    (electricity bill is preferred)
                                  </h5>
                                  <img
                                    src={UploadImg2}
                                    onClick={() =>
                                      fileInputRef4.current?.click()
                                    }
                                  />
                                  <h5 style={{ color: "black" }}>
                                    {utilityBillImageLinks.length > 0 &&
                                      utilityBillImageLinks.length}
                                  </h5>
                                </label>
                              </li>
                              <li>
                                <label htmlFor="docs">
                                  <input
                                    type="file"
                                    ref={fileInputRef5}
                                    style={{ display: "none" }}
                                    multiple
                                    onChange={(e) => {
                                      setdoctorTestimonial(e.target.files);
                                    }}
                                  />
                                  <h5>
                                    Picture of the current doctor’s testimonial
                                    certifying that the patient is under his
                                    care
                                  </h5>
                                  <img
                                    src={UploadImg2}
                                    onClick={() =>
                                      fileInputRef5.current?.click()
                                    }
                                  />
                                  <h5 style={{ color: "black" }}>
                                    {doctorTestimonial.length > 0 &&
                                      doctorTestimonial.length}
                                  </h5>
                                </label>
                              </li>
                              <li>
                                <label htmlFor="docs">
                                  <input
                                    type="file"
                                    ref={fileInputRef6}
                                    style={{ display: "none" }}
                                    multiple
                                    onChange={(e) => {
                                      setprescription(e.target.files);
                                    }}
                                  />
                                  <h5>
                                    Picture of the prescription recommending the
                                    test/medication/treatment.
                                  </h5>
                                  <img
                                    src={UploadImg2}
                                    onClick={() =>
                                      fileInputRef6.current?.click()
                                    }
                                  />
                                  <h5 style={{ color: "black" }}>
                                    {prescription.length > 0 &&
                                      prescription.length}
                                  </h5>
                                  pr
                                </label>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-6 text-center text-lg-end">
                          <div className="step-img pb-5 pb-lg-0">
                            <img src={Step4Img} />
                          </div>
                        </div>
                      </div>
                      <div className="step-center-text mt-5">
                        {loading ? (
                          <button className="stepMain-btn">
                            <ThreeDots
                              visible={true}
                              height="30"
                              width="40"
                              color="#FFFFFF  "
                              radius="9"
                              ariaLabel="three-dots-loading"
                            />
                          </button>
                        ) : (
                          <button
                            className="stepMain-btn"
                            onClick={navigateHandler}
                          >
                            Done
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <div>
        <FooterDashboard />
      </div>
    </>
  );
}

export default MultistepForm;
