import React, {useState} from 'react'
import SideBar from '../../../components/Sidebar/SideBar'
import '../editpages.css'
// import FundraiseDetails from '../../../components/Forms/Fundraise Form/FundraiseDetail'
import Patientsverification from '../../../components/Forms/Patients Verification/Patientsverification'


const Detailpage4 = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <>
       <div className="main ">
      <div className="">
        <SideBar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar}/>
        </div>
    <div className=''>

      <Patientsverification isSidebarOpen={isSidebarOpen}/>
    </div>
     

      </div>
    </>
  )
}

export default Detailpage4
