import React from 'react'
import Header from "../components/Header/header";
import Footer from '../components/Footer/footer'
import GigDescriptionComponent from '../components/GigDescriptionComponent/GigDescriptionComponent';

const GigDescriptionPage = () => {
    return (
        <>
            <Header />
            <GigDescriptionComponent />
            <Footer />
        </>
    )
}

export default GigDescriptionPage