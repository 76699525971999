import React from "react";
import "./allcomments.scss";
import cmtprofile from "../../../assets/casesImgs/cmtprofile.png";
import cmtprofile1 from "../../../assets/casesImgs/cmtprofile1.png";
import Commentcard from "../caseinfo/commentcard";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";

const Allcomments = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate("/cases");
  };
  return (
    <>
      <div className="allcomments-main-container">
        <div className="all-cmts-wrapper d-flex flex-column ">
          <Icon
            icon="fluent-mdl2:navigate-back"
            color="#014e7f"
            width="50"
            height="50"
            onClick={handleBackClick}
            style={{ cursor: "pointer" }}
            className="cmt-back-icon"
          />
          <div className="cmt-search my-5">
            <input
              type="text"
              placeholder="Leave good wishes for *patient name*"
              className="cmts-input-val"
            />
            <button>Post</button>
          </div>
          <div className="cmt-card-wrapper">
            <Commentcard profile={cmtprofile} />
            <Commentcard profile={cmtprofile1} />
            <Commentcard profile={cmtprofile1} />
            <Commentcard profile={cmtprofile} />
          </div>
          <button className="load-more-cmts-btn">Load More</button>
        </div>
      </div>
    </>
  );
};

export default Allcomments;
