import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import BannerImg from "../../../assets/images/bannerImg.png";
import "./Banner.css";

const Banner = () => {
  return (
    <>
      <Row className="BannerSection  mb-5">
        <Col lg={5} md={6} className="offset-lg-1  px-5 py-5 mt-5">
          {" "}
          {/* Add 'offset-lg-2' class */}
          <h5 className="LetsConnectHeading">Let’s connect!</h5>
          <p className="letsconnectdescription mt-3">
            Feel free to reach out to us and we will get back to you as soon as
            we can!
          </p>
        </Col>
        <Col lg={5} md={6}>
          <img
            src={BannerImg}
            className="offset-lg-1  img-fluid mt-4"
            alt="BannerImg"
          />
        </Col>
      </Row>
    </>
  );
};

export default Banner;
