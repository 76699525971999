import React, { useState } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { Heading, Line, TitleWrap } from '../HeadingElement';
import { Card, CardContent, Circle, DashLine, DashLineWrap, Heading1, Heading2, StepContainer, StepNo, StepNoWrap } from './StepsSectionElement';
import { Button } from '../../ButtonElement';

const StepsSection = () => {
    return (
        <>
            <Container className='px-5'>
                <Row>
                    <TitleWrap>
                        <Line></Line>
                        <Heading>Follow these steps to start today</Heading>
                    </TitleWrap>
                </Row>
                
            </Container>
            <Container className='d-flex my-5  justify-content-center' style={{marginLeft:"20%"}}>
            <Row>
            <Col >
            <StepContainer>
            <Circle>
                            <StepNoWrap>
                                <StepNo>1</StepNo>
                            </StepNoWrap>
                        </Circle>
                        <DashLine>------------------</DashLine>
            </StepContainer>
            </Col>
            <Col >
            <StepContainer>
            <Circle>
                            <StepNoWrap>
                                <StepNo>2</StepNo>
                            </StepNoWrap>
                        </Circle>
                        <DashLine>------------------</DashLine>
            </StepContainer>
            </Col>
            <Col >
            <StepContainer>
            <Circle>
                            <StepNoWrap>
                                <StepNo>3</StepNo>
                            </StepNoWrap>
                        </Circle>
                        {/* <DashLine>---------------------</DashLine> */}
            </StepContainer>
            </Col>
                </Row>
            </Container>
            <Container className='d-flex my-5  justify-content-center align-items-center'>
            <Row>
                <Col>
                    <Container className='px-5 py-2'>
                        <Heading1 className='py-2'>Start with the basics</Heading1>
                        <Heading2 className='pb-3'>Set your goal and location</Heading2>
                        <Card>
                            <CardContent  className='px-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</CardContent>
                            <Button backgroundColor="#B5D5D4" textColor="#595858" small={true}>Watch Video</Button>
                        </Card>
                    </Container>
                </Col>
                <Col>
                    <Container className='px-5'>
                        <Heading1 className='py-2'>Start with the basics</Heading1>
                        <Heading2 className='pb-3'>Set your goal and location</Heading2>
                        <Card>
                            <CardContent  className='px-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</CardContent>
                            <Button backgroundColor="#B5D5D4" textColor="#595858"  small={true}>Watch Video</Button>
                        </Card>
                    </Container>
                </Col>
                <Col>
                    <Container className='px-5'>
                        <Heading1 className='py-2'>Start with the basics</Heading1>
                        <Heading2 className='pb-3'>Set your goal and location</Heading2>
                        <Card>
                            <CardContent className='px-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</CardContent>
                            <Button backgroundColor="#B5D5D4" textColor="#595858" small={true}>Watch Video</Button>
                        </Card>
                    </Container>
                </Col>
            </Row>
            </Container>
            <Container className='d-flex my-5  justify-content-center align-items-center'>
                <Button backgroundColor="#02627B" textColor="#FFF" >Start Campaigning</Button>
            </Container>
            
        </>
    )
}

export default StepsSection