import React from 'react'
import './mission.css'
import Header from '../../components/Header/header'
import MissionVisionBanner from '../../components/MissionvissionBanner/MissionVisionBanner'
import OurValues from '../../components/OurValues/OurValues'

const MissionVision = () => {
  return (
    <>
        <div className='mission'>
            <div className="misson-head">
            <Header/>
            </div>
            <div className="vission-div">
            <MissionVisionBanner/>
            <OurValues/>
            </div>
        </div> 
    </>
  )
}

export default MissionVision
