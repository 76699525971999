import React from "react";
import "./waitlistmodal2.css";

import checkicon from "../../../../../src/assets/images/PngItem_1953151.png";
function Waitlistmodal2({ isOpen, onClose }) {
  if (!isOpen) return null;
  return (
    <div className="overlay-wl2">
      <div className="popup-wl2">
        <div className="container-wl2">
          <div className="container-2-wl2">
            <img
              style={{ margin: "30px" }}
              src={checkicon}
              alt="check-icon.png"
              width={70}
              height={70}
            />
          </div>
          <div className="container-3-wl2">
            <div className="para-2-wl2">All done!</div>
            <div className="para-1-wl2 mt-2">
              <p className="success-para-1 text-align-centre">
                You’ve successfully joined the waitlist.<br></br> Check your
                email for confirmation message.
              </p>
            </div>
            <button id="button-wl2" onClick={onClose}>
              Okay!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Waitlistmodal2;
