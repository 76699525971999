import { createContext, useContext, useEffect, useState } from "react";
import { patchDetails } from "../services/http/editDetailsApi";

const Context = createContext();

export function ContextProvider({ children }) {
  // for get case by id
  const [caseById, setCaseById] = useState({});
  const [fundraiserId, setfundraiserId] = useState("");
  useEffect(() => {
    setGoalAmount(caseById?.goalAmount);
    setmedicalCondition(caseById?.medicalCondition);
    setDescription(caseById?.description);
    setpatientFullName(caseById?.patientFullName);
    setAge(caseById?.age);
    setGender(caseById?.gender);
    setmonthlyIncome(caseById?.monthlyIncome);
    setHospitalName(caseById?.hospitalName);
    setMrNumber(caseById?.mrNumber);
    setrelationWithPatient(caseById?.relationWithPatient);
    sethowDoYouKnowAboutANF(caseById?.howDoYouKnowAboutANF);
    setWhatsAppNo(caseById?.WhatsAppNo);
    setpreviousCompanies(caseById?.previousCompanies);
    setconsultingDoctor(caseById?.consultingDoctor);
    setdoctorPhone(caseById?.doctorPhone);
  }, [caseById]);

  // for edit case
  const [customShortLinks, setcustomShortLinks] = useState([]);
  const [picturesLinks, setpicturesLinks] = useState([]);
  const [videoLinks, setvideoLinks] = useState([]);
  const [goalAmount, setGoalAmount] = useState("");
  const [medicalCondition, setmedicalCondition] = useState("");
  const [description, setDescription] = useState("");
  const [patientFullName, setpatientFullName] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [hospitalName, setHospitalName] = useState("");
  const [consentLetter, setConsentLetter] = useState([]);
  const [otherDocsLinks, setotherDocsLinks] = useState([]);
  const [ReportsLinks, setReportsLinks] = useState([]);
  const [mrNumber, setMrNumber] = useState("");
  const [employmentStatus, setEmploymentStatus] = useState(false);
  const [relationWithPatient, setrelationWithPatient] = useState("");
  const [monthlyIncome, setmonthlyIncome] = useState("");
  const [howDoYouKnowAboutANF, sethowDoYouKnowAboutANF] = useState("");
  const [campaignerWhatsAppNo, setcampaignerWhatsAppNo] = useState([]);
  const [WhatsAppNo, setWhatsAppNo] = useState("");
  const [previousCompanies, setpreviousCompanies] = useState("");
  const [UHIDNumber, setUHIDNumber] = useState([]);
  const [consultingDoctor, setconsultingDoctor] = useState("");
  const [doctorPhone, setdoctorPhone] = useState("");
  const [addTeamMember, setaddTeamMember] = useState([]);
  const [editLoading, setEditLoading] = useState(false);

  // for all Cases Filtration
  const [catId, setCatId] = useState("");
  const [date, setDate] = useState("");
  const [search, setSearch] = useState("");
  const handleSubmit = async () => {
    setEditLoading(true);
    const formData = new FormData();
    formData.append("fundraiserId", fundraiserId);
    formData.append("monthlyIncome", Number(monthlyIncome));
    formData.append("patientFullName", patientFullName);
    formData.append("age", Number(age));
    formData.append("gender", gender);
    formData.append("medicalCondition", medicalCondition);
    formData.append("goalAmount", Number(goalAmount));
    formData.append("description", description);
    if (picturesLinks) {
      for (let i = 0; i < picturesLinks?.length; i++) {
        formData.append("picturesLinks", picturesLinks[i]);
      }
    }
    if (videoLinks) {
      for (let i = 0; i < videoLinks?.length; i++) {
        formData.append("videoLinks", videoLinks[i]);
      }
    }
    if (customShortLinks) {
      for (let i = 0; i < customShortLinks?.length; i++) {
        formData.append("customShortLinks", customShortLinks[i]);
      }
    }

    formData.append("hospitalName", hospitalName);
    if (consentLetter) {
      for (let i = 0; i < consentLetter?.length; i++) {
        formData.append("consentLetter", consentLetter[i]);
      }
    }
    if (otherDocsLinks) {
      for (let i = 0; i < otherDocsLinks?.length; i++) {
        formData.append("otherDocsLinks", otherDocsLinks[i]);
      }
    }
    if (ReportsLinks) {
      for (let i = 0; i < ReportsLinks?.length; i++) {
        formData.append("ReportsLinks", ReportsLinks[i]);
      }
    }
    formData.append("mrNumber", Number(mrNumber));
    formData.append("employmentStatus", Boolean(employmentStatus));
    formData.append("relationWithPatient", relationWithPatient);
    formData.append("howDoYouKnowAboutANF", howDoYouKnowAboutANF);
    formData.append("WhatsAppNo", WhatsAppNo);
    if (campaignerWhatsAppNo?.length >= 1) {
      formData.append("campaignerWhatsAppNo", campaignerWhatsAppNo);
    }
    formData.append("previousCompanies", previousCompanies);
    if (UHIDNumber?.length >= 1) {
      formData.append("UHIDNumber", UHIDNumber);
    }
    formData.append("consultingDoctor", consultingDoctor);
    formData.append("doctorPhone", doctorPhone);
    if (addTeamMember?.length >= 1) {
      formData.append("addTeamMember", addTeamMember);
    }
    const res = await patchDetails(formData);
    setEditLoading(false);
    return res;
  };

  return (
    <Context.Provider
      value={{
        setfundraiserId,
        customShortLinks,
        setcustomShortLinks,
        picturesLinks,
        setpicturesLinks,
        videoLinks,
        setvideoLinks,
        goalAmount,
        setGoalAmount,
        medicalCondition,
        setmedicalCondition,
        description,
        setDescription,
        patientFullName,
        setpatientFullName,
        age,
        setAge,
        gender,
        setGender,
        hospitalName,
        setHospitalName,
        consentLetter,
        setConsentLetter,
        otherDocsLinks,
        setotherDocsLinks,
        ReportsLinks,
        setReportsLinks,
        mrNumber,
        setMrNumber,
        employmentStatus,
        setEmploymentStatus,
        relationWithPatient,
        setrelationWithPatient,
        monthlyIncome,
        setmonthlyIncome,
        howDoYouKnowAboutANF,
        sethowDoYouKnowAboutANF,
        campaignerWhatsAppNo,
        setcampaignerWhatsAppNo,
        WhatsAppNo,
        setWhatsAppNo,
        previousCompanies,
        setpreviousCompanies,
        UHIDNumber,
        setUHIDNumber,
        consultingDoctor,
        setconsultingDoctor,
        doctorPhone,
        setdoctorPhone,
        addTeamMember,
        setaddTeamMember,
        editLoading,
        handleSubmit,
        // for all cases filtration
        catId,
        setCatId,
        date,
        setDate,
        search,
        setSearch,
        // for get case by id
        caseById,
        setCaseById,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function Provider() {
  return useContext(Context);
}
