import React from "react";
import "./SuccessfulJoinModal.css";
import checkicon from "../../../../../src/assets/images/check-icon.png";
function SuccessfulJoinModal({ closeSuccessfulJoinModal }) {
  return (
    <div className="overlay">
      <div className="success-popup">
        <div className="success-container-1">
          <div className="success-container-2">
            <img src={checkicon} alt="check-icon.png" width={70} height={70} />
          </div>
          <div className="success-container-3">
            <p className="success-para-2">All done!</p>
            <p className="success-para-1">
              You’ve successfully joined the waitlist. Check your email for
              confirmation message.
            </p>
            <button
              className="success-button"
              onClick={() => {
                closeSuccessfulJoinModal();
              }}
            >
              Okay!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuccessfulJoinModal;
