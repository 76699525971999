import React from "react";
import Slider from "react-slick";
import Patientdoccard from "./patientdoccard";
import "./doc.scss";
import { Provider } from "../../../context/Context";
const PatientDocSlider = () => {
  const { caseById } = Provider();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: caseById?.otherDocsLinks?.length,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {caseById?.otherDocsLinks?.map((doc, index) => (
        <div key={index}>
          <Patientdoccard url={doc} />
        </div>
      ))}
    </Slider>
  );
};

export default PatientDocSlider;
