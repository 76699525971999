import React from "react";
import { Col, Row, Container, Button } from 'react-bootstrap';
import Call from "../../../assets/images/Call.svg"
import Email from "../../../assets/images/Email.svg"
import Socials from "../../../assets/images/Socials.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import "./ContactUsCards.css"

const ContactUsCards = () => {
    return (
        <>
            <Row>
                <h5 className="contactUsHeading mt-4">Contact us</h5>
            </Row>
            <Container className='d-flex my-5  justify-content-center align-items-center'>
                <Row>
                    <Col>
                        <Container className="CardContainer py-2">
                            <div className="Card">
                                <img src={Call} alt="Callicon"></img>
                                <p className="cardinnerHeading mt-2">Phone number</p>
                                <p className="cardContent mt-1">+923152344269</p>
                            </div>
                        </Container>
                    </Col>
                    <Col>
                        <Container className='CardContainer py-2'>
                            <div className="Card">
                                <img src={Email} alt="Emailicon"></img>
                                <p className="cardinnerHeading mt-2">Email Address</p>
                                <p className='cardContent mt-2 px-5'>alianafeesfoundation<br></br>@gmail.com</p>
                            </div>
                        </Container>
                    </Col>
                    <Col>
                        <Container className='CardContainer py-2'>
                            <div className="Card">
                                <img src={Socials} alt="Socialicon"></img>
                                <p className="cardinnerHeading mt-3">Socials</p>
                                <div className="socialLinks mt-">
                                    <a href="https://instagram.com/alia_nafees_foundation?igshid=OGQ5ZDc2ODk2ZA==" className="instagramsocial">
                                        <FontAwesomeIcon icon={faInstagram} size="2x" /></a>
                                    <a href="https://www.facebook.com/profile.php?id=100089498583736&mibextid=LQQJ4d" className="facebooksocial">
                                        <FontAwesomeIcon icon={faFacebook} size="2x" /></a>
                                    <a href="https://www.linkedin.com/company/alia-nafees-foundation/mycompany/" className="linkedinsocial">
                                        <FontAwesomeIcon icon={faLinkedin} size="2x" /></a>
                                </div>
                            </div>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default ContactUsCards