import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import Facebook from "../../assets/images/FacebookIcon.png";
import Whatsapp from "../../assets/images/WhatsappIcon.png";
import { Button } from '../ButtonElement';

const Campaign = () => {
    const navigate = useNavigate();

    return (
        <Container>
            <Row className='justify-content-center'>
                <Col md={10}>
                    <div className='start_campaign_main'>
                        <h5>Ready to start your own <br /> Camapign?</h5>
                        <button onClick={() => navigate("/starFundarisingForm")}>Start Campaigning</button>
                    </div>

                    <div className="ContactWithUSSection mt-5 mb-5">
                        <Row className="ContactUsContent justify-content-center">
                            <Col lg={8} md={12} sm={12} xs={12} className="mt-3">
                                <p className="ContactusText" style={{ letterSpacing: "0px" }}>
                                    Have more questions? Chat with us on Facebook or WhatsApp.</p>
                            </Col>
                            <Col lg={2} md={12} sm={12} xs={12} className="text-center">
                                <Button className="WhatsAppbutton w-100" style={{padding: "7px 0"}}>
                                    <img src={Whatsapp} className="mx-1" alt="whatsapp_icon" style={{ width: "22px" }} />
                                    Contact Us
                                </Button>
                            </Col>
                            <Col lg={2} md={12} sm={12} xs={12} className="text-center">
                                <a href='https://www.facebook.com/people/ShifaLia/100089498583736/?mibextid=LQQJ4d' className="Facebookbutton w-100" style={{padding: "7px 0"}}>
                                    <img src={Facebook} className="mx-1" alt="facebook_icon" style={{ width: "22px" }} />
                                    Contact Us
                                </a>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Campaign