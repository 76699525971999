// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.donationContainer {
    display: flex;
  align-items: center; 
  }
  
  .donationAmt {
    margin-right: 20px;
    display: inline-block;
    color: #000000;
    font-size: 11px;
    font-weight: 350;
    font-family: "Poppins";
  }
  .customBullet {
    display: inline-block;
    width: 7px;
    height: 7px;
    background-color: #014E7F;
    border-radius: 50%;
  }
  .hrLine{
    color: #F8F3EA;
    opacity: 100%;
    margin-top: -11px;
    border: 1px solid #F8F3EA;
  }
  .comments{
    margin-top: -15px !important;
    text-align: justify;
    color: #5A5A5A;
    font-size: 16px;
    font-weight: 300;
    font-family: "Poppins";
    letter-spacing: normal;
  }`, "",{"version":3,"sources":["webpack://./src/components/PatientsInfoPageComponent/WordsofSupportSection/WordsofSupportSection.css"],"names":[],"mappings":"AAAA;IACI,aAAa;EACf,mBAAmB;EACnB;;EAEA;IACE,kBAAkB;IAClB,qBAAqB;IACrB,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,sBAAsB;EACxB;EACA;IACE,qBAAqB;IACrB,UAAU;IACV,WAAW;IACX,yBAAyB;IACzB,kBAAkB;EACpB;EACA;IACE,cAAc;IACd,aAAa;IACb,iBAAiB;IACjB,yBAAyB;EAC3B;EACA;IACE,4BAA4B;IAC5B,mBAAmB;IACnB,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,sBAAsB;IACtB,sBAAsB;EACxB","sourcesContent":[".donationContainer {\n    display: flex;\n  align-items: center; \n  }\n  \n  .donationAmt {\n    margin-right: 20px;\n    display: inline-block;\n    color: #000000;\n    font-size: 11px;\n    font-weight: 350;\n    font-family: \"Poppins\";\n  }\n  .customBullet {\n    display: inline-block;\n    width: 7px;\n    height: 7px;\n    background-color: #014E7F;\n    border-radius: 50%;\n  }\n  .hrLine{\n    color: #F8F3EA;\n    opacity: 100%;\n    margin-top: -11px;\n    border: 1px solid #F8F3EA;\n  }\n  .comments{\n    margin-top: -15px !important;\n    text-align: justify;\n    color: #5A5A5A;\n    font-size: 16px;\n    font-weight: 300;\n    font-family: \"Poppins\";\n    letter-spacing: normal;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
