// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WhyFoundationContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d9e6e5;
  flex-wrap: wrap;
  margin-top: -100px !important;
}
.WaveImg {
  margin-top: -90px !important;
}

.undrawcompletedtasks {
  margin-left: 100px;
  max-width: 100%;
  height: auto;
}
.curve {
  position: absolute;
  width: 100%;
  height: 225px;
  top: -20%;
}
.liContent {
  font-family: "Poppins";
  font-size: 18px;
  color: #000000;
  font-weight: 400;
  align-items: center;
  padding-left: 100px;
  padding-right: 100px;
  text-align: justify;
}
.liContent li {
  list-style: disc;
}

@media screen and (max-width: 912px) {
  .WaveImg {
    margin-top: -150px !important;
  }

  .undrawcompletedtasks {
    max-width: 100%;
    margin-left: 0px;
  }
  .liContent {
    margin-top: 20px;
    font-size: 14px;
    padding-left: 40px;
    padding-right: 30px;
    text-align: justify;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/PricingPage1Components/WhyFoundationSection/WhyFoundationSection.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,yBAAyB;EACzB,eAAe;EACf,6BAA6B;AAC/B;AACA;EACE,4BAA4B;AAC9B;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,SAAS;AACX;AACA;EACE,sBAAsB;EACtB,eAAe;EACf,cAAc;EACd,gBAAgB;EAChB,mBAAmB;EACnB,mBAAmB;EACnB,oBAAoB;EACpB,mBAAmB;AACrB;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,6BAA6B;EAC/B;;EAEA;IACE,eAAe;IACf,gBAAgB;EAClB;EACA;IACE,gBAAgB;IAChB,eAAe;IACf,kBAAkB;IAClB,mBAAmB;IACnB,mBAAmB;EACrB;AACF","sourcesContent":[".WhyFoundationContainer {\n  position: relative;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: #d9e6e5;\n  flex-wrap: wrap;\n  margin-top: -100px !important;\n}\n.WaveImg {\n  margin-top: -90px !important;\n}\n\n.undrawcompletedtasks {\n  margin-left: 100px;\n  max-width: 100%;\n  height: auto;\n}\n.curve {\n  position: absolute;\n  width: 100%;\n  height: 225px;\n  top: -20%;\n}\n.liContent {\n  font-family: \"Poppins\";\n  font-size: 18px;\n  color: #000000;\n  font-weight: 400;\n  align-items: center;\n  padding-left: 100px;\n  padding-right: 100px;\n  text-align: justify;\n}\n.liContent li {\n  list-style: disc;\n}\n\n@media screen and (max-width: 912px) {\n  .WaveImg {\n    margin-top: -150px !important;\n  }\n\n  .undrawcompletedtasks {\n    max-width: 100%;\n    margin-left: 0px;\n  }\n  .liContent {\n    margin-top: 20px;\n    font-size: 14px;\n    padding-left: 40px;\n    padding-right: 30px;\n    text-align: justify;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
