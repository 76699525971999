// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCgnvdA5NfoGh_NGsdJ65IhZaNeeWMCflk",

  authDomain: "shifalia.firebaseapp.com",

  projectId: "shifalia",

  storageBucket: "shifalia.appspot.com",

  messagingSenderId: "16050600161",

  appId: "1:16050600161:web:e55f541fd9af5de63d9fa0",

  measurementId: "G-7Q2DJJPMQ8",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
