import React from 'react'
import Header from "../components/Header/header";
import Footer from '../components/Footer/footer'
import GigRequirementComponent from '../components/GigRequirementComponent/GigRequirementComponent';

const GigRequirement = () => {
    return (
        <>
            <Header />
            <GigRequirementComponent />
            <Footer />
        </>
    )
}

export default GigRequirement;