import React, { useState } from "react";
import "./footer.css";
import logoimg from "../../assets/WaitlistPageImages/waitlist_footer_logo.svg";
import { Col, Row, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "@fontsource/poppins";
import FooterModal from "./FooterModal/FooterModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  const [showFooterModal, setshowFooterModal] = useState(false);
  const closeFooterModal = () => setshowFooterModal(false);
  return (
    <footer className="_footer_">
      <Container>
        <Row>
          <Col lg={2} md={2} sm={12}>
            <div className="logofooter_logo">
              <img src={logoimg} alt="" className="w-10" />
            </div>
          </Col>
          <Col lg={3} md={3} sm={12}>
            <div className="Links_Section">
              <h6>Useful Links</h6>
              <ul className="links_items_section">
                {/* <a href="/about">
                  <li className="link_item">About</li>
                </a> */}
                <a href="/works">
                  <li className="link_item">How it Works</li>
                </a>
                {/* <a href=""><li className="link_item">Our Goal</li></a>
                                <a href=""><li className="link_item">Why Us</li></a> */}
                <a href="/allCases">
                  <li className="link_item">Our Cases</li>
                </a>
                <a href="/contactusPakistan">
                  <li className="link_item">Contact Us</li>
                </a>
              </ul>
            </div>
          </Col>
          <Col lg={3} md={3} sm={12}>
            <div className="Links_Section">
              <h6>Learn More</h6>
              <ul className="links_items_section">
                <a href="/faqs">
                  <li className="link_item">FAQs</li>
                </a>
                <a href="/fundraisingTips">
                  <li className="link_item">Campaigning Tips</li>
                </a>
                <a href="/fundraisingIdeas">
                  <li className="link_item">Campaigning Ideas</li>
                </a>
                <a href="/rasieConcern">
                  <li className="link_item">Raise a Concern</li>
                </a>
                <a href="/PricingPage">
                  <li className="link_item">Pricing</li>
                </a>
                <a href="/HelpCenter">
                  <li className="link_item">Help Center</li>
                </a>
              </ul>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12}>
            <div className="Links_Sec">
              <div id="links_">
                <a
                  href="https://www.youtube.com/@ShifaLia"
                  rel="noreferrer"
                  target="_blank"
                  className="youtubesocial"
                >
                  <FontAwesomeIcon icon={faYoutube} size="2x" />
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=100089498583736&mibextid=LQQJ4d"
                  rel="noreferrer"
                  target="_blank"
                  className="facebooksocial"
                >
                  <FontAwesomeIcon icon={faFacebook} size="2x" />
                </a>
                <a
                  href="https://www.instagram.com/shifaliapk/"
                  rel="noreferrer"
                  target="_blank"
                  className="instagramsocial"
                >
                  <FontAwesomeIcon icon={faInstagram} size="2x" />
                </a>
                <a
                  href="https://twitter.com/i/flow/login?redirect_after_login=%2FAlia5Foundation"
                  rel="noreferrer"
                  target="_blank"
                  className="twittersocial"
                >
                  <FontAwesomeIcon icon={faTwitter} size="2x" />
                </a>
                <a
                  href="https://www.linkedin.com/company/alia-nafees-foundation/mycompany/"
                  rel="noreferrer"
                  target="_blank"
                  className="linkedinsocial"
                >
                  <FontAwesomeIcon icon={faLinkedin} size="2x" />
                </a>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <hr className="hor-line mt-5"></hr>
        </Row>
        <Row className="mt-4 ">
          {/* <Col lg={6} md={5} sm={12} xs={12} className="optsec">
            <div
              className="opt_sec "
              onClick={() => {
                setshowFooterModal(true);
              }}
            >
              <p>United States </p>
              <p>
                <ul>
                  <li style={{ listStyle: "disc" }}> English</li>
                </ul>
              </p>
            </div>
            {showFooterModal && (
              <FooterModal closeFooterModal={closeFooterModal} />
            )}
          </Col> */}
          <Col lg={12} md={12} sm={12} xs={12} className="bottomlinks_sec">
            <div className="links_items_section">
              <ul className="bottom_links_sec">
                <a href="/trustSafety">
                  <li> Trust & Safety</li>
                </a>
                <a href="/privacypolicy">
                  <li style={{ listStyle: "disc" }}> Privacy Policy</li>
                </a>
                <a href="/termsOfUse">
                  <li style={{ listStyle: "disc" }}> Terms of Use</li>
                </a>
              </ul>
            </div>
          </Col>
        </Row>
        <Row className="mt-2">
          <div className="copyright_section">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              className="mx-1"
            >
              <path
                d="M5.656 6.202C5.691 5.971 5.768 5.768 5.866 5.593C5.964 5.418 6.104 5.271 6.279 5.159C6.447 5.054 6.657 5.005 6.916 4.998C7.077 5.005 7.224 5.033 7.357 5.089C7.497 5.152 7.623 5.236 7.721 5.341C7.819 5.446 7.896 5.572 7.959 5.712C8.022 5.852 8.05 6.006 8.057 6.16H9.31C9.296 5.831 9.233 5.53 9.114 5.257C8.995 4.984 8.834 4.746 8.624 4.55C8.414 4.354 8.162 4.2 7.868 4.088C7.574 3.976 7.252 3.927 6.895 3.927C6.44 3.927 6.041 4.004 5.705 4.165C5.369 4.326 5.089 4.536 4.865 4.809C4.641 5.082 4.473 5.397 4.368 5.761C4.263 6.125 4.2 6.503 4.2 6.909V7.098C4.2 7.504 4.256 7.882 4.361 8.246C4.466 8.61 4.634 8.925 4.858 9.191C5.082 9.457 5.362 9.674 5.698 9.828C6.034 9.982 6.433 10.066 6.888 10.066C7.217 10.066 7.525 10.01 7.812 9.905C8.099 9.8 8.351 9.653 8.568 9.464C8.785 9.275 8.96 9.058 9.086 8.806C9.212 8.554 9.289 8.288 9.296 8.001H8.043C8.036 8.148 8.001 8.281 7.938 8.407C7.875 8.533 7.791 8.638 7.686 8.729C7.581 8.82 7.462 8.89 7.322 8.939C7.189 8.988 7.049 9.002 6.902 9.009C6.65 9.002 6.44 8.953 6.279 8.848C6.104 8.736 5.964 8.589 5.866 8.414C5.768 8.239 5.691 8.029 5.656 7.798C5.621 7.567 5.6 7.329 5.6 7.098V6.909C5.6 6.664 5.621 6.433 5.656 6.202ZM7 0C3.136 0 0 3.136 0 7C0 10.864 3.136 14 7 14C10.864 14 14 10.864 14 7C14 3.136 10.864 0 7 0ZM7 12.6C3.913 12.6 1.4 10.087 1.4 7C1.4 3.913 3.913 1.4 7 1.4C10.087 1.4 12.6 3.913 12.6 7C12.6 10.087 10.087 12.6 7 12.6Z"
                fill="#B1B1B1"
              />
            </svg>
            <p className="mx-1 pt-3">2024 ShifaLia</p>
          </div>
        </Row>
      </Container>
    </footer>
  );
};
export default Footer;
