import React from 'react';
import './Faqs.css';

const Banner = () => {
  return (
    <div className='faq_banner_main'>
        <h1>Get to know about fundraising on Aliacare!</h1>
    </div>
  )
}

export default Banner