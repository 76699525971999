// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BannerSection{
    background: #014E7F;
}
.LetsConnectHeading{
    font-size: 38px;
    font-weight: 900;
    font-family: 'Poppins';
    color: #FFFFFF;
}
.letsconnectdescription, .FAQstxt{
    font-size: 20px;
    font-weight: 400;
    font-family: 'Poppins';
   
}
.letsconnectdescription{
    color: #F0ECEC;
}
.FAQstxt{
    color: #FFFFFF;
}`, "",{"version":3,"sources":["webpack://./src/components/ContactUsPage_PakistanComponents/BannerSection/Banner.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,sBAAsB;IACtB,cAAc;AAClB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,sBAAsB;;AAE1B;AACA;IACI,cAAc;AAClB;AACA;IACI,cAAc;AAClB","sourcesContent":[".BannerSection{\n    background: #014E7F;\n}\n.LetsConnectHeading{\n    font-size: 38px;\n    font-weight: 900;\n    font-family: 'Poppins';\n    color: #FFFFFF;\n}\n.letsconnectdescription, .FAQstxt{\n    font-size: 20px;\n    font-weight: 400;\n    font-family: 'Poppins';\n   \n}\n.letsconnectdescription{\n    color: #F0ECEC;\n}\n.FAQstxt{\n    color: #FFFFFF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
