import React from "react";
import "./ViewAllUpdates.css";
import { Col, Row, Container } from "react-bootstrap";
import { Section } from "../../LandingPageComponents/OurGoals/OurGoalsElement";
import Patientstoryimg1 from "../../../assets/images/Patientstoryimg1.png";
import Patientstoryimg2 from "../../../assets/images/Patientstoryimg2.png";
import Patientstoryimg3 from "../../../assets/images/Patientstoryimg3.png";
import { Provider } from "../../../context/Context";

const ViewAllUpdates = () => {
  const { caseById } = Provider();
  return (
    <>
      <Section className="viewAllUpdatesSection py-5">
        <Container>
          <Col
            lg={12}
            md={12}
            xs={12}
            className="StorySection pt-2 px-2 mb-5 pb-5"
          >
            <Row>
              <div className="UpdateRow">
                <div className="left-div">
                  <p className="StoryTitle px-3 mb-4 my-4">
                    Updates ({caseById?.CaseInfo?.length})
                  </p>
                </div>
                <div className="right-div px-3">
                  <div className="sortBy mb-4 my-4">
                    <p className="sortbytxt mx-1">Sort By:</p>
                    <select className="optiontxt mx-2">
                      <option className="sort_Options">Recent</option>
                      <option className="sort_Options">Old</option>
                    </select>
                  </div>
                </div>
              </div>
            </Row>
            <Row>
              <Col lg={12} md={12} xs={12}>
                <hr className="Line"></hr>
              </Col>
            </Row>
            {caseById?.CaseInfo?.map((item) => (
              <Col>
                <Row className="mx-3">
                  <p className="UpdateNoTxt mt-4">{item?.updateTitleNews}</p>
                  <p className="Update_date">{item?.addedDB}</p>
                </Row>

                <Row className="mx-3">
                  <p className="storyContent">{item?.updateDescription}</p>
                </Row>
                <Row className=" mt-4 mx-3">
                  <img
                    className="Patientstoryimg"
                    alt=""
                    src={item.ImageLinks[0]}
                  ></img>
                  {item?.ImageLinks?.length > 2 && (
                    <>
                      <img
                        className="Patientstoryimg"
                        alt=""
                        src={item.ImageLinks[1]}
                      ></img>
                      <img
                        className="Patientstoryimg"
                        alt=""
                        src={item.ImageLinks[2]}
                      ></img>
                    </>
                  )}
                </Row>
                <Row className="mx-2 mt-3">
                  <Col lg={12} md={12} xs={12}>
                    <hr className="PartitionLine"></hr>
                  </Col>
                </Row>
              </Col>
            ))}

            {/* <Col>
              <Row className="mx-3">
                <p className="UpdateNoTxt mt-4">Update#02</p>
                <p className="Update_date">10-Aug-2022</p>
              </Row>

              <Row className="mx-3">
                <p className="storyContent">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras
                  sed neque in orci pharetra volutpat sit amet a nunc. Ut
                  sodales iaculis urna. Nullam suscipit lacus lorem. Aliquam
                  viverra maximus diam vitae faucibus. Mauris tristique ornare
                  hendrerit. Etiam cursus faucibus elit at interdum. Proin quis
                  urna vitae neque faucibus viverra rhoncus quis ipsum. Integer
                  tortor sem, vehicula in fringilla vel, sollicitudin eu lorem.
                  Curabitur ut aliquam nisi, in porttitor dolor.<br></br>{" "}
                  Aliquam sit amet tempus eros, at vulputate diam. Sed luctus
                  ante ipsum, sit amet facilisis velit fringilla non. Quisque
                  non scelerisque nibh, vel commodo quam. Nullam aliquam lorem
                  vel pulvinar volutpat. Praesent tincidunt, nulla quis viverra
                  ornare, massa magna efficitur libero, in elementum ligula ex
                  vitae sem. Donec quis justo justo. Vivamus venenatis leo nec
                  feugiat luctus. Nulla tempor turpis ut metus tempus, id
                  euismod purus lacinia.
                </p>
              </Row>
              <Row className=" mt-4 mx-3">
                <img
                  className="Patientstoryimg"
                  alt=""
                  src={Patientstoryimg1}
                ></img>
                <img
                  className="Patientstoryimg"
                  alt=""
                  src={Patientstoryimg2}
                ></img>
                <img
                  className="Patientstoryimg"
                  alt=""
                  src={Patientstoryimg3}
                ></img>
              </Row>
              <Row className="mx-2 mt-3">
                <Col lg={12} md={12} xs={12}>
                  <hr className="PartitionLine"></hr>
                </Col>
              </Row>
            </Col>

            <Col>
              <Row className="mx-3">
                <p className="UpdateNoTxt mt-4">Update#01</p>
                <p className="Update_date">1-Aug-2022</p>
              </Row>

              <Row className="mx-3">
                <p className="storyContent">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras
                  sed neque in orci pharetra volutpat sit amet a nunc. Ut
                  sodales iaculis urna. Nullam suscipit lacus lorem. Aliquam
                  viverra maximus diam vitae faucibus. Mauris tristique ornare
                  hendrerit. Etiam cursus faucibus elit at interdum. Proin quis
                  urna vitae neque faucibus viverra rhoncus quis ipsum. Integer
                  tortor sem, vehicula in fringilla vel, sollicitudin eu lorem.
                  Curabitur ut aliquam nisi, in porttitor dolor.<br></br>{" "}
                  Aliquam sit amet tempus eros, at vulputate diam. Sed luctus
                  ante ipsum, sit amet facilisis velit fringilla non. Quisque
                  non scelerisque nibh, vel commodo quam. Nullam aliquam lorem
                  vel pulvinar volutpat. Praesent tincidunt, nulla quis viverra
                  ornare, massa magna efficitur libero, in elementum ligula ex
                  vitae sem. Donec quis justo justo. Vivamus venenatis leo nec
                  feugiat luctus. Nulla tempor turpis ut metus tempus, id
                  euismod purus lacinia.
                </p>
              </Row>
              <Row className=" mt-4 mx-3">
                <img
                  className="Patientstoryimg"
                  alt=""
                  src={Patientstoryimg1}
                ></img>
                <img
                  className="Patientstoryimg"
                  alt=""
                  src={Patientstoryimg2}
                ></img>
                <img
                  className="Patientstoryimg"
                  alt=""
                  src={Patientstoryimg3}
                ></img>
              </Row>
              <Row className="mx-2 mt-3">
                <Col lg={12} md={12} xs={12}>
                  <hr className="PartitionLine"></hr>
                </Col>
              </Row>
            </Col> */}
          </Col>
        </Container>
      </Section>
    </>
  );
};

export default ViewAllUpdates;
