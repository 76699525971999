import ArticleImg1 from "../../../assets/images/ArticleImg1.png"
import ArticleImg2 from "../../../assets/images/ArticleImg2.png"
import ArticleImg3 from "../../../assets/images/ArticleImg3.png"
import ArticleImg4 from "../../../assets/images/ArticleImg4.png"
import ArticleImg5 from "../../../assets/images/ArticleImg5.png"
import ArticleImg6 from "../../../assets/images/ArticleImg6.png"
import ArticleImg7 from "../../../assets/images/ArticleImg7.png"
import ArticleImg8 from "../../../assets/images/ArticleImg8.png"

const ArticlesData=[
    {
        id: 1,
        ImgUrl: ArticleImg1,
        ArticleTitle: 'Unbreakable Spirit: Stories of Resilience in the Face of Illness',
        ArticleAuthorName: 'By Author Name',
        ArticleDate: '21-may-22'
    },
    {
        id: 2,
        ImgUrl: ArticleImg2,
        ArticleTitle: 'Beyond the Diagnosis: Redefining Life with Chronic Illness',
        ArticleAuthorName: 'By Author Name',
        ArticleDate: '21-may-22'
    },
    {
        id: 3,
        ImgUrl: ArticleImg3,
        ArticleTitle: 'Shining a Light on Unseen Struggles',
        ArticleAuthorName: 'By Author Name',
        ArticleDate: '21-may-22'
    },
    {
        id: 4,
        ImgUrl: ArticleImg4,
        ArticleTitle: 'Shining a Light on Unseen Struggles',
        ArticleAuthorName: 'By Author Name',
        ArticleDate: '21-may-22'
    },
    {
        id: 5,
        ImgUrl: ArticleImg5,
        ArticleTitle: 'Shining a Light on Unseen Struggles',
        ArticleAuthorName: 'By Author Name',
        ArticleDate: '21-may-22'
    },
    {
        id: 6,
        ImgUrl: ArticleImg6,
        ArticleTitle: 'Shining a Light on Unseen Struggles',
        ArticleAuthorName: 'By Author Name',
        ArticleDate: '21-may-22'
    },
    {
        id: 7,
        ImgUrl: ArticleImg7,
        ArticleTitle: 'Shining a Light on Unseen Struggles',
        ArticleAuthorName: 'By Author Name',
        ArticleDate: '21-may-22'
    },
    {
        id: 8,
        ImgUrl: ArticleImg8,
        ArticleTitle: 'Shining a Light on Unseen Struggles',
        ArticleAuthorName: 'By Author Name',
        ArticleDate: '21-may-22'
    },
]

export default ArticlesData