import React, { useState } from "react";
import { Col, Row, Container, Button } from "react-bootstrap";
import Wavepattern from "../../../assets/images/wavepattern.png";
import "./GoalCalculatorSection.css";

const GoalCalculatorSection = () => {
  const [showImgContainer, setShowImgContainer] = useState(false);
  const [amount, setAmount] = useState("");
  const [resultAmount, setResultAmount] = useState("");
  const [currencyType, setCurrencyType] = useState("");
  const [resultcurrencyType, setResultcurrencyType] = useState("");
  const [platformFee, setPlatformFee] = useState("");

  const handleCalculateBtnClick = () => {
    if (amount == "" || currencyType == "") {
      return;
    }
    setShowImgContainer(true);
    setResultAmount(amount);
    setResultcurrencyType(currencyType);
    const calOnePercent = amount / 100;
    setPlatformFee(calOnePercent);
  };

  return (
    <>
      <Container className="GoalCalculatorContainer d-flex mt-5 justify-content-center align-items-center">
        <Row className="d-flex justify-content-center align-items-center">
          <h4 className="goalCal_heading">Campaigning Goal Calculator</h4>
          <hr className="goalCal_line mt-2" />
          <p className="goalCal_p px-5">
            Raise maximum funds for the cause you care about
          </p>
        </Row>
      </Container>

      <Container className="my-5 mt-4">
        <Row className="mb-5">
          <Col lg={5} md={7}>
            <Container className="AmtContainer">
              <Row
                className="align-items-center px-3 pt-4"
                style={{ position: "relative" }}
              >
                <Col
                  lg={4}
                  md={4}
                  xs={12}
                  className="d-flex align-items-center"
                >
                  <p className="wantToRaiseTxt pt-4">I want to raise</p>
                </Col>
                <Col lg={3} md={2} xs={4} className="d-flex align-items-center">
                  <select
                    onChange={(e) => setCurrencyType(e.target.value)}
                    className="CurrencyInput flex-grow-1"
                  >
                    <option>Select</option>
                    <option value="Rs.">Rs</option>
                    <option value="USD">USD</option>
                  </select>
                </Col>
                <Col lg={5} md={6} xs={8} className="d-flex align-items-center">
                  <input
                    className="wantToRaiseInput"
                    placeholder="Amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </Col>
                <hr className="bottomLine"></hr>
              </Row>
              {currencyType && amount && (
                <>
                  <Row className="d-flex justify-content-center align-items-center px-5 mt-4">
                    <Col lg={4} id="comment-box">
                      <p className="pt-2">{`${currencyType} ${amount}`}</p>
                    </Col>
                  </Row>
                  <Row className="progress mx-4 mt-3">
                    <div className="progressbar" style={{ width: "20%" }}></div>
                  </Row>
                </>
              )}
              <Row className="d-flex justify-content-center align-items-center py-4">
                <Button
                  className="CalculateBtn"
                  onClick={handleCalculateBtnClick}
                >
                  Calculate
                </Button>
              </Row>
            </Container>
          </Col>

          {showImgContainer && (
            <Col lg={7} md={12} xs={12} sm={12} className="ImgContainer">
              <Row>
                <img
                  src={Wavepattern}
                  alt="Want Pattern"
                  className="img-fluid pattern-image"
                />
                <Col>
                  <div className="Img_content ">
                    <Row>
                      <h6 className="goalHeading">
                        Consider setting a goal of approx.
                      </h6>
                    </Row>
                    <Row>
                      <h2 className="amttxt">{`${resultcurrencyType} ${
                        Number(resultAmount) + Number(platformFee)
                      }`}</h2>
                    </Row>
                    <Row>
                      <p className="discliamertxt">
                        Disclaimer: This goal is the approximate amount you
                        should consider setting where we assume that you would
                        receive 70% of the total funds from PKR contributions.
                      </p>
                    </Row>
                    <Row>
                      <h5 className="AmtBreakupTxt">Amount Breakup</h5>
                    </Row>
                    <Row>
                      <div className=" d-flex align-items-center justify-content-between R1Txt">
                        <span>
                          <i
                            class="ri-wantToraiseTxt-line"
                            className="wantToraiseTxt"
                          >
                            Want to raise ({`${resultcurrencyType}`})
                          </i>
                        </span>

                        <div className=" d-flex align-items-center gap-3">
                          <span className=" d-flex align-items-center gap-1 ">
                            <i class="ri-TAmt-line" className="TAmt">
                              {resultAmount}
                            </i>
                          </span>
                        </div>
                      </div>
                    </Row>
                    <Row>
                      <div className=" d-flex align-items-center justify-content-between R2Txt">
                        <span>
                          <i
                            class="ri-wantToraiseTxt-line"
                            className="wantToraiseTxt"
                          >
                            Platform Fee ({`${resultcurrencyType}`})
                          </i>
                        </span>

                        <div className=" d-flex align-items-center gap-3">
                          <span className=" d-flex align-items-center gap-1 ">
                            <i class="ri-TAmt-line" className="TAmt">
                              {platformFee}
                            </i>
                          </span>
                        </div>
                      </div>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default GoalCalculatorSection;
