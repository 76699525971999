import React from 'react'
import Header from "../components/Header/header"
import ViewAllUpdate from "../components/PatientsInfoPageComponent/ViewAllUpdates/ViewAllUpdates"
import Footer from "../components/Footer/footer"

const ViewAllUpdates = () => {
  return (
    <div>
      <Header />
      <ViewAllUpdate />
      <Footer />
    </div>
  )
}

export default ViewAllUpdates