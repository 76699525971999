import React, { useEffect, useState } from "react";
import { Col, Row, Container, Button } from "react-bootstrap";
import CategoriesNextbtn from "../../../assets/images/CategoriesNextbtn.png";
import CategoriesPreviousbtn from "../../../assets/images/CategoriesPreviousbtn.png";
import "./CategoriesSection.css";
import "react-datepicker/dist/react-datepicker.css";
import { GET_DICTIONARY_QUERY } from "../../../services/methods/Query";
import { useQuery } from "@apollo/client";
import { Provider } from "../../../context/Context";
import ReactDatePicker from "react-datepicker";
import * as moment from "moment";

const SearchSection = () => {
  const { data } = useQuery(GET_DICTIONARY_QUERY);
  const arrOfData = data?.getDictionary?.data;

  const categories = arrOfData?.filter((item) => {
    return item?.group === "Categories";
  });
  const [cdate, setcDate] = useState("");
  const { catId, setCatId, setDate, date } = Provider();

  const showDate = moment(cdate).format("DD-MM-YYYY");
  const new_date = moment(cdate).unix();

  useEffect(() => {
    if (cdate) {
      setDate(showDate);
    }
    console.log(date);
  }, [cdate]);

  //   const location = arrOfData?.filter((item) => {
  //     return item?.group === "Country";
  //   });

  const itemsToShow = 2;
  const [startIndex, setStartIndex] = useState(0);
  const [tab, setTab] = useState("All Categories");

  const handleNextClick = () => {
    const nextIndex = startIndex + itemsToShow;
    if (nextIndex <= categories?.length - itemsToShow) {
      setStartIndex(nextIndex);
    }
    setTab("All Categories");
  };

  const handlePrevClick = () => {
    const prevIndex = startIndex - itemsToShow;
    if (prevIndex >= 0) {
      setStartIndex(prevIndex);
    }
    setTab("All Categories");
  };
  const totalDots = Math.ceil(categories?.length / itemsToShow);

  const handleDotClick = (index) => {
    setStartIndex(index * itemsToShow);
    setTab("All Categories");
  };

  const tabHandler = (category) => {
    setTab(category);
    setCatId(category._id);
    setDate("");
    setcDate("");
  };

  return (
    <>
      <Container fluid>
        <div className="CategoriesContainer my-4">
          <h5 className="CategoryHeading mb-4 mt-3">Categories</h5>
          <div className="category_wrapper">
            <img
              src={CategoriesNextbtn}
              alt="CategoriesNextbtn"
              className="CategorSlidertbtn"
              onClick={handlePrevClick}
            />

            <div style={{ display: "flex", gap: "10px" }}>
              <Button
                className={
                  tab === "All Categories"
                    ? "AllCategoriesBtn text-center"
                    : "CategoriesTypeBtn"
                }
                onClick={() => {
                  setCatId("");
                  setDate("");
                  setcDate("");
                  setTab("All Categories");
                }}
                type="submit"
              >
                All Categories
              </Button>
              {categories
                ?.slice(startIndex, startIndex + itemsToShow)
                ?.map((category, index) => (
                  <Button
                    key={index}
                    className={
                      tab === category
                        ? "AllCategoriesBtn text-center"
                        : "CategoriesTypeBtn"
                    }
                    onClick={() => tabHandler(category)}
                  >
                    {category.code}
                  </Button>
                ))}
            </div>

            <img
              src={CategoriesPreviousbtn}
              alt="CategoriesPreviousbtn"
              className="CategorSlidertbtn"
              onClick={handleNextClick}
            />
          </div>

          <div className="mt-4 mb-4 d-flex justify-content-center">
            {Array.from({ length: totalDots }).map((_, index) => (
              <p
                key={index}
                className={
                  startIndex === index * itemsToShow
                    ? "selected-dot"
                    : "unselected-dot"
                }
                onClick={() => handleDotClick(index)}
              ></p>
            ))}
          </div>
          <Row className="mx-4 mb-4 mt-3">
            {/* <Col lg={3} md={4} xs={12} sm={12} className="mb-2">
              <div className="d-flex w-100">
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <ReactDatePicker
                    placeholderText="select date"
                    value={cdate}
                    onChange={setcDate}
                    maxDate={new Date()}
                    icon="fa fa-calendar"
                  />
                  {cdate && <p>{showDate}</p>}
                </div>
              </div>
            </Col> */}
            {/* <Col lg={3} md={4} xs={12} sm={12}>
              <div className="d-flex w-100">
                <select className="Locationbtn flex-grow-1">
                  {location?.map((item) => (
                    <>
                      <option selected disabled hidden>
                        Location
                      </option>
                      <option value="Location">{item?.code}</option>
                    </>
                  ))}
                </select>
              </div>
            </Col> */}
          </Row>
        </div>
      </Container>
    </>
  );
};

export default SearchSection;
