import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.css";
import { ApolloProvider } from "@apollo/client";
import client from "./services/GraphQLClient";
import { Toaster } from "react-hot-toast";
import { ContextProvider } from "../src/context/Context";
import { Provider } from "react-redux";
import { store } from "./redux/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <ContextProvider>
          <div>
            <Toaster position="top-right" reverseOrder={false} />
            <App />
          </div>
        </ContextProvider>
      </Provider>
    </ApolloProvider>
  // </React.StrictMode>
);
