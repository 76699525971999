// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AllcasesPageCom{
    background-color: rgb(248, 243, 234);
}
.LoadMoreSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.LoadMoreSection .loadMoretxt {
background-color: #014E7F;
padding: 10px 20px;
color: white;
font-style: normal;
text-align: center;
display: flex;
align-items: center;
justify-content: center;
border-radius: 50px;
border: none;
}

.loadmoreImg {
    cursor: pointer;
}

`, "",{"version":3,"sources":["webpack://./src/components/AllCasesPageComponents/AllCasesMainPageComponents/AllCasesMainPageCom.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;AACxC;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;AACA,yBAAyB;AACzB,kBAAkB;AAClB,YAAY;AACZ,kBAAkB;AAClB,kBAAkB;AAClB,aAAa;AACb,mBAAmB;AACnB,uBAAuB;AACvB,mBAAmB;AACnB,YAAY;AACZ;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".AllcasesPageCom{\n    background-color: rgb(248, 243, 234);\n}\n.LoadMoreSection {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 20px;\n}\n\n.LoadMoreSection .loadMoretxt {\nbackground-color: #014E7F;\npadding: 10px 20px;\ncolor: white;\nfont-style: normal;\ntext-align: center;\ndisplay: flex;\nalign-items: center;\njustify-content: center;\nborder-radius: 50px;\nborder: none;\n}\n\n.loadmoreImg {\n    cursor: pointer;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
