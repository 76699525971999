import styled, { keyframes } from 'styled-components';

export const Button = styled.button`
background: ${({ backgroundColor }) => backgroundColor};
color: ${({ textColor }) => textColor};
${'' /* background: #B5D5D4;
color: #000000; */}
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 5px;
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 15px;
font-size: ${({small}) => (small ? '15px': '18px')};
line-height: 30px;
text-align: center;
${'' /* padding: 12px 15px; */}
padding: ${({small}) => (small ? '9px 20px': '12px 30px')};
display:flex;
 justify-content: center;
align-items: center;
border: ${({border}) => (border ? '2px solid black': 'none')};
${'' /* border:3px solid white; */}
&:hover {
  ${'' /* color: ${({hoverEffect}) => (hoverEffect ? 'black': 'white')}; */}
  ${'' /* background-color: ${({hoverEffect}) => (hoverEffect ? '#fcdc18': 'white')}; */}
  ${'' /* border: ${({hoverEffect}) => (hoverEffect ? '3px solid black': '3px solid white')}; */}
  transform: scale(1.02);
}

@media screen and (max-width: 460px) {
    font-size: 12px;
    font-size: ${({small}) => (small ? '15px': '12px')};
    padding: ${({small}) => (small ? '9px 20px': '12px 10px')};
`