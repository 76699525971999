import React from "react";
import tipsbannerImg from "../../../assets/FundraisingTipsImages/tipsBanner.png";
import "./tipsbanner.scss";

const Tipsbanner = () => {
  return (
    <>
      <div className="tipsbanner-section">
        <div className="tipsBanner">
          <div className="tipsbanner-content">
            <h5>Practical & Efficient Tips for successful Campaign</h5>
            <img src={tipsbannerImg} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Tipsbanner;
