import React from 'react'
import { Container } from 'react-bootstrap'

const Step1 = () => {
    const [dragActive, setDragActive] = React.useState(false);
    // ref
    const inputRef = React.useRef(null);

    // handle drag events
    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            // handleFiles(e.dataTransfer.files);
        }
    };

    // triggers when file is selected with click
    const handleChange = function (e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            // handleFiles(e.target.files);
        }
    };

    // triggers the input when the button is clicked
    const onButtonClick = () => {
        inputRef.current.click();
    };

    return (
        <>
            <h3>Gig Requirements</h3>
            <p>Assist the seller by providing more information that will give them a better idea of your needs</p>

            <div>
                <ul class="step_progressbar">
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>

            <div>
                <h6>Provide seller with the necessary images:  </h6>

                <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                    <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} />
                    <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : ""}>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 200 160" fill="none">
                                <g clip-path="url(#clip0_3015_135)">
                                    <path d="M66.6667 46.6667C66.6667 52.2222 64.7222 56.9444 60.8333 60.8333C56.9444 64.7222 52.2222 66.6667 46.6667 66.6667C41.1111 66.6667 36.3889 64.7222 32.5 60.8333C28.6111 56.9444 26.6667 52.2222 26.6667 46.6667C26.6667 41.1111 28.6111 36.3889 32.5 32.5C36.3889 28.6111 41.1111 26.6667 46.6667 26.6667C52.2222 26.6667 56.9444 28.6111 60.8333 32.5C64.7222 36.3889 66.6667 41.1111 66.6667 46.6667ZM173.333 86.6667V133.333H26.6667V113.333L60 80L76.6667 96.6667L130 43.3333L173.333 86.6667ZM183.333 13.3333H16.6667C15.7639 13.3333 14.9826 13.6632 14.3229 14.3229C13.6632 14.9826 13.3333 15.7639 13.3333 16.6667V143.333C13.3333 144.236 13.6632 145.017 14.3229 145.677C14.9826 146.337 15.7639 146.667 16.6667 146.667H183.333C184.236 146.667 185.017 146.337 185.677 145.677C186.337 145.017 186.667 144.236 186.667 143.333V16.6667C186.667 15.7639 186.337 14.9826 185.677 14.3229C185.017 13.6632 184.236 13.3333 183.333 13.3333ZM200 16.6667V143.333C200 147.917 198.368 151.84 195.104 155.104C191.84 158.368 187.917 160 183.333 160H16.6667C12.0833 160 8.15972 158.368 4.89583 155.104C1.63194 151.84 0 147.917 0 143.333V16.6667C0 12.0833 1.63194 8.15972 4.89583 4.89583C8.15972 1.63194 12.0833 0 16.6667 0H183.333C187.917 0 191.84 1.63194 195.104 4.89583C198.368 8.15972 200 12.0833 200 16.6667Z" fill="#9B9B9B" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_3015_135">
                                        <rect width="200" height="160" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>

                            <p>Drag a photo</p>
                            <button className="upload_button" onClick={onButtonClick}>Upload a Photo</button>
                        </div>
                    </label>
                    {dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
                </form>

                <div className='progress_box'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 30 30" fill="none">
                        <g clip-path="url(#clip0_3015_151)">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 15C0 11.0218 1.58035 7.20644 4.3934 4.3934C7.20644 1.58035 11.0218 0 15 0C18.9782 0 22.7936 1.58035 25.6066 4.3934C28.4196 7.20644 30 11.0218 30 15C30 18.9782 28.4196 22.7936 25.6066 25.6066C22.7936 28.4196 18.9782 30 15 30C11.0218 30 7.20644 28.4196 4.3934 25.6066C1.58035 22.7936 0 18.9782 0 15ZM14.144 21.42L22.78 10.624L21.22 9.376L13.856 18.578L8.64 14.232L7.36 15.768L14.144 21.42Z" fill="#D8D8D8" />
                        </g>
                        <defs>
                            <clipPath id="clip0_3015_151">
                                <rect width="30" height="30" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>

                    <div className='d-flex align-items-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 50 50" fill="none">
                            <path d="M15 5C13.6739 5 12.4021 5.52678 11.4645 6.46447C10.5268 7.40215 10 8.67392 10 10V40C10 41.3261 10.5268 42.5979 11.4645 43.5355C12.4021 44.4732 13.6739 45 15 45H35C36.3261 45 37.5979 44.4732 38.5355 43.5355C39.4732 42.5979 40 41.3261 40 40V18.535C39.9991 17.5408 39.6035 16.5876 38.9 15.885L29.115 6.0975C28.412 5.39495 27.4589 5.00021 26.465 5H15ZM12.5 10C12.5 9.33696 12.7634 8.70107 13.2322 8.23223C13.7011 7.76339 14.337 7.5 15 7.5H25V16.25C25 17.2446 25.3951 18.1984 26.0983 18.9017C26.8016 19.6049 27.7554 20 28.75 20H37.5V40C37.5 40.663 37.2366 41.2989 36.7678 41.7678C36.2989 42.2366 35.663 42.5 35 42.5H15C14.337 42.5 13.7011 42.2366 13.2322 41.7678C12.7634 41.2989 12.5 40.663 12.5 40V10ZM36.9825 17.5H28.75C28.4185 17.5 28.1005 17.3683 27.8661 17.1339C27.6317 16.8995 27.5 16.5815 27.5 16.25V8.0175L36.9825 17.5Z" fill="#383838" />
                        </svg>
                        <h6 style={{ fontSize: "11px", marginBottom: "0" }}>Image1.png <br />  <span>441 kb</span> </h6>
                    </div>

                    <div class="progress">
                        <div class="progress-bar" role="progressbar" style={{ width: "75%" }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>

                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 50 50" fill="none" style={{ cursor: "pointer" }}>
                        <path d="M25.0007 44.0476C35.5205 44.0476 44.0484 35.5197 44.0484 25C44.0484 14.4803 35.5205 5.95239 25.0007 5.95239C14.481 5.95239 5.95312 14.4803 5.95312 25C5.95312 35.5197 14.481 44.0476 25.0007 44.0476Z" stroke="#383838" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M17.8594 17.8572L32.1451 32.1429M32.1451 17.8572L17.8594 32.1429" stroke="#383838" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>

                <div className='progress_box'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 30 30" fill="none">
                        <g clip-path="url(#clip0_3015_151)">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 15C0 11.0218 1.58035 7.20644 4.3934 4.3934C7.20644 1.58035 11.0218 0 15 0C18.9782 0 22.7936 1.58035 25.6066 4.3934C28.4196 7.20644 30 11.0218 30 15C30 18.9782 28.4196 22.7936 25.6066 25.6066C22.7936 28.4196 18.9782 30 15 30C11.0218 30 7.20644 28.4196 4.3934 25.6066C1.58035 22.7936 0 18.9782 0 15ZM14.144 21.42L22.78 10.624L21.22 9.376L13.856 18.578L8.64 14.232L7.36 15.768L14.144 21.42Z" fill="#D8D8D8" />
                        </g>
                        <defs>
                            <clipPath id="clip0_3015_151">
                                <rect width="30" height="30" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>

                    <div className='d-flex align-items-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 50 50" fill="none">
                            <path d="M15 5C13.6739 5 12.4021 5.52678 11.4645 6.46447C10.5268 7.40215 10 8.67392 10 10V40C10 41.3261 10.5268 42.5979 11.4645 43.5355C12.4021 44.4732 13.6739 45 15 45H35C36.3261 45 37.5979 44.4732 38.5355 43.5355C39.4732 42.5979 40 41.3261 40 40V18.535C39.9991 17.5408 39.6035 16.5876 38.9 15.885L29.115 6.0975C28.412 5.39495 27.4589 5.00021 26.465 5H15ZM12.5 10C12.5 9.33696 12.7634 8.70107 13.2322 8.23223C13.7011 7.76339 14.337 7.5 15 7.5H25V16.25C25 17.2446 25.3951 18.1984 26.0983 18.9017C26.8016 19.6049 27.7554 20 28.75 20H37.5V40C37.5 40.663 37.2366 41.2989 36.7678 41.7678C36.2989 42.2366 35.663 42.5 35 42.5H15C14.337 42.5 13.7011 42.2366 13.2322 41.7678C12.7634 41.2989 12.5 40.663 12.5 40V10ZM36.9825 17.5H28.75C28.4185 17.5 28.1005 17.3683 27.8661 17.1339C27.6317 16.8995 27.5 16.5815 27.5 16.25V8.0175L36.9825 17.5Z" fill="#383838" />
                        </svg>
                        <h6 style={{ fontSize: "11px", marginBottom: "0" }}>Image1.png <br />  <span>441 kb</span> </h6>
                    </div>

                    <div class="progress">
                        <div class="progress-bar" role="progressbar" style={{ width: "75%" }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>

                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 50 50" fill="none" style={{ cursor: "pointer" }}>
                        <path d="M25.0007 44.0476C35.5205 44.0476 44.0484 35.5197 44.0484 25C44.0484 14.4803 35.5205 5.95239 25.0007 5.95239C14.481 5.95239 5.95312 14.4803 5.95312 25C5.95312 35.5197 14.481 44.0476 25.0007 44.0476Z" stroke="#383838" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M17.8594 17.8572L32.1451 32.1429M32.1451 17.8572L17.8594 32.1429" stroke="#383838" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>

                <div className='progress_box'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 30 30" fill="none">
                        <g clip-path="url(#clip0_3015_179)">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 15C0 11.0218 1.58035 7.20644 4.3934 4.3934C7.20644 1.58035 11.0218 0 15 0C18.9782 0 22.7936 1.58035 25.6066 4.3934C28.4196 7.20644 30 11.0218 30 15C30 18.9782 28.4196 22.7936 25.6066 25.6066C22.7936 28.4196 18.9782 30 15 30C11.0218 30 7.20644 28.4196 4.3934 25.6066C1.58035 22.7936 0 18.9782 0 15ZM14.144 21.42L22.78 10.624L21.22 9.376L13.856 18.578L8.64 14.232L7.36 15.768L14.144 21.42Z" fill="#78DF67" />
                        </g>
                        <defs>
                            <clipPath id="clip0_3015_179">
                                <rect width="30" height="30" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>

                    <div className='d-flex align-items-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 50 50" fill="none">
                            <path d="M15 5C13.6739 5 12.4021 5.52678 11.4645 6.46447C10.5268 7.40215 10 8.67392 10 10V40C10 41.3261 10.5268 42.5979 11.4645 43.5355C12.4021 44.4732 13.6739 45 15 45H35C36.3261 45 37.5979 44.4732 38.5355 43.5355C39.4732 42.5979 40 41.3261 40 40V18.535C39.9991 17.5408 39.6035 16.5876 38.9 15.885L29.115 6.0975C28.412 5.39495 27.4589 5.00021 26.465 5H15ZM12.5 10C12.5 9.33696 12.7634 8.70107 13.2322 8.23223C13.7011 7.76339 14.337 7.5 15 7.5H25V16.25C25 17.2446 25.3951 18.1984 26.0983 18.9017C26.8016 19.6049 27.7554 20 28.75 20H37.5V40C37.5 40.663 37.2366 41.2989 36.7678 41.7678C36.2989 42.2366 35.663 42.5 35 42.5H15C14.337 42.5 13.7011 42.2366 13.2322 41.7678C12.7634 41.2989 12.5 40.663 12.5 40V10ZM36.9825 17.5H28.75C28.4185 17.5 28.1005 17.3683 27.8661 17.1339C27.6317 16.8995 27.5 16.5815 27.5 16.25V8.0175L36.9825 17.5Z" fill="#383838" />
                        </svg>
                        <h6 style={{ fontSize: "11px", marginBottom: "0" }}>Image1.png <br />  <span>441 kb</span> </h6>
                    </div>

                    <div style={{ width: "70%" }}></div>

                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 50 50" fill="none" style={{ cursor: "pointer" }}>
                        <path d="M25.0007 44.0476C35.5205 44.0476 44.0484 35.5197 44.0484 25C44.0484 14.4803 35.5205 5.95239 25.0007 5.95239C14.481 5.95239 5.95312 14.4803 5.95312 25C5.95312 35.5197 14.481 44.0476 25.0007 44.0476Z" stroke="#383838" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M17.8594 17.8572L32.1451 32.1429M32.1451 17.8572L17.8594 32.1429" stroke="#383838" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
            </div>

            <div className='mt-5'>
                <h6>Provide seller with the necessary documents and assets  </h6>

                <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                    <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} />
                    <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : ""}>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 200 200" fill="none">
                                <path d="M106.25 168.75V131.25H131.25L100 93.75L68.75 131.25H93.75V168.75H62.5V168.438C61.45 168.5 60.45 168.75 59.375 168.75C46.943 168.75 35.0201 163.811 26.2294 155.021C17.4386 146.23 12.5 134.307 12.5 121.875C12.5 97.825 30.6875 78.225 54.025 75.5375C56.0711 64.8412 61.7805 55.1921 70.1713 48.25C78.5621 41.3079 89.1097 37.5066 100 37.5C110.892 37.5061 121.441 41.3069 129.834 48.2489C138.227 55.1908 143.939 64.8401 145.987 75.5375C169.325 78.225 187.487 97.825 187.487 121.875C187.487 134.307 182.549 146.23 173.758 155.021C164.967 163.811 153.045 168.75 140.612 168.75C139.562 168.75 138.55 168.5 137.487 168.438V168.75H106.25Z" fill="#999999" />
                            </svg>

                            <p>Drag your Files</p>
                            <button className="upload_button" onClick={onButtonClick}>Upload a File</button>
                        </div>
                    </label>
                    {dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
                </form>


                <div className='progress_box'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 30 30" fill="none">
                        <g clip-path="url(#clip0_3015_151)">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 15C0 11.0218 1.58035 7.20644 4.3934 4.3934C7.20644 1.58035 11.0218 0 15 0C18.9782 0 22.7936 1.58035 25.6066 4.3934C28.4196 7.20644 30 11.0218 30 15C30 18.9782 28.4196 22.7936 25.6066 25.6066C22.7936 28.4196 18.9782 30 15 30C11.0218 30 7.20644 28.4196 4.3934 25.6066C1.58035 22.7936 0 18.9782 0 15ZM14.144 21.42L22.78 10.624L21.22 9.376L13.856 18.578L8.64 14.232L7.36 15.768L14.144 21.42Z" fill="#D8D8D8" />
                        </g>
                        <defs>
                            <clipPath id="clip0_3015_151">
                                <rect width="30" height="30" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>

                    <div className='d-flex align-items-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 50 50" fill="none">
                            <path d="M15 5C13.6739 5 12.4021 5.52678 11.4645 6.46447C10.5268 7.40215 10 8.67392 10 10V40C10 41.3261 10.5268 42.5979 11.4645 43.5355C12.4021 44.4732 13.6739 45 15 45H35C36.3261 45 37.5979 44.4732 38.5355 43.5355C39.4732 42.5979 40 41.3261 40 40V18.535C39.9991 17.5408 39.6035 16.5876 38.9 15.885L29.115 6.0975C28.412 5.39495 27.4589 5.00021 26.465 5H15ZM12.5 10C12.5 9.33696 12.7634 8.70107 13.2322 8.23223C13.7011 7.76339 14.337 7.5 15 7.5H25V16.25C25 17.2446 25.3951 18.1984 26.0983 18.9017C26.8016 19.6049 27.7554 20 28.75 20H37.5V40C37.5 40.663 37.2366 41.2989 36.7678 41.7678C36.2989 42.2366 35.663 42.5 35 42.5H15C14.337 42.5 13.7011 42.2366 13.2322 41.7678C12.7634 41.2989 12.5 40.663 12.5 40V10ZM36.9825 17.5H28.75C28.4185 17.5 28.1005 17.3683 27.8661 17.1339C27.6317 16.8995 27.5 16.5815 27.5 16.25V8.0175L36.9825 17.5Z" fill="#383838" />
                        </svg>
                        <h6 style={{ fontSize: "11px", marginBottom: "0" }}>Image1.png <br />  <span>441 kb</span> </h6>
                    </div>

                    <div class="progress">
                        <div class="progress-bar" role="progressbar" style={{ width: "75%" }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>

                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 50 50" fill="none" style={{ cursor: "pointer" }}>
                        <path d="M25.0007 44.0476C35.5205 44.0476 44.0484 35.5197 44.0484 25C44.0484 14.4803 35.5205 5.95239 25.0007 5.95239C14.481 5.95239 5.95312 14.4803 5.95312 25C5.95312 35.5197 14.481 44.0476 25.0007 44.0476Z" stroke="#383838" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M17.8594 17.8572L32.1451 32.1429M32.1451 17.8572L17.8594 32.1429" stroke="#383838" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>

                <div className='progress_box'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 30 30" fill="none">
                        <g clip-path="url(#clip0_3015_151)">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 15C0 11.0218 1.58035 7.20644 4.3934 4.3934C7.20644 1.58035 11.0218 0 15 0C18.9782 0 22.7936 1.58035 25.6066 4.3934C28.4196 7.20644 30 11.0218 30 15C30 18.9782 28.4196 22.7936 25.6066 25.6066C22.7936 28.4196 18.9782 30 15 30C11.0218 30 7.20644 28.4196 4.3934 25.6066C1.58035 22.7936 0 18.9782 0 15ZM14.144 21.42L22.78 10.624L21.22 9.376L13.856 18.578L8.64 14.232L7.36 15.768L14.144 21.42Z" fill="#D8D8D8" />
                        </g>
                        <defs>
                            <clipPath id="clip0_3015_151">
                                <rect width="30" height="30" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>

                    <div className='d-flex align-items-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 50 50" fill="none">
                            <path d="M15 5C13.6739 5 12.4021 5.52678 11.4645 6.46447C10.5268 7.40215 10 8.67392 10 10V40C10 41.3261 10.5268 42.5979 11.4645 43.5355C12.4021 44.4732 13.6739 45 15 45H35C36.3261 45 37.5979 44.4732 38.5355 43.5355C39.4732 42.5979 40 41.3261 40 40V18.535C39.9991 17.5408 39.6035 16.5876 38.9 15.885L29.115 6.0975C28.412 5.39495 27.4589 5.00021 26.465 5H15ZM12.5 10C12.5 9.33696 12.7634 8.70107 13.2322 8.23223C13.7011 7.76339 14.337 7.5 15 7.5H25V16.25C25 17.2446 25.3951 18.1984 26.0983 18.9017C26.8016 19.6049 27.7554 20 28.75 20H37.5V40C37.5 40.663 37.2366 41.2989 36.7678 41.7678C36.2989 42.2366 35.663 42.5 35 42.5H15C14.337 42.5 13.7011 42.2366 13.2322 41.7678C12.7634 41.2989 12.5 40.663 12.5 40V10ZM36.9825 17.5H28.75C28.4185 17.5 28.1005 17.3683 27.8661 17.1339C27.6317 16.8995 27.5 16.5815 27.5 16.25V8.0175L36.9825 17.5Z" fill="#383838" />
                        </svg>
                        <h6 style={{ fontSize: "11px", marginBottom: "0" }}>Image1.png <br />  <span>441 kb</span> </h6>
                    </div>

                    <div class="progress">
                        <div class="progress-bar" role="progressbar" style={{ width: "75%" }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>

                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 50 50" fill="none" style={{ cursor: "pointer" }}>
                        <path d="M25.0007 44.0476C35.5205 44.0476 44.0484 35.5197 44.0484 25C44.0484 14.4803 35.5205 5.95239 25.0007 5.95239C14.481 5.95239 5.95312 14.4803 5.95312 25C5.95312 35.5197 14.481 44.0476 25.0007 44.0476Z" stroke="#383838" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M17.8594 17.8572L32.1451 32.1429M32.1451 17.8572L17.8594 32.1429" stroke="#383838" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>

                <div className='progress_box'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 30 30" fill="none">
                        <g clip-path="url(#clip0_3015_179)">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 15C0 11.0218 1.58035 7.20644 4.3934 4.3934C7.20644 1.58035 11.0218 0 15 0C18.9782 0 22.7936 1.58035 25.6066 4.3934C28.4196 7.20644 30 11.0218 30 15C30 18.9782 28.4196 22.7936 25.6066 25.6066C22.7936 28.4196 18.9782 30 15 30C11.0218 30 7.20644 28.4196 4.3934 25.6066C1.58035 22.7936 0 18.9782 0 15ZM14.144 21.42L22.78 10.624L21.22 9.376L13.856 18.578L8.64 14.232L7.36 15.768L14.144 21.42Z" fill="#78DF67" />
                        </g>
                        <defs>
                            <clipPath id="clip0_3015_179">
                                <rect width="30" height="30" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>

                    <div className='d-flex align-items-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 50 50" fill="none">
                            <path d="M15 5C13.6739 5 12.4021 5.52678 11.4645 6.46447C10.5268 7.40215 10 8.67392 10 10V40C10 41.3261 10.5268 42.5979 11.4645 43.5355C12.4021 44.4732 13.6739 45 15 45H35C36.3261 45 37.5979 44.4732 38.5355 43.5355C39.4732 42.5979 40 41.3261 40 40V18.535C39.9991 17.5408 39.6035 16.5876 38.9 15.885L29.115 6.0975C28.412 5.39495 27.4589 5.00021 26.465 5H15ZM12.5 10C12.5 9.33696 12.7634 8.70107 13.2322 8.23223C13.7011 7.76339 14.337 7.5 15 7.5H25V16.25C25 17.2446 25.3951 18.1984 26.0983 18.9017C26.8016 19.6049 27.7554 20 28.75 20H37.5V40C37.5 40.663 37.2366 41.2989 36.7678 41.7678C36.2989 42.2366 35.663 42.5 35 42.5H15C14.337 42.5 13.7011 42.2366 13.2322 41.7678C12.7634 41.2989 12.5 40.663 12.5 40V10ZM36.9825 17.5H28.75C28.4185 17.5 28.1005 17.3683 27.8661 17.1339C27.6317 16.8995 27.5 16.5815 27.5 16.25V8.0175L36.9825 17.5Z" fill="#383838" />
                        </svg>
                        <h6 style={{ fontSize: "11px", marginBottom: "0" }}>Image1.png <br />  <span>441 kb</span> </h6>
                    </div>

                    <div style={{ width: "70%" }}></div>

                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 50 50" fill="none" style={{ cursor: "pointer" }}>
                        <path d="M25.0007 44.0476C35.5205 44.0476 44.0484 35.5197 44.0484 25C44.0484 14.4803 35.5205 5.95239 25.0007 5.95239C14.481 5.95239 5.95312 14.4803 5.95312 25C5.95312 35.5197 14.481 44.0476 25.0007 44.0476Z" stroke="#383838" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M17.8594 17.8572L32.1451 32.1429M32.1451 17.8572L17.8594 32.1429" stroke="#383838" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
            </div>

            <div className='mt-5'>
                <h6>Additional Comments if any:</h6>

                <textarea rows={6}></textarea>

            </div>
        </>
    )
}

export default Step1