import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

function PrivateRoutes (props)  {
  const { Component } = props;
  const navigate = useNavigate();
  // useEffect(() => {
  //   let login = localStorage.getItem('login');
  //   if (!login) {
  //     navigate('/waitlist')
  //   }
  // });
  return (
    <div>
      <Component/>
    </div>
  )
}

export default PrivateRoutes

