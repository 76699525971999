import React from "react";
import "../services/services.scss";
import reviewImg from "../../../assets/GigDescriptionImages/review_img.png";

const Testimonialcard = () => {
  return (
    <>
      <div className="review_div">
        <img src={reviewImg} alt="" />
        <div>
          <div className="review_user_name">
            <h6>Username</h6>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M7.32831 19.923L8.56831 14.61L4.44531 11.038L9.87631 10.568L12.0033 5.55701L14.1303 10.567L19.5603 11.037L15.4383 14.609L16.6783 19.922L12.0033 17.102L7.32831 19.923Z"
                  fill="#F8F3EA"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M7.32831 19.923L8.56831 14.61L4.44531 11.038L9.87631 10.568L12.0033 5.55701L14.1303 10.567L19.5603 11.037L15.4383 14.609L16.6783 19.922L12.0033 17.102L7.32831 19.923Z"
                  fill="#F8F3EA"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M7.32831 19.923L8.56831 14.61L4.44531 11.038L9.87631 10.568L12.0033 5.55701L14.1303 10.567L19.5603 11.037L15.4383 14.609L16.6783 19.922L12.0033 17.102L7.32831 19.923Z"
                  fill="#F8F3EA"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M7.32831 19.923L8.56831 14.61L4.44531 11.038L9.87631 10.568L12.0033 5.55701L14.1303 10.567L19.5603 11.037L15.4383 14.609L16.6783 19.922L12.0033 17.102L7.32831 19.923Z"
                  fill="#F8F3EA"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M7.32831 19.923L8.56831 14.61L4.44531 11.038L9.87631 10.568L12.0033 5.55701L14.1303 10.567L19.5603 11.037L15.4383 14.609L16.6783 19.922L12.0033 17.102L7.32831 19.923Z"
                  fill="#F8F3EA"
                />
              </svg>
            </div>
          </div>
          <p>1 day ago</p>
          <p className="mt-4">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis
            pellentesque sem. Duis elementum luctus justo, quis posuere magna
            facilisis nec. Donec quis ipsum ut nunc vulputate fermentum at nec
            dolor. Praesent at nibh vitae tortor maximus volutpat. Morbi ut
            risus sed ante condimentum aliquam eget vitae est.
          </p>
        </div>
      </div>
    </>
  );
};

export default Testimonialcard;
