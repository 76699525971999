// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.mission {
    background: #fff!important;
}
.misson-head{
    padding: 1.5rem 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/OurMission/mission.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;AAC9B;AACA;IACI,iBAAiB;AACrB","sourcesContent":["div.mission {\r\n    background: #fff!important;\r\n}\r\n.misson-head{\r\n    padding: 1.5rem 0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
