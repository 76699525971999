// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.our_goal_section .mt-5 h1{
    font-size: 24px;
    font-weight: 700;
}

.our_goal_section p{
    margin-top: 10px;
}

.our_goal_section button{
    font-size: 16px !important;
    line-height: 18px !important;
    padding: 12px 15px !important;
    font-weight: 700 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/LandingPageComponents/OurGoals/OurGoals.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,0BAA0B;IAC1B,4BAA4B;IAC5B,6BAA6B;IAC7B,2BAA2B;AAC/B","sourcesContent":[".our_goal_section .mt-5 h1{\n    font-size: 24px;\n    font-weight: 700;\n}\n\n.our_goal_section p{\n    margin-top: 10px;\n}\n\n.our_goal_section button{\n    font-size: 16px !important;\n    line-height: 18px !important;\n    padding: 12px 15px !important;\n    font-weight: 700 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
