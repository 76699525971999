// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (width: 280px) {
.ContentRow{
    margin: 0px !important;
    margin-top: 20px !important;
}
.QuestionsTxt{
    font-size: 15px !important;
}
.AnswersTxt{
    font-size: 10px !important;
}
.displayContent{
    padding: 10px !important;
}
}`, "",{"version":3,"sources":["webpack://./src/components/PricingPage1Components/FAQSection/Accordion.css"],"names":[],"mappings":"AAAA;AACA;IACI,sBAAsB;IACtB,2BAA2B;AAC/B;AACA;IACI,0BAA0B;AAC9B;AACA;IACI,0BAA0B;AAC9B;AACA;IACI,wBAAwB;AAC5B;AACA","sourcesContent":["@media screen and (width: 280px) {\n.ContentRow{\n    margin: 0px !important;\n    margin-top: 20px !important;\n}\n.QuestionsTxt{\n    font-size: 15px !important;\n}\n.AnswersTxt{\n    font-size: 10px !important;\n}\n.displayContent{\n    padding: 10px !important;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
