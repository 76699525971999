// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 600px) {
  .QuestionsTxt {
    font-size: 16px !important;
  }
  .displayContent {
    padding: 30px !important;
  }
  .AnswersTxt {
    font-size: 14px !important;
  }
  .editFAQIcon {
    margin-left: -10px;
    margin-right: 15px;
  }
}
.faqsContainer {
  background-color: #f8f3ea;
}
.faqs_heading {
  color: #014e7f;
  font-family: Poppins;
  font-size: 34px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.ContentRow .AnswersTxt {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out; /* Adjust the transition duration as needed */
}
`, "",{"version":3,"sources":["webpack://./src/components/WaitListPageComponents/WaitlistFAQS/WaitlistFAQS.css"],"names":[],"mappings":"AAAA;EACE;IACE,0BAA0B;EAC5B;EACA;IACE,wBAAwB;EAC1B;EACA;IACE,0BAA0B;EAC5B;EACA;IACE,kBAAkB;IAClB,kBAAkB;EACpB;AACF;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,cAAc;EACd,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,gBAAgB;EAChB,uCAAuC,EAAE,6CAA6C;AACxF","sourcesContent":["@media only screen and (max-width: 600px) {\n  .QuestionsTxt {\n    font-size: 16px !important;\n  }\n  .displayContent {\n    padding: 30px !important;\n  }\n  .AnswersTxt {\n    font-size: 14px !important;\n  }\n  .editFAQIcon {\n    margin-left: -10px;\n    margin-right: 15px;\n  }\n}\n.faqsContainer {\n  background-color: #f8f3ea;\n}\n.faqs_heading {\n  color: #014e7f;\n  font-family: Poppins;\n  font-size: 34px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n}\n.ContentRow .AnswersTxt {\n  max-height: 0;\n  overflow: hidden;\n  transition: max-height 0.3s ease-in-out; /* Adjust the transition duration as needed */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
