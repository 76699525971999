import React, { useState } from "react";
import "./tab.scss";
import Caseinfo from "../caseinfo/caseinfo";
import Documents from "../documents/doc";
import Donations from "../donations/donations";
import Services from "../services/services";
const Tab = () => {
  const [activetab, setactivetab] = useState("CaseInfo");
  return (
    <>
      <div className="tab-main d-flex flex-column">
        <div className="tab mb-5">
          <ul className="tab ul">
            <li
              className={`${activetab === "CaseInfo" ? "liactive" : ""}`}
              onClick={() => setactivetab("CaseInfo")}
            >
              Case Info
            </li>
            <li
              className={`${activetab === "Documents" ? "liactive" : ""}`}
              onClick={() => setactivetab("Documents")}
            >
              Documents
            </li>
            <li
              className={`${activetab === "Services" ? "liactive" : ""}`}
              onClick={() => setactivetab("Services")}
            >
              Services
            </li>
            <li
              className={`${activetab === "Donations" ? "liactive" : ""}`}
              onClick={() => setactivetab("Donations")}
            >
              Donations
            </li>
          </ul>
        </div>
      </div>
      <div className="main-tabContainer">
        {activetab === "CaseInfo" ? <Caseinfo /> : setactivetab === ""}
        {activetab === "Documents" ? <Documents /> : setactivetab === ""}
        {activetab === "Services" ? <Services /> : setactivetab === ""}
        {activetab === "Donations" ? <Donations /> : setactivetab === ""}
      </div>
    </>
  );
};

export default Tab;
