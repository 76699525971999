import React, { useState } from "react";
import "./waitlistmodal.css";
import Toast from "../../../../functions/Toast";
import Sadface from "../../../../../src/assets/images/Sadface.png";
import { useMutation } from "@apollo/client";
import { WAITLIST_MUTATION } from "../../../../services/methods/Mutations";
import { isEmailValid } from "../../../../services/helper/helper.js";
import { useApi } from "../../../../services/mutation&Query/GraphqlApi";
import Waitlistmodal2 from "../Modal2/Waitlistmodal2";
import crossicon from "../../../../../src/assets/images/cross-icon.png";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { Modal } from "react-bootstrap";

function Waitlistmodal({ isOpen, onClose }) {
    const [email, setEmail] = useState("");
    const [waitList] = useMutation(WAITLIST_MUTATION);
    const [isValid, setIsValid] = useState(true);
    const [isEmpty, setIsEmpty] = useState(false);
    const [ModalDone, setModalDone] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const closeToastModal = () => {
        setModalDone(false);
    };

    const { subscribeToWaitlist } = useApi();

    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
        setIsValid(isEmailValid(newEmail));
        setIsEmpty(newEmail.trim() === "");
    };
    const handleSubscribe = () => {
        const inputData = {
            email: email,
        };

        if (email !== "") {
            subscribeToWaitlist(waitList, inputData);
            setEmail("");
            setModalDone(true);
        } else {
            Toast.error("Fill the correct email address");
        }
    };

    return (
        <>
            <Modal
                show={isOpen}
                onHide={onClose}
                centered
                size="lg"
                className="not_joined_waitlist"
            >
                <Modal.Body>
                    <div className="container-1">
                        <div className="container-2">
                            <button onClick={onClose} className="close-iconn">
                                <IoIosCloseCircleOutline
                                    size="45px"
                                    className="icxon"
                                />
                            </button>
                            <img
                                src={Sadface}
                                alt="Sadface.png"
                                color="#6F978B"
                                className="sad-face"
                            />
                            <div className="para-1">
                                <p>
                                    We see you still have not joined the
                                    waitlist.
                                </p>
                            </div>
                            <div className="para-2">
                                <p>
                                    Don’t miss out on being a part of something
                                    meaningful.
                                </p>
                            </div>
                            <input
                                type="email"
                                placeholder="Enter your Email"
                                className="input-Email input_1"
                                value={email}
                                onChange={handleEmailChange}
                            ></input>
                            {!isValid && (
                                <p style={{ color: "red" }}>
                                    {isEmpty
                                        ? ""
                                        : "Please enter a valid email address."}
                                </p>
                            )}
                            <button
                                className="button button-modals"
                                onClick={handleSubscribe}
                                disabled={isEmpty}
                            >
                                Join Waitlist
                            </button>
                            {ModalDone && (
                                <Waitlistmodal2
                                    isOpen={ModalDone}
                                    onClose={closeToastModal}
                                />
                            )}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Waitlistmodal2 isOpen={isModalOpen} onClose={closeModal} />
        </>
    );
}

export default Waitlistmodal;
