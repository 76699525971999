import React from "react";
import "./mainbanner.css";
import bannerimg1 from "../../../assets/images/banner-img-1.png";
import bannerimg2 from "../../../assets/images/banner-img-2.png";
import bannerimg3 from "../../../assets/images/banner-img-3.png";
import bannerimg4 from "../../../assets/images/banner-img-4.png";
import SmileIcon from "../../../assets/images/smile-icon.png";
import { Link, useNavigate } from "react-router-dom";

export default function Mainbanner() {
  const navigate = useNavigate();

  return (
    <>
      <section className="Main-banner">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-12 col-md-9 col-lg-8 col-xl-8 col-xxl-8">
              <div className="content-box">
                <h2>
                  Revolutionizing Medical <br></br> Support for Brighter{" "}
                  <br></br> Tomorrows
                </h2>
                <p className="para">Join Us in Creating a Healthier Pakistan</p>
                <ul className="banner-btn">
                  <li>
                    <Link to="/allCases" className="donate-btn">
                      Support Now
                    </Link>
                  </li>
                  <li>
                    <a className="video-btn" href="/#watchVideo">
                      Watch Video
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="lower-box">
            <div className="row align-items-end">
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                <div className="img-card">
                  <div className="img-box">
                    <img src={bannerimg1} alt="" className="img-fluid" />
                  </div>
                  <div className="text-box-1">
                    <div className="row align-items-center">
                      <div className="col-4">
                        <img src={SmileIcon} alt="" className="img-fluid" />
                      </div>
                      <div className="col-8">
                        <p className="short-text">
                          Everybody deserves access to medical care, regardless
                          of their financial limitations.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                <div className="img-card">
                  <div className="img-box">
                    <img src={bannerimg2} alt="" className="img-fluid" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                <div className="img-card">
                  <div className="img-box">
                    <img src={bannerimg3} alt="" className="img-fluid" />
                  </div>
                  <div className="text-box-2">
                    <div className="row">
                      <div className="col-12">
                        <p className="short-text">
                          Empowering the underprivileged, one step at a time.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                <div className="img-card">
                  <div className="img-box">
                    <img src={bannerimg4} alt="" className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
