import React from "react";
import Header from "../components/Header/header";
import Tab from "../components/cases/tab/tab";
import Footer from "../components/Footer/footer";
import { useQuery } from "@apollo/client";
import { GET_CASE_BY_ID } from "../services/methods/Query";
import { useParams } from "react-router";
import { Provider } from "../context/Context";
import Loader from "../components/Loader/Loader";

const Cases = () => {
  const { setCaseById, caseById } = Provider();
  const params = useParams();
  const { data, loading } = useQuery(GET_CASE_BY_ID, {
    fetchPolicy: "network-only",
    variables: {
      getAllCasesId: params?.id,
    },
  });
  setCaseById(data?.getAllCases?.data?.items[0]);
  return (
    <>
      <Header />
      {loading ? <Loader /> : <Tab />}
      <Footer />
    </>
  );
};

export default Cases;
