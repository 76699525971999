import React from "react";
import { Col, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import "./PopularArticlesSection.css";
import ArticlesData from './ArticlesData';
import loadmoreArrow from "../../../assets/images/loadmoreArrow.png";

const PopularArticlesSection = () => {

    const firstRow = ArticlesData.slice(0, 2);
    const secondRow = ArticlesData.slice(2, 5);

    return (
        <>
            {/* Popular Articles Heading Section */}
            <h1 className="PopularArticlesHeading mt-4">Popular Articles</h1>
            <Row className="mb-4">
                <Col lg={9} md={12} sm={12} xs={12}>
                    <p className="HeadingParatxt">Discover captivating stories of crowdfunding success, social impact, and transformative change. Explore our popular articles that inspire and motivate, showcasing the incredible potential of crowdfunding to create a brighter future for all.</p>
                </Col>
                <Col lg={{ span: 2, offset: 1 }} md={12} sm={12} xs={12}>
                    <Link to='/popularArticles'><Button className="ViewAllBtn">View All
                        <img src={loadmoreArrow} alt="loadmoreArrow" className="loadmoreImg mx-2"></img>
                    </Button></Link>
                </Col>
            </Row>

            <Row>
                {firstRow.map(item => (
                    <Col key={item.id} lg={5} md={6} sm={6} className="mb-5 mx-4 ">
                        <div className="ArticleCard mx-1">
                            <div className="ArticleImg">
                                <img src={item.ImgUrl} alt="ArticleImg1" className="w-100" />
                            </div>

                            <div className="Article_info">
                                <div className="title-wrapper">
                                    <Link to={``} className="article__title">
                                        {item.ArticleTitle}
                                    </Link>
                                </div>

                                <div className="d-flex align-items-center justify-content-between authorNameandDate">
                                    <span className="authorName">
                                        <i className="ri-authorName-line "></i>{item.ArticleAuthorName}
                                    </span>

                                    <div className="d-flex align-items-center gap-3">
                                        <span className="d-flex align-items-center gap-1 date">
                                            <i className="ri-date-line"></i>{item.ArticleDate}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                ))}
            </Row>

            <Row>
                {secondRow.map(item => (
                    <Col key={item.id} lg={4} md={6} sm={6} className="mb-5">
                        <div className="ArticleCard">
                            <div className="ArticleImg">
                                <img src={item.ImgUrl} alt="ArticleImg1" className="w-100" />
                            </div>

                            <div className="Article_info">
                                <div className="title-wrapper">
                                    <Link to={``} className="article_title">
                                        {item.ArticleTitle}
                                    </Link>
                                </div>

                                <div className="d-flex align-items-center justify-content-between authorNameandDate">
                                    <span className="authorName">
                                        <i className="ri-authorName-line "></i>{item.ArticleAuthorName}
                                    </span>

                                    <div className="d-flex align-items-center gap-3">
                                        <span className="d-flex align-items-center gap-1 date">
                                            <i className="ri-date-line"></i>{item.ArticleDate}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                ))}
            </Row>
        </>
    )
}

export default PopularArticlesSection;
