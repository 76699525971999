import React from "react";
import { Col, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Facebook from "../../../assets/images/FacebookIcon.png";
import Whatsapp from "../../../assets/images/WhatsappIcon.png";

const ContactUsButtonsRow = () => {
    return (
        <>
            <div className="ContactWithUSSection mt-5 mb-4">
                <Row className="ContactUsContent justify-content-center">
                    <Col lg={8} md={12} sm={12} xs={12} className="mt-3">
                        <p className="ContactusText">Have a question? Check out our <Link to={`/works`} className="FAQStxt">FAQs</Link> page or chat with us on Facebook or WhatsApp.</p>
                    </Col>
                    <Col lg={2} md={12} sm={12} xs={12} className="text-center">
                        <Button className="WhatsAppbutton w-100">
                            <img src={Whatsapp} className="mx-1" alt="whatsapp_icon" />
                            Contact Us
                        </Button>
                    </Col>
                    <Col lg={2} md={12} sm={12} xs={12} className="text-center">
                        <a className="Facebookbutton w-100"
                            href="https://www.facebook.com/people/ShifaLia/100089498583736/?mibextid=LQQJ4d"
                            target="_blank">
                            <img src={Facebook} className="mx-1" alt="facebook_icon" />
                            Contact Us
                        </a>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default ContactUsButtonsRow