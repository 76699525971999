// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.LoadMoreSection{
    display: inline;
    display: flex;
    align-items: center;
    justify-content: center;
}

.load-more-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.loadMoreTxt{
    font-size: 22px;
    font-family: 'Poppins';
    color: #014E7F;
    font-weight: 800;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.load-more-icon {
    color: #014E7F;
    cursor: pointer;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}


`, "",{"version":3,"sources":["webpack://./src/components/PatientsInfoPageComponent/SeeAllComments/SeeAllComments.css"],"names":[],"mappings":";AACA;IACI,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,eAAe;IACf,sBAAsB;IACtB,cAAc;IACd,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;AACnB;AACA;IACI,cAAc;IACd,eAAe;IACf,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":["\n.LoadMoreSection{\n    display: inline;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.load-more-container {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.loadMoreTxt{\n    font-size: 22px;\n    font-family: 'Poppins';\n    color: #014E7F;\n    font-weight: 800;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n}\n.load-more-icon {\n    color: #014E7F;\n    cursor: pointer;\n    height: 28px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
