import React from "react";
import { Icon } from "@iconify/react";

import "./caseinfo.scss";
const Commentcard = (props) => {
  return (
    <>
      <div className="cmt-card my-4">
        <div className="cmt-profile-img">
          <img src={props?.image} alt="profile" />
        </div>
        <div className="cmt-card-content">
          <h5>{props?.name}</h5>
          <h6>{props?.date}</h6>
          <p className="mt-4">
           {props?.comment}
          </p>
        </div>
        <div className="cmt-likes">
          <span className="d-flex gap-2 justify-content-center align-items-center">
            <Icon icon="ph:heart-bold" color="#014E7F" width="24" height="24" className="heart-bold"/>
            <h5>12</h5>
          </span>
        </div>
      </div>
    </>
  );
};

export default Commentcard;
