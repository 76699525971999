import React, { useEffect, useState } from "react";
import SideBar from "../../components/Sidebar/SideBar";
import "../Edit details page/editpage1.css";
import FundraiseDetails from "../../components/Forms/Fundraise Form/FundraiseDetail";
import { useQuery } from "@apollo/client";
import { GET_CASE_BY_ID } from "../../services/methods/Query";
import { useLocation } from "react-router-dom";
import { Provider } from "../../context/Context";
import Loader from "../../components/loaderComponent/loader";
import { ColorRing } from "react-loader-spinner";

const Editdetailpage1 = () => {
  const { setCaseById, setfundraiserId } = Provider();
  const location = useLocation();
  const state = location.state;
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { data, loading } = useQuery(GET_CASE_BY_ID, {
    fetchPolicy: "network-only",
    variables: {
      getAllCasesId: state?.from,
    },
    onCompleted: () => setCaseById(data?.getAllCases?.data?.items[0]),
  });
  setfundraiserId(state?.from);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <div className="main ">
        <div className="">
          <SideBar
            isSidebarOpen={isSidebarOpen}
            toggleSidebar={toggleSidebar}
          />
        </div>
        <div>
          <FundraiseDetails isSidebarOpen={isSidebarOpen} loading={loading} />
        </div>
      </div>
    </>
  );
};

export default Editdetailpage1;
