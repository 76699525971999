import React, { useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import "font-awesome/css/font-awesome.min.css";
import "./SearchSection.css";
import { Provider } from "../../../context/Context";

const SearchSection = () => {
  const { search, setSearch } = Provider();
  const [localSearch, setLocalSearch] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    setSearch(localSearch);
    console.log(search);
  };
  return (
    <>
      <div>
        <form
          className="input-box"
          style={{ display: "flex" }}
          onSubmit={handleSubmit}
        >
          <input
            value={localSearch}
            onChange={(e) => setLocalSearch(e.target.value)}
            type="text"
            placeholder="Search for Campaigns"
            className="form-control px-2"
            style={{ display: "flex" }}
          />
          <button
            style={{ border: "none" }}
            className="fa fa-search"
            type="submit"
          ></button>
        </form>
      </div>
    </>
  );
};

export default SearchSection;
