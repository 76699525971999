import React from "react";
import "./Safety.css";
import { Col, Container, Row } from "react-bootstrap";
import protectImg from "../../assets/SafetyImags/responsibility_banner.png";
import icon1 from "../../assets/SafetyImags/profile_icon.png";
import icon2 from "../../assets/SafetyImags/donation.png";
import icon3 from "../../assets/SafetyImags/chat_icon.png";
import phoneIcon from "../../assets/SafetyImags/phone_icon.png";

const SafetyComponent = () => {
  return (
    <div className="trust_safety_banner">
      <div className="safety_banner">
        <h1>Trust , Safety and Security</h1>
      </div>
      <Container>
        <div className="step_protecting">
          <h2>Protecting your data is out top priority</h2>
          <p>
            At ShifaLia, we understand the importance of personal data,.
            Therefore, we <br />
            use the securest encryption to ensure data security of the highest
            level.{" "}
          </p>

          <Row>
            <Col md={6}>
              <div className="protect_points">
                <h6>Mission</h6>
                <p>
                  ShifaLia’s mission is simple. We are working towards a
                  Pakistan where access to healthcare is affordable to all,
                  regardless of their financial status. In our endeavor, we
                  depend on the generosity and philanthropy of the Pakistani
                  public to help out their fellow Pakistanis so they can reach
                  their financial goals to afford the healthcare they require.{" "}
                </p>
              </div>
            </Col>
            <Col md={6}>
              <div className="protect_points">
                <h6>Vision</h6>
                <p>
                  Our vision is a Pakistan where collaborative effort among
                  Pakistanis makes it possible for the public to have access to
                  good healthcare without having to worry about their financial
                  restrictions. Pakistanis do the most amount of philanthropy,
                  and we aim to use that goodwill for the betterment of society
                  from a healthcare perspective.{" "}
                </p>
              </div>
            </Col>
          </Row>

          <div className="mt-4">
            <h2>Our Responsibilities</h2>
            <ul>
              <li>
                ShifaLia is responsible for providing a platform that allows
                user to create
                <br />
                Campaigns for their own medical requirements, or to support
                other
                <br />
                Campaigns.
              </li>
              <li>
                We take full responsibility of the security and safety of all
                the data that
                <br />
                is provided to us by our users.
              </li>
              <li>
                ShifaLia takes responsibility for publication of only verified
                cases to
                <br />
                ensure help is provided to only those who deserve it.
              </li>
              <li>
                We are a platform that is ingrained in the Pakistani society,
                therefore we
                <br />
                ensure that we comply with laws and regulations of the country.
              </li>
            </ul>
          </div>

          <div>
            <img src={protectImg} alt="" />
          </div>

          <div className="donate_fund">
            <h3>Support Campaigns or Create Your Own.</h3>
            <p>
              Supporting Campaigns or creating your own ones, both are now
              possible <br />
              with just a few clicks. ShifaLia allows both without any hassle.
            </p>

            <Row>
              <Col md={4}>
                <div className="donate_box">
                  <img src={icon1} alt="" />
                  <h5>Personal Information</h5>
                  <p>
                    Let us get to know you so it is easier for us to cater best
                    to your needs.
                  </p>
                </div>
              </Col>
              <Col md={4}>
                <div className="donate_box">
                  <img src={icon2} alt="" />
                  <h5>Safe Donations</h5>
                  <p>
                    We utilize the securest encryption to ensure the safety of
                    all your donations and data.
                  </p>
                </div>
              </Col>
              <Col md={4}>
                <div className="donate_box">
                  <img src={icon3} alt="" />
                  <h5>24/7 Customer Support</h5>
                  <p>
                    Confused about something on our platform? Reach out to our
                    team of professionals and we will ensure your queries are
                    resolved.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="safety_contact">
          <img src={phoneIcon} alt="" />
          <p>
            We're here to help with any issues you may have, with a friendly
            customer support team at your service 24/7.
          </p>
        </div>
      </Container>
    </div>
  );
};

export default SafetyComponent;
