import React from "react";
import { Col, Row, Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import "./AllArticlesSection.css";
import ArticlesData from '../PopularArticlesSection/ArticlesData';
import TrendingBlogsSection from "../TrendingBlogsSection/TrendingBlogsSection";
import loadmoreArrow from "../../../assets/images/loadmoreArrow.png";

const AllArticlesSection = () => {

    const RowofArray = ArticlesData.slice(2,8);

    return (
        <>
            {/* All Articles Heading Section */}
            <h1 className="PopularArticlesHeading mt-4">All Articles</h1>
            <Row className="mb-4">
                <Col lg={9} md={12} sm={12} xs={12}>
                    <p className="HeadingParatxt">Discover captivating stories of crowdfunding success, social impact, and transformative change. Explore our popular articles that inspire and motivate, showcasing the incredible potential of crowdfunding to create a brighter future for all.</p>
                </Col>
                <Col lg={{ span: 2, offset: 1 }} md={12} sm={12} xs={12}>
                    <Link to='/allArticles'><Button className="ViewAllBtn">View All
                        <img src={loadmoreArrow} alt="loadmoreArrow" className="loadmoreImg mx-2"></img>
                    </Button></Link>
                </Col>
            </Row>

            <Container>
                <Row>
                    <Col lg="8" md="12">
                        <Row>
                            {RowofArray.map(item => (
                                <Col key={item.id} lg={6} md={6} sm={6} className="mb-5">
                                    <div className="ArticleCard mx-3 ">
                                        <div className="ArticleImg">
                                            <img src={item.ImgUrl} alt="ArticleImg1" className="w-100" />
                                        </div>

                                        <div className="Article_info">
                                            <div className="title-wrapper">
                                                <Link to={``} className="article_title">
                                                    {item.ArticleTitle}
                                                </Link>
                                            </div>

                                            <div className="d-flex align-items-center justify-content-between authorNameandDate">
                                                <span className="authorName">
                                                    <i className="ri-authorName-line "></i>{item.ArticleAuthorName}
                                                </span>

                                                <div className="d-flex align-items-center gap-3">
                                                    <span className="d-flex align-items-center gap-1 date">
                                                        <i className="ri-date-line"></i>{item.ArticleDate}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Col>

                    <Col lg="4" md="12">
                        <TrendingBlogsSection />
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default AllArticlesSection;
