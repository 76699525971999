// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-6 {
    padding: 4rem;
}

.center {
    text-align: center;
}




.bg-blue{
    background: #014E7F!important;
    color: #fff;
}

.flex-col {
    flex-direction: column;
}
.bg-green {
    background:#6F978B!important;
}

.bg-light-blue{
    background: #4E8DB6!important;
    color: #fff;
}

.bg-blue-light {
    background: #B5D5D4;
}

.value-div1.row{
    padding: 0!important;
    /* margin: 0!important; */

}

.value-div01.col {
    padding: 0;
}
.value-div02.col {
    padding: 0;
}

.value-div01 img{
    width: 100%;
}


.value-div02 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background: #D1DCD8;
}



.value-div02 .para-values {
    padding: 3rem;
    font-size: .9rem;
}

.our-values-heading{
    font-family: poppins;
    font-weight: 700;
}
`, "",{"version":3,"sources":["webpack://./src/components/OurValues/ourvalues.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;AACtB;;;;;AAKA;IACI,6BAA6B;IAC7B,WAAW;AACf;;AAEA;IACI,sBAAsB;AAC1B;AACA;IACI,4BAA4B;AAChC;;AAEA;IACI,6BAA6B;IAC7B,WAAW;AACf;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,oBAAoB;IACpB,yBAAyB;;AAE7B;;AAEA;IACI,UAAU;AACd;AACA;IACI,UAAU;AACd;;AAEA;IACI,WAAW;AACf;;;AAGA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,mBAAmB;AACvB;;;;AAIA;IACI,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,oBAAoB;IACpB,gBAAgB;AACpB","sourcesContent":[".p-6 {\r\n    padding: 4rem;\r\n}\r\n\r\n.center {\r\n    text-align: center;\r\n}\r\n\r\n\r\n\r\n\r\n.bg-blue{\r\n    background: #014E7F!important;\r\n    color: #fff;\r\n}\r\n\r\n.flex-col {\r\n    flex-direction: column;\r\n}\r\n.bg-green {\r\n    background:#6F978B!important;\r\n}\r\n\r\n.bg-light-blue{\r\n    background: #4E8DB6!important;\r\n    color: #fff;\r\n}\r\n\r\n.bg-blue-light {\r\n    background: #B5D5D4;\r\n}\r\n\r\n.value-div1.row{\r\n    padding: 0!important;\r\n    /* margin: 0!important; */\r\n\r\n}\r\n\r\n.value-div01.col {\r\n    padding: 0;\r\n}\r\n.value-div02.col {\r\n    padding: 0;\r\n}\r\n\r\n.value-div01 img{\r\n    width: 100%;\r\n}\r\n\r\n\r\n.value-div02 {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    padding: 1rem;\r\n    background: #D1DCD8;\r\n}\r\n\r\n\r\n\r\n.value-div02 .para-values {\r\n    padding: 3rem;\r\n    font-size: .9rem;\r\n}\r\n\r\n.our-values-heading{\r\n    font-family: poppins;\r\n    font-weight: 700;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
