import React, { useState } from "react";
import "./WaitlistHeader.css";
import Logo from "../../../assets/images/Logo.png";
import "@fontsource/poppins";
import { Link } from "react-router-dom";
import facebook from "../../../assets/WaitlistPageImages/waitlist_facebookIcon.png";
import insta from "../../../assets/WaitlistPageImages/waitlist_instaIcon.png";
import linkedin from "../../../assets/WaitlistPageImages/waitlist_linkedinIcon.png";
import Waitlistmodal from "../../../components/Modals/WaitlistModals/Modal1/Waitlistmodal";

function WaitlistHeader() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <section className="main_header">
        <div className="container">
          <nav className="navbar navbox navbar-expand-lg navbar-light">
            <a className="navbar-brand" href="/">
              <img src={Logo} alt="" className="img-fluid shifaliaLogo" />
              <p className="LogoHeading">ShifaLia</p>
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <div className="d-flex align-items-center justify-content-center flex-grow-1 centerlinksdiv">
                <a className="centerLinks" href="#commitment">
                  Overview
                </a>
                <a className="centerLinks" href="#benefit">
                Health advocacy
                </a>
                <a className="centerLinks" href="#contact">
                  Contact Us
                </a>
              </div>
              <div className="side-btn">
                <div className="inner-links">
                  <Link
                    to="https://www.facebook.com/profile.php?id=100089498583736&mibextid=LQQJ4d"
                    className="c-link"
                  >
                    <img src={facebook} alt="facebook" />
                  </Link>
                  <Link
                    to="https://www.instagram.com/shifaliapk/"
                    className="c-link"
                  >
                    <img src={insta} alt="insta" />
                  </Link>
                  <Link
                    to="https://www.linkedin.com/company/alia-nafees-foundation/mycompany/"
                    className="c-link"
                  >
                    <img src={linkedin} alt="linkedin" />
                  </Link>
                  {/* <Link
                  to=""
                  className="c-link"
                >
                  <img src={youtube} alt="linkedin" />
                </Link>
                <Link
                  to=""
                  className="c-link"
                >
                  <img src={titok} alt="linkedin" />
                </Link> */}
                </div>

                <button onClick={openModal} className="joinWaitlist-btn">
                  Join Waitlist
                </button>
              </div>
            </div>
          </nav>
        </div>
      </section>
      <Waitlistmodal isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
}

export default WaitlistHeader;
