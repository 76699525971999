import React from 'react'
import Header from "../components/Header/header"
import ContactUsMainPageCom from "../components/ContactUsPage_USAComponents/ContactUsPage_Main_USA/ContactUsPage_Main_USA"
import Footer from "../components/Footer/footer"

const ContactUsPage_USA = () => {
  return (
    <div>
      <Header />
      <ContactUsMainPageCom />
      <Footer />
    </div>
  )
}

export default ContactUsPage_USA