import React, { useState } from "react";
import "./personalinfo.css";
import Toast from "../../../functions/Toast";

function PersonalInfo({ Setsteps }) {
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setemail] = useState("");
  const [address, setaddress] = useState("");
  const [postal, setpostal] = useState("");
  const [city, setcity] = useState("");
  const [province, setprovince] = useState("");
  const [country, setcountry] = useState("");

  return (
    <div className="Personalcontainer-1">
      <div className="Personalcontainer-2">
        <div className="Personalicon-2">
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            onClick={() => {
              Setsteps(0);
            }}
          >
            <path
              d="M40 49.9167L41.25 48.6667L33.5 40.9167H50.9167V39.0833H33.5L41.25 31.3333L40 30.0833L30.0833 40L40 49.9167ZM40.0145 69C36.0048 69 32.2484 68.2391 28.7452 66.7174C25.2419 65.1956 22.1704 63.1165 19.5306 60.4801C16.8909 57.8436 14.8092 54.7759 13.2855 51.2772C11.7618 47.7784 11 44.0242 11 40.0145C11 36.0048 11.7609 32.2345 13.2826 28.7035C14.8044 25.1724 16.8835 22.1009 19.5199 19.489C22.1564 16.877 25.2241 14.8092 28.7228 13.2855C32.2216 11.7618 35.9758 11 39.9855 11C43.9952 11 47.7655 11.7609 51.2965 13.2826C54.8275 14.8044 57.8991 16.8696 60.511 19.4782C63.123 22.087 65.1908 25.1546 66.7145 28.6812C68.2382 32.2077 69 35.9758 69 39.9855C69 43.9952 68.2391 47.7516 66.7174 51.2548C65.1956 54.7581 63.1304 57.8296 60.5217 60.4694C57.913 63.1091 54.8454 65.1908 51.3188 66.7145C47.7923 68.2382 44.0242 69 40.0145 69ZM40 67.1667C47.5555 67.1667 53.9722 64.5278 59.25 59.25C64.5278 53.9722 67.1667 47.5555 67.1667 40C67.1667 32.4444 64.5278 26.0278 59.25 20.75C53.9722 15.4722 47.5555 12.8333 40 12.8333C32.4444 12.8333 26.0278 15.4722 20.75 20.75C15.4722 26.0278 12.8333 32.4444 12.8333 40C12.8333 47.5555 15.4722 53.9722 20.75 59.25C26.0278 64.5278 32.4444 67.1667 40 67.1667Z"
              fill="black"
            />
          </svg> */}
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="170"
              height="50"
              viewBox="0 0 250 50"
              fill="none"
            >
              <rect x="25" y="23" width="102" height="5" fill="#014E7F" />
              <rect x="127" y="23" width="102" height="5" fill="#BFBFBF" />
              <circle cx="25" cy="25" r="25" fill="#014E7F" />
              <circle cx="125" cy="25" r="25" fill="#014E7F" />
              <circle cx="225" cy="25" r="25" fill="#BFBFBF" />
            </svg>
          </div>
        </div>
        <div>
          <h1 className="Personalheading-1"> Personal Info</h1>
        </div>
        <div className="personalcontainer-5">
          <div className="Personalcontainer-3">
            <div className="Personalcontainer-6">
              <input
                className="Personalinputtab"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setfirstName(e.target.value)}
              />

              <div className="PersonalInfoline"></div>
            </div>
            <div className="Personalcontainer-6">
              <input
                className="Personalinputtab"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setlastName(e.target.value)}
              />
              <div className="PersonalInfoline"></div>
            </div>
          </div>
          <div className=" Personalcontainer-4">
            <input
              className="Personalinputtab"
              placeholder="Email"
              value={email}
              onChange={(e) => setemail(e.target.value)}
            />
            <div className="PersonalInfoline"></div>
          </div>
          <div className=" Personalcontainer-4">
            <input
              className="Personalinputtab"
              placeholder="Address"
              value={address}
              onChange={(e) => setaddress(e.target.value)}
            />
            <div className="PersonalInfoline"></div>
          </div>
          <div className="Personalcontainer-3">
            <div className="Personalcontainer-6">
              <input
                type="number"
                className="Personalinputtab"
                placeholder="Postal"
                value={postal}
                onChange={(e) => setpostal(e.target.value)}
              />

              <div className="PersonalInfoline"></div>
            </div>
            <div className="Personalcontainer-6">
              <input
                className="Personalinputtab"
                placeholder="City"
                value={city}
                onChange={(e) => setcity(e.target.value)}
              />
              <div className="PersonalInfoline"></div>
            </div>
          </div>
          <div className=" Personalcontainer-4">
            <input
              className="Personalinputtab"
              placeholder="State/Province"
              value={province}
              onChange={(e) => setprovince(e.target.value)}
            />
            <div className="PersonalInfoline"></div>
          </div>
          <div className=" Personalcontainer-4">
            <input
              className="Personalinputtab"
              placeholder="Country"
              value={country}
              onChange={(e) => setcountry(e.target.value)}
            />
            <div className="PersonalInfoline"></div>
          </div>
        </div>

        <div className="Personalcontainer-7">
          <button
            className="Personalnext"
            onClick={() => {
              Setsteps(0);
            }}
          >
            Back
          </button>
          <button
            className="Personalnext"
            onClick={() => {
              const data = {
                firstName,
                lastName,
                email,
                address,
                postal,
                city,
                province,
                country,
              };
              if (
                firstName == "" ||
                lastName == "" ||
                email == "" ||
                address == "" ||
                postal == "" ||
                city == "" ||
                province == "" ||
                country == ""
              ) {
                Toast.error("Please provide all fileds");
                return;
              }
              sessionStorage.setItem("donation_data_2", JSON.stringify(data));
              Setsteps(2);
            }}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default PersonalInfo;
