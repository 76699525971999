import React from "react";
import { Row, Button, Col } from "react-bootstrap"
import "./StartMonthlyDonationSection.css"
import StartMonthlyDonationImg from '../../../assets/images/StartMonthlyDonationImg.png'

const StartMonthlyDonationSection = () => {
    return (
        <>
            <div className="DonationImg mb-5">
                <img src={StartMonthlyDonationImg} alt="StartMonthlyDonationImg" className="w-100" />
                <Row>
                    <h4 className="DonationImgText">Start Monthly Donations to <br />save more lives</h4>
                </Row>
                {/* <Row>
                    <Col>
                        <Button className="startDonatingBtn">Start Donating Monthly</Button>
                    </Col>
                </Row> */}
            </div>
        </>
    )
}

export default StartMonthlyDonationSection;