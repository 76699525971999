import React from "react";
import { Row} from "react-bootstrap"
import "./HeaderImgSection.css"
import BlogPageHeaderimg from '../../../assets/images/BlogPageHeaderImg.png'

const HeaderImgSection = () => {
    return (
        <>
            <Row className="mb-4 pt-5">
                <div className="HeaderImg">
                    <img src={BlogPageHeaderimg} alt="AllCasesHeaderimg" />
                    <h4 className="HeaderImgText">Building Bridges of Hope: <br />Stories of Impact and Inspiration</h4>
                </div>
            </Row>
        </>
    )
}

export default HeaderImgSection;