import React, { useEffect, useState } from "react";
import WaitlistBanner from "../components/WaitListPageComponents/WaitlistBanner/WaitlistBanner";
import WaitlistCommitmentToChange from "../components/WaitListPageComponents/WaitlistCommitmentToChange/WaitlistCommitmentToChange";
import WaitlistProgress from "../components/WaitListPageComponents/WaitlistProgress/WaitlistProgress";
import WaitlistFAQS from "../components/WaitListPageComponents/WaitlistFAQS/WaitlistFAQS";
import WaitlistContactusSection from "../components/WaitListPageComponents/WaitlistContactusSection/WaitlistContactusSection";
import WaitlistFooter from "../components/WaitListPageComponents/WaitlistFooter/WaitlistFooter";
import Loader from "../components/loaderComponent/loader";
import WaitlistBenefits from "../components/WaitListPageComponents/WaitlistBenefits/WaitlistBenefits";
import waitlistFaqData from "../components/WaitListPageComponents/WaitlistFAQS/FAQs.json";

const WaitListPage = () => {
  const [isloading, setisloading] = useState(true);
  useEffect(() => {
    const datafetch = () => {
      setTimeout(() => {
        setisloading(false);
      }, 1000);
    };
    datafetch();
  }, []);

  return isloading ? (
    <Loader />
  ) : (
    <div>
      <WaitlistBanner />
      <WaitlistCommitmentToChange />
      <WaitlistBenefits />
      <WaitlistProgress />
      <WaitlistFAQS faqData={waitlistFaqData} />
      <WaitlistContactusSection />
      <WaitlistFooter />
    </div>
  );
};

export default WaitListPage;
