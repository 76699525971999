import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import "./Faqs.css";

const Content = () => {
    return (
        <Container>
            <Row className='justify-content-center'>
                <Col md={11}>
                    <div className='faq_content_main'>
                        <div>
                            <h6>Q1. What is ShifaLia?</h6>
                            <p>ShifaLia is a medical fundraising platform that connects those in need of
                                financial assistance for their medical expenses with potential supporters.
                                The supporters provide financial assistance without expecting anything
                                in return, except for the satisfaction of helping another soul.</p>
                        </div>
                        <div>
                            <h6>Q2. What is medical fundraising?</h6>
                            <p>Medical fundraising is an alternative method for individuals to raise funds
                                for their expensive medical procedures by appealing to the goodwill of others.
                                This method allows even the tiniest contributions to add up to colossal
                                amounts that are enough to pay off the medical expenses of those in need. </p>
                        </div>
                        <div>
                            <h6>Q3. How does ShifaLia work?</h6>
                            <p>Our platform allows people to start Campaigns that are visible for all
                                our users to see. This in turn makes their Campaign reach a wider audience,
                                thereby increasing their chances of garnering support for their Campaign
                                from potential backers.  </p>
                        </div>
                        <div>
                            <h6>Q4. How much does it cost to use ShifaLia’s services?</h6>
                            <p>Signing up for ShifaLia and making Campaigns is absolutely free.
                                However, we charge a 1% platform fee from the total amount collected by
                                a Campaign.   </p>
                        </div>
                        <div>
                            <h6>Q5. How do I know that the Campaigns are legit?</h6>
                            <p>Our Operations Team personally verifies every Campaign on the platform
                                to ensure authenticity. In addition to this, we encourage all our
                                Campaigners to upload their complete medical documents to ensure
                                the legitimacy of their Campaign.  </p>
                        </div>
                        <div>
                            <h6>Q6. How can I start my own Campaign?</h6>
                            <p><a href='/works'> Here’s a quick guide on how to start your own Campaign. </a>  </p>
                        </div>
                        <div>
                            <h6>Q7. Can I raise funds on someone else’s behalf?</h6>
                            <p>Yes, you can. However, raising funds for someone else requires the
                                beneficiary to sign off on a consent form that can be found here.  </p>
                        </div>
                        <div>
                            <h6>Q8. Can I support campaigns anonymously?</h6>
                            <p>Yes, Supporters can opt to stay anonymous.  </p>
                        </div>
                        <div>
                            <h6>Q9. Is ShifaLia an NGO?</h6>
                            <p>No, ShifaLia is not an NGO.  </p>
                        </div>
                        <div>
                            <h6>Q10. How do I know that my funds are being used for their intended purpose?</h6>
                            <p>All Campaigns will be updated regularly with their progress. In addition
                                 to this, all Campaigns will also have a live tracking feature that will
                                  indicate how much funds have been collected by the Campaign.  </p>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Content