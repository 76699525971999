import { toast } from 'react-hot-toast';

const Toast = {
  error: (message) => {
    return toast.error(message);
  },
  success: (message) => {
    return toast.success(message);
  },
  loading: (message) => {
    return toast.loading(message);
  }
};

export default Toast;