import React from "react";
import { Col, Row, Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import "./OurLocationSectionUSA.css"

const OurLocationSectionUSA = () => {
    return (
        <>
            <Row>
                <h5 className="OurLocationHeading mt-5 mb-4">Our Location</h5>
            </Row>
            <Row>
                <div className="map-container mb-5 mt-5">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d380511.451728915!2d-88.06161955725585!3d41.83377337729335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e2c3cd0f4cbed%3A0xafe0a6ad09c0c000!2sChicago%2C%20IL%2C%20USA!5e0!3m2!1sen!2s!4v1690896764030!5m2!1sen!2s"
                        width="100%" height="650" style={{ border: 0 }} allowFullScreen="" loading="eager">
                    </iframe>
                    <Col lg={4} md={5} sm={8} xs={9} className="map-overlay px-4 py-5">
                        <div className="map-overlay-content">
                            <h5 className="countryTxt">USA</h5>
                            <p className="mtxt mx-3">City:</p>
                            <p className="majortxt mx-3">Chicago</p>
                            <p className="mtxt mx-3">Address:</p>
                            <p className="majortxt mx-3">Abc street, abc area</p>
                            <p className="mtxt mx-3">Phone No.</p>
                            <p className="majortxt mx-3">+923152344269</p>
                            <p className="mtxt mx-3">Email Address</p>
                            <p className="majortxt mx-3">alianafeesfoundation<br></br>@gmail.com</p>
                        </div>
                        <Link to="/contactusPakistan">
                            <Button className="PakBtn">Pakistan</Button>
                        </Link>
                    </Col>
                </div>
            </Row>
        </>
    )
}

export default OurLocationSectionUSA