// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.mission-banner{
 background: #fff;
}
.main-mission-banner{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5rem;
}

div.div-1 {
    padding: 1rem 3rem;
    width: 50%;
}

div.banner-heading h1{
    font-size: 2.8em;
    line-height: 1.4;
    font-weight: 800;
    font-family: Poppins;
    letter-spacing: .1rem;
}
div.banner-heading h1 span{
   color: #014E7F;
}
div.div-2 {
    width: 50%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

div.div-2 img {
    width: 630px;
}`, "",{"version":3,"sources":["webpack://./src/components/MissionvissionBanner/bannermission.css"],"names":[],"mappings":";AAGA;CACC,gBAAgB;AACjB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;IAChB,oBAAoB;IACpB,qBAAqB;AACzB;AACA;GACG,cAAc;AACjB;AACA;IACI,UAAU;IACV,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,YAAY;AAChB","sourcesContent":["\r\n@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');\r\n\r\n.mission-banner{\r\n background: #fff;\r\n}\r\n.main-mission-banner{\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    padding: 5rem;\r\n}\r\n\r\ndiv.div-1 {\r\n    padding: 1rem 3rem;\r\n    width: 50%;\r\n}\r\n\r\ndiv.banner-heading h1{\r\n    font-size: 2.8em;\r\n    line-height: 1.4;\r\n    font-weight: 800;\r\n    font-family: Poppins;\r\n    letter-spacing: .1rem;\r\n}\r\ndiv.banner-heading h1 span{\r\n   color: #014E7F;\r\n}\r\ndiv.div-2 {\r\n    width: 50%;\r\n    padding: 1rem;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n}\r\n\r\ndiv.div-2 img {\r\n    width: 630px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
