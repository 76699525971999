import React, { useState } from "react";
import "./background.css";
import { Col, Row, Container, Button, Form, FormGroup } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { Provider } from "../../../context/Context";
import Toast from "../../../functions/Toast";
const BackGround = ({ isSidebarOpen }) => {
  const navigate = useNavigate();
  const {
    relationWithPatient,
    setrelationWithPatient,
    monthlyIncome,
    setmonthlyIncome,
    howDoYouKnowAboutANF,
    sethowDoYouKnowAboutANF,
  } = Provider();

  const handleSubmit = () => {
    // if (!relationWithPatient || !monthlyIncome || !howDoYouKnowAboutANF) {
    //   Toast.error("Please provide all fields");
    // }
    navigate("/detailpage6");
  };
  return (
    <>
      <div
        className={`form-container ${
          isSidebarOpen ? "form-container-open" : ""
        }`}
      >
        <h3 className=" socialprofile">Background</h3>
        <Container className="mt-1 mb-5 form-09">
          <Row>
            <Container className=" py-2 px-5">
              <Form>
                <FormGroup className="d-flex flex-wrap form-colom  ">
                  <Col lg={4} md={4} sm={12} xs={12} className="form-group ">
                    <input
                      type="text"
                      id="text"
                      className="fields px-4"
                      placeholder="Relation with Patient"
                      value={relationWithPatient}
                      onChange={(e) => setrelationWithPatient(e.target.value)}
                    />
                    <Form.Text className="Form-Text">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut.{" "}
                    </Form.Text>
                  </Col>
                  <Col lg={4} md={4} sm={12} xs={12} className="form-group ">
                    <div>
                      <input
                        type="number"
                        id="document"
                        className="fields px-4"
                        placeholder="Monthly Income"
                        value={monthlyIncome}
                        onChange={(e) => setmonthlyIncome(e.target.value)}
                      />
                      <Form.Text className="Form-Text">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut.{" "}
                      </Form.Text>
                    </div>
                  </Col>
                </FormGroup>
                <FormGroup className="d-flex flex-wrap form-colom ">
                  <Col lg={4} md={4} sm={12} xs={12} className="form-group">
                    <input
                      type=""
                      id="document"
                      className="fields px-4"
                      placeholder="How did you know about ANF?"
                      value={howDoYouKnowAboutANF}
                      onChange={(e) => sethowDoYouKnowAboutANF(e.target.value)}
                    />
                    <Form.Text className=" mt-4">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut.
                    </Form.Text>{" "}
                  </Col>
                </FormGroup>

                <Button onClick={handleSubmit} className="Done-button mt-4">
                  Done
                </Button>
              </Form>
            </Container>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default BackGround;
