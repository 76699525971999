import React from "react";
import Header from "../components/Header/header";
import MainBanner from "../components/LandingPageComponents/MainBanner/mainbanner";
import OurGoals from "../components/LandingPageComponents/OurGoals";
import HowitWorksSection from "../components/LandingPageComponents/HowitWorksSection/HowitWorksSection";
import DonateNow from "../components/LandingPageComponents/DonateNow/donateNow";
import AboutSection from "../components/LandingPageComponents/AboutSection";
import WhyChooseUs from "../components/LandingPageComponents/WhyChooseUs/chooseUs";
import DonateNowSection from "../components/LandingPageComponents/DonateNowSection";
import Footer from "../components/Footer/footer";

const Landingpage = () => {
  return (
    <div>
      <Header />
      <MainBanner />
      <OurGoals />
      <HowitWorksSection />
      {/* <DonateNow /> */}
      <AboutSection />
      <WhyChooseUs />
      <DonateNowSection />
      <Footer />
    </div>
  );
};
export default Landingpage;
