// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BlogPageMainComp{
    background-color: rgb(248, 243, 234);
}`, "",{"version":3,"sources":["webpack://./src/components/BlogpageComponents/BlogPageMainComp/BlogPageMainComp.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;AACxC","sourcesContent":[".BlogPageMainComp{\n    background-color: rgb(248, 243, 234);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
