import React, { useState } from "react";
import "./waitlistmodal3.css";
import crossicon from "../../../../../src/assets/images/cross-icon.png";
import { useMutation } from "@apollo/client";
import { USERQUERY_MUTATION } from "../../../../services/methods/Mutations";
import { isEmailValid } from "../../../../services/helper/helper.js";
import { useApi } from "../../../../services/mutation&Query/GraphqlApi";
import Waitlistmodal2 from "../Modal2/Waitlistmodal2";
import { IoIosCloseCircleOutline } from "react-icons/io";

function Waitlistmodal3({ isOpen, onClose }) {
  const [isValid, setIsValid] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const [form, setForm] = useState({
    name: "",
    email: "",
    message: "",
  });
  const { userQueryMutation } = useApi();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name === "email" && value.trim() === "");
    if (name === "email") {
      setIsValid(isEmailValid(value));
    }
    setForm({
      ...form,
      [name]: value,
    });
  };
  const [userQuery] = useMutation(USERQUERY_MUTATION);
  const handleSubscribe = () => {
    const inputData = {
      name: form.name,
      email: form.email,
      message: form.message,
    };
    userQueryMutation(userQuery, inputData);
    setIsModalOpen(true);
    setForm({
      name: "",
      email: "",
      message: "",
    });
    onClose();
  };
  if (!isOpen) return null;
  return (
    <div className="overlay">
      {/* <div>
                <div className="container-1"></div>
            </div> */}
      <div className="container-22">
        <div className="main-line">
          <div className="outerContainer">
            <div className="rotateBox-3"></div>
            <div className="smallSquare-3"></div>
          </div>
          <div className="outerContainer">
            <div className="rotateBox-2"></div>
            <div className="smallSquare-2"></div>
          </div>
          <div className="outerContainer">
            <div className="rotateBox-1"></div>
            <div className="smallSquare-1"></div>
          </div>
          <div className="">
            {/* <button onClick={onClose}>
                            <img
                                src={crossicon}
                                alt="check-icon.png"
                                width={20}
                                height={20}
                            />
                        </button> */}
            <button onClick={onClose} className="close-iconn-modal3">
              <IoIosCloseCircleOutline size="45px" className="icxon" />
            </button>
          </div>
        </div>
        <div className="container-3">
          <p className="para-12">Join the Waitlist</p>

          <input
            type="text"
            className="input-1 px-4 mt-2"
            placeholder="First Name"
            name="name"
            value={form.name}
            onChange={handleInputChange}
          />
          <input
            type="email"
            className="input-1 px-4 mt-2"
            placeholder="Email"
            name="email"
            value={form.email}
            onChange={handleInputChange}
          />
          {!isValid && (
            <p style={{ color: "red" }}>Please enter a valid Email Address.</p>
          )}
          <textarea
            className="input-2 px-4 mt-2"
            rows={9}
            name="message"
            placeholder="Send a Message"
            value={form.message}
            onChange={handleInputChange}
          />
          {isValid && (
            <button
              className="button button-modals"
              onClick={handleSubscribe}
              disabled={!isValid}
            >
              Join Waitlist
            </button>
          )}
          {/* <Waitlistmodal2 isOpen={isModalOpen} onClose={closeModal} /> */}
        </div>
      </div>
    </div>
  );
}

export default Waitlistmodal3;
