import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./DocumentSection.css";
import { Row, Col } from "react-bootstrap";
import DonatorContainer from "../DonatorConatiner/DonatorContainer";
import WordsofSupportSection from "../WordsofSupportSection/WordsofSupportSection";
import DocumentImages from "../DocumentImages/DocumentImages";
import ShareWindowModal from "../ShareWindowModal/ShareWindowModal";

const DocumentSection = () => {
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);

  return (
    <>
      <Col lg={12} md={12} sm={12} xs={12} className="DonatorSection">
        <Col className="mx-3">
          <Row className="mt-3">
            <p className="AmountOutof">
              <h4 className="amountText px-1">$160,490</h4>out of 180,000
            </p>
          </Row>
          <Row className="progress mx-1">
            <div className="progress-bar"></div>
          </Row>
          <Row>
            <p className="AmountOutof mx-2">3.7K Donations</p>
          </Row>
          <Row className="buttonContainer mt-4">
            <Row>
              <center>
                <button className="_donateButton">Donate</button>
              </center>
            </Row>
            <Row className="mt-3">
              <center>
                <button
                  className="_buttonShare"
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  Share
                </button>
              </center>
              {showModal && <ShareWindowModal closeModal={closeModal} />}
            </Row>
          </Row>
          <Row className="DonatorsConatinerSection mt-5">
            <DonatorContainer />
            <DonatorContainer />
            <DonatorContainer />
            <DonatorContainer />
          </Row>
          <Row className="mt-3 mb-2">
            <center>
              <button className="_buttonSeeAll">See All</button>
            </center>
          </Row>
        </Col>
      </Col>

      <Col lg={12} md={12} xs={12} className="DocumentSection mt-4">
        <Col className="mx-3">
          <Row>
            <h4 className="DocumentTitle px-4">Documents</h4>
          </Row>
          <Row>
            <Col lg={12} md={12} xs={12}>
              <hr className="hrLine mx-2"></hr>
            </Col>
          </Row>
          <Row className="mt-4 mb-3">
            <DocumentImages />
          </Row>
        </Col>
      </Col>

      <Col lg={12} md={12} xs={12} className="WordsofSupportSection mt-4">
        <Col className="mx-3">
          <Row>
            <h4 className="WordsofSupportTitle px-4">Words of Support</h4>
          </Row>
          <Row>
            <Col lg={12} md={12} xs={12}>
              <hr className="Line"></hr>
            </Col>
          </Row>
          <Row>
            <p className="DonationwordsHead mx-2 mt-2">
              Donate to leave empathetic words!
            </p>
          </Row>
          <Row className="WordsofSupportSectionSection mt-3">
            <WordsofSupportSection />
            <WordsofSupportSection />
            <WordsofSupportSection />
          </Row>
          <Row className="mt-3 mb-5 mx-2">
            <center>
              <Link to="/allComments">
                <button className="_buttonSeeAllComments">
                  See All Comments
                </button>
              </Link>
            </center>
          </Row>
        </Col>
      </Col>
    </>
  );
};

export default DocumentSection;
