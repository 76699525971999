// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TrendingBlogsHeading{
    color: #000000;
    font-size: 20px;
    font-weight: 500;
    font-family: 'Poppins';
}
.recent__blog-post{
    background-color: #6F978B;
    border-radius: 10px;
    padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/BlogpageComponents/AllArticlesSection/AllArticlesSection.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,sBAAsB;AAC1B;AACA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;AACjB","sourcesContent":[".TrendingBlogsHeading{\n    color: #000000;\n    font-size: 20px;\n    font-weight: 500;\n    font-family: 'Poppins';\n}\n.recent__blog-post{\n    background-color: #6F978B;\n    border-radius: 10px;\n    padding: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
