import React from 'react'
import Header from "../components/Header/header"
import Blog_Content from "../components/BlogpageComponents/BlogContent/BlogContent"
import Footer from "../components/Footer/footer"

const BlogContent = () => {
  return (
    <div>
      <Header />
      <Blog_Content />
      <Footer />
    </div>
  )
}

export default BlogContent