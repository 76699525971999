import React from 'react';
import Header from "../components/Header/header";
import Footer from "../components/Footer/footer";
import Banner from '../components/PrivacyPolicyComponents/Banner/Banner';
import Description from '../components/PrivacyPolicyComponents/Description/Description';

const PrivacyPolicy = () => {
    return (
        <div>
            <Header />
            <Banner />
            <Description />
            <Footer />
        </div>
    )
}

export default PrivacyPolicy