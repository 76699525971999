import React from "react";
import { Row, Container } from 'react-bootstrap';
import Banner from '../../ContactUsPage_PakistanComponents/BannerSection/Banner'
import ContactUsCards from '../../ContactUsPage_PakistanComponents/ContactUsCardsSection/ContactUsCards'
import OurLocation from '../OurLocationSectionUSA/OurLocationSectionUSA'
import Form from '../../ContactUsPage_PakistanComponents/FormSection/Form'
import ContactUsButtonsRow from '../../ContactUsPage_PakistanComponents/ContactUsButtonsRow/ContactUsButtonsRow'

const ContactUsPage_Main_USA = () => {
    return (
        <>
            <section className="ContactUsMainPageCom pt-5">
                <Banner />
                <Container>
                    <Container>
                        <Row>
                            <ContactUsCards />
                        </Row>
                    </Container>
                </Container>
                <OurLocation />
                <Container>
                    <Container>
                        <Row>
                            <Form />
                        </Row>
                        <Row>
                            <ContactUsButtonsRow />
                        </Row>
                    </Container>
                </Container>
            </section>
        </>
    )
}

export default ContactUsPage_Main_USA