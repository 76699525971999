// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/PolicyTermsImages/terms_banner.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.terms_banner_main{
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: 100%;
    min-height: 50vh;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.terms_banner_main h1{
    color: #fff;
}

@media (max-width: 767px) {
    .terms_banner_main{
        min-height: 20vh;
        background-size: 100% 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/TermsOfUseComponents/Banner/Banner.css"],"names":[],"mappings":"AAAA;IACI,yDAA2E;IAC3E,qBAAqB;IACrB,gBAAgB;IAChB,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI;QACI,gBAAgB;QAChB,0BAA0B;IAC9B;AACJ","sourcesContent":[".terms_banner_main{\n    background-image: url('../../../assets/PolicyTermsImages/terms_banner.jpg');\n    background-size: 100%;\n    min-height: 50vh;\n    margin-top: 30px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.terms_banner_main h1{\n    color: #fff;\n}\n\n@media (max-width: 767px) {\n    .terms_banner_main{\n        min-height: 20vh;\n        background-size: 100% 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
