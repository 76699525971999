import React from 'react';
import './GigRequirement.css';
import { Container } from 'react-bootstrap';
import Step1 from "./Step1.jsx";
import Step2 from "./Step2.jsx";
import Step3 from './Step3.jsx';

const GigRequirementComponent = () => {
  const [stepShow, setStepShow] = React.useState(1)

  const stepChangeHandler = () => {
    if (stepShow === 1) {
      setStepShow(2)
    }

    else if (stepShow === 2) {
      setStepShow(3)
    }

    else if (stepShow === 3) {
      setStepShow(4)
    }
  }

  const stepBackHandler = () => {
    if (stepShow === 2) {
      setStepShow(1)
    }

    else if (stepShow === 3) {
      setStepShow(2)
    }
  }

  return (
    <div className='gig_requirement_main'>
      <Container>
        {stepShow === 1 && <Step1 />}
        {stepShow === 2 && <Step2 />}
        {(stepShow === 3 || stepShow === 4) && <Step3 stepShow={stepShow} setStepShow={setStepShow} />}

        {
          (stepShow !== 3 && stepShow !== 4) && <div className='d-flex align-items-center gap-3 justify-content-end'>
            {stepShow !== 1 && <div className='back_btn'> <button onClick={stepBackHandler}>Back</button> </div>}
            <div className='next_btn'> <button onClick={stepChangeHandler}>Next</button> </div>
          </div>
        }

        {
          stepShow === 3 &&
          <div className='step3_sure'>
            <div className='mt-4'> 
              Are you sure you want to get this service?
            </div>
            <div>
              {stepShow !== 1 && <div className='back_btn'> <button onClick={stepBackHandler}>Back</button> </div>}
              <div className='next_btn'> <button onClick={stepChangeHandler}>Confirm</button> </div>
            </div>
          </div>
        }

      </Container>
    </div>
  )
}

export default GigRequirementComponent