// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-section{
    background-color: #F8F3EA;
    width: 100%;
    height: 100vh;
    position: relative;
}
.loader_{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.c1{
    animation: moveUpDown 0.8s infinite alternate;
}
@keyframes moveUpDown {
    100% {
        transform: translateY(0);
    }
    0% {
        transform: translateY(25px); 
    }
}
.c3{
    animation: moveUpDown3 0.8s infinite alternate;
}
@keyframes moveUpDown3 {
    0% {
        transform: translateY(-25px);
    }
    100% {
        transform: translateY(0px); 
    }
}
.c2{
    animation: moveRightLeft 0.8s infinite alternate;
}
@keyframes moveRightLeft {
    100% {
        transform: translateX(0);
    }
    0% {
        transform: translateX(25px); 
    }
    
}
.c4{
    animation: moveRightLeft4 0.8s infinite alternate;
}
@keyframes moveRightLeft4 {
    0% {
        transform: translateX(-25px);
    }
    100% {
        transform: translateX(0px); 
    }
}`, "",{"version":3,"sources":["webpack://./src/components/loaderComponent/loader.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;IACX,aAAa;IACb,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,QAAQ;IACR,SAAS;IACT,gCAAgC;AACpC;AACA;IACI,6CAA6C;AACjD;AACA;IACI;QACI,wBAAwB;IAC5B;IACA;QACI,2BAA2B;IAC/B;AACJ;AACA;IACI,8CAA8C;AAClD;AACA;IACI;QACI,4BAA4B;IAChC;IACA;QACI,0BAA0B;IAC9B;AACJ;AACA;IACI,gDAAgD;AACpD;AACA;IACI;QACI,wBAAwB;IAC5B;IACA;QACI,2BAA2B;IAC/B;;AAEJ;AACA;IACI,iDAAiD;AACrD;AACA;IACI;QACI,4BAA4B;IAChC;IACA;QACI,0BAA0B;IAC9B;AACJ","sourcesContent":[".loader-section{\n    background-color: #F8F3EA;\n    width: 100%;\n    height: 100vh;\n    position: relative;\n}\n.loader_{\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n}\n.c1{\n    animation: moveUpDown 0.8s infinite alternate;\n}\n@keyframes moveUpDown {\n    100% {\n        transform: translateY(0);\n    }\n    0% {\n        transform: translateY(25px); \n    }\n}\n.c3{\n    animation: moveUpDown3 0.8s infinite alternate;\n}\n@keyframes moveUpDown3 {\n    0% {\n        transform: translateY(-25px);\n    }\n    100% {\n        transform: translateY(0px); \n    }\n}\n.c2{\n    animation: moveRightLeft 0.8s infinite alternate;\n}\n@keyframes moveRightLeft {\n    100% {\n        transform: translateX(0);\n    }\n    0% {\n        transform: translateX(25px); \n    }\n    \n}\n.c4{\n    animation: moveRightLeft4 0.8s infinite alternate;\n}\n@keyframes moveRightLeft4 {\n    0% {\n        transform: translateX(-25px);\n    }\n    100% {\n        transform: translateX(0px); \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
