import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import OurMissionLeft from "../../assets/aboutusImages/mission_left.png";
import OurMissionRight from "../../assets/aboutusImages/mission_right.png";

const OurMission = () => {
    return (
        <div className='our_mission_main'>
            <Container fluid>
                <h4>Our Mission</h4>
                <Row className='align-items-center'>
                    <Col md={4} style={{ paddingLeft: "0px" }}>
                        <img src={OurMissionLeft} alt='' />
                    </Col>
                    <Col md={4}>
                        <p>Lorem ipsum dolor sit amet. At consequatur cumque et asperiores neque
                            eum dolorem facere. Sit ipsam quia et omnis odit id quidem harum et illo
                            accusantium eum alias nemo et voluptatem perferendis. Lorem ipsum dolor
                            sit amet. At consequatur cumque et asperiores neque eum dolorem facere.
                            Lorem ipsum dolor sit amet. </p>
                    </Col>
                    <Col md={4} style={{ paddingRight: "0px" }}>
                        <img src={OurMissionRight} alt='' />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default OurMission