import React from "react";
import Raisebanner from "../components/RaiseConcernComponents/RaiseBanner/raisebanner";
import Footer from "../components/Footer/footer";

const RaiseAConcernpage = () => {
  return (
    <>
      <Raisebanner />
      <Footer />
    </>
  );
};

export default RaiseAConcernpage;
