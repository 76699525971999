import React from 'react'
import './DonatorContainer.css';
import { Row, Col} from 'react-bootstrap';
import donatorsProfileimg from "../../../assets/images/donatorsProfileimg.png"

const DonatorContainer = () => {

    return (
        <>
                    <Row className='DonatorsConatiner mt-1'>
                        <Row>
                            <Col lg={2} md={2} xs={5}>
                                <img src={donatorsProfileimg} alt="profile"></img>
                            </Col>
                            <Col lg={10} md={10} xs={7}>
                                <Row>
                                    <h5 className='DonatorName'>Anonymous</h5>
                                </Row>
                                <Row className='donationContainer'>
                                    <p className='donationAmt'>$50
                                        <p className='donationAmt mx-2'>
                                            <span className='customBullet mx-2'></span> Id
                                        </p>
                                    </p>
                                </Row>
                            </Col>
                        </Row>
                        <Col lg={12} md={12} xs={12}>
                            <hr className="hrLine mx-2"></hr>
                        </Col>
                    </Row>
        </>
    )
}

export default DonatorContainer;