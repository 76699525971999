import React, { useState, useEffect } from "react";
import "./ShareWindowModal.css";
import { Button } from "react-bootstrap";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import copiedContent from "../../../assets/images/copiedContent.png";

const ShareWindowModal = ({ closeModal }) => {
  const [copyText, setcopyText] = useState(
    "https://www.examplefundraiserlink.com/"
  );
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(copyText);
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 800);
  };

  // useEffect(() => {
  //     document.body.style.overflowY = "hidden";
  //     return () => {
  //       document.body.style.overflowY = "scroll";
  //     };
  //   }, []);

  return (
    <>
      <div className="Modalwrapper"></div>
      <div className="Modal_Container">
        <FontAwesomeIcon
          icon={faTimes}
          className="closeIcon"
          onClick={closeModal}
        />
        <h4 className="modal_heading">Share your Campaign with the world!</h4>
        <p className="modal_para mt-3 mb-3">
          Let your case reach more people. Every contribution brings us closer
          to our goal. Together, let's create a brighter future.
        </p>

        <div id="allsocialLinks">
          <a
            href="https://www.facebook.com/profile.php?id=100089498583736&mibextid=LQQJ4d"
            className="facebooksocial"
          >
            <FontAwesomeIcon icon={faFacebook} size="2x" />
          </a>
          <a
            href="https://www.linkedin.com/company/alia-nafees-foundation/mycompany/"
            className="linkedinsocial"
          >
            <FontAwesomeIcon icon={faLinkedin} size="2x" />
          </a>
          <a
            href="https://twitter.com/i/flow/login?redirect_after_login=%2FAlia5Foundation"
            className="twittersocial"
          >
            <FontAwesomeIcon icon={faTwitter} size="2x" />
          </a>
          <a
            href="https://instagram.com/alia_nafees_foundation?igshid=OGQ5ZDc2ODk2ZA=="
            className="instagramsocial"
          >
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </a>
        </div>
        <div className="copyfield mt-3">
          <input
            value={copyText}
            className="CopyText px-4 mt-4"
            readOnly
            onChange={(e) => setcopyText(e.target.value)}
          />
          <Button className="copyBtn" onClick={handleCopy}>
            <img
              src={copiedContent}
              alt="Copy Icon"
              className="copiedcontent"
            />
          </Button>
          {isCopied && <span className="copiedMessage"></span>}
        </div>
      </div>
    </>
  );
};

export default ShareWindowModal;
