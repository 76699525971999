import React, { useState } from "react";
import "../Social Profile/socialprofile.css";
import { Col, Row, Container, Button, Form, FormGroup } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "../../../context/Context";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import Toast from "../../../functions/Toast";
const Team = ({ isSidebarOpen }) => {
  const {
    addTeamMember,
    setaddTeamMember,
    handleSubmit,
    editLoading,
    caseById,
  } = Provider();
  const [teamMember, setTeamMember] = useState("");

  const handleAdd = (e) => {
    e.preventDefault();
    if (teamMember == "") {
      return;
    }
    setTeamMember("");
    setaddTeamMember([...addTeamMember, teamMember]);
  };

  const navigate = useNavigate();
  const handleUpdate = async () => {
    const res = await handleSubmit();
    console.log(res);
    if (res?.data?.success) {
      navigate("/");
      Toast.success("Updated Successfully");
    }
  };
  return (
    <>
      <div
        className={`form-container ${
          isSidebarOpen ? "form-container-open" : ""
        }`}
      >
        <h3 className=" socialprofile">Team</h3>
        <Container className="mt-1 mb-5 form-09">
          <Row>
            <Container className=" py-2  team-con">
              <Form>
                <FormGroup className="d-flex flex-wrap form-colom   ">
                  <Col lg={4} md={4} sm={5} xs={5} className="form-group ">
                    <input
                      type="text"
                      id="text"
                      className="fields px-4"
                      placeholder="Add Team Member"
                      value={teamMember}
                      onChange={(e) => setTeamMember(e.target.value)}
                    />
                    <button
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        marginTop: "5px",
                        background: "transparent",
                      }}
                      onClick={handleAdd}
                    >
                      Add
                    </button>

                    {addTeamMember.length >= 1
                      ? addTeamMember?.map((i) => <li>{i}</li>)
                      : caseById?.addTeamMember?.map((i) => <li>{i}</li>)}
                  </Col>
                  {/* <Col lg={4} md={6} sm={12} xs={12} className='form-group '>
                                    < input type="text" id="text" className="fields px-4" placeholder="Add Team Member" />
                                    
                                </Col> */}
                </FormGroup>
                {editLoading ? (
                  <Button
                    className="Done-button mt-4"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <ThreeDots
                      visible={true}
                      height="40"
                      width="40"
                      color="#FFFFFF"
                      radius="9"
                      ariaLabel="three-dots-loading"
                    />
                  </Button>
                ) : (
                  <Button onClick={handleUpdate} className="Done-button mt-4">
                    Done
                  </Button>
                )}
              </Form>
            </Container>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Team;
