import React from "react";
import { Container, Col } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom'
import PopularArticlesData from './PopularArticlesData';
import ContactUsButtonsRow from "../ContactUsPage_PakistanComponents/ContactUsButtonsRow/ContactUsButtonsRow"
import UnbreakableSpiritImg from "../../assets/images/Unbreakable Spirit.png"
import Arrow_Right from "../../assets/images/Arrow_Right.svg"
import "./PopularArticlesPage.css"

const PopularArticlesPage = () => {

    const data = PopularArticlesData.slice(0, 6);
    return (
        <>
            <section className="AllArticlePage_Section ">
                <Container className='py-3'>
                
                    <div className='topDiv px-1'>
                        <div>
                            <h3 className="ArticleHeading pt-5">Articles</h3>
                        </div>

                        <div className="options-row mt-4 px-2">
                            <NavLink to="/allArticles" exact className="optLink" activeClassName="active">
                                <h6 className="option">All</h6>
                            </NavLink>
                            <NavLink to="/popularArticles" className="optLink" activeClassName="active">
                                <h6 className="option">Popular</h6>
                            </NavLink>
                            <NavLink to="/trendingArticles" className="optLink" activeClassName="active">
                                <h6 className="option">Trending</h6>
                            </NavLink>
                        </div>

                        <div className="banner_div my-4 ">
                            <div className="StoriesofR_Div py-5">
                                <h6 className="unBreakHeading py-3">Unbreakable Spirit: Stories of Resilience in the Face of Illness</h6>
                                <p className="unBreakPara">Al contrario del pensamiento popular, el texto de Lorem Ipsum no es simplemente texto aleatorio.</p>
                                <Link src='' className="ReadFullArticleTxt ">
                                    <img src={Arrow_Right} alt="Arrow_Right" className="Arrow_Right px-2"></img>Read full article</Link>
                            </div>
                            <div className="img_div py-5">
                                <img src={UnbreakableSpiritImg} className="img_Link_txt w-100" alt="UnbreakableSpiritImg"></img>
                            </div>
                        </div>

                    </div>

                    <div className='middle_content_Div px-1'>
                        {data.map(item => (
                            <Col key={item.id} lg={4} md={4} sm={12} xs={12} className="mb-5 px-3">
                                <div className="ArticleImg">
                                    <img src={item.ImgUrl} alt="ArticleImg1" className="w-100" />
                                </div>

                                <div className="AllArticle_date pt-1">
                                    <span className=" article_date">
                                        <i className="ridate-line"></i>{item.ArticleDate}
                                    </span>
                                </div>

                                <div className="A_title-wrapper mt-3">
                                    <Link to={``} className="allarticle__title">
                                        {item.ArticleTitle}
                                    </Link>
                                </div>

                                <div className="DpAndUname mt-2">
                                    <div className="UserDpImg">
                                        <img src={item.UserDpImg} alt="ArticleImg1" className="" />
                                    </div>
                                    <div>
                                        <span className="A_authorName">
                                            <i className="ri-authorName-line "></i>{item.ArticleAuthorName}
                                        </span>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </div>

                    <div>
                    <hr className="py-3"></hr>
                        <ContactUsButtonsRow />
                    </div>

                </Container>
            </section >
        </>
    )
}

export default PopularArticlesPage