import { gql } from "@apollo/client";

const LOGIN_QUERY = gql`
  mutation Login($data: LoginDto!) {
    login(data: $data) {
      success
      message
      error
      data
    }
  }
`;

const GET_PROFILE_QUERY = gql`
  query Query {
    GetPatientProfile {
      success
      message
      error
      data
    }
  }
`;

const GET_DICTIONARY_QUERY = gql`
  query Query {
    getDictionary {
      success
      message
      error
      data
    }
  }
`;

const GET_ALL_CASES_QUERY = gql`
query GetAllCases($page: Float, $pageSize: Float, $query: String, $filterByDate: String, $filterByCategory: String) {
  getAllCases(page: $page, pageSize: $pageSize, query: $query, filterByDate: $filterByDate, filterByCategory: $filterByCategory) {
      success
      message
      error
      data {
        total
        items {
          _id
          categoryId
          title
          patientFullName
          goalAmount
          addedDB
          updatedIn
          picturesLinks
          totalAmountCollected
          supporter
        }
      }
    }
  }
`;

const GET_CASE_BY_ID = gql`
  query Query($getAllCasesId: String) {
    getAllCases(id: $getAllCasesId) {
      success
      message
      error
      data {
        items {
          _id
          userId
          categoryId
          fullName
          email
          phone
          patientPhone
          patientFullName
          CNICNumber
          maritalStatus
          patientAddress
          relationWithPatient
          healthIssue
          hospitalName
          city
          title
          WhatsAppNo
          consultingDoctor
          description
          doctorPhone
          gender
          howDoYouKnowAboutANF
          medicalCondition
          addedDB
          previousCompanies
          goalAmount
          age
          monthlyIncome
          mrNumber
          totalAmountCollected
          supporter
          utilityBillImageLinks
          picturesLinks
          videoLinks
          customShortLinks
          ReportsLinks
          otherDocsLinks
          consentLetter
          campaignerWhatsAppNo
          UHIDNumber
          addTeamMember
          Service
          anonymous
          verifyCNIC
          verifyBill
          status
          updatedIn
          verifySupporterCNIC
          employmentStatus
          categoryDetails {
            _id
            group
            code
          }
          AmountCollectionDetails {
            _id
            fundraisingID
            firstName
            lastName
            amount
          }
          userDetails {
            _id
            firstName
            lastName
            email
          }
          CaseInfo {
            ImageLinks
            updateTitleNews
            updateDescription
            addedDB
            updatedIn
            status
          }
        }
      }
    }
  }
`;

const GET_MY_CASES_QUERY = gql`
  query Query {
    getAllPatientCases {
      success
      message
      error
      data {
        _id
        title
        patientFullName
        goalAmount
        addedDB
        updatedIn
        picturesLinks
        totalAmountCollected
        supporter
        AmountCollectionDetails {
          _id
          fundraisingID
          firstName
          lastName
          amount
        }
      }
    }
  }
`;

const GET_COMMENTS = gql`
  query Query($fundraiserId: String) {
    getComments(fundraiserId: $fundraiserId) {
      success
      message
      error
      data
    }
  }
`;

const GET_MY_SERVICES = gql`
  query GetMyServices {
    getMyServices {
      success
      message
      error
      data
    }
  }
`;

const GET_SERVICE_BY_ID = gql`
  query Query($getMyServicesByIdId: String) {
    getMyServicesByID(id: $getMyServicesByIdId) {
      success
      message
      error
      data
    }
  }
`;
export {
  LOGIN_QUERY,
  GET_PROFILE_QUERY,
  GET_DICTIONARY_QUERY,
  GET_ALL_CASES_QUERY,
  GET_MY_CASES_QUERY,
  GET_CASE_BY_ID,
  GET_COMMENTS,
  GET_MY_SERVICES,
  GET_SERVICE_BY_ID,
};
