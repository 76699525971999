import React, { useEffect, useState } from "react";
import "./GoogleModal.css";
import { Col } from "react-bootstrap";
import Google from "../../../assets/images/google.svg";
import { app } from "../../../firebase";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { useApi } from "../../../services/mutation&Query/GraphqlApi";
import { GOOGLE_REGISTRATION } from "../../../services/methods/Mutations";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import Toast from "../../../functions/Toast";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { checkToken } from "../../../redux/userSlice";
import { ThreeDots } from "react-loader-spinner";

const GoogleModal = ({ closeFooterModal }) => {
  useEffect(() => {
    document.body.style.overflowY = "hidden";
    return () => {
      document.body.style.overflowY = "scroll";
    };
  }, []);

  const [role, setRole] = useState(1);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const usenavigate = useNavigate();
  const auth = getAuth(app);
  const provider = new GoogleAuthProvider();

  const [registration] = useMutation(GOOGLE_REGISTRATION);
  const { GoogleMutation } = useApi();

  const handleSignUp = async () => {
    if (password == "") {
      Toast.error("Please provide password");
      return;
    }
    setLoading(true);
    await signInWithPopup(auth, provider)
      .then(async (result) => {
        await GoogleAuthProvider.credentialFromResult(result);
        const user = await result.user;
        const split = user?.displayName.split(" ");
        const data = {
          email: user?.email,
          firstName: split[0],
          lastName: split[1] || "",
          googleUserID: user.uid,
          password,
          profileImageLink: user?.photoURL,
          role: Number(role),
          loginType: "google",
        };
        console.log(data);
        const response = await GoogleMutation(registration, data);
        setLoading(false);
        if (response?.googleAuth?.success) {
          await dispatch(checkToken(true));
          usenavigate("/landingpages");
        }
      })
      .catch((error) => {
        console.log(error);
        Toast.error("Google Signin error");
        setLoading(false);
      });
  };
  return (
    <>
      <div className="Modalwrapper"></div>
      <div className="Modal_Container_">
        {!loading && (
          <FontAwesomeIcon
            icon={faTimes}
            className="closeIcon_"
            onClick={closeFooterModal}
          />
        )}
        <h4 className="modal_heading_">Google Signup</h4>
        {/* <p className="modal_para_ mt-2 mb-0">
          Update Your language and country
        </p> */}
        <Col lg={12} md={12} sm={12}>
          <input
            disabled={loading}
            className="w-100 signup-inputs m-0 p-3"
            placeholder="Password"
            type="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Col>
        <Col
          lg={12}
          md={12}
          sm={12}
          style={{
            display: "flex",
            gap: "30px",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "5px",
              alignItems: "center",
            }}
          >
            <label for="donar">As Campaigner</label>
            <input
              // disabled={loading}
              type="radio"
              name="role"
              value="1"
              onChange={(e) => setRole(e.target.value)}
              defaultChecked
            />
          </div>
          <div
            style={{
              display: "flex",
              gap: "5px",
              alignItems: "center",
            }}
          >
            <label for="donar">As Supporter</label>
            <input
              // disabled={loading}
              type="radio"
              name="role"
              value="2"
              onChange={(e) => setRole(e.target.value)}
            />
          </div>
        </Col>
        <Col lg={12} md={12} sm={12}>
          {loading ? (
            <button
              className="w-100"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ThreeDots
                visible={true}
                height="20"
                width="50"
                color="#4285F4"
                radius="9"
                ariaLabel="three-dots-loading"
              />
            </button>
          ) : (
            <button onClick={handleSignUp} className="w-100">
              <img src={Google} alt="Google" /> Sign up with Google
            </button>
          )}
        </Col>
      </div>
    </>
  );
};

export default GoogleModal;
