export const cities = [
  "Abbottabad",
  "Adezai",
  "Ahmadpur East",
  "Ahmed Nager",
  "Ali Khan",
  "Alipur",
  "Arifwala",
  "Attock",
  "Ayubia",
  "Badin",
  "Bahawalnagar",
  "Bahawalpur",
  "Banda Daud",
  "Bannu",
  "Batkhela",
  "Battagram",
  "Bhera",
  "Bhalwal",
  "Birote",
  "Burewala",
  "Chagai",
  "Chak",
  "Chakdara",
  "Chakwal",
  "Charsadda",
  "Chillianwala",
  "Chiniot",
  "Chishtian",
  "Chitral",
  "Dadu",
  "Daggar",
  "Dargai",
  "Darya Khan",
  "Dera Bugti",
  "Dera Ghazi",
  "Dera Ismail",
  "Dhaular",
  "Dhodak",
  "Dhurnkot",
  "Dina",
  "Dinga",
  "Dipalpur",
  "Diplo",
  "Dir",
  "Digri",
  "Dokri",
  "Doaba",
  "Faisalabad",
  "Fateh Jhang",
  "Ghakhar Mandi",
  "Ghotki",
  "Gilgit",
  "Gojra",
  "Gujranwala",
  "Gujrat",
  "Gwadar",
  "Hafizabad",
  "Hangu",
  "Haripur",
  "Haroonabad",
  "Hasilpur",
  "Haveli",
  "Harnai",
  "Hyderabad",
  "Islamabad",
  "Islamkot",
  "Jacobabad",
  "Jamshoro",
  "Jampur",
  "Jaranwala",
  "Jatoi",
  "Jauharabad",
  "Jhang",
  "Jhal Magsi",
  "Jhelum",
  "Jungshahi",
  "Kacchi",
  "Kahuta",
  "Kalabagh",
  "Karachi",
  "Karak",
  "Kashmore",
  "Kasur",
  "Kandhkot",
  "Kandiaro",
  "Kanpur",
  "Karak",
  "Karor Lal",
  "Kashmor",
  "Kasur",
  "Keti Bandar",
  "Khairpur",
  "Khanewal",
  "Khanpur",
  "Kharan",
  "Kharian",
  "Khuzdar",
  "Kohat",
  "Kot Adu",
  "Kotri",
  "Kulachi",
  "Kundian",
  "Kundla",
  "Lahore",
  "Lakha",
  "Laki Marwat",
  "Lalamusa",
  "Larkana",
  "Lasbela",
  "Latamber",
  "Layyah",
  "Lehri",
  "Liaquat Pur",
  "Lodhran",
  "Loralai",
  "Madyan",
  "Malakand",
  "Malakwal",
  "Mamoori",
  "Mansehra",
  "Mardan",
  "Mastung",
  "Mastuj",
  "Matiari",
  "Mehar",
  "Mehrabpur",
  "Mian Channu",
  "Mianwali",
  "Mingora",
  "Mirpur Khas",
  "Mithi",
  "Mithani",
  "Moro",
  "Multan",
  "Murree",
  "Muridke",
  "Muzaffargarh",
  "Nagarparkar",
  "Nankana Sahib",
  "Narowal",
  "Nasirabad",
  "Naudero",
  "Naushahro Feroze",
  "Naushara",
  "Nawabshah",
  "North Waziristan",
  "Nowshera",
  "Okara",
  "Ormara",
  "Pabbi",
  "Paharpur",
  "Pakpattan",
  "Panjgur",
  "Pattoki",
  "Peshawar",
  "Pishin valley",
  "Quetta",
  "Rahim Yar",
  "Raiwind",
  "Rajanpur",
  "Ranipur",
  "Ratodero",
  "Rawalpindi",
  "Rohri",
  "Sadiqabad",
  "Sahiwal",
  "Sakrand",
  "Samarbagh",
  "Sanghar",
  "Sangla Hill",
  "Sarai Alamgir",
  "Sargodha",
  "Shahdadkot",
  "Shahdadpur",
  "Shahpur Chakar",
  "Shahbandar",
  "Shakargarh",
  "Shangla",
  "Shikarpaur",
  "Shorkot",
  "Shujaabad",
  "Sialkot",
  "Sibi",
  "Sohawa",
  "Sohbatpur",
  "Sudhanoti",
  "Sukkur",
  "Swabi",
  "Swat",
  "Talagang",
  "Talhar",
  "Tando Adam",
  "Tando Allahyar",
  "Tando Muhammad",
  "Tank",
  "Tarbela",
  "Tatlay Wali",
  "Taxila",
  "Thall",
  "Thar",
  "Thatta",
  "Timergara",
  "Toba Tek",
  "Tordher",
  "Tangi",
  "Tandlianwala",
  "Topi",
  "Umerkot",
  "Usta Mohammad",
  "Vehari",
  "Wah Cantonment",
  "Wana",
  "Wazirabad",
  "Zhob",
  "Ziarat",
];
