import React from "react";
import "./services.scss";
import { Link } from "react-router-dom";
import "../documents/doc.scss";

const Gigcard = ({ id, gigimg, gigname, para, amt }) => {
  return (
    <>
      <div className="gigcard- d-flex flex-column">
        <img src={gigimg} alt="gigImg" />
        <h6 className="mt-3 mx-3">{gigname}</h6>
        <p className="mt-2 mx-3">{para}</p>
        <span className=" mx-3">Starting From: {amt}</span>
        <Link to={`/gigDescriptionPage/${id}`}>
          <button className="my-3 mx-3">Check out</button>
        </Link>
      </div>
    </>
  );
};

export default Gigcard;
