// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modalContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContent {
    position: relative;
    background-color: #fff;
    padding: 10px;
    border-radius: 2px;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
  
  .centerImage {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    max-height: 100%;
  }
  
  .closeModal {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
  }
  @media (width: 280px) and (height: 653px) {
    .ImagesRow{
       padding-left: -100px !important;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/PatientsInfoPageComponent/DocumentImages/DocumentImages.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC,EAAE,iCAAiC;IACvE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,sBAAsB;IACtB,aAAa;IACb,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,2CAA2C;EAC7C;;EAEA;IACE,cAAc;IACd,cAAc;IACd,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,eAAe;IACf,eAAe;EACjB;EACA;IACE;OACG,+BAA+B;IAClC;EACF","sourcesContent":[".modalContainer {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.8); /* Transparent black background */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  \n  .modalContent {\n    position: relative;\n    background-color: #fff;\n    padding: 10px;\n    border-radius: 2px;\n    max-width: 100%;\n    max-height: 100%;\n    overflow: auto;\n    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);\n  }\n  \n  .centerImage {\n    display: block;\n    margin: 0 auto;\n    max-width: 100%;\n    max-height: 100%;\n  }\n  \n  .closeModal {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    font-size: 24px;\n    cursor: pointer;\n  }\n  @media (width: 280px) and (height: 653px) {\n    .ImagesRow{\n       padding-left: -100px !important;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
