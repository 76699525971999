import React from 'react'
import Header from "../components/Header/header"
import ContactUsMainPageCom from "../components/ContactUsPage_PakistanComponents/ContactUsMainPageCom/ContactUsMainPageCom"
import Footer from "../components/Footer/footer"

const ContactUsPage_Pakistan = () => {
  return (
    <div>
        <Header />
        <ContactUsMainPageCom />
        <Footer />
    </div>
  )
}

export default ContactUsPage_Pakistan