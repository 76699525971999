import Toast from "../../functions/Toast";
import Cookies from "universal-cookie";

export const useApi = () => {
  const cookies = new Cookies(null, {
    path: "/",
  });

  const subscribeToWaitlist = (mutation, inputData) => {
    console.log("mutation", mutation);
    mutation({
      variables: {
        Data: inputData,
      },
    })
      .then((result) => {
        const { data } = result;
        console.log(data);
        if (data.waitList.success) {
          Toast.success(data.waitList.data);
        } else if (data.waitList.success === false) {
          Toast.error(data.waitList.data);
        }
      })
      .catch((error) => {
        const message = error["message"];
        Toast.error(message);
      });
  };

  const userQueryMutation = (userQuery, inputData) => {
    userQuery({
      variables: {
        Data: inputData,
      },
    })
      .then((result) => {
        const { data } = result;
        if (data.userQuery.success) {
          Toast.success(data.userQuery.data);
        } else if (data.userQuery.success === false) {
          Toast.error(data.userQuery.data);
        }
      })
      .catch((error) => {
        const message = error["message"];
        Toast.error(message);
      });
  };

  const ActionsUsersRegistrationMutation = (mutation, inputData) => {
    return mutation({
      variables: {
        rawData: inputData,
      },
    })
      .then((result) => {
        const { data } = result;
        if (data.actionsUsersRegistration.success) {
          Toast.success(data.actionsUsersRegistration.data);
          return data;
        } else if (data.actionsUsersRegistration.success === false) {
          Toast.error(data.actionsUsersRegistration.data);
        }
      })
      .catch((error) => {
        const message = error["message"];
        Toast.error(message);
      });
  };

  const GoogleMutation = (mutation, inputData) => {
    return mutation({
      variables: {
        data: inputData,
      },
    })
      .then((result) => {
        const { data } = result;
        console.log(data);
        if (data.googleAuth.success) {
          cookies.set("token", data.googleAuth.data?.token);
          Toast.success(data?.googleAuth?.message);
          return data;
        } else if (data.googleAuth.success === false) {
          Toast.error("Registration Failed");
        }
      })
      .catch((error) => {
        const message = error["message"];
        Toast.error(message);
      });
  };

  const GoogleLoginMutation = (mutation, inputData) => {
    return mutation({
      variables: {
        data: inputData,
      },
    })
      .then((result) => {
        const { data } = result;
        console.log(data);
        if (data.googleLogin.success) {
          cookies.set("token", data.googleLogin.data?.token);
          Toast.success(data?.googleLogin?.message);
          return data;
        } else if (data.googleLogin.success === false) {
          Toast.error("Signin Failed");
        }
      })
      .catch((error) => {
        const message = error["message"];
        Toast.error(message);
      });
  };

  const loginQuery = (mutation, inputData) => {
    return mutation({
      variables: {
        data: inputData,
      },
    })
      .then((result) => {
        console.log("login", result);
        const { data } = result;
        if (data.login.success) {
          Toast.success("Login successful");
          cookies.set("token", data.login.data?.token);
          return data;
        } else if (data.login.success === false) {
          Toast.error("Login failed: " + data.login.error);
          return data;
        }
      })
      .catch((error) => {
        console.log(error);
        const message = error.message;
        Toast.error("Error during login: " + message);
      });
  };
  const updateUser = (mutation, inputData) => {
    return mutation({
      variables: {
        rawData: inputData,
      },
    })
      .then((result) => {
        console.log(result);
        const { data } = result;
        if (data.updateUsers.success) {
          Toast.success("Update Data Successfully");
          return data;
        } else if (data.updateUsers.success === false) {
          Toast.error("Update Data failed: " + data.updateUsers.error);
          return data;
        }
      })
      .catch((error) => {
        console.log(error);
        const message = error.message;
        Toast.error("Error during login: " + message);
      });
  };
  const contactForm = (mutation, inputData) => {
    return mutation({
      variables: {
        rawData: inputData,
      },
    })
      .then((result) => {
        console.log(result);
        const { data } = result;
        if (data.contactForm.success) {
          Toast.success("Thank you");
          return data;
        } else if (data.contactForm.success === false) {
          Toast.error("failed " + data.contactForm.error);
          return data;
        }
      })
      .catch((error) => {
        console.log(error);
        const message = error.message;
        Toast.error("Error during login: " + message);
      });
  };
  const donation = (mutation, inputData) => {
    return mutation({
      variables: {
        rawData: inputData,
      },
    })
      .then((result) => {
        console.log(result);
        const { data } = result;
        if (data.donation.success) {
          Toast.success("Thank you");
          return data;
        } else if (data.donation.success === false) {
          Toast.error("failed " + data.donation.error);
          return data;
        }
      })
      .catch((error) => {
        console.log(error);
        const message = error.message;
        Toast.error("Error during login: " + message);
      });
  };
  const RaiseAConcern = (mutation, inputData) => {
    return mutation({
      variables: {
        rawData: inputData,
      },
    })
      .then((result) => {
        console.log(result);
        const { data } = result;
        if (data.RaiseAConcern.success) {
          Toast.success("Thank you");
          return data;
        } else if (data.RaiseAConcern.success === false) {
          Toast.error("failed " + data.RaiseAConcern.error);
          return data;
        }
      })
      .catch((error) => {
        console.log(error);
        const message = error.message;
        Toast.error("Error during login: " + message);
      });
  };

  const EmailSendMutation = (mutation, inputData) => {
    return mutation({
      variables: {
        email: inputData,
      },
    })
      .then((result) => {
        const { data } = result;
        if (data.forgetEmailSend.success) {
          Toast.success(data.forgetEmailSend.data);
          return data;
        } else if (data.forgetEmailSend.success === false) {
          Toast.error(data.forgetEmailSend.data);
        }
      })
      .catch((error) => {
        const message = error["message"];
        Toast.error(message);
      });
  };

  const OtpSendMutation = (mutation, inputData) => {
    return mutation({
      variables: {
        email: inputData.email,
        otpCode: inputData.otpCode,
      },
    })
      .then((result) => {
        const { data } = result;
        console.log(data);
        if (data.forgetOTPSend.success) {
          Toast.success(data.forgetOTPSend.message);
          return data;
        } else if (data.forgetOTPSend.success === false) {
          Toast.error(data.forgetOTPSend.error);
        }
      })
      .catch((error) => {
        const message = error["message"];
        Toast.error(message);
      });
  };

  const NewPasswordMutation = (mutation, inputData) => {
    return mutation({
      variables: {
        newPassword: inputData.newPassword,
      },
      context: {
        headers: {
          Authorization: inputData.token,
        },
      },
    })
      .then((result) => {
        const { data } = result;
        if (data.forgetPasswordChange.success) {
          Toast.success(data.forgetPasswordChange.message);
          return data;
        } else if (data.forgetPasswordChange.success === false) {
          Toast.error(data.forgetPasswordChange.message);
        }
      })
      .catch((error) => {
        const message = error["message"];
        Toast.error(message);
      });
  };

  const FundraiserAmountCollectionMutation = (mutation, inputData) => {
    return mutation({
      variables: {
        rawData: inputData,
      },
      context: {
        headers: {
          Authorization: cookies.get("token"),
        },
      },
    })
      .then((result) => {
        const { data } = result;
        console.log(data);
        if (data.fundraiserAmountCollection.success) {
          Toast.success(data.fundraiserAmountCollection.data);
          return data;
        } else if (data.fundraiserAmountCollection.success === false) {
          Toast.error(data.fundraiserAmountCollection.data);
        }
      })
      .catch((error) => {
        const message = error["message"];
        Toast.error(message);
      });
  };

  const CreateCommentsMutation = (mutation, inputData) => {
    return mutation({
      variables: {
        rawData: {
          fundraisingID: inputData.id,
          comments: inputData.comment,
        },
      },
      context: {
        headers: {
          Authorization: cookies.get("token"),
        },
      },
    })
      .then((result) => {
        const { data } = result;
        console.log(data);
        if (data.caseComments.success) {
          Toast.success(data.caseComments.data);
          return data;
        } else if (data.caseComments.success === false) {
          Toast.error(data.caseComments.data);
        }
      })
      .catch((error) => {
        const message = error["message"];
        Toast.error(message);
      });
  };

  return {
    EmailSendMutation,
    OtpSendMutation,
    NewPasswordMutation,
    updateUser,
    contactForm,
    donation,
    RaiseAConcern,
    subscribeToWaitlist,
    userQueryMutation,
    ActionsUsersRegistrationMutation,
    loginQuery,
    FundraiserAmountCollectionMutation,
    CreateCommentsMutation,
    GoogleMutation,
    GoogleLoginMutation,
  };
};
