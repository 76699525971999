import React from "react";
import "./chooseUs.css";
import ChooseImg1 from "../../../assets/images/choose-img-1.png";
import ChooseImg2 from "../../../assets/images/choose-img-2.png";
import ChooseImg3 from "../../../assets/images/choose-img-3.png";

export default function chooseUs() {
  return (
    <>
      <section className="sec-why-choose py-5">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
              <div className="sec-title">
                <h2>Why Choose Us</h2>
                <p className="para">
                  ShifaLia is a first of its kind platform that allows
                  individuals to gain financial assistance for their medical
                  expenses.
                </p>
              </div>
            </div>
          </div>
          <div className="choose-box">
            <div className="row justify-content-center">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <div className="row  py-5">
                  <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                    <div className="card-box ">
                      <div className="img-box">
                        <img className="img-fluid" src={ChooseImg1} />
                      </div>
                      <h6>01</h6>
                      <h2>Minimum Platform Fees</h2>
                      <ul>
                        <li>
                          Fees charged is to cover only our operations fees.
                        </li>
                        {/* <li>No contract locks.</li> */}
                      </ul>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                    <div className="card-box">
                      <div className="img-box">
                        <img className="img-fluid" src={ChooseImg2} />
                      </div>
                      <h6>02</h6>
                      <h2>Verified Campaigns </h2>
                      <ul>
                        <li>
                          Our operations team personally verifies each campaign
                          to ensure authenticity.
                        </li>
                        <li>
                          All necessary medical documents shared publicly with
                          consent.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                    <div className="card-box">
                      <div className="img-box">
                        <img className="img-fluid" src={ChooseImg3} />
                      </div>
                      <h6>03</h6>
                      <h2>Secure and Transparent</h2>
                      <ul>
                        <li>
                          All donations are handled securely and transparently.
                        </li>
                        <li>Funds raised are visible to all. </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                    <div className="card-box">
                      <div className="img-box">
                        <img className="img-fluid" src={ChooseImg1} />
                      </div>
                      <h6>04</h6>
                      <h2>Global Outreach</h2>
                      <ul>
                        <li>
                          We connect you with a global community of
                          compassionate backers
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                    <div className="card-box">
                      <div className="img-box">
                        <img className="img-fluid" src={ChooseImg2} />
                      </div>
                      <h6>05</h6>
                      <h2>Promotional Boost</h2>
                      <ul>
                        <li>
                          {" "}
                          Leverage our marketing and promotional resources to
                          reach an even wider audience of potential supporters
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                    <div className="card-box">
                      <div className="img-box">
                        <img className="img-fluid" src={ChooseImg2} />
                      </div>
                      <h6>06</h6>
                      <h2>User Friendly Interface</h2>
                      <ul>
                        <li>
                          {" "}
                          Simple interface to ensure a smooth process when
                          creating a campaign.
                        </li>
                        <li>Donations made easy in just a few  clicks.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
