import React, { useState, useEffect } from "react";
import "./WaitlistCommitmentToChange.css";
import WaitlistCommitmentToChange1 from "../../../assets/WaitlistPageImages/WaitlistCommitmentToChange1.png";
import lines from "../../../assets/WaitlistPageImages/Pak-hospitals.png";
import pakImages from "../../../assets/WaitlistPageImages/pak.jpg";
import Waitlistmodal from "../../../components/Modals/WaitlistModals/Modal1/Waitlistmodal";
import AOS from "aos";
import "aos/dist/aos.css";

const WaitlistCommitmentToChange = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Waitlistmodal isOpen={isModalOpen} onClose={closeModal} />
      <section className="sec-commitment" id="commitment">
        <div className="container">
          <div className="about-box">
            <div className="sec-title mt-3">
              <h2>What Is Healthcare Financing?</h2>
            </div>
            <div className="row align-items-center">
              <div className="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-8 col-xxl-8">
                <div className="content-box mb-2" data-aos="zoom-in">
                  <p className="para ">
                    Healthcare in Pakistan has become a privilege. It is only
                    affordable for those who come from strong financial
                    backgrounds, whereas those from weaker ones are forced into
                    getting either inadequate treatment or in some cases, no
                    treatment at all.
                    <br />
                  </p>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4 col-xxl-4">
                <div className="img-box">
                  <img
                    src={lines}
                    alt=""
                    className="img-fluid comm"
                    data-aos="fade-left"
                  />
                </div>
              </div>
            </div>
            {/* <span className="boldtxt" data-aos="zoom-out">
                            ShifaLia Is Here For That
                        </span> */}
            <div className="row align-items-center mt-5 sec-row">
              <div className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4 col-xxl-4">
                <div className="img-box">
                  <img
                    src={WaitlistCommitmentToChange1}
                    alt=""
                    className="img-fluid comm"
                    data-aos="zoom-in"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-8 col-xxl-8">
                <div className="content-box">
                  <p className="para" data-aos="fade-up">
                    However, a simple and effective solution for this problem is
                    for the community to come together and act as one to help
                    individuals in need of financial assistance. This is where
                    the concept of healthcare financing comes in.
                  </p>
                </div>
              </div>
            </div>
            <div className="row align-items-center mt-5">
              <div className="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-8 col-xxl-8">
                <div className="content-box mb-2" data-aos="zoom-in">
                  <p className="para">
                    Individuals in need of financial assistance depend on the
                    generosity of their fellow members of society by asking them
                    for contributions to their cause. These contributions on an
                    individual level might not seem a lot, but when seen as a
                    cumulative figure, they have the potential to change lives
                    for the better. .
                    <br />
                  </p>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4 col-xxl-4">
                <div className="img-box">
                  <img
                    src={pakImages}
                    alt=""
                    className="img-fluid comm"
                    data-aos="fade-left"
                  />
                </div>
              </div>
            </div>
            <div className="joinusDiv">
              <p>
                <b>ShifaLia </b>
                is the pioneer of healthcare financing websites in Pakistan,
                offering a secure, transparent, and empathetic avenue for both
                supporters and patients to access the life-saving funds they so
                urgently require. We provide a secure platform for people in
                need of financial assistance to connect with our generous
                Pakistanis who never falter when it comes to helping others in
                need.
              </p>
            </div>
            <div className="joinusDiv">
              <p>
                Join us today – the best Campaigning site for medical expenses.
                Together, we make the difference they've been waiting for, one
                act of kindness at a time.
              </p>
              <button className="join_txt_btn" onClick={openModal}>
                Join Waitlist
              </button>
            </div>
            <div className="sec-title mt-5">
              <h2 className="mt-5">
                Why Choose Us As A Healthcare Financing Platform?
              </h2>
            </div>
            <div className="why-chooseus-div col-lg-12">
              <div className="row d-flex justify-content-center">
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="inner-card" data-aos="flip-down">
                    <h6>Become a Financial Bridge</h6>
                    <p>
                      ShifaLia Financial help isn't always possible. Another way
                      to support patients in need is by amplifying their
                      stories. Just a like, comment, or share can make a
                      significant difference..
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="inner-card" data-aos="flip-down">
                    <h6>Direct Transfer</h6>
                    <p>
                      Your funds are directly transferred to the patient’s
                      account without any deductions in exchange for any
                      skill/service by them or their close ones.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="inner-card" data-aos="flip-down">
                    <h6>Helps You To Help Others</h6>
                    <p>
                      ShifaLia provides you with a platform to help others deal
                      with financial issues in addition to dealing with some
                      terrible health conditions.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4  col-md-6 col-sm-12">
                  <div className="inner-card" data-aos="flip-down">
                    <h6>Seamless Campaigning</h6>
                    <p>
                      ShifaLia simplifies the process of online healthcare
                      financing. With just a few clicks, you can financially
                      support those dealing with various diseases.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4  col-md-6 col-sm-12">
                  <div className="inner-card" data-aos="flip-down">
                    <h6>Secure & Transparent</h6>
                    <p>
                      Your security is our priority. ShifaLia ensures that all
                      contributions are secure and transparent. You can trust us
                      to handle your funds with the utmost care.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4  col-md-6 col-sm-12">
                  <div className="inner-card" data-aos="flip-down">
                    <h6>Accessible Healthcare</h6>
                    <p>
                      ShifaLia strives to bridge the gap between healthcare
                      expenses and individuals in need. Together, we can bring
                      life-changing treatments within reach.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row d-flex justify-content-center mb-3 sec-row-inner">
                <div className="col-lg-4  col-md-6 col-sm-12">
                  <div className="inner-card" data-aos="flip-down">
                    <h6>Cutting-Edge Technology</h6>
                    <p>
                      Our platform is designed with the latest technology to
                      make online healthcare financing and supporting campaigns
                      as smooth and efficient as possible.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section style={{ background: "#F8F3EA" }}></section>
    </>
  );
};

export default WaitlistCommitmentToChange;
