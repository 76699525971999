import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const AboutBoxSection = () => {
    return (
        <div className='about_box_section'>
            <h2>Lorem Ipsum</h2>

            <Container>
                <Row className='justify-content-around'>
                    <Col md={3}>
                        <div className='about_box'>
                            <h6>Helps You To Help Others</h6>
                            <p>Shifalia provides you with a platform to help others deal with financial issues in addition to dealing with some terrible health conditions.</p>
                        </div>
                    </Col>
                    <Col md={3}>
                        <div className='about_box'>
                            <h6>Helps You To Help Others</h6>
                            <p>Shifalia provides you with a platform to help others deal with financial issues in addition to dealing with some terrible health conditions.</p>
                        </div>
                    </Col>
                    <Col md={3}>
                        <div className='about_box'>
                            <h6>Helps You To Help Others</h6>
                            <p>Shifalia provides you with a platform to help others deal with financial issues in addition to dealing with some terrible health conditions.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default AboutBoxSection