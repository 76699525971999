import React, { useState } from "react";
import "../Auth.css";
import { Container } from "react-bootstrap";
import Logo from "../../../assets/images/Logo.png";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { NEW_PASSWORD } from "../../../services/methods/Mutations";
import { useApi } from "../../../services/mutation&Query/GraphqlApi";
import { useMutation } from "@apollo/client";
import Toast from "../../../functions/Toast";
import { ThreeDots } from "react-loader-spinner";

const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get("token");
  const [showPass, setShowPass] = useState({
    new: false,
    confirm: false,
  });
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [newpassword] = useMutation(NEW_PASSWORD);
  const { NewPasswordMutation } = useApi();

  const data = { newPassword, token };

  const verifyHandler = async () => {
    if (newPassword == "" || confirmPassword == "") {
      Toast.error("Please provide all fields");
      return;
    }
    if (newPassword !== confirmPassword) {
      Toast.error("Confirm password is not matched");
      return;
    }
    setLoading(true);
    const response = await NewPasswordMutation(newpassword, data);
    setLoading(false);
    if (response?.forgetPasswordChange?.success) {
      navigate("/changed-succesfully");
    }
  };
  return (
    <div className="forget_password">
      <Container>
        <a className="navbar-brand" href="/">
          <img src={Logo} alt="" className="img-fluid" />
          <p>ShifaLia</p>
        </a>

        <div className="forget_password_box">
          <h5>Change password</h5>

          <div className="forget_inner_box recovery_email change_password">
            <label>Enter New Password</label>
            <span>
              <input
                disabled={loading}
                type={showPass.new ? "text" : "password"}
                placeholder="Enter your New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              {showPass.new ? (
                <IoEyeOffOutline
                  onClick={() =>
                    setShowPass({
                      ...showPass,
                      new: false,
                    })
                  }
                />
              ) : (
                <IoEyeOutline
                  onClick={() =>
                    setShowPass({
                      ...showPass,
                      new: true,
                    })
                  }
                />
              )}
            </span>

            <label>Confirm New Password</label>
            <span>
              <input
                disabled={loading}
                type={showPass.confirm ? "text" : "password"}
                placeholder="Enter Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {showPass.confirm ? (
                <IoEyeOffOutline
                  onClick={() =>
                    setShowPass({
                      ...showPass,
                      confirm: false,
                    })
                  }
                />
              ) : (
                <IoEyeOutline
                  onClick={() =>
                    setShowPass({
                      ...showPass,
                      confirm: true,
                    })
                  }
                />
              )}
            </span>
          </div>
          {loading ? (
            <button style={{ display: "flex", justifyContent: "center" }}>
              <ThreeDots
                visible={true}
                height="25"
                width="50"
                color="#FFFFFF"
                radius="9"
                ariaLabel="three-dots-loading"
              />
            </button>
          ) : (
            <button onClick={verifyHandler}>Submit</button>
          )}
        </div>
      </Container>
    </div>
  );
};

export default ChangePassword;
