import React from "react";
import Header from "../components/Header/header";
import MultistepForm from "../components/multiStepFormComponents/MultistepForm";
import Footer from "../components/Footer/footer";

const StartFundraisingForms = () => {
  return (
    <div>
      <Header />
      <MultistepForm />
      {/* <Footer /> */}
    </div>
  );
};

export default StartFundraisingForms;
