import React, { useState, useEffect } from "react";
import "./SeeAllComments.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleDown } from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Container } from "react-bootstrap";
import donatorsProfileimg from "../../../assets/images/donatorsProfileimg.png";
import { Section } from "../../LandingPageComponents/OurGoals/OurGoalsElement";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_COMMENTS } from "../../../services/methods/Query";

const SeeAllComments = () => {
  const [showAdditionalRow, setShowAdditionalRow] = useState(false);
  const [DisappearLoadMore, setDisappearLoadMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleToggleRow = () => {
    setDisappearLoadMore(true);
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setShowAdditionalRow(true);
    }, 1000);
  };

  const params = useParams();

  // get comments
  const { data, refetch } = useQuery(GET_COMMENTS, {
    variables: {
      fundraiserId: params?.id,
    },
  });
  const allComments = data?.getComments?.data;

  return (
    <>
      <Section className="allCommentsSection  ">
        <Container className="py-5">
          <Col lg={12} md={12} xs={12} className="WordsofSupportSection  my-5">
            <Col className="mx-3">
              <Row>
                <h4 className="WordsofSupportTitle px-4 mt-3 mb-4">
                  Words of Support
                </h4>
              </Row>
              <Row>
                <Col lg={12} md={12} xs={12}>
                  <hr className="Line"></hr>
                </Col>
              </Row>
              {allComments?.map((item) => (
                <Row className="WordsofSupportSectionSection mt-3">
                  <Row className="DonatorsConatiner mt-1">
                    <Row>
                      <Col lg={1} md={2} xs={3}>
                        <img src={donatorsProfileimg} alt="profile"></img>
                      </Col>
                      <Col lg={11} md={10} xs={8}>
                        <Row>
                          <h5 className="DonatorName">{item?.userDetails?.firstName}</h5>
                        </Row>
                        <Row className="donationContainer">
                          <p className="donationAmt">
                            $50
                            <p className="donationAmt mx-2">
                              <span className="customBullet mx-2"></span> Id
                            </p>
                          </p>
                        </Row>
                        <Row>
                          <p className="comments">
                       {item?.comments}
                          </p>
                        </Row>
                      </Col>
                    </Row>
                    <Col lg={12} md={12} xs={12}>
                      <hr className="hrLine mx-2 mt-1"></hr>
                    </Col>
                  </Row>

                  {/* {showAdditionalRow && (
                    <Row className="DonatorsConatiner mt-1">
                      <Row>
                        <Col lg={1} md={2} xs={3}>
                          <img src={donatorsProfileimg} alt="profile"></img>
                        </Col>
                        <Col lg={11} md={10} xs={8}>
                          <Row>
                            <h5 className="DonatorName">Aleesha</h5>
                          </Row>
                          <Row className="donationContainer">
                            <p className="donationAmt">
                              $50
                              <p className="donationAmt mx-2">
                                <span className="customBullet mx-2"></span> Id
                              </p>
                            </p>
                          </Row>
                          <Row>
                            <p className="comments">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Cras sed nc. Ut sodales iaculis urna. Nullam
                              suscipit lacus lorem.
                            </p>
                          </Row>
                        </Col>
                      </Row>
                      <Col lg={12} md={12} xs={12}>
                        <hr className="hrLine mx-2 mt-1"></hr>
                      </Col>
                    </Row>
                  )}
                  {!DisappearLoadMore && !showAdditionalRow && (
                    <Row className="LoadMoreSection">
                      <div
                        className="load-more-container mb-3 mt-2"
                        onClick={handleToggleRow}
                      >
                        <h2 className="loadMoreTxt mx-3">Load More</h2>
                        <FontAwesomeIcon
                          className="load-more-icon"
                          icon={faAngleDoubleDown}
                        />
                      </div>
                    </Row>
                  )}
                  {DisappearLoadMore && isLoading && (
                    <center>
                      <p>Loading...</p>
                    </center>
                  )} */}
                </Row>
              ))}
            </Col>
          </Col>
        </Container>
      </Section>
    </>
  );
};

export default SeeAllComments;
