import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

const httpLink = new HttpLink({
  uri: 'https://backend.shifalia.com/graphql',
});

// https://backend.shifalia.com/graphql

// http://localhost:3000/graphql

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});

export default client;
