// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contactUsHeading {
    color: #457B6D;
    font-size: 34px;
    font-weight: 600;
    font-family: 'Poppins';
    text-align: center;
}
.CardContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.Card {
    width: 300px;
    height: 297px;
    display: flex;
    border-radius: 8px;
    background: #D9E6E5;
    box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.25);
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.cardinnerHeading{
    color: #767474;
    font-size: 23px;
    font-weight: 600;
    font-family: 'Poppins';
}
.cardContent{
    color: #457B6D;
    font-size: 19px;
    font-weight: 500;
    font-family: 'Poppins';
    text-align: center;
}
.socialLinks a {
    line-height: 65px;
    margin-left: 20px;
    color: #457B6D;
}
@media only screen and (max-width: 280px) {
    .Card {
        width: 250px;
        height: 297px;
    }
    .cardContent{
        font-size: 17px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/ContactUsPage_PakistanComponents/ContactUsCardsSection/ContactUsCards.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,sBAAsB;IACtB,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;AAC1B;AACA;IACI,YAAY;IACZ,aAAa;IACb,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,+CAA+C;IAC/C,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,sBAAsB;AAC1B;AACA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,sBAAsB;IACtB,kBAAkB;AACtB;AACA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,cAAc;AAClB;AACA;IACI;QACI,YAAY;QACZ,aAAa;IACjB;IACA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".contactUsHeading {\n    color: #457B6D;\n    font-size: 34px;\n    font-weight: 600;\n    font-family: 'Poppins';\n    text-align: center;\n}\n.CardContainer{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n}\n.Card {\n    width: 300px;\n    height: 297px;\n    display: flex;\n    border-radius: 8px;\n    background: #D9E6E5;\n    box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.25);\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n}\n\n.cardinnerHeading{\n    color: #767474;\n    font-size: 23px;\n    font-weight: 600;\n    font-family: 'Poppins';\n}\n.cardContent{\n    color: #457B6D;\n    font-size: 19px;\n    font-weight: 500;\n    font-family: 'Poppins';\n    text-align: center;\n}\n.socialLinks a {\n    line-height: 65px;\n    margin-left: 20px;\n    color: #457B6D;\n}\n@media only screen and (max-width: 280px) {\n    .Card {\n        width: 250px;\n        height: 297px;\n    }\n    .cardContent{\n        font-size: 17px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
