import styled, { keyframes } from 'styled-components';

export const Title = styled.div`
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 38px;
line-height: 57px;
letter-spacing: 0.03em;
color: #014E7F;
text-align:center;
`

export const Subtitle = styled.div`
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 25px;
${'' /* line-height: 32px;
letter-spacing: 0.03em; */}
color: #707070;
text-align:center;
padding-top: 10px;
`

export const TitleWrap = styled.div`
display:flex;
align-items:center;
${'' /* justify-content:center; */}
`
export const Line= styled.div`
width:3rem;
border:3px solid #457B6D;
margin-right:5px;
border-radius: 3px;
`

export const Heading= styled.h1`
font-family: 'Poppins', sans-serif;
font-weight: 600;
font-size: 24px;
line-height: 30px;
color: #457B6D;
margin:5px;

`
export const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${'' /* height: 60vh; */}
  width: 1120px;
height: 450px;
border-radius: 20px;
border: 1px solid #707070;
background: linear-gradient(0deg, #000 0%, #000 100%), url(<path-to-image>), lightgray 50% / cover no-repeat;
backdrop-filter: blur(5px);
`;
export const Video = styled.video`
  width: 100%;
  height: 100%;
`;

export const PlayButton = styled.button`
  background-color: white;
  border: none;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
`;