import React from "react";
import "./confirmation.css";
function Confirmation({ Setsteps }) {
  return (
    <div className="conformation-container1">
      <div className="conformation-container2">
        <div className="confirmation-heading1">Thanks for your Donation</div>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="200"
            height="200"
            viewBox="0 0 200 200"
            fill="none"
            className="confirmation-tick"
          >
            <g clip-path="url(#clip0_1221_179)">
              <path
                d="M100 0C44.7719 0 0 44.7719 0 100C0 155.231 44.7719 200 100 200C155.231 200 200 155.231 200 100C200 44.7719 155.231 0 100 0ZM100 187.697C51.7531 187.697 12.5 148.247 12.5 99.9996C12.5 51.7527 51.7531 12.4996 100 12.4996C148.247 12.4996 187.5 51.7529 187.5 99.9996C187.5 148.246 148.247 187.697 100 187.697ZM139.909 63.4094L81.2373 122.45L54.8154 96.0281C52.3748 93.5875 48.4186 93.5875 45.9748 96.0281C43.5342 98.4688 43.5342 102.425 45.9748 104.866L76.9092 135.803C79.3498 138.241 83.3061 138.241 85.7498 135.803C86.0311 135.522 86.2719 135.215 86.4906 134.897L148.753 72.2498C151.191 69.8092 151.191 65.8529 148.753 63.4094C146.309 60.9688 142.353 60.9688 139.909 63.4094Z"
                fill="#457B6D"
              />
            </g>
            <defs>
              <clipPath id="clip0_1221_179">
                <rect width="200" height="200" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <p className="confirmation-para">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam non felis
          vel metus pulvinar commodo eu imperdiet eros. Cras lacus diam,
          sagittis lacinia elit ac, ultrices ultricies enim
        </p>

        <button className="confirmation-next">Next</button>
      </div>
    </div>
  );
}

export default Confirmation;
