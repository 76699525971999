import React from 'react'
// import Header from "../components/Header/header"
import EditDetails from "../components/EditFundraiserDetails/EditDetails_I/EditDetail_I"
// import Footer from "../components/Footer/footer"

const EditDetails1 = () => {
  return (
    <div>
      {/* <Header /> */}
      <EditDetails />
      {/* <Footer /> */}
    </div>
  )
}

export default EditDetails1