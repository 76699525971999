// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-content {
    height: 190vh;
    position: absolute;
    top: 140px;
    left: 335px;
    z-index: 1;
    width: 75%;
    margin-left: px;
    /* border: 1px solid black; */
    background-color: #D9E6E5;
}
.medicaldocument {
    color: #457B6D;

font-family: Poppins;
font-size: 30px;
font-style: normal;
font-weight: 600;
line-height: normal;
/* padding: 10px ; */
padding: 60px 10px 10px 10px;
margin-left: 90px;
}
.info-para {
/*    
    border: 1px solid black;
    font-size: 15px; */
    color: #6C6969;

font-family: Poppins;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.15px;
/* text-align: left; */
/* margin-left: 25px; */
text-align: justify;


}
.fields {
    width: 95%;
    height: 62px;
    background-color: #D9E6E5;
    /* color: #088a7f; */
    border-radius: 10px;
    border: 1pxx solid gray;
}
.form-group {
    /* border: 1px solid black;  */
    padding: 20px;
    
}
.Done-button {
    border-radius: 8px;
background: #014E7F;
font-size: 20px;
margin-left: 20px;
width: 12%;

}
.Done-button:hover {
    
background: #014E7F;

}`, "",{"version":3,"sources":["webpack://./src/components/Forms/Medical Document/medicaldocument.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,UAAU;IACV,UAAU;IACV,eAAe;IACf,6BAA6B;IAC7B,yBAAyB;AAC7B;AACA;IACI,cAAc;;AAElB,oBAAoB;AACpB,eAAe;AACf,kBAAkB;AAClB,gBAAgB;AAChB,mBAAmB;AACnB,oBAAoB;AACpB,4BAA4B;AAC5B,iBAAiB;AACjB;AACA;AACA;;sBAEsB;IAClB,cAAc;;AAElB,oBAAoB;AACpB,eAAe;AACf,kBAAkB;AAClB,gBAAgB;AAChB,mBAAmB;AACnB,sBAAsB;AACtB,sBAAsB;AACtB,uBAAuB;AACvB,mBAAmB;;;AAGnB;AACA;IACI,UAAU;IACV,YAAY;IACZ,yBAAyB;IACzB,oBAAoB;IACpB,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI,8BAA8B;IAC9B,aAAa;;AAEjB;AACA;IACI,kBAAkB;AACtB,mBAAmB;AACnB,eAAe;AACf,iBAAiB;AACjB,UAAU;;AAEV;AACA;;AAEA,mBAAmB;;AAEnB","sourcesContent":[".main-content {\n    height: 190vh;\n    position: absolute;\n    top: 140px;\n    left: 335px;\n    z-index: 1;\n    width: 75%;\n    margin-left: px;\n    /* border: 1px solid black; */\n    background-color: #D9E6E5;\n}\n.medicaldocument {\n    color: #457B6D;\n\nfont-family: Poppins;\nfont-size: 30px;\nfont-style: normal;\nfont-weight: 600;\nline-height: normal;\n/* padding: 10px ; */\npadding: 60px 10px 10px 10px;\nmargin-left: 90px;\n}\n.info-para {\n/*    \n    border: 1px solid black;\n    font-size: 15px; */\n    color: #6C6969;\n\nfont-family: Poppins;\nfont-size: 15px;\nfont-style: normal;\nfont-weight: 400;\nline-height: normal;\nletter-spacing: 0.15px;\n/* text-align: left; */\n/* margin-left: 25px; */\ntext-align: justify;\n\n\n}\n.fields {\n    width: 95%;\n    height: 62px;\n    background-color: #D9E6E5;\n    /* color: #088a7f; */\n    border-radius: 10px;\n    border: 1pxx solid gray;\n}\n.form-group {\n    /* border: 1px solid black;  */\n    padding: 20px;\n    \n}\n.Done-button {\n    border-radius: 8px;\nbackground: #014E7F;\nfont-size: 20px;\nmargin-left: 20px;\nwidth: 12%;\n\n}\n.Done-button:hover {\n    \nbackground: #014E7F;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
