import React from "react";
import { Row, Col, Conatiner } from "react-bootstrap"
import "./HeaderImgSection.css"
import AllCasesHeaderimg from '../../../assets/images/AllCasesHeaderimg.png'

const HeaderImgSection = () => {
    return (
        <>
            <Row className="mb-4 h-row">
                <div className="HeaderImg">
                    <img src={AllCasesHeaderimg} alt="AllCasesHeaderimg" />
                    <h4 className="HeaderImgText">Unleashing Hope: Empowering Change<br />through Generosity!</h4>
                </div>
            </Row>
        </>
    )
}

export default HeaderImgSection;