import React from "react";
import { Row, Container, Col } from "react-bootstrap";
import CurvePattern from "../../../assets/images/CurvePattern.svg";
import Wave from "../../../assets/images/wave.svg";
import undrawcompletedtasks from "../../../assets/images/undraw_completed_tasks.png";
import "./WhyFoundationSection.css";

const WhyFoundationSection = () => {
  return (
    <>
      <Container className="GoalCalculatorContainer d-flex mt-5 justify-content-center align-items-center">
        <Row className="d-flex justify-content-center align-items-center">
          <h4 className="goalCal_heading">Why ShifaLia?</h4>
          <hr className="goalCal_line mt-2" />
          <p className="goalCal_p px-5">
            You must be wondering, why choose ShifaLia? The<br></br> answer is
            simple:
          </p>
        </Row>
      </Container>

      <img src={Wave} className="WaveImg"></img>
      <div className="row justify-content-center align-items-center WhyFoundationContainer mb-5">
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <img
            src={undrawcompletedtasks}
            className="undrawcompletedtasks "
            alt="Completed Tasks"
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <ul className="liContent">
            <li>
              ShifaLia’s easy-to-use platform allows a smooth process for the
              creation of your Campaign.
            </li>
            <li className="mt-4">
              ShifaLia charges a minimum platform fees of only 1%. Lowest among
              all platforms in Pakistan.{" "}
            </li>
            <li className="mt-4">
              Our platform utilizes the securest encryption to date to ensure
              that all your funds are safe and secure.​
            </li>
            <li className="mt-4">
              Our customer support team is always available to provide
              assistance.
            </li>
            <li className="mt-4">
              ShifaLia’s operations team ensures that all cases are thoroughly
              verified before being published on the platform making us a
              credible platform for both Campaigners and Supporter
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default WhyFoundationSection;
