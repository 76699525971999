import React from 'react';
import '../Auth.css';
import { Container } from 'react-bootstrap';
import Logo from "../../../assets/images/Logo.png";
import phoneIcon from "../../../assets/ForgetPasswordImages/phone_icon.png";
import { useNavigate } from 'react-router-dom';
import { successNotify } from '../../../services/helper/helper';

const RecoveryPhone = () => {
    const navigate = useNavigate();

    const verifyHandler = () => {
        navigate("/verify-account?verify=phone")
    }

    const resendCode = () => {
        successNotify("Code has been resent!")
    }

    return (
        <div className='forget_password'>
            <Container>
                <a className="navbar-brand" href="/">
                    <img src={Logo} alt="" className="img-fluid" />
                    <p>ShifaLia</p>
                </a>

                <div className='forget_password_box'>
                    <h5>Forgot your password?</h5>

                    <div className='forget_inner_box recovery_email'>
                        <div>
                            <img src={phoneIcon} alt='' />
                            <div>
                                <b>Recover password via Phone Number</b>
                            </div>
                        </div>
                        <input placeholder='Enter your Phone Number' />
                    </div>

                    <button onClick={verifyHandler}>Submit</button>
                    <p className='didnt_receive'>Did not receive the code?
                        <span style={{ cursor: "pointer" }} onClick={resendCode}> Resend code</span></p>
                </div>
            </Container>
        </div>
    )
}

export default RecoveryPhone;