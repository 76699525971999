import React from "react";
import { Col, Row, Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import "./OurLocation.css"

const OurLocation = () => {
    return (
        <>
            <Row>
                <h5 className="OurLocationHeading mt-5 mb-4">Our Location</h5>
            </Row>
            <Row>
                <div className="map-container mb-5 mt-5">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14483.343572633337!2d67.04780358377604!3d24.835285147126903!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33c3765cb8f1f%3A0x1fdb63938983f474!2sD.H.A.%20Phase%202%20Phase%202%20Defence%20Housing%20Authority%2C%20Karachi%2C%20Karachi%20City%2C%20Sindh%2075500%2C%20Pakistan!5e0!3m2!1sen!2s!4v1690831544877!5m2!1sen!2s"
                        width="100%" height="650" style={{ border: 0 }} allowFullScreen="" loading="eager">
                    </iframe>
                    <Col lg={4} md={5} sm={8} xs={9} className="map-overlay px-4 py-5">
                        <div className="map-overlay-content">
                            <h5 className="countryTxt">Pakistan</h5>
                            <p className="mtxt mx-3">City:</p>
                            <p className="majortxt mx-3">Karachi</p>
                            <p className="mtxt mx-3">Address:</p>
                            <p className="majortxt mx-3">Unit#377, Block 20, Fb area karachi</p>
                            <p className="mtxt mx-3">Phone No.</p>
                            <p className="majortxt mx-3">+923152344269 / 03152344269</p>
                            <p className="mtxt mx-3">Email Address</p>
                            <p className="majortxt mx-3">alianafeesfoundation<br></br>@gmail.com</p>
                        </div>
                        <Link to="/contactusUSA">
                            <Button className="USABtn">USA</Button>
                        </Link>
                    </Col>
                </div>
            </Row>
        </>
    )
}

export default OurLocation