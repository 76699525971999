import React from "react";
import { Icon } from "@iconify/react";
import PatientDoc1 from "../../../assets/images/PatientDoc1.png";
import PatientDoc2 from "../../../assets/images/PatientDoc2.png";
import "./support.scss";

const Support = () => {
  return (
    <div className="support-section">
      <div className="left-div">
        <h6>Documents</h6>
        <div className="doc-hr"></div>
        <div className="doc-images">
          <img src={PatientDoc1} alt="" className="docimg1"/>
          <div className="below-doc">
          <img src={PatientDoc2} alt="" className="docimg2"/>
          <img src={PatientDoc2} alt="" className="docimg2"/>
          <img src={PatientDoc2} alt="" className="docimg2"/>
          </div>
        </div>
      </div>
      <div className="right-div">
        <h6>Words of Support (0)</h6>
        <div className="wordsodsupport-hr"></div>
        <p>
          Become a moral supporter for the Campaigner by leaving words of
          encouragement.
        </p>
        <div className="comments-div">
          <Icon
            icon="iconamoon:profile-circle-thin"
            color="#014e7f"
            width="50"
            height="50"
          />
          <div>
            <input
              type="text"
              placeholder="Share your well wishes here!"
              className="comment"
            />
          </div>
        </div>
        <button>Post</button>
      </div>
    </div>
  );
};

export default Support;
