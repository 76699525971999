import React from "react";
import { Link } from 'react-router-dom'
import Logo from "../../../assets/images/Logo.png"
import editfundDetail1 from '../../../assets/images/editfundDetail1.svg'
import editfundDetail2 from '../../../assets/images/editfundDetail2.svg'
import editfundDetail3 from '../../../assets/images/editfundDetail3.svg'
import editfundDetail4 from '../../../assets/images/editfundDetail4.svg'
import editfundDetail5 from '../../../assets/images/editfundDetail5.svg'
import editfundDetail6 from '../../../assets/images/editfundDetail6.svg'
import editfundDetail7 from '../../../assets/images/editfundDetail7.svg'
import editfundDetailArrow from '../../../assets/images/editfundDetailArrow.svg'
import editdetailBottom from '../../../assets/images/editfundDetailbottom.svg'
import Facebook from "../../../assets/images/FacebookIcon.png";
import Whatsapp from "../../../assets/images/WhatsappIcon.png";
import "./EditDetail_I.css"

const EditDetail_I = () => {
    return (
        <>
            <section className="EditDetailsSection py-3 ">
                <div className="EditDetails_div ">

                    <div className='top_Content_div px-5'>
                        <div>
                            <div className='component_1 '>
                                <div className='_circle1'></div>
                                <div class="vl1"></div>
                            </div>

                            <div className='component_2'>
                                <div className='_circle2'></div>
                                <div class="vl2"></div>
                            </div>

                            <div className='component_2'>
                                <div className='_circle3'></div>
                                <div class="vl3"></div>
                            </div>
                        </div>
                        <div>
                            <img src={Logo} alt="Logo"></img>
                        </div>
                        <div>
                            <h4>How to start a Campaign?</h4>
                        </div>
                        <div>
                            <hr className='horLine mt-4'></hr>
                        </div>
                    </div>

                    <div className="cardsSection px-5">
                        <div className="row mt-5">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                                <div className="fundrasier_Detail_card px-2">
                                    <div className="headingContent my-3">
                                        <img src={editfundDetail1} alt="editfundDetail1"></img>
                                        <h5 className="px-3 mt-1">Campaign Details</h5>
                                    </div>
                                    <div className="pb-2">
                                        <Link className="editfundDetailArrow"><img src={editfundDetailArrow} alt={editfundDetailArrow} ></img></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                                <div className="fundrasier_Detail_card px-2">
                                    <div className="headingContent my-3">
                                        <img src={editfundDetail2} alt="editfundDetail1"></img>
                                        <h5 className="px-3 mt-1">Medical Documents</h5>
                                    </div>
                                    <div className="pb-2">
                                        <Link className="editfundDetailArrow"><img src={editfundDetailArrow} alt={editfundDetailArrow} ></img></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                                <div className="fundrasier_Detail_card px-2">
                                    <div className="headingContent my-3">
                                        <img src={editfundDetail3} alt="editfundDetail1"></img>
                                        <h5 className="px-3 mt-1">Patient Verification</h5>
                                    </div>
                                    <div className="pb-2">
                                        <Link className="editfundDetailArrow"><img src={editfundDetailArrow} alt={editfundDetailArrow} ></img></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                                <div className="fundrasier_Detail_card px-2">
                                    <div className="headingContent my-3">
                                        <img src={editfundDetail4} alt="editfundDetail1"></img>
                                        <h5 className="px-3 mt-1">Patient’s Background</h5>
                                    </div>
                                    <div className="pb-2">
                                        <Link className="editfundDetailArrow"><img src={editfundDetailArrow} alt={editfundDetailArrow} ></img></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                                <div className="fundrasier_Detail_card px-2">
                                    <div className="headingContent my-3">
                                        <img src={editfundDetail5} alt="editfundDetail1"></img>
                                        <h5 className="px-3 mt-1">Social Profiles</h5>
                                    </div>
                                    <div className="pb-2">
                                        <Link className="editfundDetailArrow"><img src={editfundDetailArrow} alt={editfundDetailArrow} ></img></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                                <div className="fundrasier_Detail_card px-2">
                                    <div className="headingContent my-3">
                                        <img src={editfundDetail6} alt="editfundDetail1"></img>
                                        <h5 className="px-3 mt-1">Hospital Details</h5>
                                    </div>
                                    <div className="pb-2">
                                        <Link className="editfundDetailArrow"><img src={editfundDetailArrow} alt={editfundDetailArrow} ></img></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                                <div className="fundrasier_Detail_card px-2">
                                    <div className="headingContent my-3">
                                        <img src={editfundDetail7} alt="editfundDetail1"></img>
                                        <h5 className="px-3 mt-1">Team</h5>
                                    </div>
                                    <div className="pb-2">
                                        <Link className="editfundDetailArrow"><img src={editfundDetailArrow} alt={editfundDetailArrow} ></img></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="lines_div">
                        <div className='component1 '>
                            <div className='c_circle'></div>
                            <hr className='Cline1'></hr>
                        </div>
                        <div className='component2 pt-1'>
                            <div className='c_circle'></div>
                            <hr className='Cline2'></hr>
                        </div>
                        <div className='component2 pt-1'>
                            <div className='c_circle'></div>
                            <hr className='Cline3'></hr>
                        </div>
                    </div>

                    <div className="bottom_content mt-4">
                        <img src={editdetailBottom} alt={editdetailBottom}></img>
                        <div className="inner_bottom_content px-2">
                            <p className="mx-4 mt-3">Have a question? Want to know more? Check out our <Link to='/works' className="FaqLink" >FAQs</Link> page or chat with us.</p>
                            <div className="mx-4">
                                <button className="WhatsAppbutton"><img src={Whatsapp} alt="Whatsapp" className="px-1"></img>
                                    Contact Us</button>
                            </div>
                            <div>
                                <button className="Facebookbutton"><img src={Facebook} alt="Facebook" className="px-1"></img>
                                    Contact Us</button>
                            </div>
                        </div>
                    </div>

                </div>
            </section >
        </>
    )
}

export default EditDetail_I