import React from 'react'
import Header from "../components/Header/header"
import SeeAllComment from "../components/PatientsInfoPageComponent/SeeAllComments/SeeAllComments"
import Footer from "../components/Footer/footer"

const SeeAllComments = () => {
  return (
    <div>
      <Header />
      <SeeAllComment />
      <Footer />
    </div>
  )
}

export default SeeAllComments