import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './ConfusedAbtFundraiserModal.css';
import { Button } from 'react-bootstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../../../assets/images/Logo.png"

const ConfusedAbtFundraiserModal = ({ closeConfusedAbtFundModal }) => {

    useEffect(() => {
        document.body.style.overflowY = "hidden";
        return () => {
            document.body.style.overflowY = "scroll";
        };
    }, []);

    return (
        <>
            <div className='CModalwrapper'></div>
            <div className='CModal_Container pt-2'>
                <div className=''>
                    <FontAwesomeIcon icon={faTimes} className='closeIcon' onClick={closeConfusedAbtFundModal} />
                </div>

                <div className='topContent_div pt-1'>
                    <img src={Logo} alt="Logo" className='C_Logo'></img>
                    <h4 className='cmodal_heading'>How to start a Campaign?</h4>
                </div>

                <div className=''>
                    <hr className='cLine '></hr>
                </div>

                <div className='component1 '>
                    <div className='c_circle'></div>
                    <hr className='Cline1'></hr>
                </div>
                <div className='component2'>
                    <div className='c_circle'></div>
                    <hr className='Cline2'></hr>
                </div>
                <div className='component2'>
                    <div className='c_circle'></div>
                    <hr className='Cline3'></hr>
                </div>

                <div className='Step1C mt-3'>
                    <div className='step1'>
                        <p className='Steptxt'>Step 01</p>
                    </div>
                    <hr className='middlecontent_line'></hr>
                    <div className='step1Content px-3 py-3'>
                        <p className='Step1Contenttxt'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                    </div>
                </div>

                <div className='Step2C mt-5'>
                    <div className='step1'>
                        <p className='Steptxt'>Step 02</p>
                    </div>
                    <hr className='middlecontent_line'></hr>
                    <div className='step1Content px-3 py-3'>
                        <p className='Step1Contenttxt '>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                    </div>
                </div>

                <div className='Step1C mt-5'>
                    <div className='step1'>
                        <p className='Steptxt '>Step 03</p>
                    </div>
                    <hr className='middlecontent_line'></hr>
                    <div className='step1Content px-3 py-3'>
                        <p className='Step1Contenttxt'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                    </div>
                </div>

                <div className='component3 mt-3'>
                    <hr className='Cline2'></hr>
                    <div className='c_circleend'></div>
                </div>
                <div className='component4'>
                    <hr className='Cline1'></hr>
                    <div className='c_circleend'></div>
                </div>

                <div className='startFundContainer px-5 py-5 mt-3 mb-4'>
                    <h4 className='startFundtxt'>You are now ready to start a campaign. Click here</h4>
                    <Button className='start_fundBtn'>Start Fundraising</Button>
                </div>

                <div>
                    <p className='HaveaQuestTxt'>Have a question? Check out our <Link to='/works' className="FAQLink"> FAQs </Link> page.</p>
                </div>

            </div>
        </>
    )
}

export default ConfusedAbtFundraiserModal;