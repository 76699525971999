import React from "react";
import { Link } from "react-router-dom";
import "./FundraiserSection.css";
import HowitWorkFundraiserImg from "../../../assets/images/howItWorksFundraiser.png";
import { Button } from "react-bootstrap";

const FundraiserSection = () => {
  return (
    <>
      <section>
        <div className="FudraiserDiv mt-4">
          <img src={HowitWorkFundraiserImg} alt="HowitWorkFundraiserImg"></img>
          <div className="MakeFund">
            <h4 className="MakeFundTxt">
              Make sure your Campaign is Successful!
            </h4>
            <div className="Btns_div">
              <Link to="/fundraisingTips">
                <Button className="fundraiser_tips">Campaign Tips</Button>
              </Link>
              <Link to="/fundraisingIdeas">
                <Button className="fundraiser_ideas">Campaign Ideas</Button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FundraiserSection;
