import React from 'react'
import Footer from '../components/Footer/footer';
import Header from "../components/Header/header";
import Banner from '../components/FaqsComponents/Banner';
import BannerBox from '../components/FaqsComponents/BannerBox';
import Content from '../components/FaqsComponents/Content';
import Campaign from '../components/FaqsComponents/Campaign';

const FAQs = () => {
    return (
        <div>
            <Header />
            <Banner />
            <BannerBox />
            <Content />
            <Campaign />
            <Footer />
        </div>
    )
}

export default FAQs