import React, {} from 'react'
import './sidebar.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { NavLink } from 'react-router-dom'
// import 'react-bootstrap-icons/font/bootstrap.icons.css'
import details from "../../assets/images/details.png"
import fundraise from "../../assets/images/fundraise.png"
import hospitaluser from "../../assets/images/hospitaluser.png"
import patient from "../../assets/images/patient-list.png"
import person from "../../assets/images/person.png"
import medical from "../../assets/images/medical-doc.png"
import social from "../../assets/images/social-icon.png"
import team from "../../assets/images/team.png"
import down from "../../assets/images/drop-down.png"
import hamburger from "../../assets/images/menuicon.png"

const SideBar = ({ isSidebarOpen, toggleSidebar }) => {
  // const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  // const [isHamburgerRotated, setIsHamburgerRotated] = useState(false);

  // const toggleSidebar = () => {
  // //   setIsSidebarOpen(!isSidebarOpen);
  // //   // setIsSidebarOpen(!isSidebarOpen);
  //   setIsHamburgerRotated(!isHamburgerRotated);
  // };




    const menuItem = [
         
        {
          path: "#",
          name: "Campaign Details",
          imageSrc: fundraise,
        }, 
        {
          path: "/detailpage3",
          name: "Medical Document",
          imageSrc: medical,
        }, 
        {
          path: "/detailpage4",
          name: "Patient Verification",
          imageSrc: patient,
        }, 
        {
          path: "/detailpage5",
          name: " Background",
          imageSrc: person,
        }, 
        {
          path: "/detailpage6",
          name: "Social Profiles",
          imageSrc: social,
        }, 
        {
          path: "/detailpage7",
          name: "Hospital Details",
          imageSrc: hospitaluser,
        }, 
        {
          path: "/detailpage8",
          name: "Team",
          imageSrc: team,
        }, 
        
        
        
      ]
  return (
    <>
      <div className=  "main">
      
        <div className={`content ${isSidebarOpen ? 'sidebar-open' : 'open'}`}>
          <div className="logo side-logo">
            <img src="Logo.png" alt="Logo" />
          </div>
          <div className={`sidebar ${isSidebarOpen ? 'sidebar-open' : 'open'}`}>
            <div className="top-section my-1">
              <span className='items-heading-main'>
                <img src={details} alt="logo" className='icons-1'/>
                <span className='mx-4 items-detail'>Details
                <img src={down} alt="icon" className=' down-icon'/>
                </span>
              </span>
            </div>
            {menuItem.map((item, index) => (
              <NavLink
                to={item.path}
                key={index}
                className="nav-link navi-links"
                activeClassName="active"
              >
                <div className="link_text d-flex align-items-center ">
                  <img src={item.imageSrc} alt={item.name} className="icons-1" />
                  <span className="mr-4 items-heading">{item.name}</span>
                </div>
              </NavLink>
            ))}
          </div>
        </div>
        
      </div>
      <button className={`  hamburger-btn ${isSidebarOpen ? 'rotate' : 'side-navv'}`} onClick={() => {
    toggleSidebar();
  }}>
          <img src={hamburger} alt="ham"  />
          <span className='ham-head mx-3 sm:mx-2'>Details</span>
        </button>
    </>
  )
}

export default SideBar
