import styled, { keyframes } from 'styled-components';

export const StepContainer = styled.div`
 display:flex;
 ${'' /* justify-content: center; */}
${'' /* align-items: center; */}
${'' /* border: 2px solid black; */}
${'' /* margin: 0 auto; */}
width:380px;
@media screen and (max-width: 1100px) {
    display:none;
`

export const Circle = styled.div`
width:50px;
height:50px;
border-radius: 50px;
background: rgba(2, 98, 123, 0.48);
`

export const StepNoWrap = styled.div`
display: flex;
width: 19px;
height: 82.804px;
${'' /* border:1px solid black; */}
flex-direction: column;
justify-content:center;
`

export const StepNo = styled.p`
text-align: center;
font-size: 65px;
font-family: Poppins;
font-weight: 600;
letter-spacing: 0.65px;
margin-top:-50px; /* Adjust the font size as needed */
  color: transparent; /* Set the color of the number */
  -webkit-text-stroke: 2px #595858; /* Set the outline color and thickness */
  -webkit-background-clip: text; /* Apply the background-clip to the text */
  background-clip: text;
`

export const DashLineWrap = styled.div`
display: flex;
width: 357px;
flex-direction: column;
${'' /* justify-content: center;
align-items: center; */}
${'' /* border:1px solid black; */}
`

export const DashLine = styled.p`
 color: #000;
text-align: center;
font-size: 30px;
font-family: Poppins;
font-weight: 300;
letter-spacing: 0.3px;
margin-left:5%;
`

export const Heading1 = styled.p`
color: #014E7F;
text-align: center;
font-size: 20px;
font-family: Poppins;
font-weight: 600;
line-height: 24px;
`

export const Heading2 = styled.p`
color: #000;
text-align: center;
font-size: 17px;
font-family: Poppins;
font-weight: 300;
line-height: 24px;
`

export const Card = styled.div`
width: 254px;
height: 193px;
display:flex;
border-radius: 15px;
background: rgba(69, 123, 109, 0.76);
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
justify-content: center;
align-items: center;
flex-direction:column;
margin: 0 auto 15px auto;
`

export const CardContent = styled.p`
color: #FFF;
text-align: center;
font-size: 17px;
font-family: Poppins;
font-weight: 500;
`





