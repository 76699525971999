import React, { useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import {
  Subtitle,
  Title,
  VideoContainer,
  Video,
  PlayButton,
} from "./VideoSectionElement";
import { Heading, Line, TitleWrap } from "../HeadingElement";
import Video1 from "../../videos/video1.mp4";

const VideoSection = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayClick = () => {
    setIsPlaying(!isPlaying);
  };
  return (
    <>
      {/* =========== Video Section ================ */}

      <Container className="py-5">
        <Row>
          <Title>How it works?</Title>
          <Subtitle>
            Here’s a quick guide on how our platform works and how you can start
            your own campaign.
          </Subtitle>
        </Row>
      </Container>
      <Container className="px-5">
        <Row>
          <TitleWrap>
            <Line></Line>
            <Heading>Watch the video</Heading>
          </TitleWrap>
        </Row>
        <Row>
          <Container className="d-flex my-5  justify-content-center align-items-center">
            <VideoContainer className="">
              <Video src={Video1} autoPlay={isPlaying} controls />
              {/* {!isPlaying && (
                            <PlayButton onClick={handlePlayClick} >
                        </PlayButton>
                        )} */}
            </VideoContainer>
          </Container>
        </Row>
      </Container>

      {/* =========== Video Section ================ */}
    </>
  );
};

export default VideoSection;
