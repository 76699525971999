import React from 'react'
import Header from "../components/Header/header"
import PopularArticlePage from "../components/PopularArticlesComponents/PopularArticlesPage"
import Footer from "../components/Footer/footer"

const PopularArticlesPage = () => {
  return (
    <div>
      <Header />
      <PopularArticlePage />
      <Footer />
    </div>
  )
}

export default PopularArticlesPage