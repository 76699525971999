import React from 'react'
import './ourvalues.css'
import img1 from './assets/img1.svg'
import img2 from './assets/img2.svg'
import img3 from './assets/img3.svg'
import diamond from './assets/diamond.svg'
import 'bootstrap/dist/css/bootstrap.min.css';
import { RiDoubleQuotesL } from "react-icons/ri";
import { RiDoubleQuotesR } from "react-icons/ri";


const OurValues = () => {
  return (
    <>
      <div className="values">
        <div className="value-div1 row">
            <div className="value-div01 col ">
              <img src={img1} alt="" />
            </div>
            <div className="value-div02 col ">
              <div className="para-values">
              <RiDoubleQuotesL size={40} color='#014E7F' />
              <span>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, at accusamus adipisci tempora voluptas eligendi aperiam quos esse. 
                <RiDoubleQuotesR size={40} color='#014E7F' />
              </span>
                
              </div>
            </div>
            <div className="value-div01 col ">
            <img src={img2} alt="" />
            </div>
        </div>
        <div className="value-div1 row">
            
            <div className="value-div02 bg-blue col ">
              <div className="para-values">
              
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, at accusamus adipisci tempora voluptas eligendi aperiam quos esse. 
                
                
              </div>
            </div>
            <div className="value-div01 center flex-col col bg-blue-light  ">
              <div className='p-6 '>

                <h1 className='our-values-heading'>OUR VALUES</h1>
            <img src={diamond} alt="" />
              </div>
            </div>
            <div className="value-div02 col bg-green ">
              <div className="para-values">
             
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, at accusamus adipisci tempora voluptas eligendi aperiam quos esse. 
                
                
              </div>
            </div>
            
        </div>
        <div className="value-div1 row">
            
            <div className="value-div02 bg-green col ">
              <div className="para-values">
              
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, at accusamus adipisci tempora voluptas eligendi aperiam quos esse. 
                
                
              </div>
            </div>
            <div className="value-div01 center flex-col col bg-blue-light  ">
              
            <img src={img3} alt="" />
            </div>
            <div className="value-div02 col bg-light-blue ">
              <div className="para-values">
             
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, at accusamus adipisci tempora voluptas eligendi aperiam quos esse. 
                
                
              </div>
            </div>
            
        </div>
        
        
      </div>
    </>
  )
}

export default OurValues
