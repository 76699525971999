import React from 'react'
import Header from "../components/Header/header"
import PricingPageMainCom from "../components/PricingPage1Components/PricingPageMainCom/PricingPageMainCom"
import Footer from "../components/Footer/footer"

const PricingPage1 = () => {
  return (
    <div>
      <Header />
      <PricingPageMainCom />
      <Footer />
    </div>
  )
}

export default PricingPage1