// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-box {
  display: flex;
  align-items: center;
  border: 2px solid #014E7F;
  border-radius: 10px;
  padding: 5px;
  color: #014E7F !important;
  background-color: rgb(248, 243, 234) !important;
  
}
.input-box .fa-search {
  margin-left: 8px;
  color: #014E7F;
  background-color: rgb(248, 243, 234) !important;
  margin-right: 10px;
}
.form-control{
  background-color: rgb(248, 243, 234) !important;
}

`, "",{"version":3,"sources":["webpack://./src/components/AllCasesPageComponents/SearchSection/SearchSection.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,mBAAmB;EACnB,YAAY;EACZ,yBAAyB;EACzB,+CAA+C;;AAEjD;AACA;EACE,gBAAgB;EAChB,cAAc;EACd,+CAA+C;EAC/C,kBAAkB;AACpB;AACA;EACE,+CAA+C;AACjD","sourcesContent":[".input-box {\n  display: flex;\n  align-items: center;\n  border: 2px solid #014E7F;\n  border-radius: 10px;\n  padding: 5px;\n  color: #014E7F !important;\n  background-color: rgb(248, 243, 234) !important;\n  \n}\n.input-box .fa-search {\n  margin-left: 8px;\n  color: #014E7F;\n  background-color: rgb(248, 243, 234) !important;\n  margin-right: 10px;\n}\n.form-control{\n  background-color: rgb(248, 243, 234) !important;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
