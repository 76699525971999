import React, { useState } from "react";
import mainImg1 from "../../../assets/casesImgs/1.png";
import mainImg2 from "../../../assets/casesImgs/2.png";
import mainImg3 from "../../../assets/casesImgs/3.png";
import cmtprofile from "../../../assets/casesImgs/cmtprofile.png";
import cmtprofile1 from "../../../assets/casesImgs/cmtprofile1.png";
import Commentcard from "./commentcard";
import Patientcard from "./patientcard";
import "./caseinfo.scss";
import { Link, useParams } from "react-router-dom";
import { Provider } from "../../../context/Context";
import { useMutation, useQuery } from "@apollo/client";
import { useApi } from "../../../services/mutation&Query/GraphqlApi";
import { CREATE_COMMENTS } from "../../../services/methods/Mutations";
import { GET_COMMENTS } from "../../../services/methods/Query";
import { useSelector } from "react-redux";

const Caseinfo = () => {
  const { caseById } = Provider();
  const { user } = useSelector((state) => state.user);
  const [comment, setComment] = useState("");
  const params = useParams();

  // get comments
  const { data, refetch } = useQuery(GET_COMMENTS, {
    variables: {
      fundraiserId: params?.id,
    },
  });
  const allComments = data?.getComments?.data;

  // create comment functionality

  const { CreateCommentsMutation } = useApi();
  const [casecomments] = useMutation(CREATE_COMMENTS);
  const comment_data = {
    id: params.id,
    comment,
  };

  const handleComment = async () => {
    await CreateCommentsMutation(casecomments, comment_data);
    refetch();
    setComment("");
  };

  return (
    <>
      {caseById && (
        <div className="case-info">
          <div className="patient-case-s">
            <Patientcard
              titlename="Patient Case Title"
              btn2="Support this Campaign"
            />
          </div>

          {caseById?.CaseInfo?.length >= 1 && (
            <div className="updates-section ">
              <div className="hor-line mb-5"></div>
              <div className="updates-heading d-flex">
                <h4>Updates ({caseById?.CaseInfo?.length})</h4>
                <Link to="/viewAllUpdates">
                  <h5>View All Updates</h5>
                </Link>
              </div>
              <div className="update-card d-flex gap-4 my-5 pb-5">
                <div className="card-left">
                  <img
                    src={caseById?.CaseInfo[0]?.ImageLinks[0]}
                    alt="mainImg"
                    className="mainImg2Update"
                  />
                  {caseById?.CaseInfo[0]?.ImageLinks.length > 2 && (
                    <div className="bottomU-imgs">
                      <img
                        src={caseById?.CaseInfo[0]?.ImageLinks[1]}
                        alt="mainImg1"
                        className="mainImg1Update"
                      />
                      <img
                        src={caseById?.CaseInfo[0]?.ImageLinks[2]}
                        alt="mainImg3"
                        className="mainImg3Update"
                      />
                    </div>
                  )}
                </div>

                <div className="card-right">
                  <div className="card-heading d-flex mb-3">
                    <h5>{caseById?.CaseInfo[0]?.updateTitleNews}</h5>
                    <span>{caseById?.CaseInfo[0]?.addedDB}</span>
                  </div>
                  <p>{caseById?.CaseInfo[0]?.updateDescription}</p>
                </div>
              </div>
              <div className="hor-line mt-5 mb-2"></div>
            </div>
          )}
          <div className="cmts-sec">
            <h5>Comments ({allComments?.length})</h5>
            {user && user?.role === "donar" && (
              <div className="cmt-search my-4">
                <input
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  type="text"
                  placeholder="Leave good wishes for *patient name*"
                  className="cmts-input-val"
                />
                <button onClick={handleComment}>Post</button>
              </div>
            )}
            {allComments?.length >= 1 && (
              <Commentcard
                image={allComments[0]?.userDetails?.profileImageLink}
                name={allComments[0]?.userDetails?.firstName}
                comment={allComments[0]?.comments}
                date={allComments[0]?.addedDB}
              />
            )}
            {/* <Commentcard
              image={allComments[1]?.userDetails?.profileImageLink}
              name={allComments[1]?.userDetails?.firstName}
              comment={allComments[1]?.comments}
              date={allComments[1]?.addedDB}
            /> */}

            {allComments?.length > 1 && (
              <div className="btn-btn m-4 d-flex justify-content-center">
                <Link to={`/allComments/${params.id}`}>
                  <button>View All</button>
                </Link>
              </div>
            )}
          </div>
        </div>
      )}
      {user?.data?._id && (
        <div className="goodwishes-sec mt-5">
          <h4>Sign up to leave Good wishes!</h4>
          <Link to="/signup">
            <button>Sign Up</button>
          </Link>
        </div>
      )}
    </>
  );
};

export default Caseinfo;
