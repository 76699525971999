import React from 'react'
import { Col, Form, Row } from 'react-bootstrap';
import gigImg from "../../assets/GigDescriptionImages/gig_title_main.jpg";
import gigSubImg from "../../assets/GigDescriptionImages/sub_img.jpg";

const Step3 = ({ stepShow, setStepShow }) => {
  return (
    <>
      <h3>Order Confirmation</h3>
      <p>Confirm your order!</p>

      <div>
        <ul className="step_progressbar">
          <li className="active"></li>
          <li className='active'></li>
          <li className={stepShow === 4 && 'active'}></li>
        </ul>
      </div>

      {stepShow !== 4 && <h4 className='order_sum'>Order Summary</h4>}
      <Row className='step_make_border'>
        {
          stepShow === 4 && <Col md={12}>
          <div className='success_brought'>
            <p>You’ve Successfully bought the service. You can check the progress of your work:</p>

            <div>
              <button onClick={() => setStepShow(1)}>Later</button>
              <button>Track Progress</button>
            </div>
          </div>
        </Col>
        }
        <Col md={6}>
          <div className='input_fields'>
            <label>Gig Name</label>
            <input placeholder='Gig Name' />
          </div>

          <div className='input_fields'>
            <label>Price</label>
            <input placeholder='Rs. 15,000' />
          </div>

          <div className='input_fields'>
            <label>Gig Detail</label>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis egestas dolor diamLorem ipsum dolor sit amet, consectetur adipiscing elit. Duis egestas dolor diamLorem ipsum dolor sit amet, consectetur adipiscing elit. Duis egestas dolor diamLorem ipsum dolor sit amet, consectetur adipiscing elit. Duis egestas dolor diamLorem ipsum dolor sit amet, consectetur adipiscing elit. Duis egestas dolor diamLorem ipsum dolor sit amet, consectetur adipiscing elit. Duis egestas dolor diamLorem ipsum dolor sit amet, consectetur adipiscing elit. Duis egestas dolor diamLorem ipsum dolor sit amet, consectetur adipiscing elit. Duis egestas dolor diamLorem ipsum dolor sit amet, consectetur adipiscing elit. Duis egestas dolor diam</p>
          </div>
        </Col>
        <Col md={6} className='payment_right_col'>
          <img src={gigImg} alt='' />

          <Row className='mt-3'>
            <Col md={4} sm={4} xs={4}>
              <img src={gigSubImg} alt='' />
            </Col>
            <Col md={4} sm={4} xs={4}>
              <img src={gigSubImg} alt='' />
            </Col>
            <Col md={4} sm={4} xs={4}>
              <img src={gigSubImg} alt='' />
            </Col>
          </Row>
        </Col>

        {
          stepShow !== 4 &&
          <Col md={12}>
            <div className='additional_services no_service_bg'>
              <h5>Additional Services</h5>

              <div className='services_show mt-5'>
                <div className='services_individual'>
                  <div>
                    <Form.Check
                      type={"checkbox"}
                      label={`Additional  Service Name`}
                      checked
                    />
                  </div>
                  <h6>Rs. 2500</h6>
                </div>
                <div className='services_individual'>
                  <div>
                    <Form.Check
                      type={"checkbox"}
                      label={`Additional  Service Name`}
                      checked
                    />
                  </div>
                  <h6>Rs. 2500</h6>
                </div>
              </div>

              <div className='services_individual mt-4'>
                <div>
                  <h6>Total</h6>
                </div>
                <h6>Rs. 2500</h6>
              </div>
            </div>
          </Col>
        }
      </Row>
    </>
  )
}

export default Step3;