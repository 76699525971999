import React from "react";
import Footer from "../components/Footer/footer";
import Comments from "../components/cases/viewallcmts/allcomments";
const Allcomments = () => {
  return (
    <>
      <div>
        <Comments />
        <Footer />
      </div>
    </>
  );
};

export default Allcomments;
