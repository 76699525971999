import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { Modal } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import ReactSwitch from "react-switch";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import AllcasesData from "../../AllCasesPageComponents/AllCasesSection/AllcasesData";
import Gigscard from "../gigscard";
import "./myProfile.scss";
import {
  GET_MY_CASES_QUERY,
  GET_MY_SERVICES,
} from "../../../services/methods/Query";
import Cookies from "universal-cookie";
import { useQuery } from "@apollo/client";
import { Provider } from "../../../context/Context";
import Loader from "../../Loader/Loader";
import { useSelector } from "react-redux";

const MyProfile = ({ user }) => {
  const [checked, setChecked] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showsupported, setshowsupported] = useState(false);
  const [myCampaigns, setmyCampaigns] = useState(false);
  const [myfundraiser, setmyfundraiser] = useState(false);
  const caseItem = [
    {
      id: 1,
      name: "BelievingInBenjamin",
      amount: "$1,500 USD",
      Date: "June 7, 2022",
    },
    {
      id: 2,
      name: "BelievingInBenjamin",
      amount: "$1,500 USD",
      Date: "June 7, 2022",
    },
    {
      id: 3,
      name: "BelievingInBenjamin",
      amount: "$1,500 USD",
      Date: "June 7, 2022",
    },
    {
      id: 4,
      name: "BelievingInBenjamin",
      amount: "$1,500 USD",
      Date: "June 7, 2022",
    },
  ];
  // const myCampaignsData = AllcasesData.slice(0, 2);
  const { user: loginUser } = useSelector((state) => state.user);

  const cookies = new Cookies();
  const { data } = useQuery(GET_MY_CASES_QUERY, {
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: cookies.get("token"),
      },
    },
  });
  const allPatientCases = data?.getAllPatientCases?.data;

  const { data: services, loading } = useQuery(GET_MY_SERVICES, {
    context: {
      headers: {
        Authorization: cookies.get("token"),
      },
    },
  });
  const allServices = services?.getMyServices?.data;

  const handleChange = (val) => {
    setChecked(val);
  };
  const changeState = () => {
    setshowsupported(!showsupported);
  };
  const showmycampaigns = () => {
    setmyCampaigns(!myCampaigns);
  };
  const showmyfundraisers = () => {
    setmyfundraiser(!myfundraiser);
  };
  const handleModal = () => {
    setShowModal(!showModal);
  };
  const handleBackClick = () => {
    setshowsupported(false);
    setmyCampaigns(false);
    setmyfundraiser(false);
  };
  return (
    <>
      <div
        className="myprofile-default"
        style={{
          display:
            showsupported || myCampaigns || myfundraiser ? "none" : "block",
        }}
      >
        <div className="welcome-div">
          <p>Friday, February 2nd</p>
          <h4>
            Welcome, <span>{user?.firstName}</span>
          </h4>
          <div className="inner">
            <select name="">
              <option value="This Week">This Week</option>
              <option value="Month">Month</option>
            </select>
            <div className="vl"></div>
            <span>
              $0.00
              <p>Donations received</p>
            </span>
            <div className="vl"></div>
            <span>
              $0.00
              <p>Donations received</p>
            </span>
          </div>
        </div>
        <div className="cards-div">
          {loginUser?.role === "donar" && (
            <div className="card-" onClick={changeState}>
              <Icon
                icon="bxs:donate-heart"
                color="#014e7f"
                width="100"
                height="100"
              />
              <p>Campaigns I’ve Supported</p>
            </div>
          )}

          {loginUser?.role === "patient" && (
            <>
              <div className="card-" onClick={showmyfundraisers}>
                <Icon
                  icon="simple-icons:crowdsource"
                  color="#014e7f"
                  width="100"
                  height="100"
                />
                <p>My Campaigns</p>
              </div>
              <div className="card-" onClick={showmycampaigns}>
                <Icon
                  icon="mingcute:pig-money-fill"
                  color="#014e7f"
                  width="100"
                  height="100"
                />
                <p>Support for My Campaigns</p>
              </div>
            </>
          )}
        </div>
        {loginUser?.role === "patient" && (
          <>
            <div className="my-gigs-div">
              {loading ? (
                <Loader />
              ) : (
                <>
                  <h6 className="px-2">My Gigs</h6>
                  <div className="gigs-wrapper">
                    {allServices?.length >= 1 && (
                      <Gigscard data={allServices} />
                    )}
                  </div>
                </>
              )}
            </div>
            <div className="profile-strength">
              <p>
                Your profile strength: <span>Weak</span>
              </p>
              <div className="progress-percent-div">
                <ProgressBar now={20} className="p-per" />
                <p>10%</p>
              </div>
              <div className="check-div">
                <div className="check-card">
                  <Icon
                    icon="icon-park-solid:check-one"
                    color="#5be045"
                    width="40"
                    height="40"
                    className="checkIcon"
                  />
                  <p>Verify phone number</p>
                </div>
                <div className="check-card">
                  <Icon
                    icon="icon-park-solid:check-one"
                    color="#5be045"
                    width="40"
                    height="40"
                  />
                  <p>Add city location</p>
                </div>
                <div className="check-card">
                  <Icon
                    icon="icon-park-solid:check-one"
                    color="#949494"
                    width="40"
                    height="40"
                  />
                  <p>Verify Email</p>
                </div>
                <div className="check-card">
                  <Icon
                    icon="icon-park-solid:check-one"
                    color="#949494"
                    width="40"
                    height="40"
                  />
                  <p>Verify indentity by submitting NIC.</p>
                </div>
                <div className="check-card">
                  <Icon
                    icon="icon-park-solid:check-one"
                    color="#949494"
                    width="40"
                    height="40"
                  />
                  <p>Add date of birth</p>
                </div>
                <div className="check-card">
                  <Icon
                    icon="icon-park-solid:check-one"
                    color="#949494"
                    width="40"
                    height="40"
                  />
                  <p>Add WhatsApp number</p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {showsupported && (
        <div className="profile-donations">
          <Icon
            icon="fluent-mdl2:navigate-back"
            color="#014e7f"
            width="50"
            height="50"
            onClick={handleBackClick}
            style={{ cursor: "pointer" }}
          />
          <h5 className="mt-3">Campaigns I’ve Supported</h5>
          <div className="toogle-div">
            <p>Unsubscribe from Monthly Donations</p>
            <ReactSwitch
              checked={checked}
              onChange={() => {
                handleModal();
                handleChange();
              }}
            />
          </div>
          <table>
            <tr>
              <th>Case(s)</th>
              <th>Amount</th>
              <th>Date</th>
            </tr>
            {caseItem.map((case_) => (
              <tr key={case_.id} className="td-div">
                <td>
                  {case_.name}
                  <button>Visit Case</button>
                </td>

                <td>{case_.amount}</td>
                <td>{case_.Date}</td>
              </tr>
            ))}
          </table>
        </div>
      )}
      {myCampaigns && (
        <div className="profile-donations">
          <Icon
            icon="fluent-mdl2:navigate-back"
            color="#014e7f"
            width="50"
            height="50"
            onClick={handleBackClick}
            style={{ cursor: "pointer" }}
          />
          <h5 className="mt-3">Support for My Campaigns</h5>
          <table className="mt-5">
            <tr>
              <th>Case(s)</th>
              <th>Amount</th>
              <th>Date</th>
            </tr>
            {allPatientCases?.map((case_) => (
              <tr key={case_?._id} className="td-div">
                <td>
                  {case_?.title}
                  <button>
                    <Link
                      to={`/cases/${case_?._id}`}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      Visit Case
                    </Link>
                  </button>
                </td>

                <td>{case_?.totalAmountCollected}</td>
                <td>{case_?.addedDB}</td>
              </tr>
            ))}
          </table>
        </div>
      )}
      {myfundraiser && (
        <div className="myfundraiser-div">
          <Icon
            icon="fluent-mdl2:navigate-back"
            color="#014e7f"
            width="50"
            height="50"
            style={{ cursor: "pointer" }}
            onClick={handleBackClick}
          />
          <div className="top- mt-3">
            <h5>Support for My Campaigns</h5>
            <Link to="/starFundarisingForm">
              <button>Add +</button>
            </Link>
          </div>
          <div className="d-flex justify-content-center gap-3 mt-5 pt-4">
            {allPatientCases?.map((item) => (
              <Col key={item._id} lg="6" md="6" sm="12" className=" mb-5">
                <div className="cases__item">
                  <img
                    src={item?.picturesLinks?.[0]}
                    alt=""
                    className="w-100"
                  />
                  <div className="cases__info p-3">
                    <Link to={`/cases/${item?._id}`} className="case__title">
                      {item.title}
                    </Link>
                    <div className="mt-4">
                      <div>
                        <p className="TotalAmt">
                          <h4 className="RemAmount px-1">
                            ${item?.totalAmountCollected}
                          </h4>
                          out of ${item?.goalAmount}
                        </p>
                      </div>
                      <div className="progress mx-1">
                        <div className="totalprogress-bar"></div>
                      </div>
                      <div>
                        <p className="LastDonationAmt px-1">
                          Last Donation:{" "}
                          <h4 className="Time">{item?.updatedIn}</h4>
                        </p>
                      </div>
                    </div>

                    <div className="case__time pt-3 mt-3 d-flex align-items-center justify-content-between">
                      <span className="uploadedonText">
                        <i class="ri-uploadedonText-line"></i> {item?.addedDB}
                      </span>

                      <div className=" d-flex align-items-center gap-3">
                        <span className=" d-flex align-items-center supporterText">
                          <i class="ri-supporterText-line"></i>{" "}
                          {item?.supporter}
                        </span>
                      </div>
                    </div>
                    <div className=" d-flex align-items-center justify-content-between">
                      <span>
                        <i
                          class="ri-uploadedondate-line"
                          className="uploadedondate"
                        ></i>{" "}
                        {item.date}
                      </span>

                      <div className=" d-flex align-items-center gap-3">
                        <span className=" d-flex align-items-center gap-1 ">
                          <i class="ri-TotalSupporters-line"></i>{" "}
                          {item.TotalSupporters}
                        </span>
                      </div>
                    </div>
                    <div className="bottom-btns d-flex gap-4 justify-content-center mt-3">
                      <Link to="/editdetailpage1" state={{ from: item?._id }}>
                        <button className="d-flex gap-2 align-items-center px-4">
                          <Icon
                            icon="clarity:edit-solid"
                            color="white"
                            width="35"
                            height="35"
                          />
                          Edit Details
                        </button>
                      </Link>
                      <button className="d-flex gap-2 align-items-center px-3">
                        <Icon
                          icon="iconamoon:trash-fill"
                          color="white"
                          width="35"
                          height="35"
                        />
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </div>
        </div>
      )}
      <Modal show={showModal} onHide={handleModal}>
        <div className="unsub-modal_Container">
          <Icon
            className="_closeIcon"
            icon="akar-icons:cross"
            color="#014e7f"
            width="22"
            height="22"
            onClick={handleModal}
          />
          <div className="modal-header">
            <h1>
              Want to Unsubscribe from Monthly Donations? We are grateful for
              your past support.
            </h1>
            <div className="input-email-div">
              <span>Email</span>
              <input type="email" className="input-Email" />
            </div>
            <button>Unsubscribe</button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default MyProfile;
