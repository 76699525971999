import React from 'react'
import Header from "../components/Header/header"
import BlogPageMainComponent from "../components/BlogpageComponents/BlogPageMainComp/BlogPageMainComp"
import Footer from "../components/Footer/footer"

const BlogPage = () => {
  return (
    <div>
      <Header />
      <BlogPageMainComponent />
      <Footer />
    </div>
  )
}

export default BlogPage