import React, { useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import StartFundraisingImg1 from "../../../assets/images/StartFundraisingImg1.png";
import StartFundraisingImg2 from "../../../assets/images/StartFundraisingImg2.png";
import ConfusedAbtFundraiserModal from "../ConfusedAbtFundraiserModal/ConfusedAbtFundraiserModal";
import Facebook from "../../../assets/images/FacebookIcon.png";
import Whatsapp from "../../../assets/images/WhatsappIcon.png";
import "./ReadyToStartSection.css";

const ReadyToStartSection = () => {
  const [showConfusedAbtFundModal, setshowConfusedAbtFundModal] =
    useState(false);
  const closeConfusedAbtFundModal = () => setshowConfusedAbtFundModal(false);
  return (
    <>
      <div className="ReadyToStartSection mt-5 mb-4">
        <Col
          lg={6}
          md={12}
          xs={12}
          sm={12}
          className="StartFundraisingSection pt-5"
        >
          <div className="d-flex flex-column justify-content-between h-100">
            <div className="mb-3 mx-3 pb-3">
              <h5 className="readytoStartHeading">
                Ready to start your own <br></br>campaign?
              </h5>
              <Link to="/starFundarisingForm">
                <Button className="readytoStartBtn mt-5 mb-4">
                  Start Campaigning
                </Button>
              </Link>
            </div>
            <div className="d-flex justify-content-end align-items-end">
              <img
                src={StartFundraisingImg1}
                alt="Campaign"
                className="StartFundraisingImg1 img-fluid position-absolute"
              />
            </div>
          </div>
        </Col>

        <Col
          lg={{ span: 5, offset: 1 }}
          md={12}
          xs={12}
          sm={12}
          className="CheckHereSection pt-5"
        >
          <div className="d-flex flex-column justify-content-center checkhereInner">
            <div className="text-center align-items-center">
              <h5 className="CheckHereHeading">
                Confused about how to start a campaign?
              </h5>
              <Button
                className="CheckHereBtn mt-5 mb-4"
                onClick={() => {
                  setshowConfusedAbtFundModal(true);
                }}
              >
                Check Here
              </Button>
              {showConfusedAbtFundModal && (
                <ConfusedAbtFundraiserModal
                  closeConfusedAbtFundModal={closeConfusedAbtFundModal}
                />
              )}
            </div>
            <div className="d-flex justify-content-end align-items-end">
              <img
                src={StartFundraisingImg2}
                alt="Campaign"
                className="StartFundraisingImg2 img-fluid "
              ></img>
            </div>
          </div>
        </Col>
      </div>

      <div className="ContactWithUSSection mt-5 mb-4">
        <Row className="ContactUsContent justify-content-center">
          <Col lg={8} md={12} sm={12} xs={12} className="mt-3">
            <p className="ContactusText">
              Have a question? Check out our{" "}
              <Link to={`/faqs`} className="FAQStxt">
                FAQs
              </Link>{" "}
              page or chat with us on Facebook or WhatsApp.
            </p>
          </Col>
          <Col lg={2} md={12} sm={12} xs={12} className="text-center">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://wa.me/+923323165701"
              className="WhatsAppbutton w-100"
            >
              <img src={Whatsapp} className="mx-1" alt="whatsapp_icon" />
              Contact Us
            </a>
          </Col>
          <Col lg={2} md={12} sm={12} xs={12} className="text-center">
            <a
              href="https://www.facebook.com/profile.php?id=100089498583736&mibextid=LQQJ4d"
              rel="noreferrer"
              target="_blank"
              className="Facebookbutton w-100"
            >
              <img src={Facebook} className="mx-1" alt="facebook_icon" />
              Visit our page
            </a>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ReadyToStartSection;
