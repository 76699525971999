import React from "react";
import "./WordsofSupportSection.css";
import { Row, Col } from "react-bootstrap";
import donatorsProfileimg from "../../../assets/images/donatorsProfileimg.png";

const WordsofSupportSection = () => {
  return (
    <>
      <Row className="DonatorsConatiner mt-1">
        <Row>
          <Col lg={2} md={2} xs={4}>
            <img src={donatorsProfileimg} alt="profile"></img>
          </Col>
          <Col lg={10} md={10} xs={8}>
            <Row>
              <h5 className="DonatorName">Anonymous</h5>
            </Row>
            <Row className="donationContainer">
              <p className="donationAmt">
                $50
                <p className="donationAmt mx-2">
                  <span className="customBullet mx-2"></span> Id
                </p>
              </p>
            </Row>
            <Row>
              <p className="comments">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras
                sed nc. Ut sodales iaculis urna. Nullam suscipit lacus lorem.
              </p>
            </Row>
          </Col>
        </Row>
        <Col lg={12} md={12} xs={12}>
          <hr className="hrLine mx-2 mt-1"></hr>
        </Col>
      </Row>
    </>
  );
};

export default WordsofSupportSection;
