import React from 'react';
import AboutBottomBanner from "../../assets/aboutusImages/about_bottom_banner.png";
import './AboutUs.css';

const AboutFooterBanner = () => {
  return (
    <div className='about_us_footer_banner'>
        <img src={AboutBottomBanner} alt='' />
    </div>
  )
}

export default AboutFooterBanner