import React, { useEffect } from "react";
import { Routes, Route, BrowserRouter, useLocation } from "react-router-dom";
import Landingpage from "../pages/landingpage";
import HowitWorksPage from "../pages/HowItWorksPage";
import SignInPage from "../pages/SignInPage";
import SignUpPage from "../pages/SignUpPage";
import PatientInfoSection from "../pages/PatientsInfoPage";
import SeeAllComments from "../pages/SeeAllComments";
import ViewAllUpdate from "../pages/ViewAllUpdates";
import AllCasesPage from "../pages/AllCasesPage";
import BlogPage from "../pages/BlogPage";
import BlogContent from "../pages/BlogContent";
import AllArticlesPage from "../pages/AllArticlePage";
import PopularArticlePage from "../pages/PopularArticlesPage";
import TrendingArticlePage from "../pages/TrendingArticlesPage";
import ContactUsPagePakistan from "../pages/ContactUsPage_Pakistan";
import ContactUsPageUSA from "../pages/ContactUsPage_USA";
import PricingPage from "../pages/PricingPage1";
import StartFundraisingForms from "../pages/multiStepStartFundraisingForms";
import RaiseConcern from "../pages/RaiseAConcernpage";
import Waitlist from "../pages/Waitlist";
import FundRaisingIdeasPage from "../pages/FundRaisingIdeasPage";
import PreviewPage from "../pages/PreviewPage";
import WaitlistPage from "../pages/WaitListPage";
import MonthlyDonation from "../pages/MonthlyDonation";
// import SideBar from "../components/Sidebar/SideBar";
import Editdetailpage1 from "../pages/Edit details page/Editdetailpage1";
import Detailpage3 from "../pages/Edit details page/Detailpage3/Detailpage3";
import Detailpage4 from "../pages/Edit details page/Detail page 4/Detailpage4";
import Detailpage5 from "../pages/Edit details page/Detail page 5/Detailpage5";
import Detailpage6 from "../pages/Edit details page/Detail page 6/Detailpage6";
import Detailpage7 from "../pages/Edit details page/Detail page 7/Detailpage7";
import Detailpage8 from "../pages/Edit details page/Detail page 8/Detailpage8";
import HelpCenter from "../pages/HelpCenter";
import Cases from "../pages/cases";
import PrivateRoute from "../components/privateroute/PrivateRoutes";
import JoinWaitlistModal from "../components/WaitListPageComponents/WaitlistModals/JoinWaitlistModal/JoinWaitlistModal";
import SuccessfulJoinModal from "../components/WaitListPageComponents/WaitlistModals/SuccessfulJoinModal/SuccessfulJoinModal";
import GigDescriptionPage from "../pages/GigDescriptionPage";
import GigRequirement from "../pages/GigRequirement";
import TrustSafety from "../pages/TrustSafety";
import FundraisingTips from "../pages/FundraisingTips";
import EditDetails_1 from "../pages/EditDetails1";
import WaitListPage from "../pages/WaitListPage";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsOfUse from "../pages/TermsOfUse";
import MissionVision from "../pages/OurMission/MissionVision";
import ForgetPassword from "../pages/Auth/ForgetPassword/ForgetPassword";
import RecoveryEmail from "../pages/Auth/RecoverPassword/RecoveryEmail";
import RecoveryPhone from "../pages/Auth/RecoverPassword/RecoveryPhone";
import VerifyAccount from "../pages/Auth/VerifyAccount/VerifyAccount";
import ChangePassword from "../pages/Auth/ChangePassword/ChangePassword";
import SuccessfulChange from "../pages/Auth/SuccessfulChange/SuccessfulChange";
import UserPSidebar from "../components/userProfileComponents/UserPSidebar/UserPSidebar";
import FAQs from "../pages/FAQs";
import Allcomments from "../pages/allcomments";
import ProtectedRoutes from "./ProtectedRoutes";
import FundraiserRoutes from "./FundraiserRoutes";
import DonerRoutes from "./DonerRoutes";
import Loader from "../components/Loader/Loader";
import AboutUs from "../pages/AboutUs";
// import START_FUNDRAISING_STEP1 from "../pages/StartFundraising_Step1"
// import START_FUNDRAISING_STEP2 from "../pages/StartFundraising_Step2"

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

const AllRoutes = () => {
  // Protected Routes
  // return (
  //   <>
  //     <BrowserRouter>
  //       <Routes>
  //         <Route
  //           exact
  //           path="/landingpages"
  //           element={<PrivateRoute Component={Landingpage}/>}
  //         />
  //         <Route
  //           exact
  //           path="/works"
  //           element={<PrivateRoute Component={HowitWorksPage} />}
  //         />
  //         <Route exact path="/signin" element={<SignInPage />} />
  //         <Route exact path="/signup" element={<SignUpPage />} />
  //         <Route
  //           exact
  //           path="/patientInfo"
  //           element={<PrivateRoute Component={PatientInfoSection} />}
  //         />
  //         <Route
  //           exact
  //           path="/allComments"
  //           element={<PrivateRoute Component={SeeAllComments} />}
  //         />
  //         <Route
  //           exact
  //           path="/viewAllUpdates"
  //           element={<PrivateRoute Component={ViewAllUpdate} />}
  //         />
  //         <Route
  //           exact
  //           path="/allCases"
  //           element={<PrivateRoute Component={AllCasesPage} />}
  //         />
  //         <Route
  //           exact
  //           path="/blogs"
  //           element={<PrivateRoute Component={BlogPage} />}
  //         />
  //
  //         <Route
  //           exact
  //           path="/allArticles"
  //           element={<PrivateRoute Component={AllArticlesPage} />}
  //         />

  //         <Route
  //           exact
  //           path="/previewPage"
  //           element={<PrivateRoute Component={PreviewPage} />}
  //         />
  //         <Route
  //           exact
  //           path="/fundraisingIdeas"
  //           element={<PrivateRoute Component={FundRaisingIdeasPage} />}
  //         />
  //         <Route
  //           exact
  //           path="/popularArticles"
  //           element={<PrivateRoute Component={PopularArticlePage} />}
  //         />
  //         <Route
  //           exact
  //           path="/trendingArticles"
  //           element={<PrivateRoute Component={TrendingArticlePage} />}
  //         />
  //         <Route
  //           exact
  //           path="/contactusPakistan"
  //           element={<PrivateRoute Component={ContactUsPagePakistan} />}
  //         />
  //         <Route
  //           exact
  //           path="/contactusUSA"
  //           element={<PrivateRoute Component={ContactUsPageUSA} />}
  //         />
  //         <Route
  //           exact
  //           path="/PricingPage"
  //           element={<PrivateRoute Component={PricingPage} />}
  //         />
  //         <Route
  //           exact
  //           path="/starFundarisingForm"
  //           element={<PrivateRoute Component={StartFundraisingForms} />}
  //         />
  // {/* <Route exact path="/editDetails1" element={<EditDetails_1 />} /> */}
  //         <Route exact path="/" element={<WaitlistPage />} />
  //         <Route exact path="/waitlist" element={<WaitlistPage />} />
  //         <Route exact path="/editdetailpage1" element={<PrivateRoute Component={Editdetailpage1} />} />           <Route exact path="/detailpage3" element={<PrivateRoute Component={Detailpage3} />} />
  //           <Route exact path="/detailpage4" element={<PrivateRoute Component={Detailpage4} />} />
  //         <Route exact path="/detailpage5" element={<PrivateRoute Component={Detailpage5} />} />
  //         <Route exact path="/detailpage6" element={<PrivateRoute Component={Detailpage6} />} />
  //         <Route exact path="/detailpage7" element={<PrivateRoute Component={Detailpage7} />} />
  //         <Route exact path="/detailpage8" element={<PrivateRoute Component={Detailpage8} />} />
  //         {/* <Route exact path="/waitlist" element={<PrivateRoute Component={WaitListPage} />} />
  // Protected Routes
  // return (
  //   <>
  //     <BrowserRouter>
  //       <Routes>
  //         <Route
  //           exact
  //           path="/landingpages"
  //           element={<PrivateRoute Component={Landingpage}/>}
  //         />
  //         <Route
  //           exact
  //           path="/works"
  //           element={<PrivateRoute Component={HowitWorksPage} />}
  //         />
  //         <Route exact path="/signin" element={<SignInPage />} />
  //         <Route exact path="/signup" element={<SignUpPage />} />
  //         <Route
  //           exact
  //           path="/patientInfo"
  //           element={<PrivateRoute Component={PatientInfoSection} />}
  //         />
  //         <Route
  //           exact
  //           path="/allComments"
  //           element={<PrivateRoute Component={SeeAllComments} />}
  //         />
  //         <Route
  //           exact
  //           path="/viewAllUpdates"
  //           element={<PrivateRoute Component={ViewAllUpdate} />}
  //         />
  //         <Route
  //           exact
  //           path="/allCases"
  //           element={<PrivateRoute Component={AllCasesPage} />}
  //         />
  //         <Route
  //           exact
  //           path="/blogs"
  //           element={<PrivateRoute Component={BlogPage} />}
  //         />
  //         <Route
  //           exact
  //           path="/blogContent"
  //           element={<PrivateRoute Component={BlogContent} />}
  //         />
  //         <Route
  //           exact
  //           path="/allArticles"
  //           element={<PrivateRoute Component={AllArticlesPage} />}
  //         />
  //         <Route
  //           exact
  //           path="/rasieConcern"
  //           element={<PrivateRoute Component={RaiseAConcernpage} />}
  //         />
  //         <Route
  //           exact
  //           path="/previewPage"
  //           element={<PrivateRoute Component={PreviewPage} />}
  //         />
  //         <Route
  //           exact
  //           path="/fundraisingIdeas"
  //           element={<PrivateRoute Component={FundRaisingIdeasPage} />}
  //         />
  //         <Route
  //           exact
  //           path="/popularArticles"
  //           element={<PrivateRoute Component={PopularArticlePage} />}
  //         />
  //         <Route
  //           exact
  //           path="/trendingArticles"
  //           element={<PrivateRoute Component={TrendingArticlePage} />}
  //         />
  //         <Route
  //           exact
  //           path="/contactusPakistan"
  //           element={<PrivateRoute Component={ContactUsPagePakistan} />}
  //         />
  //         <Route
  //           exact
  //           path="/contactusUSA"
  //           element={<PrivateRoute Component={ContactUsPageUSA} />}
  //         />
  //         <Route
  //           exact
  //           path="/PricingPage"
  //           element={<PrivateRoute Component={PricingPage} />}
  //         />
  //         <Route
  //           exact
  //           path="/starFundarisingForm"
  //           element={<PrivateRoute Component={StartFundraisingForms} />}
  //         />
  //         {/* <Route exact path="/editDetails1" element={<EditDetails_1 />} /> */}
  //         <Route exact path="/" element={<WaitlistPage />} />
  //         <Route exact path="/waitlist" element={<WaitlistPage />} />
  //         <Route exact path="/editdetailpage1" element={<PrivateRoute Component={Editdetailpage1} />} />           <Route exact path="/detailpage3" element={<PrivateRoute Component={Detailpage3} />} />
  //           <Route exact path="/detailpage4" element={<PrivateRoute Component={Detailpage4} />} />
  //         <Route exact path="/detailpage5" element={<PrivateRoute Component={Detailpage5} />} />
  //         <Route exact path="/detailpage6" element={<PrivateRoute Component={Detailpage6} />} />
  //         <Route exact path="/detailpage7" element={<PrivateRoute Component={Detailpage7} />} />
  //         <Route exact path="/detailpage8" element={<PrivateRoute Component={Detailpage8} />} />
  //         {/* <Route exact path="/waitlist" element={<PrivateRoute Component={WaitListPage} />} />

  //         {/* <Route exact path="/editdetailpage1" element={<Editdetailpage1 />} />
  //         <Route exact path="/detailpage3" element={<Detailpage3 />} />
  //         <Route exact path="/detailpage4" element={<Detailpage4 />} />
  //         <Route exact path="/detailpage5" element={<Detailpage5 />} />
  //         <Route exact path="/detailpage6" element={<Detailpage6 />} />
  //         <Route exact path="/detailpage7" element={<Detailpage7 />} />
  //         <Route exact path="/detailpage8" element={<Detailpage8 />} /> */}
  //         <Route exact path="/waitlists" element={<Waitlist />} />
  //         <Route
  //           exact
  //           path="/editdetailpage1"
  //           element={<PrivateRoute Component={Editdetailpage1} />}
  //         />
  //         {/* <Route exact path="/editdetailpage1" element={<Editdetailpage1 />} /> */}
  //         <Route
  //           exact
  //           path="/detailpage3"
  //           element={<PrivateRoute Component={Detailpage3} />}
  //         />
  //         {/* <Route exact path="/detailpage3" element={<Detailpage3 />} /> */}
  //         <Route
  //           exact
  //           path="/detailpage4"
  //           element={<PrivateRoute Component={Detailpage4} />}
  //         />
  //         <Route
  //           exact
  //           path="/detailpage5"
  //           element={<PrivateRoute Component={Detailpage5} />}
  //         />
  //         <Route
  //           exact
  //           path="/detailpage6"
  //           element={<PrivateRoute Component={Detailpage6} />}
  //         />
  //         <Route
  //           exact
  //           path="/detailpage7"
  //           element={<PrivateRoute Component={Detailpage7} />}
  //         />
  //         <Route
  //           exact
  //           path="/detailpage8"
  //           element={<PrivateRoute Component={Detailpage8} />}
  //         />
  //         {/* <Route exact path="/waitlist" element={<PrivateRoute Component={WaitListPage} />} /> */}

  //         {/* <Route exact path="/StartFundraisingStep1" element={<START_FUNDRAISING_STEP1 />} />
  //             </Routes>
  //       <Route exact path="/StartFundraisingStep2" element={<START_FUNDRAISING_STEP2 />} /> */}
  //         <Route
  //           exact
  //           path="/monthlydonation"
  //           element={<PrivateRoute Component={MonthlyDonation} />}
  //         />
  //         <Route exact path="/helpcenter" element={<HelpCenter />} />

  //       </Routes>
  //     </BrowserRouter>
  //   </>
  // );

  // Open-Routes
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />

        {/* <Routes>
          <Route exact path="/PrivateRoute" element={<PrivateRoute />} />
          <Route
            exact
            path="/joinWaitlistModal"
            element={<JoinWaitlistModal />}
          />
          <Route
            exact
            path="/successfulJoinModal"
            element={<SuccessfulJoinModal />}
          />
          <Route
            exact
            path="/gigDescriptionPage/:id"
            element={<GigDescriptionPage />}
          />
          <Route exact path="/gigRequirement" element={<GigRequirement />} />

          <Route exact path="/fundraisingTips" element={<FundraisingTips />} />
          <Route exact path="/editDetails_1" element={<EditDetails_1 />} />
          <Route exact path="/waitListPage" element={<WaitListPage />} />
        </Routes> */}
        <Routes>
          {/* <Route exact path="/landingpages" element={<Landingpage />} />
          <Route exact path="/works" element={<HowitWorksPage />} />
          <Route exact path="/about-us" element={<AboutUs />} />
          <Route exact path="/signin" element={<SignInPage />} />
          <Route exact path="/signup" element={<SignUpPage />} /> */}
          {/* <Route exact path="/fundraisingTips" element={<FundraisingTips />} /> */}
          {/* <Route exact path="/patientInfo" element={<PatientInfoSection />} />
          <Route exact path="/allComments/:id" element={<SeeAllComments />} />
          <Route exact path="/viewAllUpdates" element={<ViewAllUpdate />} />
          <Route exact path="/allCases" element={<AllCasesPage />} />
          <Route exact path="/blogs" element={<BlogPage />} />
          <Route exact path="/blogContent" element={<BlogContent />} />
          <Route exact path="/allArticles" element={<AllArticlesPage />} />
          <Route exact path="/rasieConcern" element={<RaiseConcern />} />
          <Route exact path="/previewPage" element={<PreviewPage />} />
          <Route exact path="/cases/:id" element={<Cases />} />
          <Route exact path="/faqs" element={<FAQs />} /> */}
          {/* //AUTH */}
          {/* <Route exact path="/forget-password" element={<ForgetPassword />} />
          <Route exact path="/recovery-email" element={<RecoveryEmail />} />
          <Route exact path="/recovery-phone" element={<RecoveryPhone />} />
          <Route exact path="/verify-account" element={<VerifyAccount />} />
          <Route exact path="/change-password" element={<ChangePassword />} />
          <Route
            exact
            path="/changed-succesfully"
            element={<SuccessfulChange />}
          />
          <Route exact path="/our-mission-vision" element={<MissionVision />} />
          <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route exact path="/termsOfUse" element={<TermsOfUse />} />
          <Route exact path="/previewPage" element={<PreviewPage />} />
          <Route exact path="/trustSafety" element={<TrustSafety />} />
          <Route exact path="/blogContent" element={<BlogContent />} />
          <Route
            exact
            path="/userProfile"
            element={
              <ProtectedRoutes>
                <UserPSidebar />
              </ProtectedRoutes>
            }
          />
          <Route exact path="/all-comments" element={<Allcomments />} />
          <Route
            exact
            path="/fundraisingIdeas"
            element={<FundRaisingIdeasPage />}
          />
          <Route
            exact
            path="/popularArticles"
            element={<PopularArticlePage />}
          />
          <Route
            exact
            path="/trendingArticles"
            element={<TrendingArticlePage />}
          />
          <Route
            exact
            path="/contactusPakistan"
            element={<ContactUsPagePakistan />}
          />
          <Route exact path="/contactusUSA" element={<ContactUsPageUSA />} />
          <Route exact path="/PricingPage" element={<PricingPage />} />
          .
          <Route
            exact
            path="/starFundarisingForm"
            element={
              <FundraiserRoutes>
                <StartFundraisingForms />
              </FundraiserRoutes>
            }
          /> */}
          {/* <Route exact path="/editDetails1" element={<EditDetails_1 />} /> */}
          {/* <Route exact path="/" element={<Landingpage />} /> */}
          <Route exact path="/" element={<WaitlistPage />} />
          {/* <Route exact path="/waitlists" element={<Waitlist />} /> */}
          {/* <Route
            exact
            path="/editdetailpage1"
            element={
              <FundraiserRoutes>
                <Editdetailpage1 />
              </FundraiserRoutes>
            }
          /> */}
          {/* <Route exact path="/editdetailpage1" element={<Editdetailpage1 />} /> */}
          {/* <Route
            exact
            path="/detailpage3"
            element={
              <FundraiserRoutes>
                <Detailpage3 />
              </FundraiserRoutes>
            }
          /> */}
          {/* <Route exact path="/detailpage3" element={<Detailpage3 />} /> */}
          {/* <Route
            exact
            path="/detailpage4"
            element={
              <FundraiserRoutes>
                <Detailpage4 />
              </FundraiserRoutes>
            }
          />
          <Route
            exact
            path="/detailpage5"
            element={
              <FundraiserRoutes>
                <Detailpage5 />
              </FundraiserRoutes>
            }
          />
          <Route
            exact
            path="/detailpage6"
            element={
              <FundraiserRoutes>
                <Detailpage6 />
              </FundraiserRoutes>
            }
          />
          <Route
            exact
            path="/detailpage7"
            element={
              <FundraiserRoutes>
                <Detailpage7 />
              </FundraiserRoutes>
            }
          />
          <Route
            exact
            path="/detailpage8"
            element={
              <FundraiserRoutes>
                <Detailpage8 />
              </FundraiserRoutes>
            }
          /> */}
          {/* <Route exact path="/waitlist" element={<PrivateRoute Component={WaitListPage} />} /> */}
          {/* <Route exact path="/StartFundraisingStep1" element={<START_FUNDRAISING_STEP1 />} />
                <Routes>
                    <Route
                        exact
                        path="/landingpages"
                        element={<Landingpage />}
                    />
                    <Route exact path="/works" element={<HowitWorksPage />} />
                    <Route exact path="/signin" element={<SignInPage />} />
                    <Route exact path="/signup" element={<SignUpPage />} />
                    <Route
                        exact
                        path="/patientInfo"
                        element={<PatientInfoSection />}
                    />
                    <Route
                        exact
                        path="/allComments"
                        element={<SeeAllComments />}
                    />
                    <Route
                        exact
                        path="/viewAllUpdates"
                        element={<ViewAllUpdate />}
                    />
                    <Route exact path="/allCases" element={<AllCasesPage />} />
                    <Route exact path="/blogs" element={<BlogPage />} />

                    <Route exact path="/privacyPolicy" element={<PrivacyPolicy />} />
                    <Route exact path="/termsOfUse" element={<TermsOfUse />} />

                    <Route
                        exact
                        path="/blogContent"
                        element={<BlogContent />}
                    />
                    <Route
                        exact
                        path="/allArticles"
                        element={<AllArticlesPage />}
                    />
                    <Route
                        exact
                        path="/rasieConcern"
                        element={<RaiseConcern />}
                    />
                    <Route
                        exact
                        path="/previewPage"
                        element={<PreviewPage />}
                    />{" "}
                    <Route
                        exact
                        path="/fundraisingIdeas"
                        element={<FundRaisingIdeasPage />}
                    />
                    <Route
                        exact
                        path="/popularArticles"
                        element={<PopularArticlePage />}
                    />
                    <Route
                        exact
                        path="/trendingArticles"
                        element={<TrendingArticlePage />}
                    />
                    <Route
                        exact
                        path="/contactusPakistan"
                        element={<ContactUsPagePakistan />}
                    />
                    <Route
                        exact
                        path="/contactusUSA"
                        element={<ContactUsPageUSA />}
                    />
                    <Route
                        exact
                        path="/PricingPage"
                        element={<PricingPage />}
                    />
                    .
                    <Route
                        exact
                        path="/starFundarisingForm"
                        element={<StartFundraisingForms />}
                    />
                    {/* <Route exact path="/editDetails1" element={<EditDetails_1 />} /> */}
          {/* <Route exact path="/" element={<Landingpage />} />
          <Route exact path="/waitlist" element={<WaitlistPage />} />
          <Route exact path="/waitlists" element={<Waitlist />} /> */}
          {/* <Route exact path="/editdetailpage1" element={<Editdetailpage1 />} /> */}
          {/* <Route exact path="/editdetailpage1" element={<Editdetailpage1 />} /> */}
          {/* <Route exact path="/detailpage3" element={<Detailpage3 />} /> */}
          {/* <Route exact path="/detailpage3" element={<Detailpage3 />} /> */}
          {/* <Route exact path="/detailpage4" element={<Detailpage4 />} />
          <Route exact path="/detailpage5" element={<Detailpage5 />} />
          <Route exact path="/detailpage6" element={<Detailpage6 />} />
          <Route exact path="/detailpage7" element={<Detailpage7 />} />
          <Route exact path="/detailpage8" element={<Detailpage8 />} /> */}
          {/* <Route exact path="/waitlist" element={<PrivateRoute Component={WaitListPage} />} /> */}
          {/* <Route exact path="/StartFundraisingStep1" element={<START_FUNDRAISING_STEP1 />} />
              </Routes>
        <Route exact path="/StartFundraisingStep2" element={<START_FUNDRAISING_STEP2 />} /> */}
          {/* <Route
            exact
            path="/monthlydonation/:id"
            element={
              <DonerRoutes>
                <MonthlyDonation />
              </DonerRoutes>
            }
          />
          <Route exact path="/helpcenter" element={<HelpCenter />} /> */}
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default AllRoutes;
