// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ArticleDateTxt, .ArticleAuthorNameTxt{
    color: #FFFFFF;
    font-size: 12px;
    font-family: 'Poppins';
    font-weight: 200;
    text-align: right;
}
.ArticleTitleLink{
    color: #FFFFFF !important;
    font-size: 18px !important;
    font-family: 'Poppins' !important;
    font-weight: 600 !important;
    text-decoration: none;
}
.profile-picture {
    width: 100px;
    height: 80px;
    border-radius: 50%; 
    overflow: hidden; 
}

.profile-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/components/BlogpageComponents/TrendingBlogsSection/TrendingBlogsSection.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;IACf,sBAAsB;IACtB,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,yBAAyB;IACzB,0BAA0B;IAC1B,iCAAiC;IACjC,2BAA2B;IAC3B,qBAAqB;AACzB;AACA;IACI,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":[".ArticleDateTxt, .ArticleAuthorNameTxt{\n    color: #FFFFFF;\n    font-size: 12px;\n    font-family: 'Poppins';\n    font-weight: 200;\n    text-align: right;\n}\n.ArticleTitleLink{\n    color: #FFFFFF !important;\n    font-size: 18px !important;\n    font-family: 'Poppins' !important;\n    font-weight: 600 !important;\n    text-decoration: none;\n}\n.profile-picture {\n    width: 100px;\n    height: 80px;\n    border-radius: 50%; \n    overflow: hidden; \n}\n\n.profile-img {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
