import React, {useState} from 'react'
import SideBar from '../../../components/Sidebar/SideBar'
// import '../editpages.css'
import SocialProfile from '../../../components/Forms/Social Profile/SocialProfile'
// import FundraiseDetails from '../../../components/Forms/Fundraise Form/FundraiseDetail'
// import MedicalDocument from '../../../components/Forms/Medical Document/MedicalDocument'


const Detailpage6 = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <>
       <div className="main ">
      <div className="">
        <SideBar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        </div>

    <div className=''>
      <SocialProfile isSidebarOpen={isSidebarOpen}/>
    </div>
     

      </div>
    </>
  )
}

export default Detailpage6
