import React from "react";
import FundIdeas from "../fundIdeas/fundIdeas";
import "./fundRaisingBanner.scss";

const FundRaisingBanner = () => {
  return (
    <>
      <div className="ideas-banner">
        <div className="banner-img">
          <h6>Campaigning Ideas</h6>
        </div>
        <div className="ideas">
          <FundIdeas />
        </div>
      </div>
    </>
  );
};

export default FundRaisingBanner;
