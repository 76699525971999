import React from "react";
import "./JoinWaitlistModal.css";
import crossicon from "../../../../assets/images/cross-icon.png";

function JoinWaitlistModal({ closeJoinWaitlistModal }) {
  return (
    <div className="overlay">
      {/* <div>
        <div className="container-1"></div>
      </div> */}
      <div className="model3container-2">
        <div className="model3main-line">
          <div className="model3outerContainer">
            <div className="model3rotateBox-3"></div>
            <div className="model3smallSquare-3"></div>
          </div>
          <div className="model3outerContainer">
            <div className="model3rotateBox-2"></div>
            <div className="model3smallSquare-2"></div>
          </div>
          <div className="model3outerContainer">
            <div className="model3rotateBox-1"></div>
            <div className="model3mallSquare-1"></div>
          </div>
          <div
            className="model3cross-icon"
            onClick={() => {
              closeJoinWaitlistModal();
            }}
          >
            <img src={crossicon} alt="check-icon.png" width={20} height={20} />
          </div>
        </div>
        <div className="model3container-3">
          <p className="model3para-1">Join the Waitlist</p>

          <input type="text" className="model3input-1 " placeholder="Name" />
          <input
            type="text"
            className="model3input-1 "
            placeholder="Email Address"
          />
          <input
            type="text"
            className="model3input-1 "
            placeholder="Phone Number"
          />
          <button className="model3button">Join Waitlist</button>
        </div>
      </div>
    </div>
  );
}

export default JoinWaitlistModal;
