import React, { useRef, useState } from "react";
import "./accountsetting.scss";
import { Icon } from "@iconify/react";
import userDP from "../../../../src/assets/images/user.svg";
import { updateUser } from "../../../services/http/updateUserApi";
import { cities } from "./Pakistan_Cities";
import Modal from "../UserPSidebar/confirmationModal/Modal";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const Accountsetting = ({ data, refetch }) => {
  const [showFooterModal, setshowFooterModal] = useState(false);
  const closeFooterModal = () => setshowFooterModal(false);

  const [editMode, setEditMode] = useState(false);
  const [firstName, setfirstName] = useState(data?.firstName);
  const [lastName, setlastName] = useState(data?.lastName);
  const [email, setEmail] = useState(data?.email);
  const [phoneNumber, setphoneNumber] = useState(data?.phoneNumber);
  const [countryCode, setCountryCode] = useState("");
  const [CNICNumber, setCNICNumber] = useState(data?.CNICNumber);
  const [address, setaddress] = useState("");
  const [postal, setpostal] = useState(data?.postal);
  const [city, setcity] = useState(data?.city);
  const [stateProvince, setstateProvince] = useState(data?.stateProvince);
  const [country, setcountry] = useState(data?.country);
  const [TaxId, setTaxId] = useState(data?.TaxId);
  const [profileImageLink, setprofileImageLink] = useState(null);
  const [imagesPreview, setImagesPreview] = useState(null);
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const handleImageChange = (e) => {
    setprofileImageLink(e.target.files[0]);
    const file = e.target.files[0];

    const Reader = new FileReader();
    Reader.readAsDataURL(file);

    Reader.onload = () => {
      if (Reader.readyState === 2) {
        setImagesPreview(Reader.result);
      }
    };
  };

  const changetoedit = () => {
    setEditMode(true);
  };

  const handleCancel = () => {
    setEditMode(false);
  };

  const userData = {
    firstName,
    lastName,
    phoneNumber: countryCode + phoneNumber,
    CNICNumber,
    address,
    postal: Number(postal),
    city,
    stateProvince,
    country,
    TaxId,
    profileImageLink,
  };

  return (
    <>
      <div></div>
      {editMode ? (
        <div className="editProfile-container">
          <h6>Edit Profile</h6>
          <div className="editprofile-info">
            <input
              disabled={loading}
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
            <div className="profile_image_btn">
              <img
                src={imagesPreview ? imagesPreview : data?.profileImageLink}
                alt="userDP"
                className="edit-dp"
              />
              <button onClick={handleClick}>Upload picture</button>
            </div>
            <h3>Personal Information</h3>
            <div className="editinput-div1 d-flex mt-3 gap-4">
              <div className="editInput1">
                <p>First Name</p>
                <input
                  disabled={loading}
                  type="text"
                  className="input-name"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setfirstName(e.target.value)}
                />
              </div>
              <div className="editInput2">
                <p>Last Name</p>
                <input
                  disabled={loading}
                  type="text"
                  className="input-name"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setlastName(e.target.value)}
                />
              </div>
            </div>
            <div className="editInput mt-3">
              <p>Email</p>
              <input
                disabled={loading}
                type="email"
                className="input-name"
                placeholder="useremail@example.com"
                value={email}
                readOnly
              />
            </div>
            <div>
              {/* <div className="editInput mt-3">
                <p>Country code</p>
                <select
                  disabled={loading}
                  className="input-name"
                  value={countryCode}
                  onChange={(e) => setCountryCode(e.target.value)}
                >
                  <option selected>Select</option>
                  {countries_code.map((item) => (
                    <>
                      <option selected={false}>{item?.code}</option>
                    </>
                  ))}
                </select>
              </div> */}
            </div>
            <div className="editInput mt-3">
              <p>Phone No.</p>
              <PhoneInput
                disabled={loading}
                type="text"
                defaultCountry="PK"
                className="input-name"
                placeholder="Enter your phone number"
                value={phoneNumber}
                onChange={setphoneNumber}
              />
            </div>
            <div className="editInput mt-3">
              <p>CNIC Number</p>
              <input
                disabled={loading}
                type="number"
                className="input-name"
                placeholder="01234567890"
                value={CNICNumber}
                onChange={(e) => setCNICNumber(e.target.value)}
              />
            </div>
            <h3 className="mt-5 pt-2">Address</h3>
            <div className="editInput mt-3">
              <p>Country</p>
              <input
                disabled={loading}
                type="text"
                className="input-name"
                placeholder="Country Name"
                value={country}
                onChange={(e) => setcountry(e.target.value)}
              />
            </div>
            <div className="editInput mt-3">
              <p>State</p>
              <input
                disabled={loading}
                type="text"
                className="input-name"
                placeholder="Sindh"
                value={stateProvince}
                onChange={(e) => setstateProvince(e.target.value)}
              />
            </div>
            <div className="editInput mt-3">
              <p>City</p>
              <select
                disabled={loading}
                className="input-name"
                value={city}
                onChange={(e) => setcity(e.target.value)}
              >
                {cities.map((item) => (
                  <>
                    <option>{item}</option>
                  </>
                ))}
              </select>
              {/* <input type="text" className="input-name" placeholder="Karachi" /> */}
            </div>
            <div className="editInput mt-3">
              <p>Postal Code</p>
              <input
                disabled={loading}
                type="number"
                className="input-name"
                placeholder="35677"
                value={postal}
                onChange={(e) => setpostal(e.target.value)}
              />
            </div>
            <div className="editInput mt-3">
              <p>Tax Id</p>
              <input
                disabled={loading}
                type="number"
                className="input-name"
                placeholder="id"
                value={TaxId}
                onChange={(e) => setTaxId(e.target.value)}
              />
            </div>
            <div className="btns-bottom">
              <button onClick={handleCancel}>Cancel</button>
              <button
                onClick={() => {
                  setshowFooterModal(true);
                }}
              >
                Save
              </button>
            </div>
            {showFooterModal && (
              <Modal
                closeFooterModal={closeFooterModal}
                loading={loading}
                setLoading={setLoading}
                userData={userData}
                setEditMode={setEditMode}
                refetch={refetch}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="account-setting-container">
          <div className="top-heading d-flex">
            <h6>My Profile</h6>
            <div className="edit-div d-flex gap-1" onClick={changetoedit}>
              <p>Edit</p>
              <Icon
                icon="iconamoon:edit"
                color="#4e4e4e"
                width="20"
                height="20"
              />
            </div>
          </div>
          <div className="profile-info">
            <div className="profile-picture-div d-flex">
              <img
                src={data?.profileImageLink}
                alt="userDP"
                className="setting-dp"
              />
              <div className="name-email">
                <h5>User Fullname</h5>
                <p>
                  {data?.firstName} {data?.lastName}
                </p>
              </div>
            </div>
            <h3>Personal Information</h3>
            <div className="input-div d-flex">
              <div>
                <p>First Name</p>
                <p>{data?.firstName}</p>
              </div>
              <div>
                <p>Last Name</p>
                <p>{data?.lastName}</p>
              </div>
            </div>
            <div className="input-div d-flex">
              <div>
                <p>Email</p>
                <p>{data?.email}</p>
              </div>
              <div>
                <p>Phone No.</p>
                <p>{data?.phoneNumber}</p>
              </div>
            </div>
            <div className="input-div d-flex">
              <div>
                <p>CNIC Number</p>
                <p>{data?.CNICNumber}</p>
              </div>
            </div>
            <h3 className="mt-5 pt-5">Address</h3>
            <div className="input-div d-flex">
              <div>
                <p>Country</p>
                <p>{data?.country}</p>
              </div>
              <div>
                <p>City/State</p>
                <p>{data?.city}</p>
              </div>
            </div>
            <div className="input-div d-flex">
              <div>
                <p>Postal Code</p>
                <p>{data?.postal}</p>
              </div>
              <div>
                <p>TaxId</p>
                <p>{data?.TaxId}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Accountsetting;
