import React from "react";
// import SignUp from "../components/SignUpPageComponent";
import Signup from "../components/signup/signup";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const SignUpPage = () => {
  const { user } = useSelector((state) => state.user);
  return (
    <div>
      {/* <SignUp /> */}
      {user?.data && <Navigate to="/landingpages" replace={true} />}
      <Signup />
    </div>
  );
};

export default SignUpPage;
