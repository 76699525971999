import React from 'react'
import Header from "../components/Header/header"
import AllCasesMainPageCom from "../components/AllCasesPageComponents/AllCasesMainPageComponents/AllCasesMainPageCom"
import Footer from "../components/Footer/footer"

const AllCasesPage = () => {
  return (
    <div>
      <Header />
      <AllCasesMainPageCom />
      <Footer />
    </div>
  )
}

export default AllCasesPage