import React, { useEffect } from 'react'
import './FooterModal.css';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FooterModal = ({ closeFooterModal }) => {

    useEffect(() => {
        document.body.style.overflowY = "hidden";
        return () => {
            document.body.style.overflowY = "scroll";
        };
    }, []);

    return (
        <>
            <div className='Modalwrapper'></div>
            <div className='Modal_Container_'>
                <FontAwesomeIcon icon={faTimes} className='closeIcon_' onClick={closeFooterModal} />
                <h4 className='modal_heading_'>Your Preference</h4>
                <p className='modal_para_ mt-2 mb-5'>Update Your language and country</p>
                <div className='mt-5'>
                    <button className='Language_Input_ px-3 py-1'>
                        Select a language
                        <select className="input_language">
                            <option value="Language" >English</option>
                            <option value="Language" >Urdu</option>
                        </select>
                    </button>
                </div>
                <div className='mt-4'>
                    <button className='Country_Input_ px-3 py-1'>
                    Select a Country
                        <select className="input_country">
                            <option value="Country" >Pakistan</option>
                            <option value="Country" >USA</option>
                        </select>
                    </button>
                </div>
                <div className='Btn_save_container mt-3'>
                    <button className='save_btn mt-5'>Save</button>
                </div>


            </div>
        </>
    )
}

export default FooterModal;