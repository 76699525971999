import React from "react";
import "../../styles/AboutSection.css";
import aboutImg from "../../assets/images/about pic.png";
import "bootstrap/dist/css/bootstrap.css";
import "@fontsource/poppins";

const AboutSection = () => {
  return (
    <section className="sec-about">
      <div className="container">
        <div className="about-box">
          <div className="sec-title">
            <h2>About Us</h2>
          </div>
          <div className="row align-items-center mb-5">
            <div className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4 col-xxl-4">
              <div className="img-box">
                <img src={aboutImg} alt="" className="img-fluid" />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-8 col-xxl-8">
              <div className="content-box">
                <h4>ShifaLia -- A Campaigning Platform</h4>
                <p className="para">
                  ShifaLia is a venture led by young Pakistani entrepreneurs
                  with the aim of creating a society where access to healthcare
                  isn't restricted by financial constraints. We firmly believe
                  that healthcare is a fundamental human right that should never
                  be compromised due to one's financial status. This belief
                  pushed us towards establishing a platform that empowers
                  individuals to launch their own medical campaigns in order to
                  reach their required financial targets. Our endeavor thrives
                  on the philanthropy and empathy of users who share our vision
                  and support such initiatives.
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-8 col-xxl-8">
              <div className="content-box">
                <h4>The ShifaLia Team</h4>
                <p className="para">
                  The ShifaLia Team consists of young Pakistani entrepreneurs
                  that hail from various academic backgrounds, all working in
                  sync to create a platform that best serves the community. We
                  envision a Pakistan where access to healthcare is not limited
                  by financial constraints.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4 col-xxl-4">
              <div className="img-box">
                <img src={aboutImg} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
