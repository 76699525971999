import React from "react";
import "../../styles/DonateNowSection.css";
import DonateNowbackimg from "../../assets/images/Image Carasoule.png";
import "bootstrap/dist/css/bootstrap.css";
import "@fontsource/poppins";
import { ArrowRight } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

const DonateNowSection = () => {
  return (
    <div className="DonateNowSection pt-5">
      <div className="top_content">
        <h3 className="section_heading">
          Building a Healthier Future through Shared Generosity
        </h3>
        <p className="section_subcontent">
          Help us in creating a Pakistan where access to healthcare is not
          limited by financial constraints. Donate to campaigns that have been
          verified for authenticity by our team and become a beacon for hope.
        </p>
      </div>
      <div className="donateNowBtn">
        <img
          id="donatenowbackImg"
          src={DonateNowbackimg}
          alt=""
          className="w-100"
        ></img>
        <Link to="/allCases">
          <div className="donatebtn">
            <h6 className="btn_heading">Support Now</h6>
            <div className="arrow_icon">
              <ArrowRight size={96} className="align-top" />
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};
export default DonateNowSection;
