import React from "react";
import Banner from "../../../assets/PreviewPageImages/banner-img.png";
import Patientstoryimg1 from "../../../assets/images/Patientstoryimg1.png";
import Patientstoryimg2 from "../../../assets/images/Patientstoryimg2.png";
import Patientstoryimg3 from "../../../assets/images/Patientstoryimg3.png";
import updates from "../../../assets/PreviewPageImages/undraw_updates.svg";
import { Icon } from "@iconify/react";
import "./story.scss";

const Story = () => {
  return (
    <div className="story-section">
      <div className="upload-video-div">
        <img src={Banner} alt="" />
        <div className="upload-video-div">
          <label htmlFor="file-upload" className="upload-div">
            Upload Video
            <input
              type="file"
              id="file-upload"
              accept="video/mp4,video/x-m4v,video/*"
            />
          </label>
        </div>
      </div>
      <div className="story-content">
        <h6>Story</h6>
        <div className="story-hr"></div>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sed
          neque in orci pharetra volutpat sit amet a nunc. Ut sodales iaculis
          urna. Nullam suscipit lacus lorem. Aliquam viverra maximus diam vitae
          faucibus. Mauris tristique ornare hendrerit. Etiam cursus faucibus
          elit at interdum. Proin quis urna vitae neque faucibus viverra rhoncus
          quis ipsum. Integer tortor sem, vehicula in fringilla vel,
          sollicitudin eu lorem. Curabitur ut aliquam nisi, in porttitor dolor.
          Aliquam sit amet tempus eros, at vulputate diam.<br></br> Sed luctus
          ante ipsum, sit amet facilisis velit fringilla non. Quisque non
          scelerisque nibh, vel commodo quam. Nullam aliquam lorem vel pulvinar
          volutpat. Praesent tincidunt, nulla quis viverra ornare, massa magna
          efficitur libero, in elementum ligula ex vitae sem. Donec quis justo
          justo. Vivamus venenatis leo nec feugiat luctus. Nulla tempor turpis
          ut metus tempus, id euismod purus lacinia. Phasellus ac augue tellus.
          Quisque ornare risus ac nulla porta, ac consequat nisl dignissim. Sed
          lacinia pellentesque lorem, et convallis justo gravida non. Ut finibus
          sed sapien eget sollicitudin. Phasellus faucibus metus ante, at
          imperdiet leo consequat vel. Nunc sodales enim efficitur velit
          scelerisque, non rutrum nisl dapibus. Aenean condimentum ipsum
          fermentum est aliquet ornare non quis ipsum. Aenean tincidunt ornare
          est, non lobortis libero. Donec tincidunt odio mi.
        </p>
        <div className="story-images">
          <img alt="" src={Patientstoryimg1}></img>
          <img alt="" src={Patientstoryimg2}></img>
          <img alt="" src={Patientstoryimg3}></img>
        </div>
        <div className="updates-content">
          <h6>Updates (0)</h6>
          <Icon
            icon="carbon:add-filled"
            color="#014e7f"
            width="48"
            height="48"
            className="addicon"
          />
        </div>
        <div className="story-hr"></div>
        <div className="recent-updates">
          <div className="recent-updates-inner">
            <img src={updates} alt="" />
            <p>You have no updates !</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Story;
