// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.aside-bar {
    width: 25%;
    background-color: black;
    

}
.mian-pro {
    background-color: blue;
    width: 75%;
    height: 100vh;
    margin-top: 130px;
   


}`, "",{"version":3,"sources":["webpack://./src/pages/Edit details page/editpages.css"],"names":[],"mappings":";AACA;IACI,UAAU;IACV,uBAAuB;;;AAG3B;AACA;IACI,sBAAsB;IACtB,UAAU;IACV,aAAa;IACb,iBAAiB;;;;AAIrB","sourcesContent":["\n.aside-bar {\n    width: 25%;\n    background-color: black;\n    \n\n}\n.mian-pro {\n    background-color: blue;\n    width: 75%;\n    height: 100vh;\n    margin-top: 130px;\n   \n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
