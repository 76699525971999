import React from 'react'
import Header from "../components/Header/header"
import AllArticlesPage from "../components/AllArticlePageComponents/AllArticlePage"
import Footer from "../components/Footer/footer"

const AllArticlePage = () => {
  return (
    <div>
      <Header />
      <AllArticlesPage />
      <Footer />
    </div>
  )
}

export default AllArticlePage