import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Toast from "../functions/Toast";

const DonerRoutes = ({ children }) => {
  const { user } = useSelector((state) => state.user);
  if (!user?.data) {
    return <Navigate to="/signin" replace={true} />;
  }
  if (user?.role !== "donar") {
    Toast.error("Only accessible to supporter");
    return <Navigate to="/" />;
  }
  return children;
};

export default DonerRoutes;
