import React, { useState } from "react";
import Payment from "../components/MonthlyDonation/Payment/Payment";
import PersonalInfo from "../components/MonthlyDonation/PersonalInfo/PersonalInfo";
import PeymentMethodes from "../components/MonthlyDonation/PaymentMethode/PaymentMethode";
import Confirmation from "../components/MonthlyDonation/Conformation/confirmation";

function MonthlyDonation() {
  const [steps, Setsteps] = useState(0);
  return (
    <>
      <div>
        {steps === 0 && <Payment Setsteps={Setsteps} />}
        {steps === 1 && <PersonalInfo Setsteps={Setsteps} />}
        {steps === 2 && <PeymentMethodes Setsteps={Setsteps} />}
        {steps === 3 && <Confirmation Setsteps={Setsteps} />}
      </div>
    </>
  );
}

export default MonthlyDonation;
