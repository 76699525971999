import React, { useState } from "react";
import "../Auth.css";
import { Container } from "react-bootstrap";
import Logo from "../../../assets/images/Logo.png";
import emailIcon from "../../../assets/ForgetPasswordImages/email_icon.png";
import phoneIcon from "../../../assets/ForgetPasswordImages/phone_icon.png";
import { useNavigate } from "react-router-dom";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState("");

  const verifyHandler = () => {
    if (tab === "email") {
      navigate("/recovery-email");
    } else if (tab === "phone") {
      navigate("/recovery-phone");
    }
  };
  return (
    <div className="forget_password">
      <Container>
        <a className="navbar-brand" href="/">
          <img src={Logo} alt="" className="img-fluid" />
          <p>ShifaLia</p>
        </a>

        <div className="forget_password_box">
          <h5>Forgot your password?</h5>

          <div
            className={
              tab === "email"
                ? "forget_inner_box make_border"
                : "forget_inner_box"
            }
            onClick={() => setTab("email")}
          >
            <img src={emailIcon} alt="" />
            <div className="recover_text">
              <b>Recover password via Email</b>
              <p>You’ll get an email with a link to reset password.</p>
            </div>
          </div>
          {/* <div className={tab === "phone" ? 'forget_inner_box make_border' : 'forget_inner_box'}
                        onClick={() => setTab("phone")}>
                        <img src={phoneIcon} alt='' />
                        <div>
                            <b>Recover password via Phone Number</b>
                            <p>You’ll get a code with a link to reset password.</p>
                        </div>
                    </div> */}

          <button onClick={verifyHandler}>Verify</button>
        </div>
      </Container>
    </div>
  );
};

export default ForgetPassword;
