import React from "react";
import Header from "../components/Header/header";
import TipBanner from "../components/FundraisingTipsComponents/TipsBanner/tipsbanner";
import TipsTOC from "../components/FundraisingTipsComponents/TipsTOC/toc";
import Startfund from "../components/FundraisingTipsComponents/StartFundraiser/startfund";
import Footer from "../components/Footer/footer";
const FundraisingTips = () => {
  return (
    <>
      <Header />
      <TipBanner />
      <TipsTOC />
      <Startfund />
      <Footer />
    </>
  );
};

export default FundraisingTips;
