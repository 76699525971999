import React, { useState } from "react";
import "./notifications.scss";
import { Icon } from "@iconify/react";
import profilepic1 from "../../../assets/images/profilePic.png";
import profilepic2 from "../../../assets/images/profilePic1.png";
import Comment from "../comment";

const Notifications = () => {
  const [activeTab, setActiveTab] = useState("All");
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  return (
    <>
      <div className="all-notications">
        <div className="notification-container">
          <h3>Notifications</h3>
          <div className="switch-tab d-flex ">
            <div className="left d-flex gap-4">
              <h6
                className={activeTab === "All" ? "active-tab" : ""}
                onClick={() => handleTabClick("All")}
              >
                All
              </h6>
              <h6
                className={activeTab === "Unread" ? "active-tab" : ""}
                onClick={() => handleTabClick("Unread")}
              >
                Unread
              </h6>
              <h6
                className={activeTab === "Archive" ? "active-tab" : ""}
                onClick={() => handleTabClick("Archive")}
              >
                Archive
              </h6>
              <h6
                className={activeTab === "Comments" ? "active-tab" : ""}
                onClick={() => handleTabClick("Comments")}
              >
                Comments
              </h6>
            </div>
            <div className="right d-flex">
              <h6>Mark all as read</h6>
            </div>
          </div>
          {activeTab === "All" && (
            <div className="all-cmts-div">
              <div className="cmt-div d-flex gap-3">
                <img src={profilepic1} alt="profilepic1" />
                <div className="txt-div d-flex flex-column justify-content-center align-item-center">
                  <h6>
                    User Fullname became a Supporter for your Campaign
                    BelievingInBenjamin
                  </h6>
                  <p>5 min ago</p>
                </div>
              </div>
              <Comment all="d-none" imgprofile={profilepic2} />
              <div className="cmt-div d-flex gap-3">
                <img src={profilepic1} alt="profilepic1" />
                <div className="txt-div d-flex flex-column justify-content-center align-item-center">
                  <h6>
                    User Fullname became a Supporter for your Campaign
                    BelievingInBenjamin
                  </h6>
                  <p>5 min ago</p>
                </div>
              </div>
              <Comment all="d-none" imgprofile={profilepic2} />
            </div>
          )}

          {activeTab === "Unread" && (
            <div className="unread-cmts-div">
              <Icon
                icon="system-uicons:cross-circle"
                color="#707070"
                width="140"
                height="140"
                className="dotsIcon"
              />
              <h5>You have no unread notifications!</h5>
            </div>
          )}
          {activeTab === "Archive" && (
            <div className="archive-cmts-div">
              <Icon
                icon="system-uicons:cross-circle"
                color="#707070"
                width="140"
                height="140"
                className="dotsIcon"
              />
              <h5>You have no Archived notifications!</h5>
            </div>
          )}
          {activeTab === "Comments" && (
            <div className="all-cmts-div">
              <Comment imgprofile={profilepic1} />
              <Comment imgprofile={profilepic2} />
              <Comment imgprofile={profilepic1} />
              <Comment imgprofile={profilepic2} />
            </div>
          )}
         
        </div>
      </div>
    </>
  );
};

export default Notifications;
