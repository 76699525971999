import React from "react";
import Header from "../components/Header/header";
import FundRaisingBanner from "../components/FundraisingIdeas/fundRaisingBanner/fundRaisingBanner";
import Startfund from "../components/FundraisingTipsComponents/StartFundraiser/startfund";
import Footer from "../components/Footer/footer";

const FundRaisingIdeasPage = () => {
  return (
    <>
      <Header />
      <FundRaisingBanner />
      <Startfund />
      <Footer />
    </>
  );
};

export default FundRaisingIdeasPage;
