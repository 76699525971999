import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Waitlist_contactTask from "../../../assets/WaitlistPageImages/waitlist_contacttask.png";
import Waitlist_location from "../../../assets/WaitlistPageImages/waitlist_Location.svg";
import Waitlist_Phone from "../../../assets/WaitlistPageImages/waitlist_Phone.svg";
import Waitlist_Email from "../../../assets/WaitlistPageImages/waitlist_Email.svg";
import "./WaitlistContactusSection.css";
import { USERQUERY_MUTATION } from "../../../services/methods/Mutations";
import { useMutation } from "@apollo/client";
import { isEmailValid } from "../../../services/helper/helper.js";
import { useApi } from "../../../services/mutation&Query/GraphqlApi";
import { ThreeDots } from "react-loader-spinner";
import Toast from "../../../functions/Toast";
import Waitlistmodal2 from "../../../components/Modals/WaitlistModals/Modal2/Waitlistmodal2";

const WaitlistContactusSection = () => {
  const [isValid, setIsValid] = useState(true);
  const [ModalDone, setModalDone] = useState(false);
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({
    name: "",
    email: "",
    message: "",
  });
  const { userQueryMutation } = useApi();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name === "email" && value.trim() === "");
    if (name === "email") {
      setIsValid(isEmailValid(value));
    }
    setForm({
      ...form,
      [name]: value,
    });
  };
  const [userQuery] = useMutation(USERQUERY_MUTATION);
  const handleSubscribe = () => {
    const inputData = {
      name: form.name,
      email: form.email,
      message: form.message,
    };
    if (form.email !== "" && form.message !== "" && form.name !== "") {
      setLoading(true);
      userQueryMutation(userQuery, inputData);
      setTimeout(() => {
        // Set loading back to false when the operation is complete
        setLoading(false);
      }, 2000);
      setModalDone(true);
    } else {
      Toast.error("Fill your query");
    }
  };
  const closeModal = () => {
    setModalDone(false);
  };
  return (
    <>
      <section className="waitlist-contact-section pt-4 pb-4" id="contact">
        <div
          className="contactSection_content   col-md-9 col-lg-9 col-xl-9 col-xxl-9 col-sm-12"
          data-aos="flip-right"
        >
          <div className="row align-items-center py-5">
            <div className="col-xxs-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <div>
                <h6>Get in Touch!</h6>
                <p className="getinTouchPara">
                  We are here for you! How can we help?
                </p>
              </div>
              <div>
                <div>
                  <input
                    type="text"
                    className="U_Name px-4 mt-4"
                    placeholder="Full Name"
                    name="name"
                    value={form.name}
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <input
                    type="email"
                    className="U_Email px-4 mt-4"
                    placeholder="Email"
                    name="email"
                    value={form.email}
                    onChange={handleInputChange}
                  />
                  {!isValid && (
                    <p style={{ color: "red" }}>
                      Please enter a valid Email Address.
                    </p>
                  )}
                </div>
                <div>
                  <textarea
                    className="U_Message px-4 mt-4"
                    rows={9}
                    name="message"
                    placeholder="Send a Message"
                    value={form.message}
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  {isValid && (
                    <Button
                      className="SendMsg mt-4 w-100"
                      onClick={handleSubscribe}
                      disabled={!isValid}
                    >
                      Send Message
                    </Button>
                  )}
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "50px",
                    }}
                  ></div>
                  {loading && (
                    <div
                      style={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: 9999, // Adjust the zIndex to ensure the spinner appears above other elements
                      }}
                    >
                      <ThreeDots
                        visible={true}
                        height="80"
                        width="80"
                        color="#4fa94d"
                        radius="9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                    </div>
                  )}
                  <Waitlistmodal2 isOpen={ModalDone} onClose={closeModal} />
                </div>
              </div>
            </div>

            <div className="col-xxs-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 Info_section">
              <div>
                <img
                  src={Waitlist_contactTask}
                  alt="Waitlist_contactTask"
                  className=" mt-2 Waitlist_contactTask w-100 h-auto"
                ></img>
              </div>
              <div className="info_for_contact my-5">
                <img src={Waitlist_location} alt="Waitlist_location"></img>
                <p className="location_info mt-3">
                  Unit#377, Block 20, Fb area karachi
                </p>
              </div>
              <div className="info_for_contact my-5">
                <img src={Waitlist_Phone} alt="Waitlist_Phone"></img>
                <p className="contact_info mt-3">+923152344269</p>
              </div>

              <div className="info_for_contact my-5">
                <img src={Waitlist_Email} alt="Waitlist_Email"></img>
                <p className="email_info mt-3">aliacareventures@gmail.com</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WaitlistContactusSection;
