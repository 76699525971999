import React from 'react';
import "./Banner.css";

const Banner = () => {
    return (
        <div className='terms_banner_main'>
            <h1>Terms of Use</h1>
        </div>
    )
}

export default Banner