import React, { useState } from "react";
import "./patientcard.scss";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import mainImg from "../../../assets/casesImgs/mainImg.png";
import mainImg1 from "../../../assets/casesImgs/1.png";
import mainImg2 from "../../../assets/casesImgs/2.png";
import mainImg3 from "../../../assets/casesImgs/3.png";
import ShareWindowModal from "../../PatientsInfoPageComponent/ShareWindowModal/ShareWindowModal";
import { Provider } from "../../../context/Context";
import { useSelector } from "react-redux";

const Patientcard = (props) => {
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);
  const { caseById } = Provider();
  const { user } = useSelector((state) => state.user);
  return (
    <>
      <div className="PatientCaseSection d-flex gap-4 justify-content-center">
        <div className="left-sec d-flex flex-column gap-3 h-100">
          <img
            src={caseById?.picturesLinks[0]}
            alt="mainImg"
            className="mainImg"
          />
          <div className={`bottom-imgs ${props.hide}`}>
            {caseById?.picturesLinks.length > 2 && (
              <>
                <img src={caseById?.picturesLinks[1]} alt="mainImg1" />
                <img src={caseById?.picturesLinks[2]} alt="mainImg2" />
                <img src={caseById?.picturesLinks[3]} alt="mainImg3" />
              </>
            )}
          </div>
        </div>
        <div className="right-sec">
          <div className="case-heading d-flex">
            <h5>{caseById?.title}</h5>
            {caseById?.status && (
              <span className={`${props.hide} ${props.hide1}`}>
                <Icon
                  icon="mdi:tick-circle"
                  color="#4ECB71"
                  width="24"
                  height="24"
                  className="ver-icon"
                />
                verified
              </span>
            )}
          </div>
          <p className={`${props.hide}`}>{caseById?.description}</p>
          {/* <p className={`${props.hide}`}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis
            pellentesque sem. Duis elementum luctus justo, quis posuere magna
            facilisis nec. Donec quis ipsum ut nunc vulputate fermentum at nec
            dolor. Praesent at nibh vitae tortor maximus volutpat. Morbi ut
            risus sed ante condimentum aliquam eget vitae est. Lorem ipsum dolor
            sit amet, consectetur adipiscing elit. Nullam quis pellentesque sem.
            Duis elementum luctus justo, quis posuere magna facilisis nec. Donec
            quis ipsum ut nunc vulputate fermentum at nec dolor. Praesent at
            nibh vitae tortor maximus volutpat. Morbi ut risus sed ante
            condimentum aliquam eget vitae est.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis
            pellentesque sem. Duis elementum luctus justo.{props.content}
          </p> */}
          <div className="case-bottom-btns d-flex gap-2">
            <Link to={`/monthlydonation/${caseById?._id}`} className="propBtn">
              {user?.role === "donar" && (
                <button className="propsBtnn">{props.btn2}</button>
              )}
            </Link>
            <Link className="propBtn">
              <button
                className={`${props.hide} ${props.hide1} propsBtnn`}
                onClick={() => {
                  setShowModal(true);
                }}
              >
                Share
              </button>
            </Link>

            {showModal && <ShareWindowModal closeModal={closeModal} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default Patientcard;
