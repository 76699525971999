import React from "react";
import KnowmoreabtCFpic1 from "../../../assets/images/KnowmoreabtCFpic1.png";
import KnowmoreabtCFpic2 from "../../../assets/images/KnowmoreabtCFpic2.png";
import KnowmoreabtCFpic3 from "../../../assets/images/KnowmoreabtCFpic3.png";
import VideoBg from "../../../assets/images/howitworks-video.png";
import VideoIcon from "../../../assets/images/howitworks-video-icon.png";
import "./knowmore.css";
import { Link } from "react-router-dom";

const KnowMore = () => {
  return (
    <>
      <section className="howitworks-banner">
        <div className="container">
          <div className="howitworks-banner-cnt text-center">
            <h4>How it works?</h4>
            <p>
              Here’s a quick guide on how our platform works and how you can
              start your own campaign.
            </p>
          </div>
        </div>
      </section>

      <section className="howitworks-video">
        <div className="container">
          <div className="howitworks-video-cnt">
            <h4 className="howitworks-heading">Watch the video</h4>
            <div className="howitworks-video-wrap">
              <img src={VideoBg} />
              <a href="javascript:;">
                <img src={VideoIcon} />
              </a>
            </div>
            <h4 className="howitworks-heading">Know more about Crowdfunding</h4>
          </div>
        </div>
      </section>

      <section className="howitworks-crowdfunding">
        <div className="container">
          <div className="howitworks-sec-heading text-center">
            <h2>What is Medical Campaigning on ShifaLia?</h2>
          </div>
        </div>
        <div className="howitworks-crowdfunding-inner-sec">
          <div className="howitworks-crowdfunding-inner-img-wrap">
            <div className="row align-items-center">
              <div className="col-md-4">
                <img src={KnowmoreabtCFpic1} />
              </div>
              <div className="col-md-4">
                <img src={KnowmoreabtCFpic2} />
              </div>
              <div className="col-md-4">
                <img src={KnowmoreabtCFpic3} />
              </div>
            </div>
          </div>
          <div className="container">
            <p>
              <span>Medical Campaigning</span> refers to the process of raising
              funds for healthcare expenses of individuals who are unable to pay
              it on their own. It is a collective effort by a society to come
              together and make a campaign successful so that access to
              healthcare is not restricted because of financial constraints.
              ShifaLia plays its role in medical campaigning by providing a
              platform for individuals to create their own campaigning
              campaigns. We also ensure that all the campaigns on our platform
              are verified thoroughly to ensure that supporters for these
              campaigns have a peace of mind while donating.
            </p>
          </div>
        </div>
      </section>

      <section className="howitworks-steps">
        <div className="container">
          <h4 className="howitworks-heading">
            Follow these steps to start today
          </h4>
          <div className="howitworks-steps-inner-wrap">
            <div className="howitworks-steps-inner-wrap-head">
              <ul>
                <li>
                  <h6>1</h6>
                </li>
                <li>
                  <h6>2</h6>
                </li>
                <li>
                  <h6>3</h6>
                </li>
              </ul>
            </div>
            <div className="howitworks-steps-inner-wrap-card">
              <div className="row">
                <div className="col-md-4">
                  <h5>Start with the basics</h5>
                  <p>Set a financial goal for your Campaigning campaign.</p>
                  <div className="howitworks-steps-inner-wrap-card-inner">
                    <p>Watch our guide on how to get started</p>
                    <div className="howitworks-steps-inner-wrap-card-inner-hover">
                      <a href="javascript:;">Watch video</a>
                      <a href="javascript:;">Watch video</a>
                    </div>
                  </div>
                  <Link
                    className="howitworks-show-hover"
                    to="/starFundarisingForm"
                  >
                    Start Campaigning
                  </Link>
                </div>
                <div className="col-md-4">
                  <h5>Create your Story</h5>
                  <p>Upload all the necessary documents.</p>
                  <div className="howitworks-steps-inner-wrap-card-inner">
                    <p>
                      Watch our guide on what documents are required for the
                      most amount of interaction fom the audience.
                    </p>
                    <div className="howitworks-steps-inner-wrap-card-inner-hover">
                      <a href="javascript:;">Watch video</a>
                      <a href="javascript:;">Watch video</a>
                    </div>
                  </div>
                  <Link
                    className="howitworks-show-hover"
                    to="/starFundarisingForm"
                  >
                    Start Campaigning
                  </Link>
                </div>
                <div className="col-md-4">
                  <h5>Share with friends</h5>
                  <p>
                    Share the word across social media platforms to reach a
                    wider audience.
                  </p>
                  <div className="howitworks-steps-inner-wrap-card-inner">
                    <p>
                      Watch our guide on how to share your story across
                      platforms.
                    </p>
                    <div className="howitworks-steps-inner-wrap-card-inner-hover">
                      <a href="javascript:;">Watch video</a>
                      <a href="javascript:;">Watch video</a>
                    </div>
                  </div>
                  <Link
                    className="howitworks-show-hover"
                    to="/starFundarisingForm"
                  >
                    Start Campaigning
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default KnowMore;
