import React, { useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import Accordion from "./Accordion";
import "./WaitlistFAQS.css";

const WaitlistFAQS = ({ faqData, hideelement }) => {
  const [data, setData] = useState(faqData);
  return (
    <>
      <section className="faqsContainer">
        <Container
          className={`d-flex pt-5 justify-content-center align-items-center ${hideelement} `}
        >
          <Row className="d-flex justify-content-center align-items-center">
            <h4 className="faqs_heading mt-5">FAQs</h4>
          </Row>
        </Container>

        <Container className="d-flex justify-content-center align-items-center">
          <Col
            lg={11}
            md={11}
            sm={12}
            xs={12}
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="mb-4"
          >
            <Col
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{
                marginBottom: "40px",
                marginTop: "40px",
                backgroundColor: "rgba(2, 98, 123, 0.16)",
                padding: "51px 72px",
                borderRadius: "10px",
              }}
              className="displayContent"
            >
              <Row
                className="d-flex justify-content-center"
                style={{
                  padding: "10px",
                }}
              >
                {faqData.faq.map((curElement) => {
                  const { id } = curElement;
                  return <Accordion key={id} {...curElement} />;
                })}
              </Row>
            </Col>
          </Col>
        </Container>
      </section>
    </>
  );
};

export default WaitlistFAQS;
