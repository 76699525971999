import styled, { keyframes } from 'styled-components';

export const TitleWrap = styled.div`
display:flex;
align-items:center;
${'' /* justify-content:center; */}
`
export const Line= styled.div`
width:3rem;
border:3px solid #457B6D;
margin-right:5px;
border-radius: 3px;
`

export const Heading= styled.h1`
font-family: 'Poppins', sans-serif;
font-weight: 600;
font-size: 24px;
line-height: 30px;
color: #457B6D;
margin:5px;

`