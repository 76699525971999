import React from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./AllCasesSection.css";
import UserDp from "../../../assets/images/UserDp.png";
import { Provider } from "../../../context/Context";

const AllCasesSection = ({ data }) => {
console.log(data?.items?.length);
console.log(data?.length);
  return (
    <>
      {data?.items?.length == undefined && (
        <h2 style={{ display: "flex", justifyContent: "center" }}>
          No data found
        </h2>
      )}
      {data?.items?.map((item) => (
        <Col key={item?._id} lg="4" md="6" sm="6" className=" mb-5">
          <Link to={`/cases/${item?._id}`} className="case__title">
            <div className="cases__item">
              <img src={item?.picturesLinks[0]} alt="" className="w-100 picLink" />
              <div className="cases__info p-3">
                {/* <Link to={`/patientInfo/${title}`} className="case__title"> */}
                {item?.title}
                <div className="user_profile mt-4">
                  <img src={UserDp} alt="userDp"></img>
                  <span className="case__author">
                    <i class="ri-user-line"></i> {item?.patientFullName}
                  </span>
                </div>
                <div className="mt-4">
                  <div>
                    <p className="TotalAmt">
                      <h4 className="RemAmount px-1">
                        {item?.totalAmountCollected}
                      </h4>
                      out of {item?.goalAmount}
                    </p>
                  </div>
                  <div className="progress mx-1">
                    <div className="totalprogress-bar"></div>
                  </div>
                  <div>
                    <p className="LastDonationAmt px-1">
                      Last Donation: <h4 className="Time">{item?.updatedIn}</h4>
                    </p>
                  </div>
                </div>

                <div className="case__time pt-3 mt-3 d-flex align-items-center justify-content-between">
                  <span className="uploadedonText d-flex flex-column">
                    Updated On <i class="ri-uploadedonText-line"></i>
                    {item?.addedDB}
                  </span>

                  <div className=" d-flex align-items-center gap-3">
                    <span className=" d-flex flex-column align-items-center supporterText">
                      Supporters
                      <i class="ri-supporterText-line"></i>
                      {item?.supporter}
                    </span>
                  </div>
                </div>
                <div className=" d-flex align-items-center justify-content-between">
                  <span>
                    <i
                      class="ri-uploadedondate-line"
                      className="uploadedondate"
                    ></i>{" "}
                    {item.date}
                  </span>

                  <div className=" d-flex align-items-center gap-3">
                    <span className=" d-flex align-items-center gap-1 ">
                      <i class="ri-TotalSupporters-line"></i>{" "}
                      {item.TotalSupporters}
                    </span>
                  </div>
                </div>
                <div className="bottom-btns"></div>
              </div>
            </div>
          </Link>
        </Col>
      ))}
    </>
  );
};
export default AllCasesSection;
